import { Button } from '@makedonski/admin-ui'
import { Popover } from '@varld/popover'
import { deleteNotificationsAnnexes, getData, getNotificationsAnnexes, setClientsFields, setModal, startLoading, sendNotificationsAnnexes, generateNotificationsAnnexes } from "actions"
import { Inputs, Shared } from "components"
import { notificationsGeneratedTableFields, notificationsStatusesMap, notificationsTypesFields } from "config/constants"
import { useQuery } from "hooks"
import { isEmpty } from 'lodash'
import moment from 'moment-timezone'
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getTextForConfirmationModal, mapClientsSearch, renderCell } from "utilities"
import "./styles.scss"

const availableSort = ['oldDeliveryStart', 'oldDeliveryEnd', 'oldPrice', 'oldPriceMarket', 'base', 'market', 'difference', 'deliveryStart', 'deliveryEnd', 'period', 'activationDate']
const keyMap = {
  "changes.annexNotificationOldData_oldDeliveryStart": "oldDeliveryStart",
  "changes.annexNotificationOldData_oldDeliveryEnd": "oldDeliveryEnd",
  "changes.annexNotificationOldData_oldPrice.base": "oldPrice",
  "changes.annexNotificationOldData_oldPrice.market": "oldPriceMarket",
  "changes.price.base": "base",
  "changes.price.market": "market",
  "changes.annexNotificationOldData_difference": "difference",
  "changes.contractSettings_deliveryStart": "deliveryStart",
  "changes.contractSettings_deliveryEnd": "deliveryEnd",
  "changes.annexNotificationOldData_period": "period",
}

const NotificationsGenerated = () => {
  const dispatch = useDispatch()
  const { type, sort: sortParam = '{}', filter: filterParam = '{}', handleUrlChangeMultiple } = useQuery()
  const { showCheckboxes, selectedCheckboxes } = useSelector(({ clients }) => clients)
  const { notifications, hasNextPage, nextPage, totalDocs } = useSelector(({ annex }) => annex)

  const { products } = useSelector(({ data }) => data)
  useEffect(() => { !products && dispatch(getData('products')) }, [])

  const availableFilters = useMemo(() => ({
    status: { type: 'idDropdown', values: Object.entries(notificationsStatusesMap).map(([key, value]) => ({ _id: key, name: value })), single: true },
    fullName: { type: 'regex' },
    eic: { type: 'regex' },
    mailPdf: { type: 'boolean' },
    emailDate: { type: 'boolean' },
    applied: { type: 'boolean' },
    oldProduct: { type: "idDropdown", values: products, single: true },
    newProduct: { type: "idDropdown", values: products, single: true },
    connectedGroup: { type: 'regex' },
    activationDate: { type: 'date' }
  }), [products, notificationsStatusesMap])

  const filter = useMemo(() => JSON.parse(filterParam), [filterParam])
  const sort = useMemo(() => JSON.parse(sortParam), [sortParam])
  const searchQuery = useMemo(() => ({ ...mapClientsSearch(sort, filter, undefined, availableFilters) }), [sortParam, filterParam])

  const fetchNotifications = useCallback((payload, forExport = false) => {
    dispatch(startLoading())
    dispatch(getNotificationsAnnexes({ payload: { tab: type, ...searchQuery, ...payload, }, forExport }))
  }, [type, searchQuery, dispatch])
  useEffect(() => { fetchNotifications() }, [fetchNotifications])

  const handleCancel = () => dispatch(deleteNotificationsAnnexes({
    payload: { ids: selectedCheckboxes },
    onSuccess: (deleted) => {
      dispatch(setClientsFields({ showCheckboxes: false, selectedCheckboxes: [] }))
      dispatch(setModal({
        isOpen: true,
        type: 'confirmation',
        props: {
          title: 'Анулиране на уведомления',
          children: getTextForConfirmationModal([{ text: "Анулирани", inner: `${deleted?.length} уведомления` }]),
          onClick: () => fetchNotifications({ page: 1 })
        }
      }))
    }
  }))

  const handleSend = () => {
    dispatch(startLoading())
    dispatch(sendNotificationsAnnexes({
      payload: { ids: selectedCheckboxes },
      onSuccess: () => {
        const onSuccess = () => {
          dispatch(setClientsFields({ showCheckboxes: false, selectedCheckboxes: [] }))
          fetchNotifications({ page: 1 })
        }
        dispatch(setModal({ isOpen: true, type: 'confirmation', props: { title: 'Успешно изпратени уведомления', onClick: onSuccess, onRequestClose: onSuccess } }))
      }
    }))
  }
  const handleGenerate = () => dispatch(setModal({
    isOpen: true, type: 'generateNotificationsAnnexes', props: {
      handleGenerate: (payload) => {
        dispatch(setModal({ isOpen: false }))
        dispatch(startLoading())
        dispatch(generateNotificationsAnnexes({
          payload: { ids: selectedCheckboxes, ...payload },
          onSuccess: () => {
            dispatch(setClientsFields({ showCheckboxes: false, selectedCheckboxes: [] }))
            fetchNotifications({ page: 1 })
          }
        }))
      }
    }
  }))

  const sortingComponents = notificationsGeneratedTableFields?.filter(({ sortable }) => sortable).reduce((acc, { value }) => {
    return {
      ...acc,
      [value]:
        <Popover popover={({ close }) => <Shared.SortingComponent
          hide={close}
          column={value}
          availableSort={availableSort}
          availableFilters={availableFilters}
          keyMap={keyMap}
        />}>
          <div className="icon icon-arrow-down" />
        </Popover>

    }
  }, {})

  return <div className="screen-notifications-container">
    <div className="screen-notifications-inner-container">
      <div className="screen-notifications-header row">
        <Inputs.RoundedButtons
          buttons={notificationsTypesFields}
          value={type}
          onChange={({ value }) => {
            dispatch(setClientsFields({ showCheckboxes: false, selectedCheckboxes: [] }))
            handleUrlChangeMultiple({ type: value, sort: undefined, filter: undefined })
          }}
        />
        <div className="row row-buttons">
          {/* <span>ЕИК/ЕГН:</span> */}
          {/* <Inputs.TextLabeled value={search} onChange={({ target: { value } }) => handleUrlChangeMultiple({ search: value })} /> */}
          {showCheckboxes ? <>
            <Button.Raised text='Откажи' className="btn-cancel" onClick={() => dispatch(setClientsFields({ showCheckboxes: false, selectedCheckboxes: [] }))} />
            {showCheckboxes.action === 'cancel' && <Button.Raised text='Анулирай' onClick={handleCancel} disabled={isEmpty(selectedCheckboxes)} />}
            {showCheckboxes.action === 'send' && <Button.Raised text='Изпарти' onClick={handleSend} disabled={isEmpty(selectedCheckboxes)} />}
            {showCheckboxes.action === 'generate' && <Button.Raised text='Генерирай ПДФ-и' onClick={handleGenerate} disabled={isEmpty(selectedCheckboxes)} />}
          </> :
            <>
              <Popover
                popover={({ close }) => (
                  <div className="popup-send-container">
                    <div className="row row-buttons">
                      <h4>Изберете действие</h4>
                      <Button.Icon name="plus" onClick={close} />
                    </div>
                    <p onClick={() => {
                      fetchNotifications({ pagination: false })
                      dispatch(setClientsFields({ showCheckboxes: { action: 'generate' } }))
                    }}>Генерирай ПДФ-и</p>
                    <p onClick={() => {
                      fetchNotifications({ pagination: false })
                      dispatch(setClientsFields({ showCheckboxes: { action: 'send' } }))
                    }}>Изпрати</p>
                    <p onClick={() => {
                      fetchNotifications({ pagination: false, }, true) // forExport -> second param 
                      close()
                    }}>Експорт</p>
                    <p onClick={() => {
                      handleUrlChangeMultiple({ search: null, searchBy: null, sort: null, filter: null, })
                      close()
                    }}>Изчисти всички филтри</p>
                  </div>
                )}
              >
                <Button.Raised className="btn-other-actions" text="Други действия" />
              </Popover>
              {type === 'notifications' && <Button.Raised text='Анулиране' onClick={() => {
                fetchNotifications({ pagination: false })
                dispatch(setClientsFields({ showCheckboxes: { action: 'cancel' } }))
              }} />}
            </>
          }
        </div>
      </div>
      <div className="screen-notifications-content">
        <Shared.Table
          columns={notificationsGeneratedTableFields}
          data={notifications}
          renderCell={(row, value, options) => renderCell.notifications?.notifications?.(row, value, { ...options, products })}
          handlePagination={() => { hasNextPage && fetchNotifications({ page: nextPage }) }}
          useCheckboxes={!!showCheckboxes}
          selectedCheckboxes={selectedCheckboxes}
          onCheckboxChange={(value) => dispatch(setClientsFields({ selectedCheckboxes: value }))}
          sortingComponent={sortingComponents}
          disabledCheckboxes={
            ['generate'].includes(showCheckboxes?.action) ? notifications?.filter(({ deletedAt }) => deletedAt).map(({ _id }) => _id)
              : ['send'].includes(showCheckboxes?.action) ? notifications?.filter(({ emailDate, mailPdf, deletedAt }) => emailDate || !mailPdf || deletedAt).map(({ _id }) => _id)
                : []
          }
        />
      </div>
      <div className="screen-notifications-footer row">
        <div className="screen-notifications-inner-footer row">
          <p className="row">Общо промени: <span>{totalDocs ?? <span className="inline-loader"></span>}</span></p>
          {showCheckboxes && <p className="row">Избрани: <span> {selectedCheckboxes.length}</span>
          </p>}
        </div>
      </div>
    </div >
  </div >
}

export default NotificationsGenerated