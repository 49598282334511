import React, { useState, useMemo, useEffect, useRef } from 'react'
import { Button } from '@makedonski/admin-ui'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { useQuery } from 'hooks'
import { setModal, createAnnex, checkUpcomingAnnexes } from 'actions'
import { Client, Shared } from 'components'
import { annexChangeColumns, annexChangeFields } from 'config/constants'
import { renderCell } from 'utilities'
import './styles.scss'
import moment from 'moment'

const annexTypeMap = { Annex: 'анекс', Resign: 'преподписване', Notification: 'известие', undefined: 'анекс' }

const AnnexCreate = ({ }) => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const { products, versions } = useSelector(({ data }) => data)
  const isResign = useMemo(() => pathname.includes('resign'), [pathname])
  const tabs = [
    { value: 'main', label: 'основна информация' },
    { value: isResign ? 'contract' : 'annex', label: `данни за ${isResign ? 'договор' : 'анекс'}` },
  ]
  const { tab, eic, handleUrlChangeMultiple } = useQuery({ tab: tabs })

  const [client, setClient] = useState()
  const [data, setData] = useState()
  const handleChange = (payload) => setData({ ...data, ...payload })

  const [showRequired, setShowRequired] = useState(false)

  const mainFormRef = useRef(null)
  useEffect(() => {
    if (eic) mainFormRef?.current?.startClientFetch(eic)
  }, [])

  const [hasUpcoming, setHasUpcoming] = useState([])
  useEffect(() => {
    if (client?._id) dispatch(checkUpcomingAnnexes({
      payload: { client: client._id },
      onSuccess: (res) => setHasUpcoming(res)
    }))
  }, [client])

  const props = { data, setData, showRequired, handleChange, client, setClient, editable: true }
  const renderContent = () => {
    switch (tab) {
      case 'main':
        return <Client.Forms.AnnexMain ref={mainFormRef} {...props} />
      case 'annex':
        return <Client.Forms.AnnexContract {...props} />
      case 'contract':
        return <Client.Forms.AnnexResignContract {...props} />
      default:
        return null
    }
  }

  const changes = useMemo(() => {
    return annexChangeFields
      .filter(({ value }) => Object.keys({ ...data, ...data?.price } || {}).includes(value))
      .map(({ value, label }) => {
        return {
          label,
          value,
          before: client?.price?.[value] || client?.contractSettings?.[value] || client?.invoiceSettings?.[value] || client?.[value] || null,
          after: data?.price?.[value] || data?.[value] || null,
        }
      })
  }, [data, client])

  const changesModal = {
    title: 'Обобщение',
    children: changes.length ? (
      <>
        <p style={{ textAlign: 'center' }}>Моля прегледайте и потвърдете нанесените промени</p>
        <Shared.Table
          fixedWidth
          columns={annexChangeColumns}
          data={changes}
          containerStyle={{ overflow: 'scroll' }}
          renderCell={(row, field) => renderCell.annexChange(row, field, { products, versions })}
        />
      </>
    ) : <p style={{ textAlign: 'center' }}>Няма нанесените промени</p>,
    buttons: (
      <div className="row">
        <Button.Raised
          className="cancel"
          text="Назад към добавянето"
          onClick={() => dispatch(setModal({ isOpen: false }))}
        />
        <div style={{ width: 20, height: '100%' }} />
        <Button.Raised
          text="Потвърди и продължи"
          onClick={() => dispatch(createAnnex({
            payload: {
              activationDate: data.deliveryStart,
              ...data,
              annexType: isResign ? "Resign" : "Annex"
            }
          }))} />
      </div>
    ),
  }

  const missingFileModal = {
    title: 'Липсва анекс',
    children: (
      <div style={{ textAlign: 'center' }}>
        Вие избрахте да продължите без добавянето на Документа. Моля потвърдете това действие
      </div>
    ),
    buttons: (
      <div className="row">
        <Button.Raised
          className="cancel"
          text="Назад към добавянето"
          onClick={() => dispatch(setModal({ isOpen: false }))}
        />
        <div style={{ width: 20, height: '100%' }} />
        <Button.Raised
          text="Потвърди и продължи"
          onClick={() => dispatch(setModal({ isOpen: true, type: 'confirmation', props: changesModal }))}
        />
      </div>
    ),
  }

  return (
    <div className="screen-annex-create-container">
      <div className="screen-annex-create-header row">
        <h2>{isResign ? 'Преподписване' : 'Добавяне на анекс'}</h2>
        {client?._id && <>
          <h3>ЕИК/ЕГН: <span>{client?.eic || client?.pin}</span></h3>
          <h3>Име на клиент: <span>{client?.fullName}</span></h3>
        </>
        }
        <div className="row row-buttons">
          <Button.Raised
            text="Откажи"
            className="btn-cancel"
            onClick={() => {
              setData(undefined)
              setClient(undefined)
              setHasUpcoming(undefined)
              handleUrlChangeMultiple({ tab: 'main' })
            }}
          />
          <Button.Raised
            text="Запази"
            disabled={!client || !data || tab === 'main'}
            onClick={() => {
              if (isResign ? !data.deliveryStart : !data.activationDate) {
                setShowRequired(true)
                return
              } else dispatch(setModal({ isOpen: true, type: 'confirmation', props: data?.file ? changesModal : missingFileModal }))
            }}
          />
        </div>
      </div>
      <div className="row row-main">
        <div className="screen-annex-create-navigation">
          {tabs?.map(({ value, label }, i) => (
            <div
              key={`tab-${i}`}
              className={`single-tab row ${tab === value && 'selected'}`}
              onClick={() => (!isEmpty(client) || tab !== 'main') && handleUrlChangeMultiple({ tab: value })}
            >
              {label}
              <div className={`icon icon-arrow-right${tab === value ? '-white' : ''}`} />
            </div>
          ))}
        </div>
        <div className="screen-annex-create-content">
          {!isEmpty(hasUpcoming) && <div className="upcoming-annexes">
            <h2>Клиентът има следните промени, които още не са влезли в сила.</h2>
            {hasUpcoming.map(({ _id, activationDate, createdAt, user, type }) => <div key={_id} className="upcoming-annexes-single row">
              <span>{annexTypeMap[type]}</span>
              <span>В сила от: {moment(activationDate).format('DD.MM.YYYY')}</span>
              <span>Добавен на: {moment(createdAt).format('DD.MM.YYYY')}</span>
              <span>Добавен от: {user?.fullName}</span>

            </div>)}
          </div>}
          {renderContent()}
        </div>
      </div>
    </div>
  )
}

export default AnnexCreate
