import { useQuery } from "hooks"
import React, { useMemo } from "react"
import "./styles.scss"

const Tutorials = ({ }) => {
  const tabs = [{
    label: 'Клиенти', value: 'clients', sections: [
      { label: 'Добавяне на нов клиент', value: 'newClient', url: 'https://retc.s3.eu-central-1.amazonaws.com/tutorials/new-client.mov' },
      { label: 'Добавяне на нов клиент - активен', value: 'newClientActive', url: 'https://retc.s3.eu-central-1.amazonaws.com/tutorials/new-client-active.mov' },
      { label: 'Добавяне на съществуващ клиент - активен', value: 'existingClientActive', url: 'https://retc.s3.eu-central-1.amazonaws.com/tutorials/existing-client-active.mov' },
      { label: 'Добавяне на съществуващ клиент - неактивен', value: 'existingClientInactive', url: 'https://retc.s3.eu-central-1.amazonaws.com/tutorials/existing-client-inactive.mov' },
      { label: 'Добавяне на обект', value: 'newObject', url: 'https://retc.s3.eu-central-1.amazonaws.com/tutorials/new-object.mov' },
    ]
  }, {
    label: 'Фактури', value: 'invoices', sections: [
      { label: 'Стъпка 1', value: 'step-1', url: 'https://retc.s3.eu-central-1.amazonaws.com/tutorials/1.mov' },
      { label: 'Стъпка 2', value: 'step-2', url: 'https://retc.s3.eu-central-1.amazonaws.com/tutorials/2.mov' },
      { label: 'Стъпка 3', value: 'step-3', url: 'https://retc.s3.eu-central-1.amazonaws.com/tutorials/3.mov' },
      { label: 'Стъпка 4', value: 'step-4', url: 'https://retc.s3.eu-central-1.amazonaws.com/tutorials/4.mov' },
      { label: 'Стъпка 5', value: 'step-5', url: 'https://retc.s3.eu-central-1.amazonaws.com/tutorials/5.mov' },
      { label: 'Стъпка 6', value: 'step-6', url: 'https://retc.s3.eu-central-1.amazonaws.com/tutorials/6.mov' },
    ]
  }, {
    label: 'Финанси', value: 'finance', sections: [
      { label: 'Лихви и депозити', value: 'interestsAndDeposits', url: 'https://synergon-energy.s3.eu-central-1.amazonaws.com/resources/%D0%9B%D0%B8%D1%85%D0%B2%D0%B8_%D0%94%D0%B5%D0%BF%D0%BE%D0%B7%D0%B8%D1%82%D0%B8.mov' },
      { label: 'Плащания по лихви', value: 'interestsPayments', url: 'https://synergon-energy.s3.eu-central-1.amazonaws.com/resources/%D0%9B%D0%B8%D1%85%D0%B2%D0%B8_%D0%BF%D0%BB%D0%B0%D1%89%D0%B0%D0%BD%D0%B8%D1%8F.mov' },
      { label: 'Задължения при входиране', value: 'obligationsRegistration', url: 'https://synergon-energy.s3.eu-central-1.amazonaws.com/resources/%D0%97%D0%B0%D0%B4%D1%8A%D0%BB%D0%B6%D0%B5%D0%BD%D0%B8%D1%8F_%D0%B2%D1%85%D0%BE%D0%B4%D0%B8%D1%80%D0%B0%D0%BD%D0%B5.mov' },
    ]
  }, {
    label: 'ВЕИ', value: 'vei', sections: [
      { label: 'Добавяне на производители', value: 'newProducer', url: 'https://dev-fidweb.s3.amazonaws.com/files/VWjQ6qZh2zi7heRXjcb_Y_VEI_1.mov' },
      { label: 'Настройки производители', value: 'producerSettings', url: 'https://dev-fidweb.s3.amazonaws.com/files/4fDSld2AU3padc3Z4T3Pj_VEI_2.mov' },
      { label: 'Обекти производители', value: 'producerObjects', url: 'https://dev-fidweb.s3.amazonaws.com/files/mNM9RotvEBga9TMPEM5ZW_VEI_3.mov' },
      { label: 'Справки производители', value: 'producerStats', url: 'https://synergon-energy.s3.eu-central-1.amazonaws.com/resources/%D1%81%D0%BF%D1%80%D0%B0%D0%B2%D0%BA%D0%B8_%D0%BF%D1%80%D0%BE%D0%B8%D0%B7%D0%BE%D0%B2%D0%B4%D0%B8%D1%82%D0%B5%D0%BB%D0%B8.mov' },
      { label: 'Презаписване на данни - производители', value: 'producersMO', url: 'https://synergon-energy.s3.eu-central-1.amazonaws.com/resources/%D0%BF%D1%80%D0%BE%D0%B8%D0%B7%D0%B2%D0%BE%D0%B4%D0%B8%D1%82%D0%B5%D0%BB%D0%B8_%D0%BF%D1%80%D0%B5%D0%B7%D0%B0%D0%BF%D0%B8%D1%81%D0%B2%D0%B0%D0%BD%D0%B5_%D0%B4%D0%B0%D0%BD%D0%BD%D0%B8.mov' },
    ]
  }
  ]
  // , {
  //   label: 'Справки', value: 'references', sections: [
  //     { label: 'Мерения ЕРП', value: 'erpMeasures', url: '' },
  //   ]
  // }

  const { tab } = useQuery()
  const { section, handleUrlChangeMultiple } = useQuery({ tab: tabs, sections: tabs.find(({ value }) => value === tab)?.sections })

  const content = useMemo(() => tabs?.find(({ value }) => value === tab)?.sections?.find(({ value }) => value === section), [tabs, tab, section])

  return <div className="screen-tutorials-container">
    < div className="screen-tutorials-inner-container row" >
      <div className="screen-tutorials-navigation">
        {tabs?.map(({ label, value, sections }) =>
          <React.Fragment key={`tab-${value}`}>
            <div
              className={`single-tab row ${value === tab && 'selected no-margin'}`}
              onClick={() => handleUrlChangeMultiple({ tab: value, section: null })}
            >
              {label}
              <div className={`icon icon-arrow-right${tab === value ? '-white' : ''}`} />
            </div>
            {value === tab && <div className="sections-container">
              {sections?.map(({ value, label }) => <div
                key={`section-${value}`}
                className={`sections-single-container ${section === value && 'selected'}`}
                onClick={() => handleUrlChangeMultiple({ section: value })}>
                {label}
              </div>)}
            </div>}
          </React.Fragment>
        )}
      </div>
      {content ? <div className="screen-tutorials-content">
        < h2 > {content?.label}</h2 >
        {content?.url && <video className="video" src={content?.url} controls />}
      </div > : <div className="screen-tutorials-content empty">
        <h2>Моля изберете тема!</h2>
      </div>}
    </div >
  </div >
}

export default Tutorials