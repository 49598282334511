import React, { useState } from 'react'
import { Button, Input } from '@makedonski/admin-ui'
import { Headers } from '@makedonski/socourt-utilities'
import { useDispatch } from 'react-redux'
import { setModal } from '../../../actions'
import { URL } from 'config/settings'
import './styles.scss'

const ConnectedClientGroup = ({ onCreate }) => {
  const dispatch = useDispatch()
  const [data, setData] = useState()
  const [disableButton, setDibsableBudtton] = useState(false)

  return (
    <div className="modal-connected-client-group-container">
      <div className="modal-connected-client-group-header row">
        <h2>Нова група свързани клиенти</h2>
        <Button.Icon
          name="plus"
          size="26px"
          className="btn-close"
          onClick={() => dispatch(setModal({ isOpen: false }))}
        />
      </div>
      <p>Моля дайте име на групата от свързани клиенти</p>
      <div className="modal-connected-client-group-content">
        <span>Име на група</span>
        <div className="row">
          <Input.Text value={data || ''} onChange={({ target: { value } }) => setData(value)} />
          <Button.Raised disabled={disableButton} className='generate' text='Генерирай' onClick={async () => {
            const { connectedClientsGroupNumber } = await fetch(`${URL}/connectedClientsGroupNumber/request`, {
              method: 'POST',
              headers: Headers.getWithAuth(),
              body: JSON.stringify({ "requestedFor": "ConnectedClientsGroup" }),
            }).then(res => res.json())
            setData(connectedClientsGroupNumber)
            setDibsableBudtton(true)
          }} />
        </div>
      </div>
      <div className="modal-connected-client-group-footer">
        <Button.Raised
          text="Запази"
          disabled={!data}
          onClick={() => {
            onCreate(data)
            dispatch(setModal({ isOpen: false }))
          }}
        />
      </div>
    </div>
  )
}

export default ConnectedClientGroup
