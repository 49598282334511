import { ofType, ActionsObservable } from 'redux-observable'
import { switchMap, catchError } from 'rxjs/operators'
import { Api, Alerts } from '@makedonski/socourt-utilities'
import { consumptionAnalysisTypes, stopLoading } from 'actions'
import { URL } from 'config/settings'

export const getConsumptionAnalysis = (action$) => action$.pipe(
    ofType(consumptionAnalysisTypes.GET_CONSUMPTION_ANALYSIS),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/consumption/analyze`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        )
    )
)
