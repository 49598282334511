import { isEmpty, isString, mapValues } from "lodash"
import moment from 'moment'

export const mapClientsSearch = (sort, filter, type, availableFilters) => {
  const payload = { type, sort: isEmpty(sort) ? undefined : mapValues(sort, (v => Number(v))) }
  if (!availableFilters) return payload
  Object.entries(filter).forEach(([key, value]) => {
    if (availableFilters?.[key]?.type === 'number') payload[key] = { ...(![undefined, null].includes(value?.min) && { $gte: Number(value?.min) }), ...(![undefined, null].includes(value?.max) && { $lte: Number(value?.max) }), }
    else if (availableFilters?.[key]?.type === 'date') payload[key] = { $gte: moment(value?.at(0)?.startDate).toDate(), $lte: moment(value?.at(0)?.endDate).endOf('day').toDate() }
    else if (availableFilters?.[key]?.auto) payload.otherParams = { ...payload.otherParams, [key]: isString(value) ? value.trim() : value }
    else if (availableFilters?.[key]?.type === 'priceRange') payload[key] = { ...(![undefined, null].includes(value?.min) && { $gte: Number(value?.min) }), ...(![undefined, null].includes(value?.max) && { $lte: Number(value?.max) }), }
    else payload[key] = isString(value) ? value.trim() : value
  })
  return payload
}