import { default as ClientsColumns } from './ClientsColumns'
import { default as NewTask } from './NewTask'
import { default as Task } from './Task'
import { default as Colleagues } from './Colleagues'
import { default as Profile } from './Profile'
import { default as ChangePassword } from './ChangePassword'
import { default as NewEmployee } from './NewEmployee'
import { default as InvoicesFileUpload } from './InvoicesFileUpload'
import { default as ConnectedClientGroup } from './ConnectedClientGroup'
import { default as ClientsStatus } from './ClientsStatus'
import { default as CreateClientsGroup } from './CreateClientsGroup'
import { default as ClientsProduct } from './ClientsProduct'
import { default as RegisterImportDocuments } from './RegisterImportDocuments'
import { default as Confirmation } from './Confirmation'
import { default as ExistingFileWarning } from './ExistingFileWarning'
import { default as ClientsLeaving } from './ClientsLeaving'
import { default as InvoiceGroups } from './InvoiceGroups'
import { default as ClientEditMOL } from './ClientEditMOL'
import { default as ObjectStatus } from './ObjectStatus'
import { default as IntermediateInvoicesPrice } from './IntermediateInvoicesPrice'
import { default as ExportAjur } from './ExportAjur'
import { default as ResponsibleForRegistration } from './ResponsibleForRegistration'
import { default as ExportCustoms } from './ExportCustoms'
import { default as ExportEasypay } from './ExportEasypay'
import { default as InvoiceComment } from './InvoiceComment'
import { default as EditCommissions } from './EditCommissions'
import { default as AddCommission } from './AddCommission'
import { default as GenerateInvoices } from './GenerateInvoices'
import { default as CompensationSettings } from './CompensationSettings'
import { default as PriceActiveFrom } from './PriceActiveFrom'
import { default as GenerateNotificationsAnnexes } from './GenerateNotificationsAnnexes'
import { default as RedistributeDealers } from './RedistributeDealers'
import { default as EmailStats } from './EmailStats'
import { default as ObligationsDate } from './ObligationsDate'
import { default as ProducersReferencesGenerate } from './ProducersReferencesGenerate'
import { default as ProducerSettingsFromObject } from './ProducerSettingsFromObject'
import { default as MergedObjects } from './MergedObjects'
import { default as UploadFiles } from './UploadFiles'
import { default as MassEditClientsPrice } from './MassEditClientsPrice'
import { default as MailerTestEmail } from './MailerTestEmail'
import { default as DeleteCommission } from './DeleteCommission'
import { default as InputText } from './InputText'
import { default as ClientsGroupsForm } from './ClientsGroupsForm'
import { default as InputDropdown } from './InputDropdown'
import { default as InputDate } from './InputDate'

export const Modals = {
  ClientsColumns,
  NewTask,
  Task,
  Colleagues,
  Profile,
  ChangePassword,
  NewEmployee,
  InvoicesFileUpload,
  ConnectedClientGroup,
  ClientsStatus,
  CreateClientsGroup,
  ClientsProduct,
  RegisterImportDocuments,
  Confirmation,
  ExistingFileWarning,
  ClientsLeaving,
  InvoiceGroups,
  ClientEditMOL,
  ObjectStatus,
  IntermediateInvoicesPrice,
  ExportAjur,
  ExportCustoms,
  ResponsibleForRegistration,
  ExportEasypay,
  InvoiceComment,
  EditCommissions,
  AddCommission,
  GenerateInvoices,
  CompensationSettings,
  PriceActiveFrom,
  GenerateNotificationsAnnexes,
  RedistributeDealers,
  EmailStats,
  ObligationsDate,
  ProducersReferencesGenerate,
  ProducerSettingsFromObject,
  MergedObjects,
  UploadFiles,
  MassEditClientsPrice,
  MailerTestEmail,
  DeleteCommission,
  InputText,
  ClientsGroupsForm,
  InputDropdown,
  InputDate
}

export { default } from './Modal'
