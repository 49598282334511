import React from 'react'
import { Button } from '@makedonski/admin-ui'
import { useDispatch } from 'react-redux'
import { setOverlay, createComment } from '../../../actions'
import { Inputs } from '../../'
import './styles.scss'

const Comments = (props) => {
  const { close, refType = 'Clients', obj = {}, type = 'default', onSuccess = () => { } } = props
  const dispatch = useDispatch()
  return (
    <div className="card-comments-container">
      <div className="card-comments-header row">
        <h3>Коментари</h3>
        <Button.Icon name="plus" size={28} onClick={close} />
      </div>
      <div className="card-comments-content">
        <Inputs.Comments
          comments={obj?.comments}
          handleSend={(text) => {
            if (!obj?._id) return
            dispatch(createComment({
              data: { refType, ref: obj._id, text, type },
              onSuccess: (comment) => {
                onSuccess(comment)
                dispatch(setOverlay({
                  isOpen: true,
                  type: 'comments',
                  props: { ...props, refType, obj: { ...obj, comments: [...(obj?.comments || []), comment] } },
                }))
              }
            }))
          }}
          handleDelete={(comment) => {
            onSuccess(comment)
            dispatch(setOverlay({
              isOpen: true,
              type: 'comments',
              props: { ...props, refType, obj: { ...obj, comments: obj?.comments?.filter(({ _id }) => _id !== comment._id) } },
            }))
          }}
        />
      </div>
    </div>
  )
}

export default Comments
