export const exportsTypes = {
    EXPORT_AJUR: 'exports/EXPORT_AJUR',
    EXPORT_AJUR_ALTERNATIVE: 'exports/EXPORT_AJUR_ALTERNATIVE',
    EXPORT_AJUR_COMPENSATIONS: 'exports/EXPORT_AJUR_COMPENSATIONS',
    EXPORT_AJUR_CONVERTOR: 'exports/EXPORT_AJUR_CONVERTOR',
    EXPORT_AJUR_NUMBERS: 'exports/EXPORT_AJUR_NUMBERS',
    EXPORT_CUSTOMS: 'exports/EXPORT_CUSTOMS',
    EXPORT_EASYPAY: 'exports/EXPORT_EASYPAY'
}

export const exportAjur = (payload) => ({
    type: exportsTypes.EXPORT_AJUR,
    ...payload
})

export const exportAjurAlternative = (payload) => ({
    type: exportsTypes.EXPORT_AJUR_ALTERNATIVE,
    ...payload
})

export const exportAjurCompensations = (payload) => ({
    type: exportsTypes.EXPORT_AJUR_COMPENSATIONS,
    ...payload
})

export const exportAjurConvertor = (payload) => ({
    type: exportsTypes.EXPORT_AJUR_CONVERTOR,
    ...payload
})

export const exportAjurNumbers = (payload) => ({
    type: exportsTypes.EXPORT_AJUR_NUMBERS,
    ...payload
})

export const exportCustoms = (payload) => ({
    type: exportsTypes.EXPORT_CUSTOMS,
    ...payload
})

export const exportEasypay = (payload) => ({
    type: exportsTypes.EXPORT_EASYPAY,
    ...payload
})