import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { getClient, setClientsField } from '../../actions'
import { Client } from '../../components'
import { useQuery } from '../../hooks'
import './styles.scss'

const docsTypes = {
  offer: { value: 1, label: 'Генериране на оферта' },
  analysis: { value: 2, label: 'Генериране на анализ' },
  contract: { value: 3, label: 'Генериране на договор' },
}

const ClientCreate = ({ }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { client } = useSelector((state) => state.clients)
  const { docs = '', client: clientProp } = useQuery()
  const isExistingClient = !!clientProp && clientProp !== 'new'
  useEffect(() => {
    if (isExistingClient) dispatch(getClient({ payload: clientProp }))
    return () => dispatch(setClientsField({ field: 'client', value: null }))
  }, [])
  const docsTabs = docs
    .split(',')
    ?.map((doc) => docsTypes[doc])
    ?.sort((a, b) => a.value - b.value)
    ?.map((type) => type?.label)
  const tabs = !clientProp
    ? ['Данни за клиента', 'Данни за обекта']
    : clientProp === 'new'
      ? ['Данни за клиента', 'Данни за обекта', ...docsTabs]
      : [...docsTabs]
  const [selectedTab, setSelectedTab] = useState(tabs[0])

  const isLastTab = useMemo(() => tabs.findIndex((tab) => tab === selectedTab) === tabs.length - 1, [selectedTab])
  const nextTab = useMemo(() => tabs.findIndex((tab) => tab === selectedTab) + 1, [selectedTab])

  const renderContent = () => {
    switch (selectedTab) {
      case 'Данни за клиента':
        return (
          <Client.Forms.Main
            handleSuccess={({ _id }) => {
              setSelectedTab(tabs[1])
              dispatch(getClient({ payload: _id }))
            }}
            data={isEmpty(client) ? null : client}
            editable={isEmpty(client)}
          />
        )
      case 'Данни за обекта':
        return (
          <Client.Forms.Objects
            isCreate
            client={client}
            ref={objectsRef}
            data={objectsRef?.current?.data}
            handleSuccess={docs ? () => setSelectedTab(tabs[nextTab]) : false}
          />
        )
      case 'Генериране на оферта':
        return (
          <Client.Forms.Offer
            client={client?._id}
            handleSuccess={() => {
              if (isLastTab) history.push(`${client?._id}`)
              else setSelectedTab(tabs[nextTab])
            }}
            ref={offerRef}
            data={offerRef?.current?.data}
            editable={isEmpty([])}
          />
        )
      case 'Генериране на анализ':
        return (
          <Client.Forms.Analysis
            handleSuccess={() => { }}
            ref={analysisRef}
            data={analysisRef?.current?.data}
            editable={isEmpty([])}
          />
        )
      case 'Генериране на договор':
        return (
          <Client.Forms.Contract
            client={client}
            handleSuccess={() => {
              if (isLastTab) history.push(`${client?._id}`)
              else setSelectedTab(tabs[nextTab])
            }}
            ref={contractRef}
            data={contractRef?.current?.data}
            editable={isEmpty([])}
          />
        )
      default:
        return null
    }
  }

  const objectsRef = useRef()
  const offerRef = useRef()
  const analysisRef = useRef()
  const contractRef = useRef()

  return (
    <div className="screen-client-create-container">
      {isExistingClient && (
        <div className="screen-client-create-header row">
          <h2>{client?.fullName}</h2>
          <p>
            ЕИК/ЕГН: <span>{client?.eic || client?.pin}</span>
          </p>
          <p>
            МОЛ: <span>{client?.mol?.map(({ fullName }) => fullName)?.join('; ')}</span>
          </p>
        </div>
      )}
      <div className="row">
        <div className="screen-client-create-navigation">
          {tabs?.map((tab, i) => (
            <div
              key={`tab-${i}`}
              className={`single-tab row ${tab === selectedTab && 'selected'}`}
              onClick={() => {
                if (!isEmpty(client) && !clientProp) setSelectedTab(tab)
              }}
            >
              {tab}
              <div className={`icon icon-arrow-right${tab === selectedTab ? '-white' : ''}`} />
            </div>
          ))}
        </div>
        <div className="screen-client-create-content col">{renderContent()}</div>
      </div>
    </div>
  )
}

export default ClientCreate
