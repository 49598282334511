import { useQuery } from "hooks"
import React from "react"
import { Inputs, ReferencesProducers as ReferencesProducersComp } from 'components'
import "./styles.scss"
import { referencesProducersTypes } from "config/constants"

const TypeSelector = () => {
    const { type, clear, handleUrlChangeMultiple } = useQuery()

    return <Inputs.Dropdown
        options={referencesProducersTypes}
        value={type}
        onChange={({ value }) => { clear(); handleUrlChangeMultiple({ type: value }) }}
    />
}

const ReferencesProducers = ({ }) => {
    const { type } = useQuery({ type: referencesProducersTypes })
    const props = { TypeSelector }


    const renderContent = () => {
        switch (type) {
            case 'references':
                return <ReferencesProducersComp.References {...props} />
            case 'invoices':
                return <ReferencesProducersComp.Invoices {...props} />
            case 'generated':
            default:
                return <div>
                    <div className="screen-references-producers-header row">
                        <TypeSelector />
                    </div>
                    <div className="screen-references-producers-content row">
                        <p className="empty">Изберете вида справка, която искате да визуализирате</p>
                    </div>
                </div>
        }
    }



    return <div className="screen-references-producers-container">
        {renderContent()}
    </div>
}

export default ReferencesProducers