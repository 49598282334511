import React, { useEffect, useState } from 'react'
import { Button } from '@makedonski/admin-ui'
import { useDispatch } from 'react-redux'
import { setOverlay, createComment } from '../../../actions'
import { Inputs } from '../../'
import './styles.scss'
import { sortBy } from 'lodash'
import { checkIsDealer } from 'utilities'

const ClientComments = ({ client }) => {
  const dispatch = useDispatch()
  const tabs = ['Всички', 'Администрация', 'Регистрация', 'Плащания', 'Други']
  const typesMap = {
    Всички: 'default',
    Администрация: 'administration',
    Регистрация: 'registration',
    Плащания: 'payments',
    Други: 'other',
  }
  const [selectedTab, setSelectedTab] = useState(tabs[0])

  const isDealer = checkIsDealer()
  useEffect(() => { if (isDealer) setSelectedTab(tabs[2]) }, [isDealer])

  return (
    <div className="card-client-comments-container">
      <div className="card-client-comments-header row">
        <h3>Коментари</h3>
        <Button.Icon name="plus" size={28} onClick={() => dispatch(setOverlay({ isOpen: false }))} />
      </div>
      <div className="card-client-comments-content">
        <Button.UnderlinedTab buttons={isDealer ? ['Регистрация',] : tabs} onChange={(tab) => setSelectedTab(tab)} />
        <Inputs.Comments
          disabled={isDealer}
          comments={sortBy([
            ...(client?.comments || []),
            ...(client?.objects?.reduce((acc, { itn, comments }) => [...acc, ...comments.map((c) => ({ ...c, text: `${itn} - ${c.text}`, type: 'registration' }))], []) || [])
          ], 'createdAt')?.filter(({ type }) => selectedTab === 'Всички' || type === typesMap[selectedTab])}
          handleSend={(text) => dispatch(createComment({
            data: { refType: 'Clients', ref: client?._id, text, type: typesMap[selectedTab] || 'default' },
            onSuccess: (comment) =>
              dispatch(setOverlay({
                isOpen: true,
                type: 'clientComments',
                props: { client: { ...client, comments: [...(client?.comments || []), comment] } },
              })),
          }))}
          handleDelete={(comment) => dispatch(setOverlay({
            isOpen: true,
            type: 'clientComments',
            props: { client: { ...client, comments: client?.comments?.filter(({ _id }) => _id !== comment._id) } },
          }))
          }
        />
      </div>
    </div>
  )
}

export default ClientComments
