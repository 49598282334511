import { Button, Input } from '@makedonski/admin-ui'
import Inputs from 'components/Inputs'
import { useQuery } from "hooks"
import { isEmpty, omit } from "lodash"
import moment from 'moment'
import React, { useMemo, useState } from "react"
import "./styles.scss"

const sortButtons = [{ label: 'Възходящи', value: '1' }, { label: 'Низходящи', value: '-1' }]

const SortingComponent = ({ column, hide, availableSort = [], availableFilters = [], keyMap = {}, sortProp = '{}', filterProp = '{}' }) => {
  const key = useMemo(() => Object.entries(keyMap).find((arr) => arr.includes(column))?.at(0) || column, [column])

  const { sort: sortParam = sortProp, filter: filterParam = filterProp, handleUrlChangeMultiple } = useQuery()
  const [sort, setSort] = useState(JSON.parse(sortParam)[key] || {})
  const [filter, setFilter] = useState(JSON.parse(filterParam) || {})
  const [query, setQuery] = useState('')

  const allowFilter = useMemo(() => Object.keys(availableFilters)?.map((key) => keyMap[key] || key)?.includes(column), [availableFilters])
  const allowSort = useMemo(() => availableSort?.map((key) => keyMap[key] || key)?.includes(column), [availableSort])

  const handleApply = () => {
    if (!isEmpty(filter)) handleUrlChangeMultiple({ filter: JSON.stringify(filter) })
    else handleUrlChangeMultiple({ filter: null })
    if (!isEmpty(sort)) handleUrlChangeMultiple({ sort: JSON.stringify({ [key]: sort }) })
    else if (isEmpty(omit(JSON.parse(sortParam), key))) handleUrlChangeMultiple({ sort: null })
  }

  const renderFilter = () => {
    const { type, single, min, max } = availableFilters[key]
    switch (type) {
      case 'regex':
        return <Input.Text
          placeholder="Search:"
          value={filter[key] || ''}
          onChange={({ target: { value } }) => setFilter({ ...filter, [key]: value })}
        />
      case 'date':
        return <Input.DatepickerRange
          selectionRange={filter[key]?.map(({ startDate, endDate, key } = {}) => ({ startDate: moment(startDate).toDate(), endDate: moment(endDate).toDate(), key }))}
          onChange={({ selection }) => setFilter({ ...filter, [key]: [selection] })}
        />
      case 'dropdown':
        return single ? <>
          <Input.Text value={query} onChange={({ target: { value } }) => setQuery(value)} />
          <Inputs.RadioButtons
            value={filter[key]}
            onClick={(value) => isEmpty(value) ? setFilter(omit(filter, key)) : setFilter({ ...filter, [key]: value })}
            buttons={availableFilters[key]?.values?.filter((option) => !query || option?.includes(query))}
            col
          />
        </> : <>
          <Input.Text value={query} onChange={({ target: { value } }) => setQuery(value)} />
          <Inputs.Checkboxes
            value={filter[key]}
            onClick={(value) => isEmpty(value) ? setFilter(omit(filter, key)) : setFilter({ ...filter, [key]: value })}
            buttons={availableFilters[key]?.values?.filter((option) => !query || option?.includes(query))}
            col
          />
        </>
      case 'idDropdown':
        return single ? <>
          <Input.Text value={query} onChange={({ target: { value } }) => setQuery(value)} />
          <Inputs.RadioButtons
            value={filter[key]}
            onClick={(value) => isEmpty(value) ? setFilter(omit(filter, key)) : setFilter({ ...filter, [key]: value })}
            buttons={availableFilters[key]?.values?.map(({ _id, name }) => ({ value: _id, label: name }))?.filter(({ label }) => !query || label?.includes(query))}
            col
          />
        </> : <>
          <Input.Text value={query} onChange={({ target: { value } }) => setQuery(value)} />
          <Inputs.Checkboxes
            value={filter[key]}
            onClick={(value) => isEmpty(value) ? setFilter(omit(filter, key)) : setFilter({ ...filter, [key]: value })}
            buttons={availableFilters[key]?.values?.map(({ _id, name }) => ({ value: _id, label: name }))?.filter(({ label }) => !query || label?.includes(query))}
            col
          />
        </>
      case 'boolean':
        return <Inputs.RadioButtons
          buttons={['Да', 'Не']}
          value={filter[key] === false ? 'Не' : filter[key] === true ? 'Да' : null}
          onClick={(value) => setFilter({ ...filter, [key]: value === 'Да' })}
        />
      case 'number':
      case 'priceRange':
        if (min > max) return null
        return <div className="row">
          <div className="col">
            <span>От:</span>
            <Input.Text
              placeholder={min}
              value={filter?.[key]?.min ?? ''}
              onChange={({ target: { value } }) => setFilter({ ...filter, [key]: { ...(filter?.[key]), min: value === '' ? undefined : value } })}
            />
          </div>
          <div style={{ width: 15 }} />
          <div className="col">
            <span>До:</span>
            <Input.Text
              placeholder={max}
              value={filter?.[key]?.max ?? ''}
              onChange={({ target: { value } }) => setFilter({ ...filter, [key]: { ...(filter?.[key]), max: value === '' ? undefined : value } })}
            />
          </div>
        </div>
      default:
        return null
    }
  }

  return <div className="shared-sorting-component-container">
    {allowSort && (
      <div className="sort">
        <h3>Пореди по</h3>
        <Inputs.RadioButtons col buttons={sortButtons} onClick={(data) => setSort(data)} value={sort} />
      </div>
    )}
    {allowFilter && (
      <div className="filter">
        <h3>Филтрирай по</h3>
        {renderFilter()}
      </div>
    )}
    {(allowSort || allowFilter) && (
      <div className="buttons row">
        <p
          className="btn-clear"
          onClick={() => {
            setFilter(omit(filter, key))
            setSort('')
            setQuery('')
          }}
        >
          Изчисти филтри
        </p>
        <Button.Raised
          text="Задай"
          onClick={() => {
            handleApply()
            hide()
          }}
        />
      </div>
    )}
  </div>
}

export default SortingComponent