export const importsTypes = {
  CHECK_IMPORTS_EXIST: 'imports/CHECK_IMPORTS_EXIST',
  CHECK_NETWORK_IMPORTS_EXIST: 'imports/CHECK_NETWORK_IMPORTS_EXIST',
  DELETE_IMPORT: 'import/DELETE_IMPORT',
  DELETE_NETWORK_IMPORT: 'import/DELETE_NETWORK_IMPORT',

  GET_OBJECTS_COUNT: 'imports/GET_OBJECTS_COUNT',

  IMPORT_MARKET_DATA: 'imports/IMPORT_MARKET_DATA',
  IMPORT_STP_DATA: 'imports/IMPORT_STP_DATA',

  IMPORT_LEAVING: 'imports/IMPORT_LEAVING',
  CONFIRM_LEAVING: 'imports/CONFIRM_LEAVING',

  IMPORT_DEPOSITS: 'imports/IMPORT_DEPOSITS',
  IMPORT_INTERESTS: 'imports/IMPORT_INTERESTS',

  IMPORT_INTERESTS_PAYMENTS_OLD: 'imports/IMPORT_INTERESTS_PAYMENTS_OLD',
  IMPORT_PRODUCERS_DATA: 'import/IMPORT_PRODUCERS_DATA',

  IMPORT_AJUR_NUMBERS: "import/IMPORT_AJUR_NUMBERS",
}

export const checkImportsExist = (payload) => ({
  type: importsTypes.CHECK_IMPORTS_EXIST,
  ...payload,
})

export const checkNetworkImportsExist = (payload) => ({
  type: importsTypes.CHECK_NETWORK_IMPORTS_EXIST,
  ...payload,
})

export const deleteImport = (payload) => ({
  type: importsTypes.DELETE_IMPORT,
  ...payload,
})

export const deleteNetworkImport = (payload) => ({
  type: importsTypes.DELETE_NETWORK_IMPORT,
  ...payload,
})

export const getObjectsCount = (payload) => ({
  type: importsTypes.GET_OBJECTS_COUNT,
  ...payload,
})

export const importMarketData = (payload) => ({
  type: importsTypes.IMPORT_MARKET_DATA,
  ...payload,
})

export const importSTPData = (payload) => ({
  type: importsTypes.IMPORT_STP_DATA,
  ...payload,
})

export const importLeaving = (payload) => ({
  type: importsTypes.IMPORT_LEAVING,
  ...payload,
})

export const confirmLeaving = (payload) => ({
  type: importsTypes.CONFIRM_LEAVING,
  ...payload,
})

export const importDeposits = (payload) => ({
  type: importsTypes.IMPORT_DEPOSITS,
  ...payload,
})

export const confirmInterests = (payload) => ({
  type: importsTypes.IMPORT_INTERESTS,
  ...payload,
})

export const confirmInterestsPayments = (payload) => ({
  type: importsTypes.IMPORT_INTERESTS_PAYMENTS_OLD,
  ...payload,
})

export const importProducersData = (payload) => ({
  type: importsTypes.IMPORT_PRODUCERS_DATA,
  ...payload
})

export const importAjurNumbers = (payload) => ({
  type: importsTypes.IMPORT_AJUR_NUMBERS,
  ...payload
})