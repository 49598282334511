import React, { useRef, useEffect, useMemo, useCallback } from 'react'
import { Popover } from '@varld/popover'
import { Button } from '@makedonski/admin-ui'
import moment from 'moment'
import { isEmpty } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getInvoicesFilters, setInvoicesFields, getInvoices, setModal, startLoading, exportAjur, exportCustoms, exportEasypay, exportAjurCompensations, exportInvoicesGeneratedInfo, exportAjurAlternative, stopLoading, exportAjurNumbers, exportAjurConvertor } from 'actions'
import { Inputs, Shared } from 'components'
import { SortingComponent } from './'
import {
  invoicesFields,
  invoicesEqualizingFields,
  invoicesTypesButtons,
  invoicesSearchOptions,
  clientsObjectsFields
} from 'config/constants'
import { useQuery, useCollapsable } from 'hooks'
import { renderCell, handleSendInvoice, mapClientsSearch, handleUploadedData, generateInvoiceInitial } from 'utilities'
import './styles.scss'
import { URL } from 'config/settings'
import { Alerts, Headers } from '@makedonski/socourt-utilities'

const Invoices = ({ }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const uploadRef = useRef(null)
  const {
    invoices,
    nextPage,
    hasNextPage,
    amountMax,
    amountMin,
    totalDocs,
    totalWithoutVat,
    showCheckboxes,
    selectedCheckboxes,
    availableFilters,
    availableSort,
  } = useSelector((state) => state.invoices)
  const { type } = useQuery({ type: invoicesTypesButtons })
  const {
    month: monthParam = moment().subtract(type === 'equalizing' ? 1 : 0, 'months').format('MM.YYYY'),
    searchBy = '',
    sort: sortParam = '{}',
    filter: filterParam = '{}',
    handleUrlChange,
    handleUrlChangeMultiple,
  } = useQuery()
  const month = useMemo(() => moment(monthParam, 'MM.YYYY').toDate(), [monthParam])

  const filter = useMemo(() => JSON.parse(filterParam), [filterParam])
  const sort = useMemo(() => JSON.parse(sortParam), [sortParam])

  const hasAvailableFilters = useMemo(() => !isEmpty(availableFilters), [availableFilters])
  const searchQuery = useMemo(() => {
    return { month, type, enableSortFor: availableSort, filters: availableFilters, ...mapClientsSearch(sort, filter, type, availableFilters) }
  }, [hasAvailableFilters, sortParam, filterParam, month, type,])

  const fetch = ({ getFilters, ...payload } = {}) => {
    dispatch(startLoading())
    if (!hasAvailableFilters && getFilters) dispatch(getInvoicesFilters())
    else dispatch(getInvoices({ page: 1, ...searchQuery, ...payload }))
  }

  useEffect(() => {
    fetch({ getFilters: true })
    return () => dispatch(setInvoicesFields({ invoices: null, showCheckboxes: false, selectedCheckboxes: [] }))
  }, [searchQuery])

  const collapsableRef = useRef()
  const [isExpanded, setExpanded] = useCollapsable(collapsableRef)
  // const collapsableRef_2 = useRef()
  // const [isExpanded_2, setExpanded_2] = useCollapsable(collapsableRef_2)

  // const handleExport = {
  //   ajur: () => dispatch(setModal({
  //     type: 'confirmation', isOpen: true, props: {
  //       title: "Генериране на документи - Ажур",
  //       children: '',
  //       buttons: <div className="row">
  //         <Button.Raised text='Избери фактури' onClick={() => {
  //           fetch({ pagination: false })
  //           dispatch(setInvoicesFields({ showCheckboxes: { action: 'ajur' } }))
  //           dispatch(setModal({ isOpen: false }))
  //         }} />
  //         <div style={{ width: 30 }} />
  //         <Button.Raised text='Избери период' onClick={() => dispatch(setModal({ type: "exportAjur", isOpen: true, props: { type, month } }))} />
  //       </div>
  //     }
  //   })),
  //   ajurCompensations: () => {
  //     dispatch(startLoading())
  //     dispatch(exportAjurCompensations({
  //       payload: { from: moment(month).startOf('month').toDate(), to: moment(month).endOf('month').toDate(), includes: true, "types": ["equalizing"], "field": "invoicePeriodStart" },
  //       // onSuccess: () => dispatch(setInvoicesFields({ showCheckboxes: false, selectedCheckboxes: [] }))
  //     }))
  //   },
  //   easypay: () => {
  //     if (type !== 'equalizing') dispatch(setModal({ type: "exportEasypay", isOpen: true, props: { type, month } }))
  //     else {
  //       dispatch(startLoading())
  //       dispatch(exportEasypay({ payload: { type, from: moment(month).startOf('month').toDate(), to: moment(month).endOf('month').toDate() } }))
  //     }
  //   }
  //   // customs: () => dispatch(setModal({
  //   //   type: 'confirmation', isOpen: true, props: {
  //   //     title: "Генериране на документи - Митници",
  //   //     children: '',
  //   //     buttons: <div className="row">
  //   //       <Button.Raised text='Избери фактури' onClick={() => {
  //   //         fetch({ pagination: false })
  //   //         dispatch(setInvoicesFields({ showCheckboxes: { action: 'customs' } }))
  //   //         dispatch(setModal({ isOpen: false }))
  //   //       }} />
  //   //       <div style={{ width: 30 }} />
  //   //       <Button.Raised text='Избери период' onClick={() => dispatch(setModal({ type: "exportCustoms", isOpen: true, props: { month } }))} />
  //   //     </div>
  //   //   }
  //   // }))
  // }
  const handleExport = {
    ajur: () => {
      fetch({ pagination: false })
      dispatch(setInvoicesFields({ showCheckboxes: { action: 'ajur' } }))
    },
    ajurAlternative: () => {
      dispatch(startLoading())
      dispatch(exportAjurAlternative({ payload: { from: moment(month).startOf('month').toDate(), to: moment(month).endOf('month').toDate(), includes: true, types: ["equalizing", "intermediate-10", "intermediate-15", "advance-10", "advance-15", "advance-30"], field: "invoicePeriodStart" }, }))
    },
    ajurCompensations: () => {
      dispatch(startLoading())
      dispatch(exportAjurCompensations({ payload: { from: moment(month).startOf('month').toDate(), to: moment(month).endOf('month').toDate(), includes: true, "types": ["equalizing"], "field": "invoicePeriodStart" }, }))
    },
    FSESCompensations: async () => await handleExportFSESCompensations(),
    customs: () => {
      fetch({ pagination: false })
      dispatch(setInvoicesFields({ showCheckboxes: { action: 'customs' } }))
    },
    easypay: () => {
      fetch({ pagination: false })
      dispatch(setInvoicesFields({ showCheckboxes: { action: 'easyPay' } }))
    },
    info: () => {
      dispatch(startLoading())
      dispatch(exportInvoicesGeneratedInfo({ payload: { from: moment(month).startOf("month").toDate(), to: moment(month).endOf("month").add(1, 'milliseconds').toDate(), }, }))
    },
  }

  const handleSendInvoiceInitial = () => handleSendInvoice({
    selected: selectedCheckboxes,
    onSuccess: () => {
      dispatch(setInvoicesFields({ showCheckboxes: false, selectedCheckboxes: [] }))
      dispatch(setModal({ isOpen: true, type: 'confirmation', props: { title: 'Успешно изпратени фактури ', onClick: fetch, onRequestClose: fetch } }))
    },
    dispatch,
  })
  const handleExportAjur = () => {
    dispatch(startLoading())
    dispatch(exportAjur({
      payload: { query: { _id: { $in: [...selectedCheckboxes] } } },
      onSuccess: () => dispatch(setInvoicesFields({ showCheckboxes: false, selectedCheckboxes: [] }))
    }))
  }

  const handleExportCustoms = () => {
    dispatch(startLoading())
    dispatch(exportCustoms({
      payload: { query: { _id: { $in: [...selectedCheckboxes] } } },
      onSuccess: () => dispatch(setInvoicesFields({ showCheckboxes: false, selectedCheckboxes: [] }))
    }))
  }

  const handleExportEasyPay = () => {
    dispatch(startLoading())
    dispatch(exportEasypay({
      payload: { query: { _id: { $in: [...selectedCheckboxes] } } },
      onSuccess: () => dispatch(setInvoicesFields({ showCheckboxes: false, selectedCheckboxes: [] }))
    }))
  }

  const handlePregenerate = () => generateInvoiceInitial({
    ids: selectedCheckboxes.map((_id) => {
      const invoice = invoices.find((invoice) => invoice._id === _id)
      return [invoice?.client?._id, invoice?.invoiceGroup?._id || 'notGrouped'].join(":")
    }),
    from: moment(month).startOf('month').toDate(),
    to: moment(month).endOf('month').add(1, 'days').startOf('month').toDate(),
    onSuccess: () => {
      dispatch(setInvoicesFields({ showCheckboxes: false, selectedCheckboxes: [] }))
      fetch()
    }
  })

  const handleExportFSESCompensations = async () => {
    try {
      dispatch(startLoading())
      const response = await window.fetch(`${URL}/ajur/generateCompensationsFSES`, {
        method: 'POST',
        headers: Headers.getWithAuth(),
        body: JSON.stringify({ from: moment(month).startOf('month').toDate(), to: moment(month).endOf('month').toDate(), includes: true, types: ["equalizing"], field: "invoicePeriodStart" }),
      })
      if (!response.ok) throw new Error('Грешка')
      const blob = await response.blob()
      const uril = window.URL.createObjectURL(blob)
      let link = document.createElement("a");
      link.href = uril;
      link.download = `export.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    catch (error) { Alerts.error(error?.message) }
    finally { dispatch(stopLoading()) }
  }

  const handleEasyPayConvertor = (e) => {
    const file = [...e.target.files][0]
    if (!file) return
    dispatch(startLoading())
    const reader = new FileReader()
    reader.onload = (e) => dispatch(exportAjurConvertor({ payload: { data: e.target.result } }))
    reader.readAsBinaryString(file)
  }

  const tableRef = useRef()
  const ObjectsTable = useCallback(({ row }) => {
    return (<Shared.Table
      columns={clientsObjectsFields.filter(({ includedIn }) => !includedIn)}
      data={row.original.objects}
      renderCell={renderCell.objects}
      containerStyle={{ maxHeight: 350 }}
      headerWidth={clientsObjectsFields.filter(({ includedIn }) => !includedIn).reduce((a, c) => a + (c.size || 300), 0)}
    />)
  }, [])


  const fields = {
    'equalizing': invoicesEqualizingFields,
    'notEqualizing': invoicesFields
  }

  const sortingComponents = fields[type]?.filter(({ sortable }) => sortable).reduce((acc, { value }) => {
    return {
      ...acc,
      [value]:
        <Popover popover={({ close }) => <SortingComponent hide={close} column={value} type={type} />}>
          <div className="icon icon-arrow-down" />
        </Popover>

    }
  }, {})

  return (
    <div className="screen-invoices-container">
      <div className="screen-invoices-header row">
        <Inputs.DatePicker
          customInput={<div className='row'>
            <h2 className="row">{moment(month).format('м. MMMM')}</h2>
            <div className="icon icon-calendar-custom" />
          </div>}
          className="month-picker"
          showMonthYearPicker
          showFullMonthYearPicker
          value={month}
          onChange={(date) => handleUrlChange('month', moment(date).format('MM.YYYY'))}
          minDate={moment().subtract(10, 'years').toDate()}
          maxDate={moment().add(1, 'months').endOf('month').toDate()}
        />
        <Inputs.RoundedButtons
          buttons={invoicesTypesButtons}
          value={type}
          onChange={({ value }) => handleUrlChangeMultiple({ type: value, searchBy })}
        />
        <Inputs.SearchMulti
          search={filter?.[searchBy] || ''}
          searchBy={searchBy || 'eic'}
          handleChange={({ search, searchBy: newSearchBy }) => {
            const newFilter = { ...filter, [searchBy]: undefined, [newSearchBy]: search || undefined }
            handleUrlChangeMultiple({
              searchBy: search ? newSearchBy : undefined,
              filter: Object.values(newFilter).filter(Boolean).length ? JSON.stringify(newFilter) : undefined
            })
          }}
          options={invoicesSearchOptions}
          popupText={{ secondary: ' ' }}
        />
        {/* <Button.Raised className="btn-other-filters" text="Други филтри" /> */}
        <div className="row row-buttons">
          {showCheckboxes ? (
            <Button.Raised
              className="btn-other-actions"
              text="Откажи"
              onClick={() => {
                tableRef.current.toggleAllRowsSelected(false)
                dispatch(setInvoicesFields({ showCheckboxes: false, selectedCheckboxes: [] }))
              }}
            />
          ) : (
            <Popover
              popover={({ close }) => (
                <div className="popup-send-container">
                  <div className="row">
                    <h4>Изберете действие</h4>
                    <Button.Icon name="plus" onClick={close} />
                  </div>
                  <p onClick={() => history.push('/financial/invoices/create')}>Ръчно създаване на фактура</p>
                  {type === "equalizing" && <>
                    <p onClick={() => uploadRef?.current?.click()}>Масова редакция</p>
                    <input
                      style={{ display: 'none' }}
                      type="file"
                      ref={uploadRef}
                      onChange={({ target: { files } }) => handleUploadedData({ files, dispatch, history, type: 'massEditInvoices' })}
                    />
                  </>}
                  <p onClick={() => {
                    fetch({ pagination: false })
                    dispatch(setInvoicesFields({ showCheckboxes: { action: 'send' } }))
                  }}>Изпрати</p>
                  <p className="disabled">Въвеждане на депозити</p>
                  <p className="disabled">Въвеждане на лихви</p>
                  <p className="disabled">Такса задължение на обществото</p>
                  <p className="disabled">Преглед прогрес</p>
                  <p onClick={() => setExpanded(!isExpanded)}>Експорти</p>
                  <div className="collapsable-exports" ref={collapsableRef}>
                    <p onClick={() => { fetch({ forExport: true }); close() }}>PDF-и</p>
                    {process.env.REACT_APP_AJUR_ALTERNATIVE
                      ? <>
                        <p onClick={() => { handleExport['ajurAlternative'](); close() }}>Счетоводен софтуер</p>
                        {type === "equalizing" && <p onClick={() => { handleExport['FSESCompensations'](); close() }}>Компенсации</p>}
                      </>
                      : <>
                        <p onClick={() => { handleExport['ajur'](); close() }}>Ажур</p>
                        {type === "equalizing" && <p onClick={() => { handleExport['ajurCompensations'](); close() }}>Ажур държавна помощ</p>}
                      </>}
                    {type === "equalizing" && <p onClick={() => { handleExport['customs'](); close() }}>Митници</p>}
                    <p onClick={() => { handleExport['easypay'](); close() }}>EasyPay</p>
                    {process.env.REACT_APP_AJUR_ALTERNATIVE && <Button.UploadButton text="EasyPay конвертор" accept={'.txt'} onChange={handleEasyPayConvertor} />}
                    {type === "equalizing" && <p onClick={() => { handleExport['info'](); close() }}>Инфо фактури</p>}
                  </div>
                  {/* <p onClick={() => setExpanded_2(!isExpanded_2)}>Номерация Ажур</p>
                  <div className="collapsable-exports" ref={collapsableRef_2}>
                    <p onClick={() => { handleExport['ajurNumbersExport'](); close() }}>Експорт</p>
                    <Button.UploadButton text="Импорт" accept={'.csv, .xlsx, .xls'} onChange={({ target: { files } }) => { handleUploadedData({ files, dispatch, history, type: 'ajurNumbers' }); close() }} />
                  </div> */}
                  <p onClick={() => {
                    fetch({ pagination: false })
                    dispatch(setInvoicesFields({ showCheckboxes: { action: 'pregenerate' } }))
                  }}>Прегенериране на фактури</p>
                  <p onClick={() => {
                    handleUrlChangeMultiple({ search: null, searchBy: null, sort: null, filter: null, })
                    close()
                  }}>Изчисти всички филтри</p>
                  <p onClick={() => tableRef.current.toggleAllRowsExpanded(false)}>Скрий всички обекти</p>
                </div>
              )}
            >
              <Button.Raised className="btn-other-actions" text="Други действия" />
            </Popover>
          )}
          <Button.Raised
            disabled={showCheckboxes?.action && isEmpty(selectedCheckboxes)}
            text={showCheckboxes?.action === 'send' ? 'Изпрати'
              : showCheckboxes?.action === 'pregenerate' ? 'Прегенерирай'
                : ['ajur', 'customs', 'easyPay'].includes(showCheckboxes?.action) ? "Генерирай"
                  : 'Импорт'}
            className={`${showCheckboxes?.action === 'send' ? 'btn-generate' : ''}`}
            onClick={() => {
              if (showCheckboxes?.action === 'send') handleSendInvoiceInitial()
              else if (showCheckboxes?.action === 'ajur') handleExportAjur()
              else if (showCheckboxes?.action === 'customs') handleExportCustoms()
              else if (showCheckboxes?.action === 'easyPay') handleExportEasyPay()
              else if (showCheckboxes?.action === 'pregenerate') handlePregenerate()
              else dispatch(setModal({ isOpen: true, type: 'invoicesFileUpload' }))
            }}
          />
        </div>
      </div>
      <div className="screen-invoices-content">
        <Shared.Table
          ref={tableRef}
          data={invoices}
          columns={fields[type]}
          renderCell={(invoice, field, options) => renderCell.invoices(invoice, field, { fetch, toggleRowExpanded: tableRef.current.toggleRowExpanded, isExpanded: options.isExpanded })}
          useCheckboxes={!!showCheckboxes}
          selectedCheckboxes={selectedCheckboxes}
          disabledCheckboxes={
            ['send'].includes(showCheckboxes?.action) ? invoices?.filter(({ invoiceSent, pdfFile, deletedAt }) => invoiceSent || !pdfFile || deletedAt).map(({ _id }) => _id) :
              ['pregenerate'].includes(showCheckboxes?.action) ? invoices?.filter(({ deletedAt }) => deletedAt).map(({ _id }) => _id) :
                []
          }
          onCheckboxChange={(value) => dispatch(setInvoicesFields({ selectedCheckboxes: value }))}
          sortingComponent={sortingComponents}
          handlePagination={() => hasNextPage && fetch({ page: nextPage, amountMin, amountMax, totalWithoutVat })}
          outlines={{ ...(invoices || []).reduce((acc, { _id, deletedAt }) => deletedAt ? ({ ...acc, [_id]: '#fa4444' }) : acc, {}) }}
        >
          {ObjectsTable}
        </Shared.Table>
      </div>
      <div className="screen-invoices-footer row">
        <div className="screen-invoices-inner-footer row">
          <p className="row">Общо: {totalDocs || 0} фактури</p>
          {showCheckboxes && <p className="row">Избрани: {selectedCheckboxes?.length || 0} фактури</p>}
          <p className="row">
            Сума без ДДС:{' '}
            {(totalWithoutVat || 0).toFixed(2)} лв.
          </p>
        </div>
      </div>
    </div >
  )
}

export default Invoices
