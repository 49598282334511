import React from "react"
import { useDispatch } from "react-redux"
import { updateInvoice, startLoading } from "actions"
import { Inputs } from "components"
import "./styles.scss"

const AdditionalCharges = ({ row, fetch }) => {
  const dispatch = useDispatch()

  return <Inputs.TextLabeledEdit
    value={row?.additionalCharges}
    onChange={(value) => {
      dispatch(startLoading())
      dispatch(updateInvoice({
        payload: { _id: row._id, additionalCharges: value, },
        onSuccess: fetch
      }))
    }}
    label="лв."
  />
}

export default AdditionalCharges