import S3FileUpload from "react-s3"
import { nanoid } from "nanoid"
import { s3Credentials } from "../config/settings"
import JSZip from 'jszip'
import * as Async from 'awaity'
import { chunk } from "lodash"
import { store } from "config/store"
import { setLoadingText } from "actions"

export const uploadFile = ({ file, location = '', createId } = {}) =>
  S3FileUpload.uploadFile(
    new File([file], createId ? [nanoid(), `${location}${file?.name}`,].join("_") : `${location}${file?.name}` || nanoid(), {
      type: file?.type || "",
      lastModified: file?.lastModified || new Date(),
      location
    }),
    s3Credentials
  )

export const uploadFiles = ({ files, location = '', createId } = {}) => {
  const promises = []
  Array.from(files).forEach((f) =>
    promises.push(
      S3FileUpload.uploadFile(
        new File([f], createId ? [nanoid(), `${location}${f?.name}`,].join("_") : `${location}${f?.name}` || nanoid(), {
          type: f?.type,
          lastModified: f?.lastModified || new Date(),
        }),
        s3Credentials
      )
    )
  )
  return Promise.all(promises)
}
const download = ({ url, name }) => fetch(url).then((res) => ({
  name: name || url.substring(url.lastIndexOf("/") + 1, url.length),
  blob: res.blob(),
}))
const downloadGroup = files => Promise.all(files.map(download))

export const exportBatchMeasures = async ({ files, filename = 'export', onSuccess = () => { }, type = '' }) => {
  const zip = new JSZip()
  const folder = zip.folder(filename)
  const chunks = chunk(files, 200)
  store.dispatch(setLoadingText(`Downloading ${200}/${chunks.length * 200}`))
  const blobs = await Async.reduce([...chunks], async (data, chunk, i) => {
    store.dispatch(setLoadingText(`Downloading ${(i + 1) * 200}/${files.length}`))
    return [...data, ...(await downloadGroup(chunk))]
  }, [])
  blobs.forEach(({ name, blob }) => {
    if (type === 'invoices' && process.env.REACT_APP_PLATFORM === 'Synergon') name = `${name.split("_").at(-2)}.pdf`
    return folder.file(name, blob)
  });
  zip
    .generateInternalStream({ type: "blob" })
    .accumulate()
    .then(blob => saveAs(blob, filename))
    .then(onSuccess)
    .catch(e => console.log(e));
  store.dispatch(setLoadingText(`Zipping`))
}