import { Button } from '@makedonski/admin-ui'
import { editCommissions } from 'actions'
import { Inputs } from "components"
import React, { useMemo, useState } from "react"
import { useDispatch } from 'react-redux'
import "./styles.scss"

const EditCommissions = ({ selectedCheckboxes, commissions, fetch, close }) => {
    const dispatch = useDispatch()
    const [commission, setCommission] = useState()

    const { min, max } = useMemo(() => {
        const selected = commissions.filter(({ _id }) => selectedCheckboxes.includes(_id)).map(({ commission }) => commission)
        return { min: Math.min(...selected), max: Math.max(...selected) }
    })

    return <div className="modal-edit-commissions-container">
        <div className="modal-edit-commissions-header row">
            <h2>Редакция на комисион</h2>
            <div className="icon icon-close" onClick={close} />
        </div>
        <div className="modal-edit-commissions-content row">
            <div className="col">
                <span>Стар комисион:</span>
                <p>{min} - {max} лв.</p>
            </div>
            <div className="col">
                <span>Нов комисион:</span>
                <Inputs.TextLabeled
                    label="лв."
                    value={commission}
                    onChange={({ target: { value } }) => setCommission(value)}
                />
            </div>
        </div>
        <div className="modal-edit-commissions-footer row">
            <Button.Raised text="Запази" disabled={!commission} onClick={() => dispatch(editCommissions({
                payload: { ids: selectedCheckboxes, commission },
                onSuccess: fetch
            }))} />
        </div>
    </div>
}

export default EditCommissions