import React, { useMemo } from 'react'
import { Line } from 'react-chartjs-2'
import moment from 'moment'
import { times } from 'lodash'
import { useQuery } from 'hooks'
import './styles.scss'

const Graph = ({ reference }) => {
  const { period, startDate } = useQuery()
  const data = useMemo(() => {
    const sums = reference?.reduce(
      (acc, cur) => ({
        ...acc,
        dailyPriceSum: (acc?.dailyPriceSum || 0) + (cur?.dailyPrice || 0),
        dailyVolumeSum: (acc?.dailyVolumeSum || 0) + (cur?.dailyVolume || 0),
        baseSum: (acc?.baseSum || 0) + (cur?.base || 0),
        peakSum: (acc?.peakSum || 0) + (cur?.peak || 0),
        offPeakSum: (acc?.offPeakSum || 0) + (cur?.offPeak || 0),
        data: [...(acc?.data || []), ...(cur?.data || [])],
      }),
      {}
    )
    return {
      dailyPriceAvg: sums?.dailyPriceSum / reference?.length,
      dailyVolumeAvg: sums?.dailyVolumeSum / reference?.length,
      baseAvg: sums?.baseSum / reference?.length,
      peakAvg: sums?.peakSum / reference?.length,
      offPeakAvg: sums?.offPeakSum / reference?.length,
      data: sums?.data,
    }
  }, [reference])

  const labels = useMemo(() => {
    if (period === 'day')
      return times(24).map(
        (i) =>
          `${moment(startDate).startOf('day').add(i, 'hours').format('HH')} - ${moment(startDate)
            .startOf('day')
            .add(i + 1, 'hours')
            .format('HH')}`
      )
    else if (period === 'week') return times(7).map((i) => moment(startDate).add(i, 'days').format('DD - dddd'))
    else if (period === 'month')
      return times(moment(startDate).daysInMonth()).map((i) => moment(startDate).add(i, 'days').format('DD'))
    else if (period === 'year') return times(12).map((i) => moment().month(i).format('MMM'))
  }, [period, startDate])

  const dataset = useMemo(() => {
    let temp = {}
    if (period === 'day') {
      temp.price = times(24).map((i) => reference?.[0]?.data?.[i]?.price)
      temp.volume = times(24).map((i) => reference?.[0]?.data?.[i]?.volume)
    } else if (period === 'week') {
      temp.price = times(7).map(
        (i) => reference.find(({ date }) => moment(date).isSame(moment(startDate).add(i, 'days'), 'day'))?.dailyPrice
      )
      temp.volume = times(7).map(
        (i) => reference.find(({ date }) => moment(date).isSame(moment(startDate).add(i, 'days'), 'day'))?.dailyVolume
      )
    } else if (period === 'month') {
      temp.price = times(moment(startDate).daysInMonth()).map(
        (i) => reference.find(({ date }) => moment(date).isSame(moment(startDate).add(i, 'days'), 'day'))?.dailyPrice
      )
      temp.volume = times(moment(startDate).daysInMonth()).map(
        (i) => reference.find(({ date }) => moment(date).isSame(moment(startDate).add(i, 'days'), 'day'))?.dailyVolume
      )
    } else if (period === 'year') {
      temp.price = times(12).map(
        (i) =>
          reference.find(({ date }) => moment(date).isSame(moment(startDate).add(i, 'months'), 'month'))?.dailyPrice
      )
      temp.volume = times(12).map(
        (i) =>
          reference.find(({ date }) => moment(date).isSame(moment(startDate).add(i, 'months'), 'month'))?.dailyVolume
      )
    }

    return temp
  }, [reference, period])

  return (
    <div className="references-markter-table-graph-container">
      <Line
        className="graph"
        height={400}
        data={{
          labels,
          datasets: [
            {
              label: 'Количество',
              type: 'line',
              backgroundColor: '#E87A1E',
              borderColor: '#E87A1E',
              data: dataset.volume,
              yAxisID: 'line',
            },
            {
              label: 'Цена',
              type: 'bar',
              backgroundColor: '#7CB4FF',
              data: dataset.price,
              yAxisID: 'bar',
            },
          ],
        }}
        options={{
          title: {
            text: 'Chart.js Bar Chart',
          },
          scales: {},
        }}
      />
      <div className="row row-cards">
        <div className="card">
          <span>БАЗА (1-24)</span>
          <div className="row"></div>
        </div>
        <div className="card">
          <span>ПИК (9-20)</span>
          <div className="row">
            <span>{Number(data?.peakAvg).toFixed(3)} лв/МВтч</span>
          </div>
        </div>
        <div className="card">
          <span>ОФПИК (1-8;21-24)</span>
          <div className="row">
            <span>{Number(data?.offPeakAvg).toFixed(3)} лв/МВтч</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Graph
