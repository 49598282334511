export const objectsTypes = {
  SET_FIELD: 'objects/SET_FIELD',
  SET_FIELDS: 'objects/SET_FIELDS',

  CREATE_OBJECT: 'objects/CREATE_OBJECT',
  UPDATE_OBJECT: 'objects/UPDATE_OBJECT',

  GET_OBJECTS_DOCUMENTS_MISSING: 'objects/GET_OBJECTS_DOCUMENTS_MISSING',

  CHECK_OBJECT_EXISTS: 'objects/CHECK_OBJECT_EXISTS',
  REMOVE_CLONE_OBJECT: 'objects/REMOVE_CLONE_OBJECT'
}

export const setObjectsField = (payload) => ({
  type: objectsTypes.SET_FIELD,
  payload,
})

export const setObjectsFields = (payload) => ({
  type: objectsTypes.SET_FIELDS,
  payload,
})

export const createObject = (payload) => ({
  type: objectsTypes.CREATE_OBJECT,
  payload,
})

export const updateObject = (payload) => ({
  type: objectsTypes.UPDATE_OBJECT,
  payload,
})

export const getObjectsDocumentsMissing = (payload) => ({
  type: objectsTypes.GET_OBJECTS_DOCUMENTS_MISSING,
  ...payload,
})

export const checkObjectExists = (payload) => ({
  type: objectsTypes.CHECK_OBJECT_EXISTS,
  ...payload,
})

export const removeCloneObject = (payload) => ({
  type: objectsTypes.REMOVE_CLONE_OBJECT,
  ...payload
})