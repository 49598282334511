import React from "react"
import "./styles.scss"

const BackButton = ({ onClick, text }) => {
    return <div className="shared-back-button-container row" onClick={onClick}>
        <div className="icon icon-back no-active" />
        <p>{text}</p>
    </div>
}

export default BackButton