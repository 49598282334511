export const generalTypes = {
  SET_FIELD: 'general/SET_FIELD',
  SET_FIELDS: 'general/SET_FIELDS',

  START_LOADING: 'general/START_LOADING',
  STOP_LOADING: 'general/STOP_LOADING',
  SET_LOADING_TEXT: 'general/SET_LOADING_TEXT',
  SET_MODAL: 'general/SET_MODAL',
  SET_OVERLAY: 'general/SET_OVERLAY',
}

export const setGeneralField = (payload) => ({
  type: generalTypes.SET_FIELD,
  payload,
})

export const setGeneralFields = (payload) => ({
  type: generalTypes.SET_FIELDS,
  payload,
})

export const startLoading = () => ({
  type: generalTypes.START_LOADING,
})

export const stopLoading = () => ({
  type: generalTypes.STOP_LOADING,
})

export const setLoadingText = (payload) => ({
  type: generalTypes.SET_LOADING_TEXT,
  payload,
})

export const setModal = (payload) => ({
  type: generalTypes.SET_MODAL,
  payload,
})

export const setOverlay = (payload) => ({
  type: generalTypes.SET_OVERLAY,
  payload,
})
