import React, { useState, useRef } from 'react'
import { Button } from '@makedonski/admin-ui'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { useDispatch } from 'react-redux'
import {
  setClientsField,
  confirmStatusesRegister,
  setModal,
  startLoading,
  getObjectsDocumentsMissing,
  confirmLeaving,
} from 'actions'
import { useQuery, useCollapsable } from 'hooks'
import { Inputs } from 'components'
import { xlsxExport, renderCell, getTextForConfirmationModal, checkIsDealer } from 'utilities'
import './styles.scss'

const buttons = [
  { label: 'Създай Оферта', value: 'offer' },
  // { label: 'Създай Анализ', value: 'analysis' },
  { label: 'Създай Договор', value: 'contract' },
]

const ClientsOptions = ({ hide, fetch, exportClients, toggleAllRowsExpanded }) => {
  const isDealer = checkIsDealer()
  const dispatch = useDispatch()
  const history = useHistory()
  const { type } = useQuery()

  const [selected, setSelected] = useState()
  const [isNewClient, setIsNewClient] = useState()

  const collapsableRef = useRef()
  const [isExpanded, setExpanded] = useCollapsable(collapsableRef)

  const handleForRegistration = () => {
    dispatch(setClientsField({ field: 'showCheckboxes', value: { action: 'forRegistration' } }))
    hide()
  }

  const handleTerminateRegister = () => {
    dispatch(setClientsField({ field: 'showCheckboxes', value: { action: 'terminateRegister' } }))
    hide()
  }

  const handleActivate = () => {
    dispatch(setClientsField({ field: 'showCheckboxes', value: { action: 'activateObjects' } }))
    hide()
  }

  const handleTerminateObjects = () => {
    dispatch(setClientsField({ field: 'showCheckboxes', value: { action: 'terminateObjects' } }))
    hide()
  }

  const handleCreateGroup = () => {
    dispatch(setClientsField({ field: 'showCheckboxes', value: { action: 'createGroup' } }))
    hide()
  }

  const handleChangeStatus = () => {
    dispatch(setClientsField({ field: 'showCheckboxes', value: { action: 'changeStatus' } }))
    hide()
  }

  const handleResponsibleForRegistration = () => {
    dispatch(setClientsField({ field: 'showCheckboxes', value: { action: 'responsibleForRegistration' } }))
    hide()
  }

  const handleSendDocumentsToERP = () => {
    dispatch(setClientsField({ field: 'showCheckboxes', value: { action: 'sendDocumentsToERP' } }))
    hide()
  }
  const handleImportDocumentsFromERP = () => {
    dispatch(setModal({ isOpen: true, type: 'registerImportDocuments', props: { fetch } }))
    hide()
  }
  const handleDepositsLeaving = () => {
    dispatch(setClientsField({ field: 'showCheckboxes', value: { action: 'depositsLeaving' } }))
    hide()
  }
  const handleRedistributeDealers = () => {
    dispatch(setClientsField({ field: 'showCheckboxes', value: { action: 'redistributeDealers' } }))
    hide()
  }

  const handleConsumptionAnalysis = () => {
    dispatch(setClientsField({ field: 'showCheckboxes', value: { action: 'consumptionAnalysis' } }))
    hide()
  }

  const handleConfirmStatusesTransfer = () => {
    dispatch(setModal({
      isOpen: true, type: 'confirmation', props: {
        title: "Внимание",
        children: getTextForConfirmationModal([{ inner: "Пререгистрациите следва да бъдат одобрени след приключило фактуриране за предходния месец. Моля потвърдете че искате да продължите." }]),
        onClick: handleConfirmStatusesRegister({ isClone: true })
      }
    }))
    hide()
  }

  const handleConfirmStatusesProducers = () => {
    dispatch(setModal({
      isOpen: true, type: 'confirmation', props: {
        title: "Внимание",
        children: getTextForConfirmationModal([{ inner: "Моля потвърдете че искате да продължите." }]),
        onClick: handleConfirmStatusesRegister({ isProducer: true })
      }
    }))
    hide()
  }

  const handleConfirmStatusesRegisterInitial = () => {
    dispatch(setModal({
      isOpen: true, type: 'confirmation', props: {
        title: "Внимание",
        children: getTextForConfirmationModal([{ inner: "Моля потвърдете че искате да продължите." }]),
        onClick: handleConfirmStatusesRegister()
      }
    }))
    hide()
  }

  const handleConfirmStatusesRegister = (payload) => () => {
    dispatch(startLoading())
    dispatch(confirmStatusesRegister({
      payload,
      onSuccess: ({ toActive, toBeActive, toPreregistration, toBePreregistration }) => {
        try {
          dispatch(setModal({
            isOpen: true, type: 'confirmation',
            props: {
              title: 'Одобрение пристигащи',
              // children: getTextForConfirmationModal([
              //   { text: 'Към активни:', inner: `${toActive?.length} точки` },
              //   { text: 'Изчакващи към активни:', inner: `${toBeActive?.length} точки` },
              //   { text: 'Превходиране:', inner: `${toPreregistration?.length} точки` },
              //   { text: 'Изчакващи към превходиране:', inner: `${toBePreregistration?.length} точки` },
              // ]),
              children: getTextForConfirmationModal([
                { text: 'Към активни:', inner: `${toActive?.length} точки / ${[...new Set(toActive?.filter(Boolean)?.map(({ client }) => client)) || []].length} клиента` },
                { text: 'Изчакващи към активни:', inner: `${toBeActive?.length} точки / ${[...new Set(toBeActive?.filter(Boolean)?.map(({ client }) => client)) || []].length} клиента` },
                { text: 'Превходиране:', inner: `${toPreregistration?.length} точки / ${[...new Set(toPreregistration?.filter(Boolean)?.map(({ client }) => client)) || []].length} клиента` },
                { text: 'Изчакващи към превходиране:', inner: `${toBePreregistration?.length} точки / ${[...new Set(toBePreregistration?.filter(Boolean)?.map(({ client }) => client)) || []].length} клиента` },
              ]),
              onClick: () => fetch({ pageOne: true }),
              onRequestClose: () => fetch({ pageOne: true }),
            }
          }))
        } catch (error) { console.log(error); }
      }
    }))
    hide()
  }

  const handleExport = (payload) => {
    dispatch(startLoading())
    exportClients(payload)
    hide()
  }

  const handleLeavingConfirm = () => {
    dispatch(setModal({
      isOpen: true, type: 'confirmation', props: {
        title: "Внимание",
        children: getTextForConfirmationModal([{ inner: "Моля потвърдете че искате да продължите." }]),
        onClick: () => {
          dispatch(startLoading())
          dispatch(confirmLeaving({
            onSuccess: ({ toInactive, toBeInactive }) => dispatch(setModal({
              isOpen: true, type: 'confirmation',
              props: {
                title: 'Одобрение напускащи',
                children: getTextForConfirmationModal([
                  { text: 'Към неактивни:', inner: `${toInactive?.length} точки / ${[...new Set(toInactive?.map(({ client }) => client))].length} клиента` },
                  { text: 'Изчакващи към неактивни:', inner: `${toBeInactive?.length} точки / ${[...new Set(toBeInactive?.map(({ client }) => client))].length} клиента` }
                ]),
                onClick: () => fetch({ pageOne: true }),
                onRequestClose: () => fetch({ pageOne: true }),
              },
            })),
          }))
        }
      }
    }))
    hide()
  }

  const handleUnconfirmed = (erp) => {
    dispatch(
      getObjectsDocumentsMissing({
        payload: { erp },
        onSuccess: (response) =>
          response?.length &&
          xlsxExport({
            fields: [
              { label: 'ЕРП', value: 'erp' },
              { label: 'КЛИЕНТ', value: 'fullName' },
              { label: 'ЕИК/ЕГН', value: 'eic' },
              { label: 'ИТН', value: 'itn' },
              { label: 'КОМЕНТАР', value: 'comment' },
              { label: 'ЗАДЪЛЖЕНИЯ', value: 'additionalStatus' },
              { label: 'ПРОБЛЕМ', value: 'issue' },
              //{ label: 'ТИП ЗАЯВЛЕНИЕ', value: 'application' },
              //{ label: 'БРОЙ ВХОДИРАНИ ОБЕКТИ', value: 'confirmed' },
            ],
            data: response,
            render: renderCell.objectsDocumentsMissing,
          }),
      })
    )
    hide()
  }

  const handleMassEditClientsPrice = () => {
    dispatch(setModal({ type: 'massEditClientsPrice', isOpen: true, props: { fetch } }))
    hide()
  }

  const handleChoose = () => {
    if (isEmpty(selected)) return
    if (isNewClient) {
      const url = new URLSearchParams(location.search)
      url.append('docs', selected)
      url.append('client', 'new')
      history.push(`clients/create?` + url.toString())
    } else {
      dispatch(setClientsField({ field: 'showCheckboxes', value: { action: 'addDocuments', payload: selected } }))
      hide()
    }
  }

  const handleCreateAnnex = () => history.push('/annex/create')
  const handleResign = () => history.push('resign/create')

  const handleCollapseAll = () => {
    toggleAllRowsExpanded(false)
    hide()
  }

  if (isDealer) return (
    <div className="popup-clients-options-container">
      <div className="row">
        <h4>Изберете действие</h4>
        <Button.Icon name="plus" onClick={hide} />
      </div>
      <p onClick={() => handleCollapseAll()}>Скрий всички обекти</p>
    </div>
  )

  return (
    <div className="popup-clients-options-container">
      <div className="row">
        <h4>Изберете действие</h4>
        <Button.Icon name="plus" onClick={hide} />
      </div>
      {type === 'potential' && <>
        <div className="potential">
          <Inputs.Checkboxes buttons={buttons} value={selected} onClick={(value) => setSelected(value)} col />
          <Button.Switch label="Нов клиент" isOn={isNewClient} onChange={() => setIsNewClient(!isNewClient)} />
          <Button.Raised text="Избери" onClick={handleChoose} />
        </div>
      </>}

      {type === 'active' && <>
        <p onClick={handleCreateAnnex}>Създай Анекс</p>
        <p onClick={handleResign}>Преподписване</p>
        {/* <p onClick={handleImportDocumentsFromERP}>Импорт на данни от ЕРП</p> */}
        <p onClick={handleTerminateObjects}>Прекратяване на договор</p>
      </>}

      {type === 'register' && <>
        <p onClick={handleResponsibleForRegistration}>Разпредели отговорници</p>
        <p onClick={handleSendDocumentsToERP}>Входиране</p>
        <p onClick={handleImportDocumentsFromERP}>Импорт на данни от ЕРП</p>
        <p onClick={handleConfirmStatusesRegisterInitial}>Одобри пристигащи</p>
        <p onClick={handleConfirmStatusesTransfer}>Одобри пререгистрация</p>
        <p onClick={handleConfirmStatusesProducers}>Одобри производители</p>
        <p onClick={() => setExpanded(!isExpanded)}>Експорт непотвърдени</p>
        <div className="collapsable-erps" ref={collapsableRef}>
          <p onClick={() => handleUnconfirmed('ЕВН')}>ЕВН</p>
          <p onClick={() => handleUnconfirmed('Енерго Про')}>Енерго Про</p>
          <p onClick={() => handleUnconfirmed('ЧЕЗ')}>ЧЕЗ</p>
          <p onClick={() => handleUnconfirmed('Златни Пясъци')}>Златни Пясъци</p>
        </div>
        <p onClick={handleTerminateRegister}>Прекратяване на регистрация</p>
      </>}

      {type === 'leaving' && <>
        <p onClick={handleImportDocumentsFromERP}>Импорт на данни от ЕРП</p>
        <p onClick={handleDepositsLeaving}>Издаване на депозити</p>
        <p onClick={handleLeavingConfirm}>Одобри напускащи</p>
      </>}

      {['active', 'producers'].includes(type) && <>
        <p onClick={handleTerminateObjects}>Прекратяване на договор</p>
        <p onClick={handleMassEditClientsPrice}>Масова редакция цени</p>
        <p onClick={handleCreateGroup}>Създай група</p>
      </>}


      {type === 'list' && <p onClick={handleRedistributeDealers}>Преразпредели клиенти</p>}
      {type !== 'register' && <p onClick={handleConsumptionAnalysis}>Анализ на потребление</p>}
      <p onClick={() => handleExport({ multi: false })}>Експорт първо ниво</p>
      <p onClick={() => handleExport({ multi: true })}>Експорт второ ниво</p>
      <p onClick={() => handleExport({ contacts: true })}>Експорт контакти</p>
      {type === 'potential' && <p onClick={handleForRegistration}>За регистрация</p>}
      {type === 'inactive' && <p onClick={handleActivate}>Активирай точки</p>}
      {type !== 'potential' && <p onClick={() => handleCollapseAll()}>Скрий всички обекти</p>}
      {/* <p onClick={handleChangeStatus}>Смяна статус</p> */}
    </div >
  )
}

export default ClientsOptions
