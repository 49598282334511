import React, { useEffect, useRef, createRef, useMemo, useCallback, forwardRef, useImperativeHandle } from "react"
import PropTypes from 'prop-types'
import { useTable, useFlexLayout, useExpanded, useRowSelect } from "react-table"
import { List, AutoSizer } from 'react-virtualized';
import { ScrollSyncPane } from 'react-scroll-sync'
import { isEmpty } from 'lodash'
import Checkbox from "./Checkbox"
import { useSwipeScroll as useSwipeScrollHook } from 'hooks'
import { Shared } from "components"

import "./styles.scss"

const Table = forwardRef(({
  columns: columnsProp = [],
  data: dataProp = [],
  renderCell,
  initialState = {},
  className = '',
  containerStyle = {},
  emptyState = 'Няма намерена информация в системата!',
  outlines = {},
  children = () => { },
  maxExpandHeight = Infinity,
  handlePagination = () => { },

  fixedWidth = false,
  rowHeight: rowHeightProp = 45,
  rowMargin = 8,
  headerHight = 50,
  headerWidth,
  expandField = 'objects',

  joinLeftSide = false,
  joinRightSide = false,

  useSwipeScroll = true,
  useSyncScroll = false,

  sortingComponent = {},

  useCheckboxes = false,
  allowSingle = false,
  hideSelectAll = false,
  selectedCheckboxes = [],
  disabledCheckboxes = [],
  onCheckboxChange = () => { },
  onCheckboxClick = () => { },
  onCheckboxClickAll = () => { }
},

  ref
) => {
  const columns = useMemo(() => columnsProp.map((col, i) => ({
    ...col,
    Header: col.label,
    accessor: col.value || `col-${i}`,
    width: col.size || 300,
    id: col.value || `col-${i}`,
  })), [columnsProp])
  const data = useMemo(() => dataProp || [], [dataProp])
  const getRowId = useCallback(({ _id }, relativeIndex) => _id || relativeIndex, [])
  const {
    state,
    headerGroups,
    rows,
    getTableProps,
    getTableBodyProps,
    prepareRow,
    totalColumnsWidth,
    toggleHideColumn,
    toggleRowExpanded,
    toggleAllRowsExpanded,
    toggleAllRowsSelected,
    isAllRowsSelected,
  } = useTable(
    {
      columns,
      data,
      initialState,
      autoResetHiddenColumns: false,
      expandSubRows: false,
      autoResetExpanded: false,
      autoResetSelectedRows: false,
      getRowId,
      stateReducer: (newState, action, oldState) => {
        switch (action.type) {
          case "toggleAllRowsSelected":
            const selectedRows = data.filter(({ _id }) => !disabledCheckboxes.includes(_id))
            if (onCheckboxClickAll) onCheckboxClickAll(selectedRows)
            if (isEmpty(selectedCheckboxes)) onCheckboxChange(selectedRows.map(({ _id }) => _id))
            else onCheckboxChange([])
            return oldState
          case "toggleRowSelected":
            const row = data.find(({ _id }) => _id === action.id)
            if (onCheckboxClick) onCheckboxClick(row)
            if (selectedCheckboxes.includes(action.id)) onCheckboxChange(allowSingle ? [] : selectedCheckboxes.filter(_id => _id !== action.id))
            else onCheckboxChange(allowSingle ? [action.id] : [...selectedCheckboxes, action.id])
            return oldState
          default:
            return newState
        }
      },
      useControlledState: state => {
        return useMemo(
          () => {
            return ({ ...state, selectedRowIds: selectedCheckboxes.reduce((acc, r) => ({ ...acc, [r]: true }), {}), })
          }, [state, selectedCheckboxes]
        )
      },
    },
    useFlexLayout,
    useExpanded,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        { id: 'selection', value: 'selection', width: 45, },
        ...columns,
      ])
    }
  )
  useImperativeHandle(ref, () => ({
    state,
    toggleRowExpanded: (_id) => {
      const { id, isExpanded } = rows?.find(({ original }) => original._id === _id) || {}
      toggleRowExpanded(id, !isExpanded)
    },
    setRowExpanded: (id, value) => toggleRowExpanded(id, value),
    toggleAllRowsExpanded: () => toggleAllRowsExpanded(false),
    toggleAllRowsSelected
  }), [rows, state])

  useEffect(() => {
    toggleHideColumn('selection', !useCheckboxes)
  }, [useCheckboxes])

  const scrollBarSize = React.useMemo(() => {
    const scrollDiv = document.createElement('div')
    scrollDiv.setAttribute('style', 'width: 100px; height: 100px; overflow: scroll; position:absolute; top:-9999px;')
    document.body.appendChild(scrollDiv)
    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth
    document.body.removeChild(scrollDiv)
    return scrollbarWidth
  }, [])

  const listRef = createRef()
  const rowHeight = useMemo(() => rowHeightProp + rowMargin, [rowHeightProp, rowMargin])
  const rowHeights = useMemo(() => rows?.map(({ isExpanded, original, ...o }) => {
    return isExpanded ? (rowHeight + headerHight + Math.min(maxExpandHeight, (original?.[expandField]?.length || 1) * rowHeight) + scrollBarSize) : rowHeight
  }), [rows])

  useEffect(() => {
    listRef?.current?.recomputeRowHeights(0)
  }, [rowHeights])

  const containerRef = useRef(null)
  const { hasSwiped } = useSwipeScrollHook({ sliderRef: containerRef, disabled: !useSwipeScroll })


  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index]
      prepareRow(row)
      const rowProps = row.getRowProps()
      return (
        <div
          key={rowProps.key}
          style={{ ...style, paddingTop: rowMargin / 2 }}
          className={`table-row-container ${row.isExpanded && 'active'}`}
        >
          <div
            {...rowProps}
            className="table-row-inner-container row"
            style={{
              height: rowHeightProp,
              boxShadow: Object.keys(state.selectedRowIds).includes(row.id) ? `0 0 3px #e87a1e` : outlines[row.id] ? `0 0 3px ${outlines[row.id]}` : null
            }}
          >
            {row.cells.map(cell => {
              if (cell.column.id === 'selection') {
                if (!useCheckboxes) return null
                return (
                  <div
                    {...cell.getCellProps()}
                    className={`table-row-cell row table-cell ${cell.column.value}`}
                    style={fixedWidth ? { ...cell.getCellProps().style, width: 'auto', maxWidth: 45 } : { ...cell.getCellProps().style, }}
                  >
                    <div>
                      <Checkbox
                        row={row.id}
                        {...row.getToggleRowSelectedProps()}
                        disabled={disabledCheckboxes.includes(row.id)}
                      />
                    </div>
                  </div>
                )
              } else return (
                <div
                  {...cell.getCellProps()}
                  className={`table-row-cell row table-cell ${cell.column.value}`}
                  style={fixedWidth ? { ...cell.getCellProps().style, width: 'auto', flexBasis: '0' } : { ...cell.getCellProps().style, }}
                >
                  {renderCell
                    ? renderCell(cell.row.original, cell.column.id, { index: row.index, isExpanded: row.isExpanded })
                    : ['string', 'number'].includes(typeof cell.row.original[cell.column.id])
                      ? cell.row.original[cell.column.id]
                      : '--'}
                </div>
              )
            })}
          </div>
          <div className={`table-row-children-container`} style={{ height: style.height - rowHeight + 2, marginBottom: rowMargin }}>
            {row.isExpanded ? children({ row }) : null}
          </div>
        </div >
      )
    },
    [prepareRow, rows, state]
  )

  return <div
    {...getTableProps()}
    ref={containerRef}
    className={`table-container 
      ${className}
      ${joinLeftSide && 'joinLeftSide'}
      ${joinRightSide && 'joinRightSide'}
      ${hasSwiped && 'hasSwiped'}
    `}
    style={{
      minWidth: joinRightSide || joinLeftSide ? totalColumnsWidth + 16 : 0,
      height: rows.length ? rows.length * rowHeight + headerHight + scrollBarSize : '100%',
      ...containerStyle
    }}
  >
    <AutoSizer style={{ width: 'auto', height: '100%' }}>
      {({ height, width }) => {
        return <>
          {headerGroups.map(headerGroup => (
            <div
              {...headerGroup.getHeaderGroupProps()}
              className="table-header"
              style={{ ...headerGroup.getHeaderGroupProps().style, height: headerHight, width: fixedWidth ? 'auto' : headerWidth }}
            >
              {headerGroup.headers.map(({ value, label, sortable = true, ...column }) => {
                if (column.id === 'selection') {
                  if (!useCheckboxes) return null
                  const modifiedOnChange = (event) => rows.forEach((row) => {
                    if (!disabledCheckboxes.includes(row.id)) toggleAllRowsSelected(event.currentTarget.checked)
                  })

                  let selectableRows = 0
                  let selectedRows = 0
                  rows.forEach((row) => {
                    if (row.isSelected) selectedRows++
                    if (!disabledCheckboxes.includes(row.id)) selectableRows++
                  })

                  return (
                    <div
                      {...column.getHeaderProps()}
                      className={`table-cell ${value} row`}
                      style={fixedWidth ? { ...column.getHeaderProps().style, width: 'auto', maxWidth: 45 } : { ...column.getHeaderProps().style }}
                    >
                      <div>
                        <Checkbox
                          row={{ id: `header-${Math.random()}` }}
                          onChange={modifiedOnChange}
                          disabled={selectableRows === 0}
                          checked={(isAllRowsSelected || selectableRows === selectedRows) && selectableRows !== 0}
                          isHidden={hideSelectAll || allowSingle}
                        />
                      </div>
                    </div>
                  )
                }
                else return (
                  <div
                    {...column.getHeaderProps()}
                    className={`table-cell ${value} row`}
                    style={fixedWidth ? { ...column.getHeaderProps().style, width: 'auto', flexBasis: '0' } : { ...column.getHeaderProps().style }}
                  >
                    <span>{label}</span>
                    {sortable && sortingComponent && (
                      <div className="sorting-component-container">{sortingComponent[value]}</div>
                    )}
                  </div>
                )
              })}
            </div>
          ))}
          {rows.length ?
            <div
              {...getTableBodyProps()}
              className="table-content"
              style={{ ...getTableBodyProps().style, height: height - headerHight - scrollBarSize }}>
              <Shared.ConditionalWrapper
                condition={useSyncScroll}
                wrapper={(children) => <ScrollSyncPane>
                  {children}
                </ScrollSyncPane>}
              >
                <List
                  ref={listRef}
                  height={height - headerHight - scrollBarSize}
                  width={fixedWidth ? width : totalColumnsWidth + scrollBarSize}
                  rowCount={rows.length}
                  rowHeight={({ index }) => rowHeights[index]}
                  rowRenderer={RenderRow}
                  onRowsRendered={({ overscanStopIndex }) => {
                    if (handlePagination && overscanStopIndex > rows?.length - 4) handlePagination()
                  }}
                />
              </Shared.ConditionalWrapper>
            </div>
            : <div style={{ height: height - headerHight }} className="table-content-empty row">{emptyState}</div>}
        </>
      }}
    </AutoSizer>
  </div >
})

export default Table

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object),
  renderCell: PropTypes.func,
  initialState: PropTypes.object,
  className: PropTypes.string,
  containerStyle: PropTypes.object,
  emptyState: PropTypes.node,
  outlines: PropTypes.objectOf(PropTypes.string.isRequired),
  children: PropTypes.func,
  handlePagination: PropTypes.func,

  fixedWidth: PropTypes.bool,
  rowHeight: PropTypes.number,
  rowMargin: PropTypes.number,
  headerHight: PropTypes.number,
  headerWidth: PropTypes.number,
  joinLeftSide: PropTypes.bool,
  joinRightSide: PropTypes.bool,

  useSwipeScroll: PropTypes.bool,
  useSyncScroll: PropTypes.bool,
  sortingComponent: PropTypes.objectOf(PropTypes.node),

  useCheckboxes: PropTypes.bool,
  allowSingle: PropTypes.bool,
  hideSelectAll: PropTypes.bool,
  selectedCheckboxes: PropTypes.array,
  disabledCheckboxes: PropTypes.array,
  onCheckboxChange: PropTypes.func,
  onCheckboxClick: PropTypes.func,
  onCheckboxClickAll: PropTypes.func,
}