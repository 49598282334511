import React, { useState } from "react"
import moment from 'moment'
import { emit } from "../helpers"
import "./styles.scss"

const Comments = ({ task, socket, disabled }) => {
  const { _id, comments } = task || {}
  const currentUser = JSON.parse(localStorage.getItem('user'))._id

  const [text, setText] = useState('')
  const handleSend = () => {
    if (!text) return
    emit(socket, { type: 'comments/create', payload: { taskId: _id, text } })
    setText('')
  }

  return <div className="task-management-comments-container col">
    {comments?.length ? <div className="comments-scroll-container col">
      {comments?.map(({ _id, owner, createdAt, text }) => (
        <div key={`single-comment-${_id}`} className={`single-comment-container ${(true || currentUser === owner._id) && 'isMe'}`}>
          <div className="row row-details">
            <div className="single-member row">
              <div
                className="profile-image"
                style={{ backgroundImage: `url(${owner?.coverPhoto || require('../assets/default-user-avatar.jpg')})` }}
              />
              <p className="member-fullName">{owner?.fullName}</p>
            </div>
            <p className='time'>{createdAt && moment(createdAt).format('HH:mm | DD.MM.YYYY')}</p>
          </div>
          <div className="row row-text">
            <p>{text}</p>
          </div>
        </div>
      ))}
    </div> : <div className="empty-state col">
      <span>Няма добавени коментари.</span>
      <div className="image" />
    </div>}
    {!disabled && <div className="task-management-comments-input">
      <input
        className="input-text-container"
        value={text || ""}
        onChange={({ target: { value } }) => setText(value)}
        onKeyDown={({ key }) => key === 'Enter' && handleSend()}
        disabled={disabled}
        placeholder={"Добави коментар"}
      />
      <div className="tasks-icon tasks-icon-arrow card" onClick={handleSend} />

    </div>}
  </div>
}

export default Comments