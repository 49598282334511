import React from 'react'
import './styles.scss'

const RoundedButtons = ({ buttons = [], value = '', onChange = () => {} }) => {
  return (
    <div className="inputs-rounded-buttons-container row">
      {buttons.map((btn, i) => (
        <div
          key={`button-${i}`}
          className={`btn-outlined ripple row ${[btn, btn?.value].includes(value) && 'active'}`}
          onClick={() => onChange(btn)}
        >
          {btn?.label || btn}
        </div>
      ))}
    </div>
  )
}

export default RoundedButtons
