import { useQuery } from 'hooks'
import React, { useEffect, useMemo, useState } from 'react'
import { Line } from 'react-chartjs-2'
import { Shared } from 'components'
import './styles.scss'
import { pick } from 'lodash'
import moment from 'moment'

const allFields = [
  { label: 'Количество', value: "total" },
  { label: 'Цена', value: "price" },
  { label: 'Средно', value: "avg" },
  { label: 'Мин', value: "min" },
  { label: 'Макс', value: "max" },
  { label: 'База', value: "base" },
  { label: 'База %', value: "basePercent" },
  { label: 'Пик', value: "peak" },
  { label: 'Пик %', value: "peakPercent" },
  { label: 'Офпик', value: "offPeak" },
  { label: 'Офпик %', value: "offPeakPercent" },
]

const ConsumptionGraph = ({ mainStats = {}, handleExport, marketPrices }) => {

  const { type = 'hours' } = useQuery()

  const labels = useMemo(() => Object.keys(mainStats), [mainStats])

  const fields = useMemo(() => allFields.filter(({ value }) => {
    if (['minutes', 'hours'].includes(type) && !['total', 'price'].includes(value)) return false
    if (!['minutes', 'hours'].includes(type) && ['price'].includes(value)) return false
    return true
  }), [allFields, type])

  const data = useMemo(() => {
    const temp = {}
    Object.entries(mainStats).forEach(([period, object]) => {
      Object.entries(pick(
        { ...object, ...(['minutes', 'hours'].includes(type) && { price: marketPrices[period] }) },
        fields.map(({ value }) => value))).forEach(([key, value]) => {
          temp[key] = { ...(temp?.[key] || {}), [period]: value }
        })
    })
    return Object.values(temp)
  }, [mainStats, fields])

  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    const listener = ({ key }) => key === 'Escape' && setExpanded(false)
    if (expanded) document.addEventListener('keydown', listener, { once: true })
  }, [expanded])

  return (
    <div className={`card-consumption-graph-container ${expanded && 'expanded'}`}>
      <div className="card-consumption-graph-header row">
        <h2>Визуализация на потреблението</h2>
        <div className="row row-buttons">
          <div className="icon icon-export" onClick={handleExport} />
          <div className={`icon icon-${expanded ? "collapse" : 'expand'}`} onClick={() => setExpanded(!expanded)} />
        </div>
      </div>
      <div className="card-consumption-graph">
        <Line
          className="graph"
          height={expanded ? 800 : 400}
          data={{
            labels,
            datasets: [{
              label: 'База',
              type: 'line',
              backgroundColor: '#E87A1E',
              data: Object.values(mainStats).map(({ base }) => base),
              yAxisID: 'line',
            }, {
              label: 'Общо',
              type: 'bar',
              backgroundColor: ({ index }) => ['minutes', 'hours'].includes(type)
                ? moment(labels[index]?.slice(0, 5), 'HH:mm').isBetween(moment('09:00', 'HH:mm'), moment('21:00', 'HH:mm'), 'hours') ? "red" : '#7CB4FF'
                : '#7CB4FF',
              data: Object.values(mainStats).map(({ total }) => total),
              yAxisID: 'line',
            }].filter(({ label }) => !type || type === 'hours' || label !== 'База'),
          }}
        />
      </div>
      <h2>Данни обобщено потребление</h2>
      <div className="card-consumption-table row">
        <div className="col">
          <p>.</p>
          {fields.map(({ label }) => <p key={label}>{label}</p>)}
        </div>
        <Shared.Table
          columns={Object.keys(mainStats).map((label) => ({ label, value: label, size: 140 }))}
          data={data}
          renderCell={(row, field, { index }) => {
            const value = fields?.[index]?.value
            if (typeof row?.[field] === 'string') return row?.[field]
            if (value.includes('Percent')) return (row?.[field] * 100).toFixed(2) + " %"
            return row?.[field]?.toFixed(2)
          }}
        />
      </div>
    </div>
  )
}

export default ConsumptionGraph
