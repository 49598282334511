import React, { useState, useMemo } from 'react'
import { Button } from '@makedonski/admin-ui'
import { useDispatch } from 'react-redux'
import { isEmpty } from 'lodash'
import { useHistory } from 'react-router-dom'
import { setModal, checkImportsExist } from 'actions'
import { Inputs } from 'components'
import { handleUploadedData } from 'utilities'
import './styles.scss'

const UploadFiles = ({ close, warning, options, ...rest }) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const [data, setData] = useState({ type: options[0].value })
    const handleChange = (field, value) => setData({ ...data, [field]: value })

    const handleParse = () => handleUploadedData({ files: data.files, dispatch, history, type: data.type, })

    const skipConfirmationModal = useMemo(() => !JSON.parse(localStorage.getItem('hideExistingFilesWarning')), [])

    return <div className="modal-upload-files-container">
        <div className="modal-upload-files-header row">
            <h2>Импорт на</h2>
            <div className="icon icon-close" onClick={close} />
        </div>
        <div className="modal-upload-files-content">
            <Inputs.RadioButtons buttons={options} value={data?.type} onClick={(value) => handleChange('type', value)} />
            {warning}
            <div className="row">
                <span>Файлове</span>
                <Button.UploadButton
                    text={<span className="row ripple">  Добави файл <div className="icon icon-attach" />  </span>}
                    onChange={({ target: { files } }) => dispatch(checkImportsExist({
                        payload: { fileName: [...files].map(({ name }) => name) },
                        onSuccess: () => handleChange('files', [...(data?.files || []), ...files]),
                        onFail: (response) => setData({
                            ...data,
                            files: [...(data?.files || []), ...(files || [])],
                            existingFiles: [...(data?.existingFiles || []), ...(response?.files || [])],
                        }),
                    }))}
                    accept={'.csv, .xlsx, .xls'}
                    {...rest}
                />
            </div>
            <div className="row row-files">
                {!isEmpty(data?.files) && [...data?.files]?.map((file, i) => (
                    <div className={` row-file ${data?.existingFiles?.includes(file?.name) && 'exist'}`} key={`file-${i}`}>
                        <div className="row">
                            <Button.Icon
                                name="plus"
                                color="red"
                                onClick={() => {
                                    const newFiles = [...(data?.files || [])]
                                    newFiles.splice(i, 1)
                                    handleChange('files', newFiles)
                                }}
                            />
                            <span>{file?.name}</span>
                        </div>
                        {data?.existingFiles?.includes(file?.name) && <span>Фигурира в системата!</span>}
                    </div>
                ))}
            </div>
        </div>
        <div className="modal-upload-files-footer">
            <Button.Raised
                text="Импортирай"
                disabled={isEmpty(data?.files)}
                onClick={() => {
                    if (!isEmpty(data?.existingFiles) && skipConfirmationModal) dispatch(setModal({ isOpen: true, type: 'existingFileWarning', props: { handleParse } }))
                    else handleParse()
                }}
            />
        </div>
    </div>
}

export default UploadFiles