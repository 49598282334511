import React from 'react'
import moment from 'moment'
import { SingleNotification } from './'
import './styles.scss'

const Activity = ({}) => {
  const { coverPhoto, fullName, lastLogin, notifications = [{}, {}] } = {}

  const handlePagination = (scrollTop, height, scrollHeight) => {
    if (scrollTop + height >= scrollHeight) return
    // dispatch(getActivity({ pageNumber: activity?.nextPage }))
  }

  return (
    <div className="card-activity-container">
      <div className="card-activity-header row">
        <div
          className="profile-image"
          style={{
            backgroundImage: `url(${coverPhoto || require('../../../assets/images/default-user-avatar.jpg')})`,
          }}
        />
        <div className="text">
          <p>Здравей,</p>
          <h2>{fullName || '--'}</h2>
          <p>Последно влизане: {lastLogin ? moment(lastLogin).format('DD.MM.YYYY') : '--'}</p>
        </div>
      </div>
      <div className="card-activity-content col">
        <h3>Известия</h3>
        <div
          className="card-activity-content-scroll-container"
          onScroll={({ target }) =>
            handlePagination(target.scrollTop, target.getBoundingClientRect().height, target.scrollHeight)
          }
        >
          {notifications?.map((notification, i) => (
            <SingleNotification key={`notification-${i}`} notification={notification} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Activity
