import React from 'react'
import { Popover } from '@varld/popover'
import { useDispatch } from 'react-redux'
import { NavLink, useHistory } from 'react-router-dom'
import { Popup } from 'components'
import { setOverlay } from 'actions'
import { getClientStatus } from 'utilities'
import { useQuery } from 'hooks'
import './styles.scss'

const Status = ({ client }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { type } = useQuery()
  return (
    <div className="client-calls-status-container row status">
      <NavLink to={`/clients/${client?._id}`}>
        <div className="icon icon-folder-custom" />
      </NavLink>
      <div
        className="icon icon-details"
        onClick={() => dispatch(setOverlay({ isOpen: true, type: 'history', props: { client } }))}
      />
      {/*<Popover popover={({ close }) => <Popup.ClientSingleOptions hide={close} client={client} />}>
        <div className="icon icon-details" />
      </Popover>*/}
      {getClientStatus(client, type)}
    </div>
  )
}

export default Status
