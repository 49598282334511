import React, { useEffect, useMemo, useState } from 'react'
import { Popover } from '@varld/popover'
import { Input } from '@makedonski/admin-ui'
import { isEmpty } from 'lodash'
import { Popup } from '../../'
import './styles.scss'

const SearchMulti = ({
  popupText,
  search: searchProp,
  searchBy: searchByProp,
  handleChange = () => { },
  options
}) => {

  const [search, setSearch] = useState(searchProp)
  const [searchBy, setSearchBy] = useState(searchByProp)

  useEffect(() => {
    setSearch(searchProp)
    setSearchBy(searchByProp)
  }, [searchProp, searchByProp])

  const label = useMemo(() => {
    return options.find(({ value }) => value === searchBy)?.shortLabel || options.find(({ value }) => value === searchBy)?.label
  }, [options, searchBy])

  return (
    <div className="inputs-search-multi-container row">
      <Input.Text
        placeholder='Търси'
        value={search || ''}
        onChange={({ target: { value } }) =>  setSearch(value)}
        onKeyDown={({ key }) => key === 'Enter' && handleChange({ search, searchBy })}
        onPaste={({ clipboardData }) => clipboardData.items[0].getAsString(value => handleChange({ search: value, searchBy }))}
      />
      <div className="row inputs-search-multi-container-row-buttons">

        <div className='icon icon-search-custom' onClick={() => handleChange({ search, searchBy })} />
        <Popover
          className="header-settings"
          popover={({ close }) => (
            <Popup.Select
              skipApply
              hide={close}
              value={searchBy}
              handleChange={(value) => {
                setSearchBy(isEmpty(value) ? null : value)
              }}
              options={options}
              text={popupText}
              single
            />
          )}
        >
          <div className="dropdown-container  row">
            {label}
            <div className="icon icon-arrow-down" />
          </div>
        </Popover>
      </div>
    </div>
  )
}

export default SearchMulti
