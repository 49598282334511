import { Alerts, Api } from "@makedonski/socourt-utilities"
import { producersTypes, setLoadingText, stopLoading } from "actions"
import { producersInvoicesFields, producersReferencesExportFields, producersReferencesFields } from "config/constants"
import { URL } from "config/settings"
import { store } from "config/store"
import { ofType, ActionsObservable } from "redux-observable"
import { catchError, switchMap } from "rxjs/operators"
import { exportBatchMeasures, renderCell, xlsxExport } from "utilities"



export const producersReferencesReady = (action$) => action$.pipe(
    ofType(producersTypes.PRODUCERS_REFERENCES_READY),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/producers/ready`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            })
            )
        )
    )
)


export const producersReferencesGeneration = (action$) => action$.pipe(
    ofType(producersTypes.PRODUCERS_REFERENCES_GENERATION),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/producers/generation`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                Alerts.success({ title: "Успешно генериране!" })
                if (onSuccess) onSuccess(response?.progress)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            })
            )
        )
    )
)

export const producersReferencesBrowse = (action$) => action$.pipe(
    ofType(producersTypes.PRODUCERS_REFERENCES_BROWSE),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/producers/browse`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (payload.forExport === 'excel_1') {
                    xlsxExport({
                        fields: producersReferencesFields,
                        data: response?.results?.docs,
                        render: renderCell.producersReferencesExport
                    })
                    obs.next(stopLoading())
                } else if (payload.forExport === 'excel_2') {
                    xlsxExport({
                        fields: producersReferencesExportFields,
                        data: response?.results?.docs,
                        render: renderCell.producersReferencesExport
                    })
                    obs.next(stopLoading())
                } else if (payload.forExport) {
                    exportBatchMeasures({
                        files: response?.results?.docs?.map((doc) => ({ url: doc[payload.forExport] })),
                        onSuccess: () => { store.dispatch(setLoadingText('Loading...')); store.dispatch(stopLoading()) }
                    })
                } else {
                    if (onSuccess) onSuccess(response?.results)
                    obs.next(stopLoading())
                    obs.next({ type: producersTypes.PRODUCERS_REFERENCES_BROWSE_SUCCESS, payload: response?.results, })
                }
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            })
            )
        )
    )
)

export const producersReferenceUpdate = (action$) => action$.pipe(
    ofType(producersTypes.PRODUCERS_REFERENCE_UPDATE),
    switchMap(({ payload, onSuccess }) =>
        Api.put(`${URL}/producers/edit`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess()
                // obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        )
    )
)

export const producersReferencesSendEmails = (action$) => action$.pipe(
    ofType(producersTypes.PRODUCERS_REFERENCES_SEND_EMAILS),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/producers/send`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response?.results)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        )
    )
)

export const producersInvoicesBrowse = (action$) => action$.pipe(
    ofType(producersTypes.PRODUCERS_INVOICES_BROWSE),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/invoices/browse`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (payload.exportExcel) {
                    xlsxExport({
                        fields: [...producersInvoicesFields, { label: 'Анулирана', value: 'deletedAt' }],
                        data: response?.docs,
                        render: renderCell.producersInvoicesExport
                    })
                    obs.next(stopLoading())
                } else if (payload.forExport) {
                    exportBatchMeasures({
                        type: 'invoices',
                        files: response?.data,
                        onSuccess: () => {
                            store.dispatch(setLoadingText('Loading...'))
                            store.dispatch(stopLoading())
                        }
                    })
                } else {
                    if (onSuccess) onSuccess(response)
                    obs.next(stopLoading())
                    obs.next({ type: producersTypes.PRODUCERS_INVOICES_BROWSE_SUCCESS, payload: response, })
                }
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            })
            )
        )
    )
)

export const producersInvoiceCreate = (action$) => action$.pipe(
    ofType(producersTypes.PRODUCERS_INVOICE_CREATE),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/producers/invoiceCreate`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response?.results)
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        )
    )
)

export const producersInvoiceUpdate = (action$) => action$.pipe(
    ofType(producersTypes.PRODUCERS_INVOICE_UPDATE),
    switchMap(({ payload, onSuccess }) =>
        Api.put(`${URL}/producers/invoiceUpdate`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response?.results)
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        )
    )
)