import React, { useEffect } from "react"
import { Inputs } from "components"
import { invoicePricingFields } from 'config/constants'
import { mAdd, mMultiply, bn, mSubtract } from "utilities"
import "./styles.scss"
import { pick, pickBy } from "lodash"

const Pricing = ({ invoice, compensations, handleChange, additionalCompensations }) => {
  const { total: { consumptionTotalAmount, deliveryTotalAmount } = {}, pricing, objects } = invoice || {}
  const { extras, additionalInformation } = pricing || {}

  useEffect(() => {
    const deposits = Object.values(extras || {})?.reduce((acc, cur) => mAdd(acc, bn(cur)), 0)
    let withoutVat = mAdd((consumptionTotalAmount || 0), (deliveryTotalAmount || 0))
    if (deposits) withoutVat = mSubtract(withoutVat, deposits)
    const VAT = mMultiply(withoutVat, 0.2)
    const total = mMultiply(withoutVat, 1.2)
    const interests = Object.entries(additionalInformation ?? {})?.filter(([key]) => key.includes('Неустойка за'))?.reduce((acc, [key, value]) => mAdd(acc, bn(value)), 0)
    const withVatAndInterests = mAdd(total, interests)
    let pricing = {
      "networkTotal": consumptionTotalAmount,
      "deliveryTotal": deliveryTotalAmount,
      "bonuses": 0,
      extras,
      deposits,
      "base": withoutVat,
      "vat": VAT,
      "withVat": total,
      interests,
      withVatAndInterests,
    }

    const nonHouseholds = objects
      ?.filter(({ measureType: { name } = {} }) => name !== 'Битово')
      ?.map((obj) => obj?.results?.find(({ cipher }) => cipher === '1001')?.quantity)
      ?.reduce((acc, quantity) => mAdd((acc || 0), (quantity || 0)), 0)

    const compensationsGivenTotal = nonHouseholds ? -mMultiply(nonHouseholds, bn(compensations?.compensationsAmount || 0)) : 0
    const compensationsTotal = mAdd(
      compensationsGivenTotal,
      additionalCompensations?.reduce((acc, { value }) => mAdd(acc, bn(value)), 0)
    )
    const withVatInterestsAndCompensations = mAdd((pricing.withVatAndInterests || 0), (compensationsTotal || 0))
    const compensationsText = `${compensations?.compensationsText} към фактура {{invoiceNumber}}/{{invoiceDate}} (${nonHouseholds} мВтч, -${compensations?.compensationsAmount} лв./мВтч)`.replaceAll('.', '_')
    pricing = {
      ...pricing,
      "additionalInformation": {
        ...pickBy(additionalInformation ?? {}, (value, key) => key.includes('Неустойка за')),
        ...(compensations?.compensationsText && { [compensationsText]: compensationsGivenTotal })
      },
      compensations: compensationsTotal,
      withVatInterestsAndCompensations,
    }


    handleChange({ pricing })
  }, [consumptionTotalAmount, deliveryTotalAmount, additionalCompensations])

  return <div className="invoices-forms-pricing-container">
    <div className="invoices-forms-pricing-content">
      {invoicePricingFields
        // .filter(({ value }) => compensations || !['compensations', 'withVatInterestsAndCompensations'].includes(value))
        .map(({ label, value }) => (
          <div key={`el-${value}`} className="row">
            <p>{label}</p>
            <Inputs.TextLabeled label="лв." value={Number(pricing?.[value] || 0).toFixed(2) ?? ''} disabled />
          </div>)
        )}
    </div>
  </div>
}

export default Pricing