import React from 'react'
import './styles.scss'

const SingleShortcut = ({}) => {
  return (
    <div className="single-shortcut-container col">
      <h3>Създаване на Анализ и Оферта с борса и Таван на цената</h3>
      <div className="single-shortcut-details row">
        <p>
          Последно използван: <span>Вчера | 13:20</span>
        </p>
        <p>
          Ползван: <span>32 пъти</span>
        </p>
      </div>
      <div className="single-shortcut-footer row">
        <p>При последно ползване: Създаден Анализ №322414</p>
        <div className="icon icon-arrow-right-2-white" />
      </div>
    </div>
  )
}

export default SingleShortcut
