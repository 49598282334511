import React from 'react'
import { Input } from '@makedonski/admin-ui'
import './styles.scss'

const TextLabeled = ({ label, ...props }) => {
  return (
    <div className="inputs-text-labeled-container">
      <Input.Text {...props} />
      <span>{label}</span>
    </div>
  )
}

export default TextLabeled
