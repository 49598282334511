import React, { useState, useMemo } from 'react'
import { Button, Input } from '@makedonski/admin-ui'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty, omit } from 'lodash'
import { setModal } from 'actions'
import { Inputs } from 'components'
import { useQuery } from 'hooks'
import './styles.scss'
import moment from 'moment'
import { clientsStatus, clientTypesOptions, intermediateInvoicesTypes } from 'config/constants'

//prettier-ignore
const sortButtons = [{ label: 'Възходящи', value: '1' }, { label: 'Низходящи', value: '-1' }]
const keyMap = {
  paymentType: 'intermediateInvoicingType',
  objectsCount: 'objects',
  dealer: 'dealers',
  'contractSettings.contractType': 'contractType',
  'contractSettings.deliveryStart': 'deliveryStart',
  'contractSettings.deliveryEnd': 'deliveryEnd',
  'contractVersion': "version",
  "contractSettings.penalty": 'penalty',
  "contractSettings.terms": 'terms',
  "price.base": "base",
  "price.market": "market",
  "price.taxPerObject": "taxPerObject",
  "price.night": "night",
  "leavingDeposit": "deposit"
}

const ClientsFieldFilter = ({ column, hide, lockColumn, isLocked }) => {
  const key = useMemo(() => Object.entries(keyMap).find((arr) => arr.includes(column))?.at(0) || column, [column])
  const dispatch = useDispatch()
  const { type, sort: sortParam = '{}', filter: filterParam = '{}', handleUrlChange } = useQuery()
  const { availableSort, availableFilters } = useSelector(({ clients }) => clients)
  const [sort, setSort] = useState(JSON.parse(sortParam)[key] || {})
  const [filter, setFilter] = useState(JSON.parse(filterParam) || {})
  const [query, setQuery] = useState('')

  //prettier-ignore
  const allowFilter = useMemo(() => Object.keys(availableFilters)
    ?.filter((key) => ![
      'contractSettings.deliveryStart',
      'contractSettings.deliveryEnd',
      'contractSettings.penalty',
      'contractSettings.terms'
    ].includes(key) || ['active', 'potential', 'register', 'leaving', 'inactive'].includes(type))
    ?.filter((key) => key !== 'status' || ['register', 'leaving'].includes(type))
    ?.map((key) => keyMap[key] || key)?.includes(column), [availableFilters, type])

  const allowSort = useMemo(() => availableSort
    ?.filter((key) => ![
      'contractSettings.deliveryStart',
      'contractSettings.deliveryEnd',
    ].includes(key) || ['active', 'potential', 'register', 'leaving', 'inactive'].includes(type))
    ?.map((key) => keyMap[key] || key)?.includes(column), [availableSort])


  const handleColumns = () => {
    dispatch(setModal({ isOpen: true, type: 'clientsColumns' }))
    hide()
  }

  const handleLockedColumn = () => {
    lockColumn()
    hide()
  }

  const handleApply = () => {
    if (!isEmpty(filter)) handleUrlChange('filter', JSON.stringify(filter))
    else handleUrlChange('filter', null)
    if (!isEmpty(sort)) handleUrlChange('sort', JSON.stringify({ [key]: sort }))
    else if (isEmpty(omit(JSON.parse(sortParam), key))) handleUrlChange('sort', null)
  }

  const renderFilter = () => {
    if (type === 'leaving' && key === 'status') availableFilters.status.values = clientsStatus

    if (key === 'clientType') {
      availableFilters.clientType.values = clientTypesOptions
      if (type === 'producers') availableFilters.clientType.values = clientTypesOptions.filter((value) => value !== 'Консуматор')
      else if (!['producers', 'list', 'register'].includes(type)) availableFilters.clientType.values = clientTypesOptions.filter((value) => value !== 'Производител')
    }


    const { min, max, single } = availableFilters[key]
    switch (availableFilters[key].type) {
      case 'regex':
        return (
          <Input.Text
            placeholder="Search:"
            value={filter[key] || ''}
            onChange={({ target: { value } }) => setFilter({ ...filter, [key]: value })}
          />
        )
      case 'date':
        return (
          <Input.DatepickerRange
            selectionRange={filter[key]?.map(({ startDate, endDate, key } = {}) => ({ startDate: moment(startDate).toDate(), endDate: moment(endDate).toDate(), key }))}
            onChange={({ selection }) => setFilter({ ...filter, [key]: [selection] })}
          />
        )
      case 'dropdown':
        return single ? <>
          <Input.Text value={query} onChange={({ target: { value } }) => setQuery(value)} />
          <Inputs.RadioButtons
            value={filter[key]}
            onClick={(value) => isEmpty(value) ? setFilter(omit(filter, key)) : setFilter({ ...filter, [key]: value })}
            buttons={availableFilters[key]?.values
              ?.filter((option) => !query || option?.includes(query))
              ?.map(k => key === "paymentType" ? intermediateInvoicesTypes.find(({ value }) => value === k) : k)}
            col
          />
        </> : <>
          <Input.Text value={query} onChange={({ target: { value } }) => setQuery(value)} />
          <Inputs.Checkboxes
            value={filter[key]}
            onClick={(value) => isEmpty(value) ? setFilter(omit(filter, key)) : setFilter({ ...filter, [key]: value })}
            buttons={availableFilters[key]?.values
              ?.filter((option) => !query || option?.includes(query))
              ?.map(k => key === "paymentType" ? intermediateInvoicesTypes.find(({ value }) => value === k) : k)}
            col
          />
        </>
      case 'idDropdown':
        return (
          <>
            <Input.Text value={query} onChange={({ target: { value } }) => setQuery(value)} />
            <Inputs.Checkboxes
              buttons={availableFilters[key]?.values
                ?.map(({ _id, name, label, value }) => ({ value: _id || value, label: name || label }))
                ?.filter(({ label }) => !query || label?.includes(query))}
              value={filter[key]}
              onClick={(value) => isEmpty(value) ? setFilter(omit(filter, key)) : setFilter({ ...filter, [key]: value })}
              col
            />
          </>
        )
      case 'boolean':
        return (
          <Inputs.RadioButtons
            buttons={['Да', 'Не']}
            value={filter[key] === false ? 'Не' : filter[key] === true ? 'Да' : null}
            onClick={(value) => setFilter({ ...filter, [key]: value === 'Да' })}
          />
        )
      case 'number':
      case 'priceRange':
        if (min > max) return null
        return <div className="row">
          <div className="col">
            <span>От:</span>
            <Input.Text
              placeholder={min}
              value={filter?.[key]?.min ?? ''}
              onChange={({ target: { value } }) => setFilter({ ...filter, [key]: { ...(filter?.[key]), min: value === '' ? undefined : value } })}
            />
          </div>
          <div style={{ width: 15 }} />
          <div className="col">
            <span>До:</span>
            <Input.Text
              placeholder={max}
              value={filter?.[key]?.max ?? ''}
              onChange={({ target: { value } }) => setFilter({ ...filter, [key]: { ...(filter?.[key]), max: value === '' ? undefined : value } })}
            />
          </div>
        </div>
      default:
        return null
    }
  }

  if (['fullName', 'eic'].includes(column)) return <div className="popup-clients-field-filter-container">
    <Button.Icon name="plus" onClick={hide} />
    <div className="functions">
      <h3>Функции</h3>
      <p style={{ cursor: 'pointer' }} onClick={handleColumns}>Управлявай колони</p>
      <p style={{ cursor: 'pointer' }} onClick={handleLockedColumn}>{isLocked ? 'Отключи колона' : 'Замрази колона'}</p>
    </div>
  </div>

  return (
    <div className="popup-clients-field-filter-container">
      <Button.Icon name="plus" onClick={hide} />
      <div className="functions">
        <h3>Функции</h3>
        <p style={{ cursor: 'pointer' }} onClick={handleColumns}>Управлявай колони</p>
        <p style={{ cursor: 'pointer' }} onClick={handleLockedColumn}>{isLocked ? 'Отключи колона' : 'Замрази колона'}</p>
      </div>
      {allowSort && (
        <div className="sort">
          <h3>Подреди по</h3>
          <Inputs.RadioButtons col buttons={sortButtons} onClick={(data) => setSort(data)} value={sort} />
        </div>
      )}
      {allowFilter && (
        <div className="filter">
          <h3>Филтрирай по</h3>
          {renderFilter()}
        </div>
      )}
      {(allowSort || allowFilter) && (
        <div className="buttons row">
          <p
            className="btn-clear"
            onClick={() => {
              setFilter(omit(filter, key))
              setSort('')
              setQuery('')
            }}
          >
            Изчисти филтри
          </p>
          <Button.Raised
            text="Задай"
            onClick={() => {
              handleApply()
              hide()
            }}
          />
        </div>
      )}
    </div>
  )
}

export default ClientsFieldFilter
