import React from "react"
import { Droppable, Draggable } from 'react-beautiful-dnd'
import Task from "./Task"
import "./styles.scss"

const TasksList = ({ column }) => {
  const { _id, tasks } = column || {}
  return <Droppable droppableId={_id} type="TASKS">
    {(dropProvided) => <div {...dropProvided.droppableProps} className="task-management-tasks-list-container">
      <div className="task-management-tasks-list-drop-zone" ref={dropProvided.innerRef}>
        {tasks.map((task, i) => <Draggable key={task._id} draggableId={task._id} index={i}>
          {(dragProvided) => <div
            className="task-management-task-outer-container"
            ref={dragProvided.innerRef}
            {...dragProvided.draggableProps}
            {...dragProvided.dragHandleProps}
          >
            <Task task={task} />
          </div>}
        </Draggable>)}
        {dropProvided.placeholder}
      </div>
    </div>}
  </Droppable>
}

export default TasksList