import React, { useState, useEffect, useMemo } from 'react'
import { Button } from '@makedonski/admin-ui'
import { useDispatch, useSelector } from 'react-redux'
import { setModal, getData, updateClient, startLoading } from '../../../actions'
import { Inputs, Client } from '../../'
import { productsPricesFields } from '../../../config/constants'
import './styles.scss'

const ClientsProduct = ({ client, fetch }) => {
  const dispatch = useDispatch()
  const [data, setData] = useState({ product: client?.product?._id, price: client?.price })
  const handleChange = (field, value) => setData({ ...data, [field]: value })

  const { products } = useSelector((state) => state.data)
  useEffect(() => {
    if (!products) dispatch(getData('products'))
  }, [])

  const isInvalid = useMemo(() => {
    return productsPricesFields
      .filter(({ types }) => types.includes(products?.find(({ _id }) => data?.product === _id)?.name))
      .map(({ value }) => value)
      .some((field) => !data?.price?.[field])
  }, [data])

  return (
    <div className="modal-clients-product-container">
      <div className="modal-clients-product-header row">
        <h2>Промяна продукт</h2>
        <Button.Icon
          name="plus"
          size="26px"
          className="btn-close"
          onClick={() => dispatch(setModal({ isOpen: false }))}
        />
      </div>
      <div className="modal-clients-product-content row">
        <div className="col">
          <span>Продукт</span>
          <Inputs.Dropdown
            options={products?.filter(({ forProducer }) => !forProducer)?.map(({ _id, name }) => ({ label: name, value: _id }))}
            onChange={({ value }) => {
              const product = products?.find(({ _id }) => _id === value)?.name
              setData({ ...data, product: value, price: product === 'Борса' ? { market: 0 } : null })
            }}
            value={data?.product?._id || data?.product}
          />
        </div>
        <Client.Forms.ProductPrices
          products={products}
          data={data}
          handleChange={(field, value) => handleChange('price', { ...data?.price, [field]: value })}
        />
      </div>
      <div className="modal-clients-product-footer">
        <Button.Raised
          text="Запази"
          disabled={isInvalid}
          onClick={() => {
            dispatch(startLoading())
            dispatch(updateClient({ data: { _id: client?._id, ...data }, onSuccess: () => fetch() }))
            dispatch(setModal({ isOpen: false }))
          }}
        />
      </div>
    </div>
  )
}

export default ClientsProduct
