import { ofType, ActionsObservable } from 'redux-observable'
import { switchMap, catchError } from 'rxjs/operators'
import { Api, Alerts } from '@makedonski/socourt-utilities'
import { noticesTypes, stopLoading } from '../actions'
import { URL } from '../config/settings'

export const getNotices = (action$) =>
  action$.pipe(
    ofType(noticesTypes.GET_NOTICES),
    switchMap(({ payload: { type = 'credit', page = 1, size = 20 }, onSuccess }) =>
      Api.get(`${URL}/notices/${type}/${page ?? 1}/${size}`).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response.notices)
            obs.next({ type: noticesTypes.GET_NOTICES_SUCCESS, payload: response.notices })
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            obs.next(stopLoading())
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const searchNotices = (action$) =>
  action$.pipe(
    ofType(noticesTypes.SEARCH_NOTICES),
    switchMap(({ payload: { type, ...payload }, onSuccess }) =>
      Api.get(
        `${URL}/notices/${type}/search`,
        JSON.stringify({ limit: 20, ...payload, page: payload?.page || 1 })
      ).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response.offer)
            obs.next({ type: noticesTypes.GET_NOTICES_SUCCESS, payload: response.notices })
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )
