import React, { useEffect } from 'react'
import { Auth } from '@makedonski/socourt-utilities'
import Div100vh from 'react-div-100vh'
import { Router } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { checkUser } from './actions'
import { Routes } from './components'
import { history } from './config/store'

import moment from 'moment'
import 'moment/locale/bg'
moment.locale('bg')

import { setDefaultLocale } from 'react-datepicker'
import bg from 'date-fns/locale/bg'
setDefaultLocale(bg)

const App = () => {
  const dispatch = useDispatch()
  useEffect(() => { Auth.isAuthenticated && dispatch(checkUser()) }, [])

  return (
    <Router history={history}>
      <Div100vh>
        <Routes />
      </Div100vh>
    </Router>
  )
}

export default App
