export const authTypes = {
  LOGIN: 'authTypes/LOGIN',
  LOGIN_SUCCESS: 'authTypes/LOGIN_SUCCESS',
  LOGOUT: 'authTypes/LOGOUT',
  CHECK_USER: 'authTypes/CHECK_USER',
}

export const login = (payload) => ({
  type: authTypes.LOGIN,
  payload,
})

export const logout = () => ({
  type: authTypes.LOGOUT,
})

export const checkUser = () => ({
  type: authTypes.CHECK_USER,
})
