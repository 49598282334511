import React from 'react'
import { Button } from '@makedonski/admin-ui'
import { useHistory } from 'react-router-dom'
import './styles.scss'
import { checkIsDealer } from 'utilities'

const ClientAddObject = ({ hide, client, handleSingleAdd, handleGroupAdd, handleMergedObjects }) => {
  const history = useHistory()
  const isDealer = checkIsDealer()


  return (
    <div className="popup-client-add-object-container">
      <div className="row">
        <h4>Начин на добавяне</h4>
        <Button.Icon name="plus" onClick={hide} />
      </div>
      <p
        onClick={() => {
          handleSingleAdd()
          hide()
        }}
      >
        Единично
      </p>
      <p onClick={() => history.push(`/clients/${client._id}/create_objects`)}>Масово</p>
      {!isDealer && <p
        onClick={() => {
          handleGroupAdd()
          hide()
        }}
      >
        Група фактура
      </p>}
      {!isDealer && client.clientType !== 'Консуматор' && <p
        onClick={() => {
          handleMergedObjects()
          hide()
        }}
      >
        Група производители
      </p>}
    </div>
  )
}

export default ClientAddObject
