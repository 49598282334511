import React, { useState } from "react"
import { Button } from '@makedonski/admin-ui'
import "./styles.scss"
import { Inputs } from "components"
import moment from "moment"
import { Portal } from "react-overlays"

const DeleteCommission = ({ close, onSuccess }) => {
    const [monthYear, setMonthYear] = useState(moment().format('MM/YYYY'))

    return <div className="modal-delete-commission-container">
        <div className="modal-delete-commission-header row">
            <h2>Изтриване на комисион</h2>
            <div className="icon icon-close" onClick={close} />
        </div>
        <div className="modal-delete-commission-content col">
            <span>От месец:</span>
            <Inputs.DatePicker
                dateFormat="MMMM yyyy"
                value={moment(monthYear, 'MM/YYYY').toDate()}
                onChange={(date) => setMonthYear(moment(date).format('MM/YYYY'))}
                className="month-picker"
                showMonthYearPicker
                showFullMonthYearPicker
                popperContainer={({ children }) => (<Portal container={document.getElementById('calendar-portal')}>{children}</Portal>)}
                maxDate={moment().endOf('month').toDate()}
            />
        </div>
        <div className="modal-delete-commission-footer row">
            <Button.Raised text='Изтрий' onClick={() => { onSuccess(monthYear); close() }} />
        </div>
    </div>
}

export default DeleteCommission