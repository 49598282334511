import React from 'react'
import { useDispatch } from 'react-redux'
import { setOverlay } from '../../../../actions'
import './styles.scss'

const Comments = ({ value, client }) => {
  const dispatch = useDispatch()

  const length = (value?.length ?? 0) + (client?.objects?.reduce((acc, { comments }) => acc + comments.length, 0) ?? 0)

  return (
    <div className="client-cells-comments-container row">
      {`${length} Коментар${length !== 1 ? 'a' : ''}`}
      <div
        className="icon icon-arrow-right-2"
        onClick={() => {
          dispatch(setOverlay({ isOpen: true, type: 'clientComments', props: { client } }))
        }}
      />
    </div>
  )
}

export default Comments
