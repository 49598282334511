import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react'
import { Input } from '@makedonski/admin-ui'
import { debounce } from 'lodash'
import './styles.scss'

const Autocomplete = ({
  placeholder,
  value,
  onChange,
  options: optionsProps = [],
  updateOptions,
}) => {
  const scrollRef = useRef(null)
  const [suggestOpen, setSuggestOpen] = useState(false)
  const [index, setIndex] = useState(0)

  useEffect(() => {
    updateOptions && updateOptions('')
    //eslint-disable-next-line
  }, [])

  const updateIndex = (up = true) => {
    let newIndex = index === null ? 0 : index
    newIndex = up ? newIndex + 1 : newIndex - 1
    if (!up && newIndex < 0) newIndex = 0
    if (up && newIndex > options.length - 1) newIndex = 0
    setIndex(newIndex)
    if (scrollRef.current) scrollRef.current.scrollTop = newIndex * 35
  }

  const onChangeDelayed = useCallback(
    debounce((value) => updateOptions && updateOptions(value), 200)
  )

  const options = useMemo(
    () => optionsProps?.filter(({ label }) => !value || label.startsWith(value)),
    [value]
  )

  return (
    <div
      className={`input-autocomplete-container ${
        suggestOpen && options?.length ? 'active' : ''
      }`}
    >
      <Input.Text
        value={value}
        placeholder={placeholder ?? ''}
        onFocus={() => setSuggestOpen(true)}
        onBlur={() => setSuggestOpen(false)}
        onChange={(e) => {
          e.persist()
          onChange && onChange(e.target.value)
          onChangeDelayed(e.target.value)
        }}
        onKeyDown={({ keyCode }) => {
          setSuggestOpen(true)
          if (keyCode === 40) updateIndex(true)
          else if (keyCode === 38) updateIndex(false)
          if (keyCode === 13) {
            if (!options.length) return
            const option = options[index]
            onChange && onChange(option)
            setIndex(0)
            setSuggestOpen(false)
          }
        }}
      />
      <div ref={scrollRef} className="suggestions-container">
        <div
          className={`row single-suggestion`}
          onClick={() => {
            onChange && onChange(options[index])
            setIndex(0)
            setSuggestOpen(false)
          }}
        >
          {options[index]?.label}
        </div>
      </div>
      {suggestOpen && options.length > 1 && (
        <div className="icon icon-arrows no-active" />
      )}
    </div>
  )
}

export default Autocomplete
