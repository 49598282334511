import React, { useEffect, useRef } from 'react'
import './styles.scss'

const CollapsableRow = ({ children, header, open, hideArrow }) => {
  const collapseable = useRef()
  const arrow = useRef()
  const handleCollapse = () => {
    const elem = collapseable.current
    elem.style.height = ''
    elem.style.transition = 'none'
    const startHeight = window.getComputedStyle(elem).height
    elem.classList.toggle('active')
    arrow.current.classList.toggle('active')
    const height = window.getComputedStyle(elem).height
    elem.style.height = startHeight
    requestAnimationFrame(() => {
      elem.style.transition = ''
      requestAnimationFrame(() => (elem.style.height = height))
    })
    elem.addEventListener('transitionend', () => (elem.style.height = ''))
  }

  useEffect(() => {
    if (open) handleCollapse()
  }, [])

  return (
    <div className="shared-collapseable-row-container col">
      <div className="collapseable-row-header row">
        {header}
        {!hideArrow && <div className="icon icon-arrow-right" ref={arrow} onClick={handleCollapse} />}
      </div>
      <div className="collapseable-row-content">
        <div className="collapseable-row-inner-content" ref={collapseable}>
          {children}
        </div>
      </div>
    </div>
  )
}
export default CollapsableRow
