import React from 'react'
import { Button } from '@makedonski/admin-ui'
import moment from 'moment'
import './styles.scss'

const Files = ({
  files = [
    { filename: 'aaa', user: { fullName: 'ivan' }, createdAt: new Date() },
    { filename: 'bbb', user: { fullName: 'ivan' }, createdAt: new Date() },
    { filename: 'bbb', user: { fullName: 'ivan' }, createdAt: new Date() },
    { filename: 'bbb', user: { fullName: 'ivan' }, createdAt: new Date() },
    { filename: 'bbb', user: { fullName: 'ivan' }, createdAt: new Date() },
    { filename: 'bbb', user: { fullName: 'ivan' }, createdAt: new Date() },
  ],
  handleUpload = () => {},
}) => {
  return (
    <div className="card-files-container">
      {handleUpload && (
        <div className="row tab-files-upload">
          <Button.UploadButton
            text={
              <span className="row ripple">
                Attach files <div className="icon icon-attach" />
              </span>
            }
            multiple
            onChange={({ target: { files } }) => handleUpload(files)}
          />
        </div>
      )}
      {files?.map((file, i) => (
        <div className="single-file-tab row" key={`single-file-${i}`}>
          <div className="col col-data">
            <a href={file?.url || ''} target="_blank" rel="noopener noreferrer">
              <p className="file-name">{file?.filename}</p>
            </a>
            <p className="file-details">
              {file?.createdAt && moment(file?.createdAt).format('DD.MM.YYYY')}{' '}
              | {file?.user?.fullName}
            </p>
          </div>
          <div className="row col-buttons ">
            {/*<div className="icon icon-pen-green ripple"></div>
            <div className="icon icon-download-green ripple"></div>*/}
          </div>
        </div>
      ))}
    </div>
  )
}

export default Files
