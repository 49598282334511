import { Button } from "@makedonski/admin-ui"
import { Popover } from "@varld/popover"
import { startLoading, getCommissions, setCommissionsFields, generateCommissions, setModal, confirmCommissions } from "actions"
import { Inputs, Shared } from 'components'
import { commissionsFields, commissionsSearchOptions, commissionStatusMap } from "config/constants"
import { useQuery } from "hooks"
import { isEmpty } from "lodash"
import moment from "moment"
import React, { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { initialState } from "reducers/commissions"
import { mapClientsSearch, renderCell } from "utilities"
import "./styles.scss"

const availableFilters = {
    fullName: { type: "regex" },
    status: { type: "idDropdown", values: Object.entries(commissionStatusMap).filter(([key]) => key !== 'undefined').map(([key, value]) => ({ _id: key, name: value })) },
    userFullName: { type: "regex" },
    userIsCompany: { type: "idDropdown", single: true, values: [{ name: 'Юридическо лице', _id: 'true' }, { name: 'Физическо лице', _id: 'false' },] },
    userEmail: { type: "regex" },
    clientFullName: { type: "regex" },
    clientIdentifier: { type: "regex" },
}
const availableSort = commissionsFields.map(({ value }) => value).filter((f) => !['clientIdentifier'].includes(f))

const Commissions = ({ }) => {
    const dispatch = useDispatch()
    const history = useHistory()

    const {
        commissions,
        hasNextPage,
        nextPage,
        totalDocs,
        showCheckboxes,
        selectedCheckboxes
    } = useSelector(({ commissions }) => commissions)

    const {
        month: monthParam = moment().subtract(1, 'months').format('MM.YYYY'),
        searchBy = '',
        sort: sortParam = '{}',
        filter: filterParam = '{}',
        handleUrlChangeMultiple
    } = useQuery()

    const month = useMemo(() => moment(monthParam, 'MM.YYYY').toDate(), [monthParam])
    const filter = useMemo(() => JSON.parse(filterParam), [filterParam])
    const sort = useMemo(() => JSON.parse(sortParam), [sortParam])

    const searchQuery = useMemo(() => {
        return { monthYear: moment(month).format('MM/YYYY'), ...mapClientsSearch(sort, filter, undefined, availableFilters) }
    }, [sortParam, filterParam, month,])


    const fetch = ({ forExport = false, onSuccess = () => { }, ...payload } = {}) => {
        dispatch(startLoading())
        dispatch(getCommissions({ payload: { page: 1, ...searchQuery, ...payload }, forExport, onSuccess }))
    }

    const fetchAll = () => {
        if (totalDocs !== commissions?.length) {
            dispatch(startLoading())
            fetch({ noPagination: true })
        }
    }

    useEffect(() => {
        fetch()
        return () => dispatch(setCommissionsFields(initialState))
    }, [searchQuery])

    const sortingComponents = commissionsFields?.filter(({ sortable }) => sortable).reduce((acc, { value }) => {
        return {
            ...acc,
            [value]: <Popover popover={({ close }) => <Shared.SortingComponent
                hide={close}
                column={value}
                availableSort={availableSort}
                availableFilters={availableFilters}
            />}>
                <div className="icon icon-arrow-down" />
            </Popover>
            // <Popover popover={({ close }) => <SortingComponent hide={close} column={value} type={type} />}>
            //     <div className="icon icon-arrow-down" />
            // </Popover>

        }
    }, {})

    return <div className="screen-commissions-container">
        <div className="screen-commissions-header row">
            <Inputs.DatePicker
                customInput={<div className='row'>
                    <h2 className="row">{moment(month).format('м. MMMM')}</h2>
                    <div className="icon icon-calendar-custom" />
                </div>}
                className="month-picker"
                showMonthYearPicker
                showFullMonthYearPicker
                value={month}
                onChange={(date) => handleUrlChangeMultiple({ month: moment(date).format('MM.YYYY') })}
                minDate={moment().subtract(10, 'years').toDate()}
                maxDate={moment().endOf('month').toDate()}
            />
            <Inputs.SearchMulti
                search={filter?.[searchBy] || ''}
                searchBy={searchBy || commissionsSearchOptions.at(0).value}
                handleChange={({ search, searchBy: newSearchBy }) => {
                    const newFilter = { ...filter, [searchBy]: undefined, [newSearchBy]: search || undefined }
                    handleUrlChangeMultiple({
                        searchBy: search ? newSearchBy : undefined,
                        filter: Object.values(newFilter).filter(Boolean).length ? JSON.stringify(newFilter) : undefined
                    })
                }}
                options={commissionsSearchOptions}
                popupText={{ secondary: ' ' }}
            />
            {showCheckboxes
                ? <div className="row row-buttons">
                    <Button.Raised className="btn-other-actions" text="Откажи" onClick={() => dispatch(setCommissionsFields({ showCheckboxes: false, selectedCheckboxes: [] }))} />
                    <Button.Raised text="Потвърди"
                        disabled={isEmpty(selectedCheckboxes)}
                        onClick={() => {
                            if (showCheckboxes.action === 'massEdit') dispatch(setModal({ isOpen: true, type: 'editCommissions', props: { selectedCheckboxes, commissions, fetch } }))
                            if (showCheckboxes.action === 'confirm') {
                                dispatch(startLoading())
                                dispatch(confirmCommissions({
                                    payload: { ids: selectedCheckboxes }, onSuccess: () => {
                                        dispatch(setCommissionsFields({ showCheckboxes: false, selectedCheckboxes: [] }))
                                        fetch()
                                    }
                                }))
                            }
                            if (showCheckboxes.action === 'generate') {
                                dispatch(startLoading())
                                dispatch(generateCommissions({
                                    payload: { monthYear: moment(month).format('MM/YYYY'), ids: selectedCheckboxes }, onSuccess: () => {
                                        dispatch(setCommissionsFields({ showCheckboxes: false, selectedCheckboxes: [] }))
                                        fetch()
                                    }
                                }))
                            }
                            if (showCheckboxes.action === 'emails') dispatch(setCommissionsFields({ showCheckboxes: false, selectedCheckboxes: [] }))
                        }} />
                </div>
                : <div className="row row-buttons">
                    <Popover popover={({ close }) => <div className="screen-commissions-other-actions-popup-container">
                        <div className="row row-header"><p>Изберете действие</p><div className="icon icon-close" onClick={close} /></div>
                        {/* <p
                            className="disabled"
                            onClick={() => {
                                fetchAll()
                                dispatch(setCommissionsFields({ showCheckboxes: { action: 'emails' } }))
                                close()
                            }}>Изпрати имейли
                        </p> */}
                        <p onClick={() => {
                            history.push(`/references/commissions`)
                            close()
                        }}>Преглед справка “Комисионни”
                        </p>
                        <p onClick={() => {
                            dispatch(setModal({ isOpen: true, type: 'addCommission', props: { fetch, onRequestClose: fetch } }))
                            close()
                        }}>Добавяне на комисионна
                        </p>
                        <p onClick={() => {
                            dispatch(setCommissionsFields({ showCheckboxes: { action: 'massEdit' } }))
                            close()
                        }}>Масова редакция
                        </p>
                        <p onClick={() => {
                            fetch({ forExport: true, noPagination: true })
                            close()
                        }}>Експорт ексел формат
                        </p>
                        <p onClick={() => {
                            handleUrlChangeMultiple({ search: null, searchBy: null, sort: null, filter: null, })
                            close()
                        }}>Изчисти всички филтри</p>
                    </div>}>
                        <Button.Raised className="btn-other-actions" text="Други действия" />
                    </Popover>
                    <Button.Raised className="btn-confirm" text="Одобри" onClick={() => {
                        fetchAll()
                        dispatch(setCommissionsFields({ showCheckboxes: { action: 'confirm' } }))
                    }} />
                    <Button.Raised text="Генерирай" onClick={() => {
                        fetchAll()
                        dispatch(setCommissionsFields({ showCheckboxes: { action: 'generate' } }))
                    }} />
                </div>}
        </div>
        <div className="screen-commissions-content">
            <Shared.Table
                data={commissions}
                columns={commissionsFields}
                renderCell={(row, field) => renderCell.commissions(row, field, { fetch })}
                sortingComponent={sortingComponents}
                handlePagination={() => hasNextPage && fetch({ page: nextPage })}
                headerWidth={commissionsFields.reduce((a, c) => a + (c.size || 300), 0)}
                useCheckboxes={!!showCheckboxes}
                selectedCheckboxes={selectedCheckboxes}
                onCheckboxChange={(value) => dispatch(setCommissionsFields({ selectedCheckboxes: value }))}
                disabledCheckboxes={
                    ['confirm'].includes(showCheckboxes?.action) ? commissions.filter(({ status }) => status !== 'invoiced').map(({ _id }) => _id)
                        : ['generate'].includes(showCheckboxes?.action) ? commissions.filter(({ status }) => status !== 'confirmed').map(({ _id }) => _id)
                            : ['emails'].includes(showCheckboxes?.action) ? commissions.filter(({ status }) => status !== 'generated').map(({ _id }) => _id)
                                : []
                }
            />
        </div>
        <div className="screen-commissions-footer row">
            <div className="screen-commissions-inner-footer row">
                <p className="row">Общо: {totalDocs || 0} фактури</p>
                {showCheckboxes && <p className="row">Избрани: {selectedCheckboxes?.length || 0} фактури</p>}
            </div>
        </div>
    </div>
}

export default Commissions