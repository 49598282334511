import { getConsumptionAnalysis, startLoading } from 'actions'
import { consumptionTimeFormats } from 'config/constants'
import { useQuery } from 'hooks'
import { sortBy } from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { xlsxExport } from 'utilities'
import { Analysis } from '../../components'
import './styles.scss'

const ConsumptionAnalysis = ({ }) => {
  const dispatch = useDispatch()

  const [response, setResponse] = useState({})
  const { mainStats, objectStats, totalStats, clientsData, marketPrices } = response || {}

  const { clients, from, to, tempType, type = 'hours', exclude, handleUrlChangeMultiple } = useQuery()

  const objects = useMemo(() => {
    return JSON.stringify(clientsData
      ?.reduce((acc, { objects }) => [...acc, ...objects], [])
      ?.filter(({ _id }) => !exclude?.split(",")?.includes(_id))
      ?.map(({ _id }) => _id) || [])
  }, [clientsData, exclude])


  const consumptionPayload = useMemo(() => ({
    clients: clients.split(","),
    objects: JSON.parse(objects),
    type: tempType ?? type,
    from: moment(from, 'DD.MM.YYYY').isValid() ? moment(from, 'DD.MM.YYYY').toDate() : undefined,
    to: moment(to, 'DD.MM.YYYY').isValid() ? moment(to, 'DD.MM.YYYY').toDate() : undefined,
  }), [clients, objects, tempType, type, from, to])

  const fetch = useCallback(() => {
    if ((consumptionPayload.from && !consumptionPayload.to)) return
    dispatch(startLoading())
    dispatch(getConsumptionAnalysis({
      payload: consumptionPayload, onSuccess: (res) => {
        setResponse(res)
        if (tempType) handleUrlChangeMultiple({ type: tempType, tempType: undefined })
      }
    }))
  }, [dispatch, consumptionPayload])
  useEffect(() => { fetch() }, [])

  const handleExport = () => {
    const fields = [
      { label: 'Период', value: 'period' },
      ...(objectStats?.map(({ itn }) => ({ label: itn, value: itn })) || []),
    ]
    if (['hours', 'minutes'].includes(type)) fields.splice(1, 0, { label: 'Цена', value: 'price' })

    xlsxExport({
      fields,
      data: sortBy(objectStats?.reduce((acc, { itn, data }) => {
        const addPrice = ['hours', 'minutes'].includes(type)
        const newArray = [...acc]
        Object.entries(data).forEach(([key, value]) => {
          const periodIndex = newArray.findIndex(({ period }) => period === key)
          if (periodIndex === -1) newArray.push({ period: key, [itn]: value, price: addPrice ? marketPrices[key] : undefined })
          else newArray.splice(periodIndex, 1, { ...(newArray?.[periodIndex] || { period: key }), [itn]: value, price: addPrice ? marketPrices[key] : undefined })
        })
        return newArray
      }, []), ({ period }) => moment(period, consumptionTimeFormats[type])),
      render: (row, field) => row[field]
    })
  }

  return (
    <div className="screen-consumption-analysis-container">
      <div className="screen-consumption-analysis-content row">
        <div className="screen-consumption-analysis-filter">
          <Analysis.Filter
            clientsData={clientsData}
            selectedObjects={JSON.parse(objects) || []}
            exclude={exclude?.split(",") || []}
            fetch={fetch}
          />
        </div>
        <div className="screen-consumption-analysis-inner-content col">
          {mainStats ? <>
            <Analysis.ConsumptionGraph handleExport={handleExport} mainStats={mainStats} marketPrices={marketPrices} />
            {type !== 'minutes' && <Analysis.ConsumptionSummary totalStats={totalStats} />}
            <Analysis.ConsumptionObjects objectStats={objectStats} />
            {/* <Analysis.ConsumptionCalculator /> */}</> : <>
            <div className="empty-state row">
              <h2>Моля изберете правилните параметри и натиснете "Анализ"!</h2>
            </div>
          </>}
        </div>
      </div>
    </div>
  )
}

export default ConsumptionAnalysis
