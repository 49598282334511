const servers = {
  dev: 'https://synergon-fast-dev.fidweb.net',
  prod: 'https://synergon-fast-prod.fidweb.net',
  devOld: 'https://synergon-api-dev.fidweb.net',
  prodOld: 'https://synergon-api.fidweb.net',
  energyop: 'https://erp-api.energy-op.eu',
  energyopDev: 'https://erp-api-dev.energy-op.eu',
  retc: 'https://retc-api.fidweb.net',
  retcDev: 'https://retc-dev.fidweb.net',
  zagora: 'https://zagora-api.fidweb.net',
  proakt: 'https://proakt-api.fidweb.net',
  ws: 'https://wsenergy-api.fidweb.net',
  mexus: 'https://tests.mexus.org',
  local: 'http://localhost:5000',
}

export const URL = process.env.REACT_APP_URL || servers.local

export const s3Credentials = {
  bucketName: process.env.REACT_APP_S3_BUCKET_NAME || 'dev-fidweb',
  dirName: process.env.REACT_APP_S3_DIR_NAME || 'files',
  region: 'eu-central-1',
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID || 'AKIA5L7PZ7W5MOVC5MHY',
  secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY || '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'
}

export const APIS_API = 'https://regdata.apis.bg/api/v1'
export const APIS_CREDENTIALS = { username: 'SYNERGON2020', password: '2020ENERGY' }

export const SUPPORT_URL = process.env.REACT_APP_SUPPORT_URL || 'https://customer-support-dev.herokuapp.com'

export const EMAIL_STATS_URL = process.env.REACT_APP_EMAIL_STATS_URL || 'https://dl.synergonenergy.bg'
export const EMAIL_STATS_USERNAME = process.env.EMAIL_STATS_USERNAME || 'iDonTliKeBitLy!@222@1!'
export const EMAIL_STATS_PASSWORD = process.env.REACT_APP_EMAIL_STATS_PASSWORD || 'S0m4V3ry51ttyP455W0rDByM3XU5!!!!!'
