import { Button, Input } from '@makedonski/admin-ui'
import { Popover } from '@varld/popover'
import { createInterest, getClientsForDropdown, getInterest, startLoading, updateInterest } from 'actions'
import { Inputs, Popup } from 'components'
import { useQuery } from "hooks"
import { pick } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from 'react-router-dom'
import "./styles.scss"

const InterestsForm = ({ }) => {
    const editFields = ['_id', 'monthYear', 'client', 'amount', 'doNotCalculateInterests', 'shouldUse']
    const dispatch = useDispatch()
    const history = useHistory()
    const { _id } = useQuery()

    const { clients } = useSelector(({ data }) => data)
    useEffect(() => { dispatch(getClientsForDropdown({ search: "", getAllDetails: true })) }, [])

    const [data, setData] = useState({ monthYear: moment().subtract(1, 'months').format('MM/YYYY'), shouldUse: true })
    const handleChange = (payload) => setData((prev) => ({ ...prev, ...payload }))
    useEffect(() => { if (_id) dispatch(getInterest({ payload: _id, onSuccess: (res) => setData({ ...pick(res, editFields) }) })) }, [])

    const handleCreate = () => {
        dispatch(startLoading())
        dispatch(createInterest({
            payload: {
                ...data,
                client: data?.client?._id,
                description: 'Неустойка за просрочено плащане към дата',
                invoiceGroup: data?.client?.defaultInvoiceGroup,
                manuallyAdded: true
            },
            onSuccess: () => history.goBack()
        }))
    }
    const handleEdit = () => {
        dispatch(startLoading())
        dispatch(updateInterest({
            payload: {
                ...data,
                client: data?.client?._id,
            },
            onSuccess: () => history.goBack()
        }))
    }

    return <div className="screen-interests-form-container">
        <div className="screen-interests-form-header row">
            <h2>{_id ? "Редакция" : "Добавяне"} лихва</h2>
            <Button.Raised
                text={_id ? "Редактирай" : "Добави"}
                onClick={_id ? handleEdit : handleCreate}
            />
        </div>
        <div className="screen-interests-form-content">
            <div className="row">
                <div className="col">
                    <span>Месец</span>
                    <Inputs.DatePicker
                        dateFormat='MM/yyyy'
                        className="month-picker"
                        showMonthYearPicker
                        showFullMonthYearPicker
                        disabled={_id}
                        value={data?.monthYear && moment(data?.monthYear, 'MM/YYYY').toDate()}
                        onChange={(value) => handleChange({ monthYear: moment(value).format('MM/YYYY') })}
                    />
                </div>
                <div className="col">
                    <span>Клиент</span>
                    {_id
                        ? <Input.Text disabled value={data?.client?.fullName ?? ''} />
                        : <Popover popover={({ close }) => <Popup.Select
                            disabled={_id}
                            single
                            search
                            handleSearch={(value) => dispatch(getClientsForDropdown({ search: value, getAllDetails: true }))}
                            hide={close}
                            value={data?.client?._id}
                            handleChange={(value) => {
                                const client = clients.find(({ _id }) => _id === value)
                                handleChange({ client })
                            }}
                            options={clients?.map(({ eic, pin, fullName, _id }) => ({ label: `${fullName} | ${eic || pin}`, value: _id, }))}
                            text={{ main: 'Търси по име/ЕИК/ЕГН', secondary: 'Клиенти' }}
                        />}>
                            <Input.Text readOnly value={data?.client?.fullName ?? ''} />
                        </Popover>
                    }
                </div>
                <div className="col">
                    <span>Сума</span>
                    <Inputs.TextLabeled
                        text="лв."
                        value={data?.amount ?? ''}
                        onChange={({ target: { value } }) => {
                            if (!/^-?\d*(\.||\.\d{0,2})?$/.test(value) && value !== '') return
                            handleChange({ amount: value })
                        }}
                        disabled={_id}
                    />
                </div>
                <div className="col">
                    <span>Не фактурирай лихви</span>
                    <Button.Switch isOn={data?.doNotCalculateInterests} onChange={() => handleChange({ doNotCalculateInterests: !data?.doNotCalculateInterests })} />
                </div>
                <div className="col">
                    <span>Да се прилага във фактура</span>
                    <Button.Switch isOn={data?.shouldUse} onChange={() => handleChange({ shouldUse: !data?.shouldUse })} />
                </div>
            </div>
        </div>
    </div>
}

export default InterestsForm