import { Button } from "@makedonski/admin-ui"
import { Alerts, Headers } from "@makedonski/socourt-utilities";
import { Popover } from "@varld/popover";
import { producersReferencesBrowse, producersReferencesSendEmails, setModal, setProducersFields, startLoading, stopLoading } from "actions";
import { Inputs, Shared } from "components";
import { producersReferencesFields, producersReferencesOptions } from "config/constants";
import { URL } from "config/settings";
import { useCollapsable, useQuery } from "hooks";
import { debounce, groupBy } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef } from "react"
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getTextForConfirmationModal, mapClientsSearch, renderCell } from "utilities";
import "./styles.scss"

const References = ({ TypeSelector }) => {

  const dispatch = useDispatch()
  const history = useHistory()
  const { showCheckboxes, selectedCheckboxes, docs, totalDocs, nextPage, hasNextPage, } = useSelector(({ producers }) => producers) || {}
  const {
    monthYear = moment().subtract(1, 'months').format('MM/YYYY'),
    sort: sortParam = '{}',
    filter: filterParam = '{"type":["producerSimpleByDays","producerSimpleByHours"]}',
    searchBy = '',
    handleUrlChangeMultiple
  } = useQuery()

  const availableSort = ['createdAt', 'data.sheet1.summary1.totalProduction', 'data.sheet1.summary1.singleUnitPrice', 'data.sheet1.summary1.total',]
  const availableFilters = {
    'type': { type: 'idDropdown', values: producersReferencesOptions.filter(({ value }) => process.env.REACT_APP_PLATFORM !== 'Synergon' || !['producerAdditionalPeriodData'].includes(value)).map(({ value, label }) => ({ _id: value, name: label })) },
    'clientIdentifier': { type: 'regex' },
    'fullName': { type: 'regex' },
    'itn': { type: 'regex' },
    'object': { type: 'regex' },
    'file': { type: 'boolean' },
    'htmlFile': { type: 'boolean' },
    'statsSent': { type: 'boolean' },
    'invoice': { type: 'boolean' },
    'additionalStat': { type: 'boolean' },
    'invoiceConfirmed': { type: 'boolean' },
    'createdAt': { type: 'date' }
  }

  const filter = useMemo(() => JSON.parse(filterParam), [filterParam])
  const sort = useMemo(() => JSON.parse(sortParam), [sortParam])
  const searchQuery = useMemo(() => ({ ...mapClientsSearch(sort, filter, undefined, availableFilters) }), [sortParam, filterParam])

  const fetch = useCallback((payload) => {
    dispatch(startLoading())
    dispatch(producersReferencesBrowse({ payload: { monthYear, ...searchQuery, ...payload } }))
  }, [dispatch, monthYear, searchQuery])

  const fetchDebounced = debounce(fetch, 300)
  useEffect(() => { fetch() }, [fetch])


  const handlePregenerate = async () => {
    try {
      dispatch(startLoading())
      const selected = selectedCheckboxes.map((_id) => docs.find((doc) => doc._id === _id))
      const grouped = groupBy(selected, 'type')
      const response = await Promise.all((Object.entries(grouped ?? {}).map(([type, docs]) => new Promise(async (resolve, reject) => {
        try {
          const response = await window.fetch(`${URL}/producers/generation`, {
            method: 'POST',
            headers: Headers.getWithAuth(),
            body: JSON.stringify({ monthYear, type, ids: docs?.map(({ objects }) => objects.join(",")) }),
          })
          if (!response.ok) resolve({ success: false, error: (await response.json()).error, type, docs })
          else resolve({ success: true })
        } catch (error) { return resolve({ success: false, error, type, docs }) }
      }))))
      const errors = response.filter(({ error }) => error).map(({ error, type, docs }) => ({ text: `${error?.message} - ${producersReferencesOptions.find(({ value }) => value === type)?.label}`, inner: docs?.map(({ clientData }) => clientData?.itn)?.join(' | ') }))
      if (errors.length) dispatch(setModal({ isOpen: true, type: 'confirmation', props: { title: 'Грешка', children: getTextForConfirmationModal(errors), }, }))
      else Alerts.success({ title: 'Успешно прегенериране!' })
    } catch (error) { Alerts.error(error?.message) }
    finally {
      dispatch(stopLoading())
      dispatch(setProducersFields({ showCheckboxes: false, selectedCheckboxes: [] }))
      fetch()
    }
  }

  const handleSend = () => {
    dispatch(startLoading())
    dispatch(producersReferencesSendEmails({
      payload: selectedCheckboxes,
      onSuccess: () => { fetch(); dispatch(setProducersFields({ showCheckboxes: false, selectedCheckboxes: [] })) }
    }))
  }

  const collapsableRef = useRef(null)
  const [isExpanded, setExpanded] = useCollapsable(collapsableRef)

  const sortingComponents = producersReferencesFields?.filter(({ sortable }) => sortable).reduce((acc, { value }) => {
    return {
      ...acc,
      [value]: <Popover popover={({ close }) => <Shared.SortingComponent
        hide={close}
        column={value}
        availableSort={availableSort}
        availableFilters={availableFilters}
        keyMap={{}}
        sortProp={sortParam}
        filterProp={filterParam}
      />}>
        <div className="icon icon-arrow-down" />
      </Popover>

    }
  }, {})

  return <div className="references-producers-references-container">
    <div className="references-producers-references-header row">
      <TypeSelector />
      <Inputs.DatePicker
        customInput={<div className='row'>
          <h2 className="row">{moment(monthYear, 'MM/YYYY').format('м. MMMM')}</h2>
          <div className="icon icon-calendar-custom" />
        </div>}
        className="month-picker"
        showMonthYearPicker
        showFullMonthYearPicker
        value={moment(monthYear, 'MM/YYYY')}
        onChange={(monthYear) => handleUrlChangeMultiple({ 'monthYear': moment(monthYear).format('MM/YYYY') })}
        minDate={moment().subtract(10, 'years').toDate()}
        maxDate={moment().add(1, 'months').endOf('month').toDate()}
      />
      <div>
        <Inputs.SearchMulti
          search={filter?.[searchBy] || ''}
          searchBy={searchBy || 'clientIdentifier'}
          handleChange={({ search, searchBy: newSearchBy }) => {
            const newFilter = { ...filter, [searchBy]: undefined, [newSearchBy]: search || undefined }
            handleUrlChangeMultiple({
              searchBy: search ? newSearchBy : undefined,
              filter: Object.values(newFilter).filter(Boolean).length ? JSON.stringify(newFilter) : undefined
            })
          }}
          options={[
            { value: 'clientIdentifier', label: 'ЕИК/ЕГН', client: true },
            { value: 'fullName', label: 'Име на фирма', shortLabel: 'Фирма', client: true },
          ]}
          popupText={{ secondary: ' ' }}
        />
      </div>
      <div className="row">
        <Inputs.RoundedButtons
          value={['producerSimpleByDays', 'producerSimpleByHours'].some((type) => filter?.type?.includes(type)) && 'producerSimple'}
          buttons={[{ label: 'Опростена', value: 'producerSimple' }]}
          onChange={() => {
            const newFilter = { ...filter, type: ['producerSimpleByDays', 'producerSimpleByHours'] }
            handleUrlChangeMultiple({ filter: Object.values(newFilter).filter(Boolean).length ? JSON.stringify(newFilter) : undefined })
          }}
        />
        <Inputs.RoundedButtons
          value={['producerFullFByDays', 'producerFullFByHours'].some((type) => filter?.type?.includes(type)) && 'producerFullF'}
          buttons={[{ label: 'Пълна Ф', value: 'producerFullF' }]}
          onChange={() => {
            const newFilter = { ...filter, type: ['producerFullFByDays', 'producerFullFByHours'] }
            handleUrlChangeMultiple({ filter: Object.values(newFilter).filter(Boolean).length ? JSON.stringify(newFilter) : undefined })
          }}
        />
        <Inputs.RoundedButtons
          value={['producerFullRBByDays', 'producerFullRBByHours'].some((type) => filter?.type?.includes(type)) && 'producerFullRB'}
          buttons={[{ label: 'Пълна РБ', value: 'producerFullRB' }]}
          onChange={() => {
            const newFilter = { ...filter, type: ['producerFullRBByDays', 'producerFullRBByHours'] }
            handleUrlChangeMultiple({ filter: Object.values(newFilter).filter(Boolean).length ? JSON.stringify(newFilter) : undefined })
          }}
        />
      </div>
      {showCheckboxes
        ? <div className="row row-buttons">
          <Button.Raised text='Откажи' className="btn-other-actions" onClick={() => dispatch(setProducersFields({ showCheckboxes: false, selectedCheckboxes: [] }))} />
          {showCheckboxes?.action === 'send' ? <Button.Raised text="Изпрати" onClick={handleSend} /> :
            showCheckboxes?.action === 'pregenerate' ? <Button.Raised text="Прегенерирай" onClick={handlePregenerate} /> : null}
        </div>
        : <div className="row row-buttons">
          <Popover
            popover={({ close }) => <div className="popup-send-container">
              <div className="row">
                <h4>Изберете действие</h4>
                <Button.Icon name="plus" onClick={close} />
              </div>
              <p onClick={() => { fetch({ pagination: false }); dispatch(setProducersFields({ showCheckboxes: { action: 'send' } })); close() }}>Изпрати имейл</p>
              <p onClick={() => { fetch({ pagination: false }); dispatch(setProducersFields({ showCheckboxes: { action: 'pregenerate' } })); close() }}>Прегенерирай</p>
              <p onClick={() => setExpanded(!isExpanded)}>Експорти</p>
              <div className="collapsable-exports" ref={collapsableRef}>
                <p onClick={() => { fetch({ forExport: 'file', pagination: false, file: true, select: 'file', sort: { createdAt: -1 } }); close() }}>PDF-и</p>
                <p onClick={() => { fetch({ forExport: 'htmlFile', pagination: false, htmlFile: true, select: 'htmlFile', sort: { createdAt: -1 } }); close() }}>HTML-и</p>
                <p onClick={() => { fetch({ forExport: 'excel_1', pagination: false }); close() }}>Excel 1</p>
                <p onClick={() => { fetch({ forExport: 'excel_2', pagination: false }); close() }}>Excel 2</p>
              </div>
              <p onClick={() => { handleUrlChangeMultiple({ search: null, searchBy: null, sort: null, filter: null, }); close() }}>Изчисти всички филтри</p>
            </div>}
          >
            <Button.Raised text="Други действия" className="btn-other-actions" />
          </Popover>
          <Button.Raised text="Импорт" className="btn-import" onClick={() => dispatch(setModal({ isOpen: true, type: 'invoicesFileUpload', props: { forProducers: true } }))} />
          <Button.Raised text='Генерирай' onClick={() => dispatch(setModal({
            isOpen: true, type: 'producersReferencesGenerate', props: {
              title: 'Издаване на справки производители',
              data: { monthYear: monthYear || moment().format('MM/YYYY') },
              onClick: ({ monthYear, type, }) => {
                const url = new URLSearchParams()
                url.append('type', type)
                url.append('monthYear', monthYear)
                history.push('/references-producers/references-generate?' + url.toString())
              }
            }
          }))} />
        </div>}
    </div>
    <div className="references-producers-references-content">
      <Shared.Table
        columns={producersReferencesFields}
        data={docs}
        renderCell={(row, field, options) => renderCell.producersReferences(row, field, { ...options, dispatch, fetch })}
        handlePagination={() => hasNextPage && fetchDebounced({ page: nextPage })}
        useCheckboxes={!!showCheckboxes}
        selectedCheckboxes={selectedCheckboxes}
        onCheckboxChange={(value) => dispatch(setProducersFields({ selectedCheckboxes: value }))}
        disabledCheckboxes={[]}
        sortingComponent={sortingComponents}
      />
    </div>
    <div className="references-producers-references-footer row">
      <div className="references-producers-references-inner-footer row">
        <p className="row">Общо: {totalDocs || 0}</p>
      </div>
    </div>
  </div>
}

export default References