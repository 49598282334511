import { Alerts } from "@makedonski/socourt-utilities/lib/Alerts"
import { exportAjurNumbers, getCurrentInvoiceNumber, setCurrentInvoiceNumber, setOverlay, startLoading, updateClient } from "actions"
import { Shared, Inputs } from "components"
import { invoiceNumberTypes } from "config/constants"
import { useQuery } from "hooks"
import moment from "moment"
import React, { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { renderCell } from "utilities"
import "./styles.scss"

const InvoiceNumber = () => {
    const dispatch = useDispatch()
    const { type, startDate } = useQuery({ type: invoiceNumberTypes })

    const [data, setData] = useState([])
    const fetch = useCallback(() => {
        dispatch(startLoading())
        dispatch(getCurrentInvoiceNumber({ payload: { requestedFor: type }, onSuccess: (res) => setData([res]) }))
    }, [type])
    useEffect(() => { fetch() }, [fetch])

    const [ajurLoading, setAjurLoading] = useState(false)
    const [ajur, setAjur] = useState([])
    const fetchAjur = useCallback(() => {
        if (type !== 'ajur') return
        setAjurLoading(true)
        dispatch(exportAjurNumbers({
            payload: { invoicePeriodStart: moment(startDate).subtract(startDate ? 0 : 1, 'months').startOf('month') },
            onSuccess: (res) => { setAjur(res); setAjurLoading(false) }
        }))
    }, [type, startDate])
    useEffect(() => { fetchAjur() }, [fetchAjur])

    return <div className="references-invoice-number-container">
        <div className="references-invoice-number-table-container">
            <Shared.Table
                fixedWidth
                columns={[
                    { label: 'Последно използван номер', value: 'currentInvoiceNumber' },
                    { label: 'Следващ свободен номер', value: 'nextInvoiceNumber' },
                ]}
                data={data}
                renderCell={(row, field) => {
                    if (field === 'currentInvoiceNumber') return <div className="row">
                        {type === 'ajur' ? row?.currentInvoiceNumberRaw : row[field]}
                        {row.invoice && <div
                            className="icon icon-open"
                            onClick={() => dispatch(setOverlay({ isOpen: true, type: 'invoice', props: { invoice: row.invoice._id } }))}
                            style={{ width: 20, height: 20, marginLeft: 15 }}
                        />}
                    </div>
                    else if (field === 'nextInvoiceNumber') return <div className="">
                        <Inputs.TextEdit
                            value={type === 'ajur' ? row?.nextInvoiceNumberRaw : row[field]}
                            validation={(value) => /^\d+$/.test(value) || !value}
                            handleChange={(invoiceNumber) => {
                                const current = type === 'ajur' ? row?.nextInvoiceNumberRaw : row[field]
                                if (current === invoiceNumber) return
                                if (type === 'ajur' && !invoiceNumber.length) { Alerts.error('Грешка!', 'Моля въведете 5 символа!'); return }
                                if (type !== 'ajur' && invoiceNumber.length !== 10) { Alerts.error('Грешка!', 'Моля въведете 10 символа!'); return }
                                dispatch(startLoading())
                                dispatch(setCurrentInvoiceNumber({
                                    payload: { invoiceNumber, requestedFor: type },
                                    onSuccess: () => fetch()
                                }))
                            }}
                        />
                    </div>
                }}
            />
        </div>
        {type === 'ajur' && (<>
            <div className={`references-invoice-number-ajur-container ${ajurLoading && 'loading'}`}>
                {ajurLoading
                    ? <span className="inline-loader">Loading...</span>
                    : <Shared.Table
                        fixedWidth
                        columns={[
                            { label: '_id', value: '_id' },
                            { label: 'Име клиент', value: 'fullName' },
                            { label: 'ЕИК/ЕГН', value: 'eic' },
                            { label: 'Булстат', value: 'bulstat' },
                            { label: 'Ажур', value: 'ajur' },
                        ]}
                        data={ajur}
                        renderCell={(row, field, options) => {
                            if (field === 'eic') return row?.pin ?? row?.eic ?? ""
                            if (field === 'ajur') return <div className="">
                                <Inputs.TextEdit
                                    value={row[field]}
                                    validation={(value) => /^\d+$/.test(value) || !value}
                                    handleChange={(ajur) => {
                                        if (row[field] === ajur) return
                                        dispatch(startLoading())
                                        dispatch(updateClient({ data: { _id: row?._id, ajur }, onSuccess: fetchAjur }))
                                    }}
                                />
                            </div>
                            else return renderCell.default(row, field, options)
                        }}
                    />}
            </div>
            <div className="references-invoice-number-ajur-footer row">
                <div className="references-invoice-number-ajur-inner-footer row">
                    <p className="row">Общо: {ajur?.length || 0}</p>
                </div>
            </div>
        </>)}
    </div >
}

export default InvoiceNumber