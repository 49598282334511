import React, { useState, useMemo } from 'react'
import * as Async from 'awaity'
import { Alerts } from '@makedonski/socourt-utilities'
import { Button } from '@makedonski/admin-ui'
import { useDispatch } from 'react-redux'
import { isEmpty } from 'lodash'
import { useHistory } from 'react-router-dom'
import { setModal, setDataField, checkImportsExist, startLoading, stopLoading, setLoadingText } from 'actions'
import { Inputs } from 'components'
import { fileParser, handleUploadedData, uploadFiles } from 'utilities'
import './styles.scss'
import moment from 'moment'

const consumerOptions = [
  // { value: 'period', label: 'Почасови данни' },
  // { value: 'stpData', label: 'СТП данни' },
  { value: 'manualOverride', label: 'Сетълмент данни' },
  { value: 'stpSummarized', label: 'СТП обобщени данни' },
  { value: 'network', label: 'Мрежови данни' },
  { value: 'cfi', label: 'CFI' },
  { value: 'stp', label: 'СТП Коефициент' },
].filter(({ value }) => value !== 'cfi' || process.env.REACT_APP_PLATFORM === 'W&S')

const producerOptions = [
  { value: 'producerForecast', label: 'Прогноза' },
  { value: 'esoData', label: 'Цени на ЕСО' },
  { value: 'customGroupData', label: 'Цени на група' },
  { value: 'customIndividualData', label: 'Цени индивидуални' },
  { value: 'customProducerPricing', label: 'Почасова цена клиент' },
]

const erps = ['Златни Пясъци', 'ЕВН', 'Енерго Про', 'ЧЕЗ', 'ЕСО']
const erpsEnglishMap = { 'Златни Пясъци': 'golden', 'ЕВН': 'evn', 'Енерго Про': 'energo-pro', 'ЧЕЗ': 'chez', 'ЕСО': 'eso' }

const InvoicesFileUpload = ({ forProducers }) => {
  const options = forProducers ? producerOptions : consumerOptions
  const history = useHistory()
  const dispatch = useDispatch()
  const [data, setData] = useState({ type: options[0].value })
  const handleChange = (field, value) => setData({ ...data, [field]: value })

  const handleParse = async () => {
    if (['cfi'].includes(data.type)) {
      dispatch(startLoading())
      await uploadFiles({ files: data.files, location: `cfi/${erpsEnglishMap[data.erp]}/${moment().format('MM-YYYY')}/` })
      dispatch(stopLoading())
      dispatch(setModal({ isOpen: false }))
      Alerts.success({ title: `Успешно качване` })
    }
    else if (['manualOverride', 'stpSummarized', ...producerOptions.map(({ value }) => value)].includes(data.type)) {
      handleUploadedData({
        files: data.files,
        ...(['esoData', 'customGroupData'].includes(data.type) && { transformParsed: ([data]) => [{ ...data, parsedData: data.parsedData.slice(5) }] }),
        dispatch,
        history,
        type: data.type,
        additionalProps: { erp: data.erp }
      })
    } else {
      dispatch(startLoading())
      try {
        const files = (
          await Async.reduce(
            [...data?.files],
            async (files, file, i) => {
              dispatch(setLoadingText(`Loading... \n File ${i + 1}/${data?.files?.length}`))
              return [...files, await parseSingleFile(file)]
            },
            []
          )
        ).filter((file) => !isEmpty(file))
        if (files.some(({ error }) => error))
          throw new Error(
            files
              .map(({ error }) => error)
              .filter(Boolean)
              .join(',\n')
          )
        if (!isEmpty(files)) {
          dispatch(setDataField({ field: 'parsed', value: { type: data?.type, erp: data?.erp, files, stp: data?.stp } }))
          history.push(`/file/preview`)
          dispatch(setModal({ isOpen: false }))
        }
      } catch (e) {
        console.log(e)
        Alerts.error('Грешка при обработването на файлове!', e.message)
      } finally {
        if (['period', 'network', 'stpData'].includes(data?.type)) dispatch(stopLoading())
        dispatch(stopLoading())
        dispatch(setLoadingText(`Loading...`))
      }
    }
  }

  const parseSingleFile = async (file) =>
    new Promise((resolve) => {
      fileParser[data?.type](file, data.erp, async (parsedData, rawData) => {
        if (
          (data?.type === 'network' && isEmpty(parsedData)) ||
          (['period', 'stpData'].includes(data?.type) &&
            (isEmpty(parsedData) || parsedData.some(({ itn, total }) => !itn || typeof total !== 'number')))
        ) {
          return resolve({ error: file.name })
        } else return resolve({ fileName: file.name, parsedData, rawData })
      })
    })

  const skipConfirmationModal = useMemo(() => !JSON.parse(localStorage.getItem('hideExistingFilesWarning')), [])

  return (
    <div className="modal-invoices-file-upload-container">
      <div className="modal-invoices-file-upload-header row">
        <h2>Импорт на</h2>
        <Button.Icon
          name="plus"
          size="26px"
          className="btn-close"
          onClick={() => dispatch(setModal({ isOpen: false }))}
        />
      </div>
      <div className="modal-invoices-file-upload-content">
        <Inputs.RadioButtons buttons={options} value={data?.type} onClick={(value) => handleChange('type', value)} />
        <div className="row">
          {!forProducers && <div className="col">
            <span>ЕРП</span>
            <Inputs.Dropdown
              options={erps
                .map((erp) => ({ value: erp, label: erp }))
                .filter(({ label }) => ['manualOverride'].includes(data?.type) || label !== 'ЕСО')}
              value={data?.erp}
              onChange={({ value }) => handleChange('erp', value)}
              autoWidth
            />
          </div>}
          <div className="col">
            <span>Файлове</span>
            <Button.UploadButton
              text={
                <span className="row ripple">
                  Добави файл <div className="icon icon-attach" />
                </span>
              }
              multiple
              accept={'.csv, .xlsx, .xls'}
              onChange={({ target: { files } }) =>
                dispatch(
                  checkImportsExist({
                    payload: { fileName: [...files].map(({ name }) => name) },
                    onSuccess: () => handleChange('files', [...(data?.files || []), ...files]),
                    onFail: (response) =>
                      setData({
                        ...data,
                        files: [...(data?.files || []), ...(files || [])],
                        existingFiles: [...(data?.existingFiles || []), ...(response?.files || [])],
                      }),
                  })
                )
              }
            />
          </div>
        </div>
        <div className="col-files">
          {!isEmpty(data?.files) &&
            [...data?.files]?.map((file, i) => (
              <div className={` row-file ${data?.existingFiles?.includes(file?.name) && 'exist'}`} key={`file-${i}`}>
                <div className="row">
                  <Button.Icon
                    name="plus"
                    color="red"
                    onClick={() => {
                      const newFiles = [...(data?.files || [])]
                      newFiles.splice(i, 1)
                      handleChange('files', newFiles)
                    }}
                  />
                  <span>{file?.name}</span>
                </div>
                {data?.existingFiles?.includes(file?.name) && <span>Фигурира в системата!</span>}
              </div>
            ))}
        </div>
      </div>
      <div className="modal-invoices-file-upload-footer">
        <Button.Raised
          text="Импортирай"
          disabled={isEmpty(data?.files) || (!data?.erp && !forProducers)}
          onClick={() => {
            if (!isEmpty(data?.existingFiles) && skipConfirmationModal)
              dispatch(setModal({ isOpen: true, type: 'existingFileWarning', props: { handleParse } }))
            else handleParse()
          }}
        />
      </div>
    </div>
  )
}

export default InvoicesFileUpload
