import React from "react"
import { Button } from '@makedonski/admin-ui'
import moment from 'moment'
import { useDispatch } from "react-redux"
import { startLoading, updateObject } from "actions"
import { Inputs } from 'components'
import { useQuery } from 'hooks'
import "./styles.scss"
import { allRegistrationStatuses } from "config/constants"


const text = { documentsSentDate: 'Входиран', dpiGeneratedDate: 'Изготвен', dpiSignedDate: "Подписан" }

const ObjectExecutionDate = ({ object, field, fetch }) => {
    const dispatch = useDispatch()

    const { type } = useQuery()
    if (type !== 'register' || !allRegistrationStatuses.includes(object?.status)) return object?.[field] ? moment(object?.[field]).format('DD.MM.YYYY | HH:mm') : "--"

    if (!object?.[field]) return <div className="client-cells-object-execution-date-container row">
        <Button.Raised text={text[field]} onClick={() => {
            const data = { _id: object?._id, [field]: moment() }
            if (field === "documentsSentDate") data.status = 'documents-sent'
            dispatch(startLoading())
            dispatch(updateObject({ data, onSuccess: () => fetch({ pageOne: true }), }))
        }} />
        {['dpiGeneratedDate', 'dpiSignedDate'].includes(field) && <>
            <div style={{ width: 8 }} />
            <Button.Raised text="N/A" className='no-flex' onClick={() => {
                dispatch(startLoading())
                dispatch(updateObject({
                    data: { _id: object?._id, [field]: moment('01.01.0001', 'DD.MM.YYYY') },
                    onSuccess: () => fetch({ pageOne: true }),
                }))
            }} />
        </>}
    </div>

    return <div className="client-cells-object-execution-date-container row">
        <Inputs.TextEdit
            showTime
            type="date"
            value={object?.[field]}
            handleChange={(value) => {
                dispatch(startLoading())
                dispatch(updateObject({
                    data: { _id: object._id, [field]: value },
                    onSuccess: () => fetch({ pageOne: true }),
                }))
            }}
        />
        <div className="icon icon-delete" onClick={() => {
            dispatch(startLoading())
            dispatch(updateObject({ data: { _id: object?._id, [field]: null }, onSuccess: () => fetch({ pageOne: true }), }))
        }} />
    </div >
}

export default ObjectExecutionDate