import { default as Settings } from './Settings'
import { default as ClientsFieldFilter } from './ClientsFieldFilter'
import { default as FilterShortcuts } from './FilterShortcuts'
import { default as FilterTasksList } from './FilterTasksList'
import { default as FilterTasksBoard } from './FilterTasksBoard'
import { default as NewTaskClient } from './NewTaskClient'
import { default as NewTaskAssignees } from './NewTaskAssignees'
import { default as TaskOptions } from './TaskOptions'
import { default as Select } from './Select'
import { default as ClientsOptions } from './ClientsOptions'
import { default as ClientsFilterOptions } from './ClientsFilterOptions'
import { default as ClientSingleOptions } from './ClientSingleOptions'
import { default as ClientOptions } from './ClientOptions'
import { default as ClientAddObject } from './ClientAddObject'
import { default as ClientsObjectsFieldFilter } from './ClientsObjectsFieldFilter'
import { default as IntermediateInvoicesOptions } from './IntermediateInvoicesOptions'

export const Popup = {
  Settings,
  ClientsFieldFilter,
  FilterShortcuts,
  FilterTasksList,
  FilterTasksBoard,
  NewTaskClient,
  NewTaskAssignees,
  TaskOptions,
  Select,
  ClientsOptions,
  ClientsFilterOptions,
  ClientSingleOptions,
  ClientOptions,
  ClientAddObject,
  ClientsObjectsFieldFilter,
  IntermediateInvoicesOptions
}

export default Popup
