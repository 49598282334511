import React, { useState, useMemo, useEffect } from 'react'
import { Button } from '@makedonski/admin-ui'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { groupBy, isEmpty } from 'lodash'
import { getInvoice, setOverlay } from 'actions'
import { Shared } from 'components'
import { invoiceSectionFields, invoicePricingFields, invoiceReferencesOptions } from 'config/constants'
import { fileRequest, renderCell } from 'utilities'
import './styles.scss'
import { useHistory } from 'react-router-dom'

const Invoice = ({ invoice: _id }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [invoice, setInvoice] = useState({})
  const { type, invoiceNumber, invoiceDate, total, pricing, pdfFile, deletedAt, interestFiles, stats } = invoice || {}

  useEffect(() => {
    dispatch(getInvoice({
      payload: _id,
      onSuccess: (res) => setInvoice(res)
    }))
  }, [])


  const tabs = ['Ценообразуване', 'Експорти', 'Известия']
  const [selectedTab, setSelectedTab] = useState(tabs[0])

  const deliveryData = useMemo(() => {
    const grouped = groupBy(total?.delivery.reduce((acc, cur) => [...acc, ...cur.data], []), 'cipher')
    return Object.keys(grouped).reduce((acc, key) => [...acc, {
      "cipher": key,
      "name": grouped[key]?.at(0).name,
      "unit": grouped[key]?.at(0).unit,
      "quantity": grouped[key].reduce((a, c) => a + (c.quantity || 0), 0),
      "singleUnitPrice": grouped[key]?.at(0).singleUnitPrice,
      "value": grouped[key].reduce((a, c) => a + (c.value || 0), 0),
    }], [])
  }, [total])

  const columns = {
    'networkTotal': total?.consumption,
    'deliveryTotal': deliveryData,
  }

  const fieldsMap = useMemo(() => invoicePricingFields.reduce((acc, { value, label }) => ({ ...acc, [value]: label }), {}), [])

  return (
    <div className="card-invoice-container">
      <div className="card-invoice-header row">
        <h3>
          Фактура №: {invoiceNumber} |{' '}
          {moment(invoiceDate).format('MMMM').charAt(0).toUpperCase() + moment(invoiceDate).format('MMMM').slice(1)}
        </h3>
        {!deletedAt && <div className="icon icon-edit" onClick={() => {
          const url = new URLSearchParams()
          url.append('invoice', _id)
          if (type === 'leaving') url.append('leaving', true)
          history.push((type === 'equalizing' ? `/financial/invoices/create?` : type === 'producer' ? `/producers/invoices/form?` : `/financial/invoices-intermediate/create?`) + url.toString())
          dispatch(setOverlay({ isOpen: false }))
        }}></div>}
        {pdfFile && (
          <a href={pdfFile || ''} target="_blank" rel="noopener noreferrer">
            <div className="icon icon-open"></div>
          </a>
        )}
        <Button.Icon name="plus" size={28} onClick={() => dispatch(setOverlay({ isOpen: false }))} />
      </div>
      <div className="card-invoice-content">
        <p className="generated">Генерирана на: {moment(invoiceDate).format('DD.MM.YYYY HH:mm')}</p>

        {!isEmpty(interestFiles) && <div className="row row-interest-payments">
          Плащания по лихви: {interestFiles?.map((url, index) => <a key={index} href={url || ''} target="_blank" rel="noopener noreferrer">
            <div className="icon icon-open" />
          </a>)}
        </div>}
        {!isEmpty(stats) && <div className="row row-interest-payments">
          Справки: {stats?.map(({ url, type }, index) => <a key={index} href={url || ''} target="_blank" rel="noopener noreferrer" title={type}>
            <div className="icon icon-open" />
          </a>)}
        </div>}
        <Button.UnderlinedTab buttons={tabs} onChange={setSelectedTab} value={selectedTab} />
        {selectedTab === tabs[0] && Object.entries(pricing || {})?.filter(([key]) => !['raw', 'compensationsQuantity', 'compensationsAmount', 'extras', 'additionalInformation'].includes(key))?.map(([key, value], i) => (
          <Shared.CollapsableRow
            key={`pricing-${i}`}
            hideArrow={isEmpty(total) || !["networkTotal", 'deliveryTotal'].includes(key)}
            header={<div className="row row-invoice">
              <span>{
                key === 'withVatInterestsAndCompensations' && value < 0
                  ? "Стойност за възстановяване"
                  : fieldsMap[key]
              }</span>
              {`${Number(pricing[key]).toFixed(2)} лв.`}
            </div>}
          >
            <Shared.Table
              fixedWidth
              columns={invoiceSectionFields}
              data={columns[key] || []}
              renderCell={renderCell.invoiceSection}
            />
          </Shared.CollapsableRow>
        ))}
        {selectedTab === tabs[1] && <div style={{ padding: '0 1vw' }}>
          {[
            ...invoiceReferencesOptions,
            { label: 'Приложение по БНЕБ (общ; почасово)', value: 'bnebHourlyAggregated' },
            { label: 'Мрежови файл', value: 'networkFile' },
            { label: 'Инфо фактура', value: 'invoiceStatsByPoint' },
          ].map(({ value, label }) => <div
            key={value}
            className="row"
            style={{ cursor: 'pointer' }}
            onClick={async () => await fileRequest({
              URL: `/invoices/generateInvoiceStats`,
              payload: { _id, type: value }
            })}
          >
            <div style={{ marginRight: 14, }} className='icon icon-open' />
            <p>{label}</p>
          </div>)}
          {process.env.REACT_APP_PLATFORM === 'W&S' && <div
            className="row"
            style={{ cursor: 'pointer' }}
            onClick={async () => await fileRequest({ URL: `/parser/cfi`, payload: { _id } })}
          >
            <div style={{ marginRight: 14, }} className='icon icon-open' />
            <p>CFI</p>
          </div>}
        </div>}
      </div>
    </div>
  )
}

export default Invoice
