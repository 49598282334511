import React, { useMemo } from 'react'
import { Input } from '@makedonski/admin-ui'
import { Popover, Tooltip } from '@varld/popover'
import { Popup, Shared } from '../../'
import './styles.scss'
import { isArray } from 'lodash'

const Select = ({ className = '', editable, value, showRequired, options = [], useTooltip, ...rest }) => {
  const isRequired = () => showRequired && !value?.length && 'required'
  const popupProps = { value: rest.single && isArray(value) ? value[0] : value, options, ...rest }
  const text = useMemo(() => {
    if (rest.single) {
      const selected = options.find((opt) => opt?._id === value || opt?.value === value)
      return selected?.name || selected?.label || ""
    }
    return value?.map((type) => options?.find(({ _id }) => type?._id === _id || type === _id)?.name).join('; ') || ''
  }, [value, options])
  return (
    <Shared.ConditionalWrapper
      condition={useTooltip && text}
      wrapper={(children) => <Tooltip content={text}>{children}</Tooltip>}
    >
      <div className={`inputs-select-container ${className}`}>
        {editable && (
          <Popover popover={({ close }) => <Popup.Select hide={close} {...popupProps} />}>
            <div className="activity-icon-container row">
              <div className="icon icon-arrow-right" />
            </div>
          </Popover>
        )}
        <Input.Text value={text} disabled inputClassName={`${isRequired(value)}`} />
      </div>
    </Shared.ConditionalWrapper>
  )
}

export default Select
