import React from 'react'
import { Button } from '@makedonski/admin-ui'
import { productsPricesFields } from '../../../../config/constants'
import { Inputs } from '../../../'
import './styles.scss'

const ProductPrices = ({ products = [], data, handleChange, showRequired, editable = true }) => {
  const isRequired = (field) => showRequired && [undefined, null].includes(data?.price?.[field]) && 'required'

  return productsPricesFields.map(({ types, value, label, innerLabel }) => {
    const type = products?.find(({ _id }) => data?.product?._id === _id || data?.product === _id)?.name
    if (!type || !types?.includes(type)) return null
    return (
      <React.Fragment key={`price-field-${value}`}>
        <div className="col col-mini">
          <span>{label}</span>
          {['Микс', 'Смесен процент'].includes(type) && value === 'basePercentage' ? (
            <Inputs.PercentageSplit
              value={data?.price?.basePercentage ?? ''}
              onChange={(value) => {
                if (value > 100 || (!/^\d+$/.test(value) && value !== '')) return
                handleChange('basePercentage', value)
              }}
              inputClassName={`${isRequired('basePercentage')}`}
              disabled={!editable}
            />
          ) :
            ['Борса', 'Борса минус', 'Борса минус с Под и Таван на цената'].includes(type)
              && !['marketFloor', 'marketCeiling'].includes(value)
              && data?.price?.marketIsPercent
              ? (
                <Inputs.TextLabeled
                  label="%"
                  value={data?.price?.[value] ?? ''}
                  onChange={({ target }) => {
                    if (!/^\d{0,3}(\.||\.\d{0,2})?$/.test(target?.value) && target?.value !== '') return
                    if (Number(target?.value) > 100) return
                    handleChange(value, target.value)
                  }}
                  inputClassName={`${isRequired(value)}`}
                  disabled={!editable}
                />
              ) : (
                <Inputs.TextLabeled
                  label={innerLabel ?? "лв./МВтч"}
                  value={data?.price?.[value] ?? ''}
                  onChange={({ target }) => {
                    if (!/^\d{0,3}(\.||\.\d{0,2})?$/.test(target?.value) && target?.value !== '') return
                    handleChange(value, target.value)
                  }}
                  inputClassName={`${isRequired(value)}`}
                  disabled={!editable}
                />
              )}
        </div>
        {['Борса', 'Борса минус'].includes(type) && (
          <div className={`col col-mini client-forms-product-prices col-switch ${!editable && 'disabled'}`}>
            <span>В процент</span>
            <Button.Switch
              isOn={data?.price?.marketIsPercent}
              onChange={() => editable && handleChange('marketIsPercent', !(data?.price?.marketIsPercent))}
            />
          </div>)}
        {['Борса'].includes(type) && process.env.REACT_APP_PLATFORM === 'Energy Operations' && <div className={`col col-mini client-forms-product-prices col-switch ${!editable && 'disabled'}`}>
          <span>Борса База</span>
          <Button.Switch
            isOn={data?.price?.marketIsBase}
            onChange={() => editable && handleChange('marketIsBase', !(data?.price?.marketIsBase))}
          />
        </div>}
        {['Борса'].includes(type) && process.env.REACT_APP_PLATFORM === 'Zagora Energy' && (
          <div className="col col-mini">
            <span>Такса административно обслужване</span>
            <Inputs.TextLabeled
              label="лв./точка"
              value={data?.price?.taxPerObject ?? ''}
              onChange={({ target: { value } }) => {
                if (!/^\d{0,3}(\.||\.\d{0,2})?$/.test(value) && value !== '') return
                handleChange('taxPerObject', value)
              }}
              disabled={!editable}
            />
          </div>
        )}
      </React.Fragment>)
  })
}

export default ProductPrices
