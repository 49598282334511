import React from 'react'
import { Button } from '@makedonski/admin-ui'
import './styles.scss'

const leaves = [
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
]

const Leave = ({}) => {
  return (
    <div className="employee-leave-container">
      <div className="employee-leave-header row">
        <p>
          Полагащи се: <span>20</span>
        </p>
        <p>
          Използвани <span>4</span>
        </p>
        <p>
          Оставащи: <span>14</span>
        </p>
        <Button.Raised text="Нова" />
      </div>
      <div className="employee-leave-container">
        {leaves?.map((leave, i) => (
          <div key={`leave-${i}`} className="single-leave">
            <p className="date">12.10.- 17.10.2020</p>
            <p className="no-margin-bottom">
              Взети дни: <span>5</span>
            </p>
            <div className="row">
              <p>Служител заместващ:</p>

              <div className="single-assignee row">
                <div
                  className="profile-image icon"
                  style={{
                    backgroundImage: `url(${
                      leave?.coverPhoto ||
                      require('../../../assets/images/default-user-avatar.jpg')
                        
                    })`,
                  }}
                />
                <p className="assignee-fullName">Гергана Иванова</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Leave
