import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react'
import { Popover } from '@varld/popover'
import { Input } from '@makedonski/admin-ui'
import { useDispatch, useSelector } from 'react-redux'
import { getClientsForDropdown } from '../../../../actions'
import { Inputs, Popup } from '../../../'
import './styles.scss'

const Client = forwardRef(({ invoice, handleChange, showRequired, producers = false }, ref) => {
  const dispatch = useDispatch()
  const [client, setClient] = useState({ _id: invoice?.client })
  const { clients } = useSelector(({ data }) => data)
  useEffect(() => {
    dispatch(getClientsForDropdown({ search: "", getAllDetails: true, producers }))
  }, [])

  useImperativeHandle(ref, () => ({ setClient }))

  const generateName = (o) => {
    const addressParams = []
    if (o.city) addressParams.push(o.city)
    if (o.address) addressParams.push(o.address)
    if (o.description) addressParams.push(o.description)
    return `${o.itn} - ${addressParams.join(', ')}`.replaceAll('.', '_').replaceAll('  ', ' ').trim()
  }

  return (
    <div className={`invoices-forms-client-container ${showRequired && !invoice?.client && 'required'}`} style={{ zIndex: (invoice?.objects?.length ?? 0) + 7 }}>
      <div className="invoices-forms-client-header row">
        <h2>Данни получател</h2>
        {!invoice?._id && <Popover
          popover={({ close }) => (
            <Popup.Select
              single
              search
              handleSearch={(value) => dispatch(getClientsForDropdown({ search: value, getAllDetails: true, producers }))}
              hide={close}
              value={client?._id}
              handleChange={(value) => {
                const client = clients.find(({ _id }) => _id === value)
                setClient(client)
                handleChange({
                  client: client?._id, invoiceGroup: client?.invoiceGroups, objects: client?.objects?.map(({ _id, itn, erp, city, address, description, isProducer }) => ({
                    _id, erp, address, itn, isProducer,
                    nameInInvoice: generateName({ city, address, description, itn }),
                    tech: [{}], results: [{}]
                  })) || []
                })
              }}
              options={clients?.map(({ eic, pin, fullName, _id }) => ({
                label: `${fullName} | ${eic || pin}`,
                value: _id,
              }))}
              text={{ main: 'Търси по име/ЕИК/ЕГН', secondary: 'Клиенти' }}
            />
          )}
        >
          {invoice?.client ? <div className="icon icon-edit" /> : <div className="icon icon-add" />}
        </Popover>}
      </div>
      <div className="invoices-forms-client-content">
        <div className="row">
          <div className="col col-double">
            <span>Тип клиент</span>
            <Inputs.RadioButtons
              disabled
              buttons={['Юридическо лице', 'Физическо лице', 'Битов']}
              value="Юридическо лице"
            />
          </div>
          <div className="col">
            <span>ЕИК/ЕГН</span>
            <Input.Text disabled value={client?.eic || client?.pin || ''} />
          </div>
          <div className="col">
            <span>Име</span>
            <Input.Text disabled value={client?.fullName || ''} />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <span>МОЛ</span>
            <Input.Text disabled value={client?.mol?.at(0)?.fullName || ''} />
          </div>
          <div className="col">
            <span>Град</span>
            <Input.Text disabled value={client?.city || ''} />
          </div>
          <div className="col">
            <span>Адрес</span>
            <Input.Text disabled value={client?.address || ''} />
          </div>
          <div className="col">
            <span>ИН по ЗДДС</span>
            <Input.Text disabled value={client?.bulstat || ''} />
          </div>
        </div>
      </div>
    </div >
  )
})

export default Client
