import { generalTypes, authTypes, userTypes } from '../actions'

const initialState = {
  currentUser: null,
  loading: false,
  loadingText: 'Loading...',
  modal: { isOpen: false, type: '', props: {} },
  overlay: { isOpen: false, type: '', props: {} },
}

const general = (state = initialState, { type, payload }) => {
  switch (type) {
    case authTypes.LOGIN_SUCCESS:
      return { ...state, currentUser: payload }
    case generalTypes.START_LOADING:
      return { ...state, loading: true }
    case generalTypes.STOP_LOADING:
      return { ...state, loading: false }
    case generalTypes.SET_LOADING_TEXT:
      return { ...state, loadingText: payload }
    case generalTypes.SET_MODAL:
      return { ...state, modal: payload }
    case generalTypes.SET_OVERLAY:
      return { ...state, overlay: payload }
    case userTypes.GET_USER_FILTER_PRESETS_SUCCESS:
      return { ...state, currentUser: { ...state.currentUser, filterPresets: payload } }
    default:
      return state
  }
}

export default general
