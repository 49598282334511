import { Alerts, Api } from "@makedonski/socourt-utilities"
import { interestsTypes, stopLoading } from "actions"
import { URL } from "config/settings"
import { ofType, ActionsObservable } from "redux-observable"
import { catchError, switchMap } from "rxjs/operators"
import iconv from 'iconv-lite'
import { saveAs } from 'file-saver';

export const importInvoicePayments = (action$) => action$.pipe(
    ofType(interestsTypes.IMPORT_INVOICE_PAYMENTS),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/interests/invoicePayments`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            })
            )
        )
    )
)

export const importInterestsPayments = (action$) => action$.pipe(
    ofType(interestsTypes.IMPORT_INTERESTS_PAYMENTS),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/interests/interestPayments`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            })
            )
        )
    )
)

export const generateInterests = (action$) => action$.pipe(
    ofType(interestsTypes.GENERATE_INTERESTS),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/interests/generateInterests`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            })
            )
        )
    )
)

export const generateInterestsPayments = (action$) => action$.pipe(
    ofType(interestsTypes.GENERATE_INTERESTS_PAYMENTS),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/interests/generateInvoiceInterestsPayments`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            })
            )
        )
    )
)

export const getInterests = (action$) => action$.pipe(
    ofType(interestsTypes.GET_INTERESTS),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/interests/browse`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            })
            )
        )
    )
)

export const getInterest = (action$) => action$.pipe(
    ofType(interestsTypes.GET_INTEREST),
    switchMap(({ payload, onSuccess }) =>
        Api.get(`${URL}/interests/${payload}`).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response.interest)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            })
            )
        )
    )
)


export const createInterest = (action$) => action$.pipe(
    ofType(interestsTypes.CREATE_INTEREST),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/interests`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response)
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            })
            )
        )
    )
)

export const updateInterest = (action$) => action$.pipe(
    ofType(interestsTypes.UPDATE_INTEREST),
    switchMap(({ payload, onSuccess }) =>
        Api.put(`${URL}/interests`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response)
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            })
            )
        )
    )
)

export const deleteInterest = (action$) => action$.pipe(
    ofType(interestsTypes.DELETE_INTEREST),
    switchMap(({ payload, onSuccess }) =>
        Api.delete(`${URL}/interests`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response)
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            })
            )
        )
    )
)

export const exportInterestsAjur = (action$) => action$.pipe(
    ofType(interestsTypes.EXPORT_INTERESTS_AJUR),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/ajur/exportInterests`, JSON.stringify(payload)).pipe(
            switchMap(({ response: { RDET, RTOT, cancelled } }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess()
                if (RDET) {
                    var blob = new Blob([iconv.encode(RDET, 'win1251')], { type: 'text/plain;charset=windows-1251' })
                    saveAs(blob, `RDET.eid`);
                }
                if (RTOT) {
                    var blob = new Blob([iconv.encode(RTOT, 'win1251')], { type: 'text/plain;charset=windows-1251' })
                    saveAs(blob, `RTOT.eid`);
                }
                if (cancelled) {
                    var blob = new Blob([iconv.encode(cancelled, 'win1251')], { type: 'text/plain;charset=windows-1251' })
                    saveAs(blob, `Cancelled.txt`);
                }
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            })
            )
        )
    )
)