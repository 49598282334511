import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Button } from '@makedonski/admin-ui'
import { useDispatch } from "react-redux"
import { Shared, Inputs } from 'components'
import "./styles.scss"
import { Popover } from "@varld/popover"
import { exportInterestsAjur, exportInterestsExcel, generateInterests, generateInterestsPayments, getInterests, setModal, startLoading, stopLoading } from "actions"
import { mapClientsSearch, renderCell } from "utilities"
import moment from "moment"
import { useCollapsable, useQuery } from "hooks"
import { debounce } from "lodash"
import { interestsDetailsFields, interestsFields } from "config/constants"
import { useHistory } from "react-router-dom"
import { Alerts, Headers } from "@makedonski/socourt-utilities"
import { URL } from "config/settings"

const Interests = ({ }) => {
    const dispatch = useDispatch()
    const history = useHistory()

    const [interests, setInterests] = useState({})
    const { docs, nextPage, hasNextPage, totalDocs, showCheckboxes, selectedCheckboxes, amountMin, amountMax } = interests

    const { monthYear = moment().subtract(1, 'months').format('MM/YYYY'), sort: sortParam = '{}', filter: filterParam = '{}', handleUrlChangeMultiple } = useQuery()
    const availableSort = ['clientFullName', 'clientAjur', 'amount']
    const availableFilters = {
        clientFullName: { type: 'regex' },
        clientAjur: { type: 'regex' },
        clientEic: { type: 'regex' },
        amount: { type: 'number', min: amountMin, max: amountMax },
        doNotCalculateInterests: { type: 'boolean' },
        shouldUse: { type: 'boolean' },
        manuallyAdded: { type: 'boolean' },
    }
    const keyMap = {}
    const filter = useMemo(() => JSON.parse(filterParam), [filterParam])
    const sort = useMemo(() => JSON.parse(sortParam), [sortParam])
    const searchQuery = useMemo(() => ({ ...mapClientsSearch(sort, filter, undefined, availableFilters) }), [sortParam, filterParam])



    const fetch = useCallback((payload) => {
        dispatch(startLoading());
        dispatch(getInterests({
            payload: { monthYear, ...searchQuery, ...payload },
            onSuccess: (res) => {
                if (res?.page > 1) setInterests((prev) => ({ ...prev, ...res, docs: [...(prev?.docs ?? []), ...(res?.docs ?? [])] }))
                else setInterests((prev) => ({ ...prev, ...res }))
            }
        }))
    }, [dispatch, monthYear, searchQuery])
    const fetchDebounced = debounce(fetch, 300)
    useEffect(() => { fetch() }, [fetch])


    const handleGenerateInterests = () => {
        dispatch(setModal({
            isOpen: true, type: 'confirmation', props: {
                title: 'Внимание!',
                children: `Това действие ще презапише всички записи за месец ${moment().subtract(1, 'months').format('MMMM')}`,
                onClick: () => {
                    dispatch(startLoading())
                    dispatch(generateInterests({ onSuccess: fetch }))
                }
            }
        }))
    }
    const handleGenerateInterestsPayments = () => {
        dispatch(setModal({
            isOpen: true, type: 'confirmation', props: {
                title: 'Внимание!',
                children: <p style={{ textAlign: 'center', whiteSpace: 'pre-line' }}>{`Сигурни ли сте, че искате да генерирате удостоверения за плащания?\n\n Внимание! \n Ако вече има генерирани удостоверение повторното генериране ще ги презапише!`}</p>,
                onClick: () => {
                    dispatch(startLoading())
                    dispatch(generateInterestsPayments({
                        onSuccess: () => dispatch(setModal({ isOpen: true, type: 'confirmation', props: { title: 'Успешно генериране!', onClick: fetch, onRequestClose: fetch } }))
                    }))
                }
            }
        }))
    }

    const handleExport = {
        'ajur': () => dispatch(setModal({
            isOpen: true, type: 'inputText', props: {
                title: "Начален номер - експорт Ажур",
                text: 'Моля въведете първият свободен номер за поредица LXXXXXXXXX',
                onSuccess: (firstDocumentNumber) => {
                    dispatch(startLoading());
                    dispatch(exportInterestsAjur({
                        payload: { monthYear, firstDocumentNumber },
                        onSuccess: () => dispatch(setModal({ isOpen: false }))
                    }))
                }
            }
        })),
        'excel': async () => await handleExportExcel(),
        'excelDetailed': async () => await handleExportExcel({ detailed: true }),
    }

    const handleExportExcel = async ({ detailed } = {}) => {
        try {
            dispatch(startLoading())
            const response = await window.fetch(`${URL}/interests/exportExcel`, {
                method: 'POST',
                headers: Headers.getWithAuth(),
                body: JSON.stringify({ monthYear, detailed }),
            })
            if (!response.ok) throw new Error('Грешка')
            const blob = await response.blob()
            const uril = window.URL.createObjectURL(blob)
            let link = document.createElement("a");
            link.href = uril;
            link.download = `export.xlsx`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        catch (error) { Alerts.error(error?.message) }
        finally { dispatch(stopLoading()) }
    }

    const collapsableRef = useRef()
    const [isExpanded, setExpanded] = useCollapsable(collapsableRef)

    const tableRef = useRef(null)
    const DetailsTable = useCallback(({ row }) => <Shared.Table
        fixedWidth
        columns={interestsDetailsFields}
        data={row?.original?.details.map((obj) => ({ ...obj, parentRow: row.original }))}
        containerStyle={{ maxHeight: 350 }}
        renderCell={(row, field, options) => renderCell.interestsDetails(row, field, { ...options, fetch, dispatch })}
        outlines={row?.original?.details?.filter(({ lastEdited }) => lastEdited).reduce((acc, cur, index) => ({ ...acc, [index]: '#e87a1e' }), {})}
    />, [])


    const sortingComponents = interestsFields?.filter(({ sortable }) => sortable).reduce((acc, { value }) => ({
        ...acc,
        [value]: <Popover popover={({ close }) => <Shared.SortingComponent
            hide={close}
            column={value}
            availableSort={availableSort}
            availableFilters={availableFilters}
            keyMap={keyMap}
        />}>
            <div className="icon icon-arrow-down" />
        </Popover>

    }), {})

    return <div className="screen-interests-container">
        <div className="screen-interests-header row">
            <h2>Лихви</h2>
            <Inputs.DatePicker
                customInput={<div className='row'>
                    <p className="row">{moment(monthYear, 'MM/YYYY').format('м. MMMM')}</p>
                    <div className="icon icon-calendar-custom" />
                </div>}
                className="month-picker"
                showMonthYearPicker
                showFullMonthYearPicker
                value={moment(monthYear, 'MM/YYYY')}
                onChange={(monthYear) => handleUrlChangeMultiple({ 'monthYear': moment(monthYear).format('MM/YYYY') })}
                minDate={moment().subtract(10, 'years').toDate()}
                maxDate={moment().add(1, 'months').endOf('month').toDate()}
            />
            <div className="row row-buttons">
                <Popover
                    popover={({ close }) => (
                        <div className="popup-client-documents-container">
                            <div className="popup-client-documents-header row">
                                <span>Изберете действие</span>
                                <Button.Icon name="plus" onClick={close} />
                            </div>
                            <p onClick={() => { handleGenerateInterests(); close() }}>Генерирай лихви</p>
                            <p onClick={() => { handleGenerateInterestsPayments(); close() }}>Генерирай удостоверения за платена лихва</p>
                            <p onClick={() => { history.push('/financial/interests/form') }}>Ръчно добавяне лихва</p>
                            <p onClick={() => setExpanded(!isExpanded)}>Експорти</p>
                            <div className="collapsable-exports" ref={collapsableRef}>
                                <p onClick={() => { handleExport['ajur'](); close() }}>Ажур</p>
                                <p onClick={() => { handleExport['excel'](); close() }}>Excel</p>
                                <p onClick={() => { handleExport['excelDetailed'](); close() }}>Excel детайлно</p>
                            </div>
                            <p onClick={() => { handleUrlChangeMultiple({ sort: null, filter: null, }); close() }}>Изчисти всички филтри</p>
                            <p onClick={() => { tableRef.current.toggleAllRowsExpanded(false); close() }}>Скрий всички обекти</p>
                        </div>
                    )}
                >
                    <Button.Raised className="btn-other-actions" text="Други действия" />
                </Popover>
                <Button.Raised text="Качи файлове" onClick={() => dispatch(setModal({
                    isOpen: true, type: 'uploadFiles', props: {
                        warning: <div className="row" style={{ justifyContent: 'center' }}>
                            <p style={{ color: 'red', whiteSpace: 'pre-line', textAlign: 'center' }}>
                                {`ВНИМАНИЕ\nКачените файлове ще бъдат приложени към фактурите за месец ${moment().subtract(1, 'months').format('MMMM')}. Повторно качване на файл ще презапише ВСИЧКИ записи за месеца.`}
                            </p>
                        </div>,
                        options: [
                            { label: 'Лихви - старо', value: 'interests' },
                            { label: 'Плащания по лихви - старо', value: 'interestsPaymentsOld' },

                            { label: 'Плащания по фактури', value: 'invoicePayments' },
                            { label: 'Плащания по лихви', value: 'interestsPayments' },
                        ]
                    }
                }))} />
            </div>

        </div>
        <div className="screen-interests-content">
            <Shared.Table
                fixedWidth
                ref={tableRef}
                data={docs}
                columns={interestsFields}
                renderCell={(row, field, options) => renderCell.interests(row, field, { ...options, toggleRowExpanded: tableRef.current.toggleRowExpanded, fetch })}
                maxExpandHeight={550}
                handlePagination={() => hasNextPage && fetchDebounced({ page: nextPage })}
                sortingComponent={sortingComponents}
                expandField='details'
            >{DetailsTable}</Shared.Table>
        </div>
        <div className="screen-interests-footer row">
            <div className="screen-interests-inner-footer row">
                <p className="row">Общо: {totalDocs || 0}</p>
                {showCheckboxes && <p className="row">Избрани: {selectedCheckboxes?.length || 0}</p>}
            </div>
        </div>
    </div>
}

export default Interests