import React from 'react'
import { Popover } from '@varld/popover'
import { Button, Input } from '@makedonski/admin-ui'
import { useDispatch } from 'react-redux'
import { setModal } from '../../../actions'
import { Inputs, Popup } from '../../'
import './styles.scss'

const NewTask = ({ }) => {
  const dispatch = useDispatch()
  const handleUpload = () => { }

  //Required

  return (
    <div className="modal-new-task-container row">
      <Button.Icon name="plus" size="30px" onClick={() => dispatch(setModal({ isOpen: false }))} />

      <div className="modal-new-task-inner-left-container col">
        <h2>Нова задача</h2>
        <div className="form-container">
          <div className="row row-client">
            <div className="row">
              <span className="subtitle">Клиент:</span>
              <Popover className="header-settings" popover={({ close }) => <Popup.NewTaskClient hide={close} />}>
                <div className="icon icon-add" />
              </Popover>
            </div>
            <div className="col">
              <span>Статус</span>
              <Input.Text />
            </div>
          </div>

          <div className="col">
            <span>Заглавие</span>
            <Input.Text />
          </div>

          <div className="col">
            <span>Кратко описание</span>
            <Input.Textarea />
          </div>

          <div className="row row-deadline">
            <div className="col">
              <span>Начална дата</span>
              <Inputs.DatePicker />
            </div>
            <div className="col">
              <span>Kрайна дата</span>
              <Inputs.DatePicker />
            </div>
            <div className="col">
              <span>Начален час</span>
              <Input.TimePicker />
            </div>
            <div className="col">
              <span>Краен час</span>
              <Input.TimePicker />
            </div>
          </div>

          <div className="col">
            <span>Локация</span>
            <Input.Text />
          </div>
        </div>
        <div className="row row-assignees">
          <span className="subtitle">Изпълнител:</span>

          <Popover
            className="header-settings"
            popover={({ close }) => (
              <Popup.NewTaskAssignees
                hide={close}
                onLoad={() => {
                  setTimeout(() => {
                    const popup = document.querySelector('.go1608375944')
                    const transform = popup.style.transform
                    const coordinates = transform
                      .slice(10, transform.length - 1)
                      .split(', ')
                      .map((a) => Math.floor(a.slice(0, a.length - 2)))
                    popup.style.transform = `translate(${coordinates[0]}px, ${coordinates[1]}px)`
                  }, 100)
                }}
              />
            )}
          >
            <div className="icon icon-add" />
          </Popover>
          <div className="assignees-container row">
            {[
              { fullName: 'aaas' },
              { fullName: 'aaas' },
              { fullName: 'aaas' },
              { fullName: 'aaas' },
              { fullName: 'aaas' },
              { fullName: 'aaas' },
              { fullName: 'aaas' },
              { fullName: 'aaas' },
            ]?.map(({ coverPhoto, fullName }, i) => (
              <div key={`assignee-${i}`} className="single-assignee row">
                <div
                  className="profile-image"
                  style={{
                    backgroundImage: `url(${coverPhoto || require('../../../assets/images/default-user-avatar.jpg')
                      })`,
                  }}
                />
                <p className="assignee-fullName">{fullName}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="row row-priority">
          <span className="subtitle">Приоритет:</span>
          <Inputs.RadioButtons
            buttons={[
              { value: 'high', label: 'Висок', color: '#fa4444' },
              { value: 'mid', label: 'Среден', color: '#ffb612' },
              { value: 'low', label: 'Нисък', color: '#00cb5e' },
            ]}
          />
        </div>
        <div className="row row-files">
          <span className="subtitle">Файлове:</span>
          <Button.UploadButton
            text={
              <span className="row ripple">
                Добави файл <div className="icon icon-attach" />
              </span>
            }
            multiple
            onChange={({ target: { files } }) => handleUpload(files)}
          />
          <div className="files-scroll row">
            {[
              { filename: 'aaa' },
              { filename: 'aaa' },
              { filename: 'aaa' },
              { filename: 'aaa' },
              { filename: 'aaa' },
              { filename: 'aaa' },
              { filename: 'aaa' },
            ].map(({ filename }, i) => (
              <span className="single-file row" key={`task-file-${i}`}>
                {filename}
                <Button.Icon
                  name="plus"
                  size="10px"
                  color="red"
                  onClick={() => {
                    /*
                    const newFiles = data?.files?.slice()
                    newFiles.splice(i, 1)
                    handleChange('files', newFiles)
                  */
                  }}
                />
              </span>
            ))}
          </div>
        </div>
        <Button.Raised text="Създай задача" />
      </div>
      <div className="modal-new-task-inner-right-container">
        <h2>Чеклист</h2>
        <div className="checklist-container">
          <div className="checklist-items-container">
            {['a', 'a', 'a', 'a', 'a'].map((item, i) => (
              <div key={`checklist-single-item-${i}`} className={`checklist-single-item row`}>
                <Input.Text placeholder={`Подзадача ${i + 1}`} />
              </div>
            ))}
            <div
              className="btn-add-item row"
              onClick={() => {
                /*
                handleChange('checklist', [...(data?.checklist || []), ''])
              */
              }}
            >
              Добави подзадача
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewTask
