import { ofType, ActionsObservable } from 'redux-observable'
import { switchMap, catchError, debounceTime } from 'rxjs/operators'
import { Api, Alerts } from '@makedonski/socourt-utilities'
import { pick, omit, mapKeys } from 'lodash'
import { annexTypes, stopLoading, setModal, clientsTypes } from 'actions'
import { history } from 'config/store'
import { URL } from '../config/settings'
import { formatAnnexResponse, renderCell, xlsxExport } from 'utilities'
import { notificationsGeneratedTableFields } from 'config/constants'

const rootFields = ['_id', 'activationDate', 'client', 'file', 'comment', 'annexType']
const clientFields = ['product', 'price', "declaration", "dealers"]
const invoiceSettingsFields = ["intermediateInvoicing", "intermediateInvoicingType", "advanceFirstDate"]
const annexNotificationOldData = ["difference", "oldPrice", "oldProduct", "oldDeliveryStart", "oldDeliveryEnd", 'period']
const getAnnexPayload = (payloadParam) => ({
  ...pick(payloadParam, rootFields),
  changes: {
    ...pick(payloadParam, clientFields),
    ...mapKeys(pick(payloadParam, invoiceSettingsFields), (value, key) => `invoiceSettings_${key}`),
    ...mapKeys(pick(payloadParam, annexNotificationOldData), (value, key) => `annexNotificationOldData_${key}`),
    ...mapKeys(omit(payloadParam, [
      ...rootFields,
      ...clientFields,
      ...invoiceSettingsFields,
      ...annexNotificationOldData
    ]), (value, key) => `contractSettings_${key}`),
  },
})

export const createAnnex = (action$) =>
  action$.pipe(
    ofType(annexTypes.CREATE_ANNEX),
    switchMap(({ payload: payloadParam, onSuccess }) => {
      const payload = getAnnexPayload(payloadParam)
      return Api.post(`${URL}/annex`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response.annex)
            obs.next(
              setModal({
                isOpen: true,
                type: 'confirmation',
                props: {
                  title: payloadParam.annexType === "Annex" ? 'Успешно добавен анекс' : "Успешно добавно преподписване",
                  onRequestClose: () => history.goBack(),
                  onClick: () => history.goBack(),
                },
              })
            )
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    })
  )

export const updateAnnex = (action$) =>
  action$.pipe(
    ofType(annexTypes.UPDATE_ANNEX),
    switchMap(({ payload: { client, ...payloadParam }, onSuccess }) => {
      const payload = getAnnexPayload(payloadParam)
      return Api.put(`${URL}/annex`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response.annex)
            obs.next({ type: annexTypes.GET_ANNEXES_BY_CLIENT, payload: client })
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    })
  )

export const getAnnexesByClient = (action$) =>
  action$.pipe(
    ofType(annexTypes.GET_ANNEXES_BY_CLIENT),
    switchMap(({ payload, onSuccess }) =>
      Api.post(`${URL}/annex/listByClient`, JSON.stringify({
        client: payload,
        types: ['Annex', 'Resign', 'Notification']
      })).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            const annexes = response.annexes.map(formatAnnexResponse)
            if (onSuccess) onSuccess(annexes)
            obs.next({ type: annexTypes.GET_ANNEXES_BY_CLIENT_SUCCESS, payload: annexes })
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const createNotificationsAnnexes = (action$) => action$.pipe(
  ofType(annexTypes.CREATE_NOTIFICATIONS_ANNEXES),
  switchMap(({ payload: payloadParam, onSuccess }) => {
    const payload = payloadParam.map(p => getAnnexPayload(omit({ ...p, ...p.changes }, ['changes'])))
    return Api.post(`${URL}/annex/notifications`, JSON.stringify({ annexes: payload })).pipe(
      switchMap(({ response }) => ActionsObservable.create((obs) => {
        if (onSuccess) onSuccess(response.annexes)
        obs.next(stopLoading())
        obs.complete()
      })),
      catchError((err) => ActionsObservable.create((obs) => {
        Alerts.error('Error!', err?.response?.error?.message || '')
        obs.next(stopLoading())
        obs.complete()
      }))
    )
  })
)

export const deleteNotificationsAnnexes = (action$) => action$.pipe(
  ofType(annexTypes.DELETE_NOTIFICATIONS_ANNEXES),
  switchMap(({ payload, onSuccess }) =>
    Api.delete(`${URL}/annex/many`, JSON.stringify(payload)).pipe(
      switchMap(({ response }) => ActionsObservable.create((obs) => {
        if (onSuccess) onSuccess(response.deleted)
        obs.next(stopLoading())
        obs.complete()
      })),
      catchError((err) => ActionsObservable.create((obs) => {
        Alerts.error('Error!', err?.response?.error?.message || '')
        obs.next(stopLoading())
        obs.complete()
      }))
    )
  )
)

export const getNotificationsAnnexes = (action$) => action$.pipe(
  ofType(annexTypes.GET_NOTIFICATIONS_ANNEXES),
  debounceTime(300),
  switchMap(({ payload, forExport, onSuccess }) =>
    Api.post(`${URL}/annex/listNotifications`, JSON.stringify(payload)).pipe(
      switchMap(({ response }) => ActionsObservable.create((obs) => {
        if (forExport) xlsxExport({
          fields: notificationsGeneratedTableFields,
          data: response.annexes.docs,
          render: renderCell.notificationsGeneratedExport
        })
        if (onSuccess) onSuccess(response.annexes)
        obs.next({ type: annexTypes.GET_NOTIFICATIONS_ANNEXES_SUCCESS, payload: response.annexes })
        obs.next(stopLoading())
        obs.complete()
      })),
      catchError((err) => ActionsObservable.create((obs) => {
        console.log(err);
        Alerts.error('Error!', err?.response?.error?.message || '')
        obs.next(stopLoading())
        obs.complete()
      }))
    )
  )
)

export const getNotificationsClients = (action$) => action$.pipe(
  ofType(annexTypes.GET_NOTIFICATIONS_CLIENTS),
  debounceTime(300),
  switchMap(({ payload, onSuccess }) =>
    Api.post(`${URL}/annex/notifications/ready`, JSON.stringify(payload)).pipe(
      switchMap(({ response }) => ActionsObservable.create((obs) => {
        if (onSuccess) onSuccess(response.clients)
        obs.next({ type: clientsTypes.GET_CLIENTS_SUCCESS, payload: response.clients })
        // obs.next({ type: annexTypes.GET_NOTIFICATIONS_ANNEXES_SUCCESS, payload: response.annexes })
        obs.next(stopLoading())
        obs.complete()
      })),
      catchError((err) => ActionsObservable.create((obs) => {
        Alerts.error('Error!', err?.response?.error?.message || '')
        obs.next(stopLoading())
        obs.complete()
      }))
    )
  )
)

export const sendNotificationsAnnexes = (action$) => action$.pipe(
  ofType(annexTypes.SEND_NOTIFICATIONS_ANNEXES),
  switchMap(({ payload, onSuccess }) =>
    Api.post(`${URL}/annex/notifications/send`, JSON.stringify(payload)).pipe(
      switchMap(({ response }) => ActionsObservable.create((obs) => {
        if (onSuccess) onSuccess(response.clients)
        obs.next(stopLoading())
        obs.complete()
      })),
      catchError((err) => ActionsObservable.create((obs) => {
        Alerts.error('Error!', err?.response?.error?.message || '')
        obs.next(stopLoading())
        obs.complete()
      }))
    )
  )
)

export const checkUpcomingAnnexes = (action$) => action$.pipe(
  ofType(annexTypes.CHECK_UPCOMMING_ANNEXES),
  switchMap(({ payload, onSuccess }) =>
    Api.post(`${URL}/annex/checkUpcoming`, JSON.stringify(payload)).pipe(
      switchMap(({ response }) => ActionsObservable.create((obs) => {
        if (onSuccess) onSuccess(response.annexes)
        obs.next(stopLoading())
        obs.complete()
      })),
      catchError((err) => ActionsObservable.create((obs) => {
        Alerts.error('Error!', err?.response?.error?.message || '')
        obs.next(stopLoading())
        obs.complete()
      }))
    )
  )
)

export const generateNotificationsAnnexes = (action$) => action$.pipe(
  ofType(annexTypes.GENERATE_NOTIFICATIONS_ANNEXES),
  switchMap(({ payload, onSuccess }) =>
    Api.post(`${URL}/annex/generateNotifications`, JSON.stringify(payload)).pipe(
      switchMap(({ response }) => ActionsObservable.create((obs) => {
        if (onSuccess) onSuccess(response.notifications)
        obs.complete()
      })),
      catchError((err) => ActionsObservable.create((obs) => {
        Alerts.error('Error!', err?.response?.error?.message || '')
        obs.next(stopLoading())
        obs.complete()
      }))
    )
  )
)

export const getTextForNotificationsAnnexes = (action$) => action$.pipe(
  ofType(annexTypes.GET_TEXT_FOR_NOTIFICATIONS_ANNEXES),
  switchMap(({ payload, onSuccess }) =>
    Api.post(`${URL}/annex/getNotificationTexts`, JSON.stringify(payload)).pipe(
      switchMap(({ response }) => ActionsObservable.create((obs) => {
        if (onSuccess) onSuccess(response.texts)
        obs.next(stopLoading())
        obs.complete()
      })),
      catchError((err) => ActionsObservable.create((obs) => {
        Alerts.error('Error!', err?.response?.error?.message || '')
        obs.next(stopLoading())
        obs.complete()
      }))
    )
  )
)