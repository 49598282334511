import React, { useState } from 'react'
import { Button } from '@makedonski/admin-ui'
// import { Tasks as TasksComp } from '../../'
import './styles.scss'

const Tasks = ({}) => {
  const tabs = ['Предстоящи', 'Активи', 'Завършени', 'Просрочени']
  const [selectedTab, setSelectedTab] = useState(tabs[0])

  return (
    <div className="employee-tasks-container">
      <div className="employee-tasks-header">
        <h2>Задачи</h2>
        <Button.UnderlinedTab buttons={tabs} value={selectedTab} onChange={(tab) => setSelectedTab(tab)} />
      </div>
      <div className="employee-tasks-content">
        {/* <TasksComp.Task /> */}
      </div>
    </div>
  )
}

export default Tasks
