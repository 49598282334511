import React from "react"
import { Support } from '@fidweb/customer-support'
import { useSelector } from "react-redux"
import "./styles.scss"



const SupportScreen = ({ socket }) => {
    const { currentUser } = useSelector(({ general }) => general)

    return <div className="screen-customer-support-container row">
        {currentUser && socket && <Support
            {...socket}
            currentUser={currentUser}
            logo={require('../../assets/images/logo-support.png')}
            colors={{
                '--main-color': '#e87a1e',
                '--secondary-color': '#002157',
                '--light-color': '#fdf9f5'
            }}
        />}
    </div>
}

export default SupportScreen