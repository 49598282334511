import { ofType, ActionsObservable } from 'redux-observable'
import { switchMap, catchError } from 'rxjs/operators'
import { Api, Alerts } from '@makedonski/socourt-utilities'
import { activityTypes, stopLoading } from 'actions'
import { URL } from '../config/settings'

export const getClientActivity = (action$) =>
  action$.pipe(
    ofType(activityTypes.GET_CLIENT_ACTIVITY),
    switchMap(({ payload, onSuccess }) =>
      Api.get(`${URL}/clients/activityCo/${payload}`).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response?.activities)
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )
