import { editCommissions, startLoading } from "actions"
import Inputs from "components/Inputs"
import React from "react"
import { useDispatch } from "react-redux"
import "./styles.scss"

const EditCommission = ({ row: { _id, ...row } = {}, field, fetch }) => {
    const dispatch = useDispatch()
    return <Inputs.TextEdit
        type="text"
        value={row?.[field] || ""}
        handleChange={(value) => {
            dispatch(startLoading())
            dispatch(editCommissions({
                payload: { ids: [_id], [field]: value },
                onSuccess: fetch
            }))
        }}
    />
}

export default EditCommission