import React, { useEffect, useMemo, forwardRef, useImperativeHandle } from 'react'
import { Input, Button } from '@makedonski/admin-ui'
import { Alerts } from '@makedonski/socourt-utilities'
import { Tooltip } from '@varld/popover'
import { Portal } from 'react-overlays'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { isEmpty, omit } from 'lodash'
import { startLoading, stopLoading } from 'actions'
import { Inputs } from 'components'
import { uploadFiles, handleCheck, checkIsDealer } from 'utilities'
import './styles.scss'
import { defaultProducerSettings } from 'config/constants'

const ObjectForm = forwardRef(({
  data,
  handleChange,
  showRequired,
  options,
  editable,
  handleChangeMultiple,
  isMulti,
  showInvalid,
  handleRemove,
  client,
  index,
  massCheck
}, ref) => {
  const { erps, measureTypes, profiles, voltages, forecasts, seasonings, versions } = options || {}
  const dispatch = useDispatch()
  const isDealer = checkIsDealer()

  const isRequired = (field) => {
    if (!showRequired) return ''
    else if (field !== 'itn') return !data?.[field] ? 'required' : ''
    else if (erp === 'ЕВН') return !data?.itn?.startsWith('BG5521900') || data?.itn?.length !== 33 ? 'required' : ''
    else if (erp === 'ЕСО') return !data?.itn?.startsWith('32X') || data?.itn?.length !== 16 ? 'required' : ''
    else return !data?.itn?.startsWith('32Z') || data?.itn?.length !== 16 ? 'required' : ''
  }

  const handleUpload = (files, type) => {
    dispatch(startLoading())
    uploadFiles({ files, createId: true }).then((uploaded) => {
      type === 'invoice'
        ? handleChange('invoice', { name: files[0]?.name, url: uploaded[0]?.location, type: data?.isProducer ? "protocol" : "invoice", new: true })
        : handleChange('otherDocuments', uploaded.map(({ location }, i) => ({ name: files[i].name, url: location, type: 'other', new: true })))
      dispatch(stopLoading())
    })
  }

  const erp = useMemo(() => erps?.find(({ _id }) => _id === data?.erp)?.name || data?.erp?.name, [data?.erp])
  const measureType = useMemo(
    () => measureTypes?.find(({ _id }) => _id === data?.measureType)?.name || data?.measureType?.name,
    [data?.measureType]
  )

  const defaultProducerData = {
    isProducer: true,
    existingObject: true,
    producerForecasting: true,
    ...(!client?.producerSettings && { producerSettings: { ...defaultProducerSettings, version: versions?.find(({ name }) => name === 'Г10.22')?._id } })
  }

  useEffect(() => {
    const keys = ['voltages', 'forecasts', 'seasonings', 'versions']
    if (client && keys.every((key) => options[key]) && isEmpty(omit(data, ['existingObject', 'entryEnd', 'isProducer']))) handleChangeMultiple({
      voltage: voltages?.find(({ name }) => name === 'Ниско напрежение')?._id,
      forecast: forecasts?.find(({ name }) => name === 'Лесно прогнозиране')?._id,
      seasoning: seasonings?.find(({ name }) => name === 'Целогодишна')?._id,
      ...(data?.isProducer && defaultProducerData)
    }, index)
  }, [options, data])

  const allowRequiresNetworkData = useMemo(() => {
    return (client?.contractSettings?.type === 'Стандартен' && client?.declaration /*&& ['СТП', 'Битово'].includes(measureType)*/)
  }, [client, measureType])

  const handleCheckStart = (onSuccess) => handleCheck({ client, data, index, onSuccess, massCheck, handleChangeMultiple })

  useImperativeHandle(ref, () => ({ handleCheckStart }))

  return (
    <div className={`client-forms-object-container`}>
      {isMulti && showInvalid && data.error && <Tooltip content={data.error}><span className="invalid">!</span></Tooltip>}
      <div className={`row ${showInvalid && 'required'}`}>
        {isMulti && !isDealer && (
          <div className="col col-existingObject">
            <span>Незабавна активация</span>
            <Button.Switch
              isOn={!!data?.existingObject}
              onChange={() => handleChange('existingObject', !data?.existingObject)}
            />
          </div>
        )}
        {!isMulti && <>
          {client?.clientType === 'Смесен' && <div className="col">
            <span>Вид обект</span>
            <Inputs.RadioButtons
              buttons={['Консумация', 'Производство']}
              value={data?.isProducer ? 'Производство' : 'Консумация'}
              onClick={(value) => {
                if (value === 'Производство') handleChangeMultiple(defaultProducerData, index)
                else handleChangeMultiple({ isProducer: false, existingObject: undefined, producerForecasting: undefined, producerSettings: undefined }, index)
              }}
              disabled={data?._id}
            />
          </div>}
          <div style={{ flexBasis: '100%' }} />
        </>}
        <div className="col">
          <span>Тип обект/описание</span>
          <Input.Text
            value={data?.description || ''}
            onChange={({ target: { value } }) => handleChange('description', value)}
            disabled={!editable}
            inputClassName={`${data?.isProducer && isRequired('description')}`}
          />
        </div>
        <div className="col">
          <span>ЕРП</span>
          <Inputs.Dropdown
            autoWidth={isMulti}
            options={erps?.map(({ _id, name }) => ({
              label: name,
              value: _id,
            }))}
            onChange={({ value, label }) => {
              if (label === "ЕСО") handleChangeMultiple({
                erp: value,
                measureType: measureTypes?.find(({ name }) => name === 'Почасово')?._id,
                voltage: voltages?.find(({ name }) => name === 'Високо напрежение')?._id,
                itnMMS: null
              }, index)
              else handleChange('erp', value)
            }}
            value={data?.erp?._id || data?.erp}
            className={`${isRequired('erp')}`}
            disabled={!editable}
          />
        </div>
        <div className="col col-double">
          <span>ИТН</span>
          <Input.Text
            value={data?.itn || ''}
            onChange={({ target: { value } }) => {
              if (!/^[A-Za-z\d]+$/.test(value) && value !== '') return
              handleChange('itn', value)
            }}
            inputClassName={`${isRequired('itn')}`}
            disabled={!editable}
          />
        </div>
        {(!massCheck && !isMulti) && <div className="col col-button">
          <span></span>
          <Button.Raised text="Провери" onClick={() => handleCheckStart()} disabled={data?._id || !data?.itn} />
        </div>}
        {!isMulti && <div style={{ flexBasis: '100%' }} />}
        {['ЕСО'].includes(erp) ? (
          <div className="col">
            <span>ИТН Мерения</span>
            <Input.Text
              value={data?.itnMeasure || ''}
              onChange={({ target: { value } }) => handleChange('itnMeasure', value)}
              disabled={!editable}
            />
          </div>
        ) : (
          <div className="col-placeholder-itnMeasure" />
        )}
        <div className="col">
          <span>Град</span>
          <Input.Text
            value={data?.city || ''}
            onChange={({ target: { value } }) => {
              if (!/^[\u0400-\u04FF. ]+$/.test(value) && value !== '') return
              handleChange('city', value)
            }}
            inputClassName={`${isRequired('city')}`}
            disabled={!editable}
          />
        </div>
        <div className={`col ${!['СТП', 'Битово']?.includes(measureType) && 'col-double'}`}>
          <span>Адрес</span>
          <Input.Text
            value={data?.address || ''}
            onChange={({ target: { value } }) => handleChange('address', value)}
            disabled={!editable}
          />
        </div>
        {/* <div className="col">
          <span>Клиентски номер ЕРП </span>
          <Input.Text
            value={data?.erpClientNumber || ''}
            onChange={({ target: { value } }) => {
              if (!/^\d+$/.test(value) && value !== '') return
              handleChange('erpClientNumber', value)
            }}
            disabled={!editable}
          />
        </div> */}
        {!data?.isProducer && <div className="col">
          <span>Тип на мерене</span>
          <Inputs.Dropdown
            autoWidth={isMulti}
            options={measureTypes
              ?.map(({ _id, name }) => ({
                label: name,
                value: _id,
              }))
              ?.filter(({ label }) => (erp !== 'ЕСО' ? label !== 'Високо' : true))}
            onChange={({ value, label }) => {
              if (client?.contractSettings?.type === 'Стандартен' && ['СТП', 'Битово'].includes(label)) {
                if (!client?.declaration) Alerts.error('Грешка', 'Клиента е на стандартен договор и няма декларация')
                else handleChangeMultiple({ measureType: value, requiresNetworkData: true, STPMeasure: null }, index)
              } else handleChangeMultiple({ measureType: value, requiresNetworkData: false, STPMeasure: null }, index)
            }}
            value={data?.measureType?._id || data?.measureType}
            className={`${isRequired('measureType')}`}
            disabled={!editable || erp === 'ЕСО'}
          />
        </div>}
        {['СТП', 'Битово']?.includes(measureType) ? (
          <div className="col">
            <span>СТП Профил</span>
            <Inputs.Dropdown
              autoWidth={isMulti}
              options={profiles?.[erp]
                ?.filter(({ usedInHousehold }) => (measureType === 'Битово' ? usedInHousehold : !usedInHousehold))
                ?.map(({ value: profile }) => ({ label: profile, value: profile }))}
              onChange={({ value }) => handleChange('STPMeasure', value)}
              value={data?.STPMeasure?._id || data?.STPMeasure}
              className={`${isRequired('STPMeasure')}`}
              disabled={!editable}
            />
          </div>
        ) : (
          <div className="col-placeholder-STP" />
        )}
        {/* {!isDealer && <div className="col">
          <span>Тарифност</span>
          <Inputs.Dropdown
            autoWidth={isMulti}
            options={tariffs?.map(({ _id, name }) => ({
              label: name,
              value: _id,
            }))}
            onChange={({ value }) => handleChange('tariff', value)}
            value={data?.tariff?._id || data?.tariff}
            className={`${isRequired('tariff')}`}
            disabled={!editable}
          />
        </div>} */}
        <div className="col">
          <span>Напрежение</span>
          <Inputs.Dropdown
            autoWidth={isMulti}
            options={voltages?.map(({ _id, name }) => ({
              label: name,
              value: _id,
            }))}
            onChange={({ value }) => handleChange('voltage', value)}
            value={data?.voltage?._id || data?.voltage}
            className={`${isRequired('voltage')}`}
            disabled={!editable || erp === 'ЕСО'}
          />
        </div>
        {!data?.isProducer && <div className="col">
          <span>Предоставена Мощност</span>
          <Inputs.TextLabeled
            label="Kw"
            value={data?.deliveredPower || ''}
            onChange={({ target: { value } }) => {
              if (!/^\d{1,5}(\.||\.\d{0,3})?$/.test(value) && value !== '') return
              handleChange('deliveredPower', value)
            }}
            disabled={!editable}
          />
        </div>}
        {!data?.isProducer && <div className="col">
          <span>Прогнозна Месечна Консумация</span>
          <Inputs.TextLabeled
            label="MWh"
            value={data?.averageConsumption || ''}
            onChange={({ target: { value } }) => {
              if (!/^\d{1,5}(\.||\.\d{0,3})?$/.test(value) && value !== '') return
              handleChange('averageConsumption', value)
            }}
            inputClassName={`${isRequired('averageConsumption')}`}
            disabled={!editable}
          />
        </div>}
        {data?.isProducer && <>
          <div className="col">
            <span>Инсталирана мощност</span>
            <Inputs.TextLabeled
              label='Kw'
              value={data?.producerProductionPower || ''}
              onChange={({ target: { value } }) => {
                if (!/^\d{1,5}(\.||\.\d{0,3})?$/.test(value) && value !== '') return
                handleChange('producerProductionPower', value)
              }}
              inputClassName={`${isRequired('producerProductionPower')}`}
              disabled={!editable}
            />
          </div>
          <div className="col">
            <span>Тип ВЕИ</span>
            <Inputs.Dropdown
              autoWidth={isMulti}
              options={['ФЕЦ', 'ВЕЦ', 'ВтЕЦ', 'БиоГЕЦ', 'Когенерация']?.map((value) => ({ value, label: value }))}
              onChange={({ value }) => handleChange('producerType', value)}
              value={data?.producerType}
              className={`${isRequired('producerType')}`}
              disabled={!editable}
            />
          </div>
          <div className={`col col-switch ${!editable && 'disabled'}`}>
            <span>Прогнозиране ВЕИ</span>
            <Button.Switch
              isOn={data?.producerForecasting}
              onChange={() => editable && handleChange('producerForecasting', !data?.producerForecasting)}
            />
          </div>
          <div className={`col col-switch ${!editable && 'disabled'}`}>
            <span>Ново ВЕИ 2021+</span>
            <Button.Switch
              isOn={data?.producerAfter2021}
              onChange={() => editable && handleChange('producerAfter2021', !data?.producerAfter2021)}
            />
          </div>
          <div className={`col col-switch ${!editable && 'disabled'}`}>
            <span>Преференции</span>
            <Button.Switch
              isOn={data?.producerPreferences}
              onChange={() => editable && handleChange('producerPreferences', !data?.producerPreferences)}
            />
          </div>
          <div className="col">
            <span>Член</span>
            <Inputs.RadioButtons
              buttons={['Непряк', 'Пряк']}
              value={data?.producerDirectMember ? 'Пряк' : 'Непряк'}
              onClick={(value) => handleChangeMultiple({ producerDirectMember: value === 'Пряк', itnMMS: null }, index)}
            />
          </div>
          {data?.producerDirectMember && !['ЕСО'].includes(erp) && <div className="col">
            <span>ИТН ММС</span>
            <Input.Text
              value={data?.itnMMS || ''}
              onChange={({ target: { value } }) => {
                if (!/^[A-Za-z\d]+$/.test(value) && value !== '') return
                handleChange('itnMMS', value)
              }}
              inputClassName={`${isRequired('itnMMS')}`}
              disabled={!editable}
            />
          </div>}
        </>}
        {!data?.isProducer && !isDealer && <div className="col">
          <span>Абонатен Номер</span>
          <Input.Text
            value={data?.subscriberNumber || ''}
            onChange={({ target: { value } }) => {
              if (!/^\d+$/.test(value) && value !== '') return
              handleChange('subscriberNumber', value)
            }}
            disabled={!editable}
          />
        </div>}
        {!data?.isProducer && process.env.REACT_APP_PLATFORM !== 'Synergon' && <div className="col">
          <span>Номер на електромер</span>
          <Input.Text
            value={data?.meterNumber || ''}
            onChange={({ target: { value } }) => {
              if (!/^\d+$/.test(value) && value !== '') return
              handleChange('meterNumber', value)
            }}
            disabled={!editable}
          />
        </div>}
        {!data?.isProducer && !isDealer && <div className="col">
          <span>Прогнозируемост</span>
          <Inputs.Dropdown
            autoWidth={isMulti}
            options={forecasts?.map(({ _id, name }) => ({
              label: name,
              value: _id,
            }))}
            onChange={({ value }) => handleChange('forecast', value)}
            value={data?.forecast?._id || data?.forecast}
            disabled={!editable}
          />
        </div>}
        {!data?.isProducer && <div className="col">
          <span>Сезонност</span>
          <Inputs.Dropdown
            autoWidth={isMulti}
            options={seasonings?.map(({ _id, name }) => ({
              label: name,
              value: _id,
            }))}
            onChange={({ value }) => handleChange('seasoning', value)}
            value={data?.seasoning?._id || data?.seasoning}
            className={`${isRequired('seasoning')}`}
            disabled={!editable}
          />
        </div>}
        {!isDealer && !data?.existingObject && <div className="col">
          <span>Край на входиране</span>
          <Inputs.DatePicker
            value={data?.entryEnd}
            onChange={(value) => {
              if (data?.isProducer) handleChange('entryEnd', value)
              else if (data?.status === 'transfer' && ['active', 'leaving'].includes(data.previousStatus)) handleChange('entryEnd', moment(value).startOf('month').add(24, 'days').toDate())
              else handleChange('entryEnd', moment(value).startOf('month').add(9, 'days').toDate())
            }}
            className={`month-picker`}
            showMonthYearPicker={!data?.isProducer}
            showFullMonthYearPicker={!data?.isProducer}
            disabled={!editable}
            popperContainer={({ children }) => (<Portal container={document.getElementById('calendar-portal')}>{children}</Portal>)}
          />
        </div>}
        {!isMulti && <div style={{ flexBasis: '100%' }} />}
        {!data?.isProducer && client?.declaration && (
          <div className={`col col-switch ${(!editable || !allowRequiresNetworkData) && 'disabled'}`}>
            <span>Фактурирай мрежи</span>
            <Button.Switch
              isOn={data?.requiresNetworkData}
              onChange={() =>
                editable &&
                allowRequiresNetworkData &&
                handleChange('requiresNetworkData', !data?.requiresNetworkData)
              }
            />
          </div>
        )}
        <div className={`col col-switch ${(!editable || ['active', 'leaving'].includes(data.previousStatus)) && 'disabled'}`}>
          <span>Пререгистрация</span>
          <Button.Switch
            isOn={data?.status === 'transfer' || data?.isClone}
            onChange={() =>
              editable && !data?.isClone &&
              !['active', 'leaving'].includes(data.previousStatus) &&
              handleChange('status',
                data?.status === 'transfer'
                  ? data.previousStatus
                    ? 'registration'
                    : undefined
                  : 'transfer')
            }
          />
        </div>
        {!isMulti && <div style={{ flexBasis: '100%' }} />}
        <div className="col col-invoice">
          <span>{data?.isProducer ? "Протокол" : "Фактура"}</span>
          {data?.invoice ? (
            <div className="invoice-container row">
              <a href={data?.invoice?.url || ''} target="_blank" rel="noopener noreferrer">
                <span>{data?.invoice?.name}</span>
              </a>
              {editable && <Button.Icon name="plus" color="red" onClick={() => handleChange('invoice', null)} />}
            </div>
          ) : editable ? (
            <Button.UploadButton
              text={
                <Input.DragAndDrop handleDrop={(files) => handleUpload(files, 'invoice')}>
                  <span className="row ripple">
                    Добави файл <div className="icon icon-attach" />
                  </span>
                </Input.DragAndDrop>
              }
              onChange={({ target: { files } }) => handleUpload(files, 'invoice')}
              disabled={!editable}
              accept="*"
            />
          ) : (
            <div className="placeholder" />
          )}
        </div>
        <div className="col">
          <span>Други документи</span>
          {data?.otherDocuments?.length ? (
            <div className="other-documents-container row">
              {data?.otherDocuments?.map(({ name, url }, i) => (
                <div className="row" key={`file-${i}`}>
                  <a href={url || ''} target="_blank" rel="noopener noreferrer">
                    <span>{name}</span>
                  </a>
                  {editable && (
                    <Button.Icon
                      name="plus"
                      color="red"
                      onClick={() => {
                        const newFiles = data?.otherDocuments?.slice()
                        newFiles.splice(i, 1)
                        handleChange('otherDocuments', newFiles)
                      }}
                    />
                  )}
                </div>
              ))}
            </div>
          ) : editable ? (
            <Button.UploadButton
              text={
                <Input.DragAndDrop handleDrop={(files) => handleUpload(files, 'otherDocuments')}>
                  <span className="row ripple">
                    Добави файл <div className="icon icon-attach" />
                  </span>
                </Input.DragAndDrop>
              }
              onChange={({ target: { files } }) => handleUpload(files, 'otherDocuments')}
              multiple
              accept="*"
              disabled={!editable}
            />
          ) : (
            <div className="placeholder" />
          )}
        </div>
        <div className="col col-double">
          <span>Коментар към обект</span>
          <Input.Text
            value={data?.comments?.[0]?.text || ''}
            onChange={({ target: { value } }) => handleChange('comments', [{ ...data?.comments?.[0], text: value }])}
            disabled={!editable}
          />
        </div>
        {(data?.status === 'active' || data?.existingObject) ? <div className="col col">
          <span>Получава енергия от</span>
          <Inputs.DatePicker
            value={data?.activeFrom}
            onChange={(value) => handleChange('activeFrom', data?.isProducer ? value : moment(value).startOf('month').toDate())}
            disabled={!editable || (data?.status !== 'active' && !data?.existingObject)}
            className={`month-picker ${showRequired && data?.existingObject && !data.activeFrom && 'required'}`}
            showMonthYearPicker={!data?.isProducer}
            showFullMonthYearPicker={!data?.isProducer}
            maxDate={new Date()}
            popperContainer={({ children }) => <Portal container={document.getElementById('calendar-portal')}>{children}</Portal>}
          />
        </div> : <div className="col-placeholder-activeFrom" />}
        {data?.issue && <div className="col col-double">
          <span>Проблем</span>
          <Input.Text
            value={data?.issue || ''}
            onChange={({ target: { value } }) => handleChange('issue', value)}
            disabled={!editable}
          />
        </div>}
        {isMulti && handleRemove && (
          <div className="col col-trash">
            <div className="icon icon-delete" onClick={handleRemove}></div>
          </div>
        )}
      </div>
    </div>
  )
})

export default ObjectForm
