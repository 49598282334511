import * as Async from 'awaity'
import { Alerts } from "@makedonski/socourt-utilities"
import { Button, Input } from "@makedonski/admin-ui"
import React, { useMemo, useState } from "react"
import "./styles.scss"
import { parseSingleFile, xlsxExport } from "utilities"
import { useDispatch } from "react-redux"
import { sendEmails, setModal, startLoading, stopLoading } from 'actions'
import { isEmpty } from 'lodash'
import { Shared } from 'components'
import { editorConfig } from 'config/constants'
import { CKEditor } from 'ckeditor4-react'
import { useHistory } from 'react-router-dom'

const Mailer = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [data, setData] = useState({})
  const handleChange = (payload) => setData((prev) => ({ ...prev, ...payload }))
  const columns = useMemo(() => Object.keys(data?.parsed?.[0] ?? {})?.map((label) => ({ label, value: label })), [data])

  const handleDownload = () => xlsxExport({ fields: ['to', 'cc', 'bcc'].map((label) => ({ label, value: label })), data: [] })

  const handleUpload = async ({ target: { files } }) => {
    dispatch(startLoading())
    try {
      const parsed = (await Async.reduce([...files], async (files, file) => [...files, await parseSingleFile(file, 'default')], [])).filter((file) => !isEmpty(file))
      if (!isEmpty(parsed)) handleChange({ parsed: parsed[0].parsedData })
    }
    catch (e) { Alerts.error('Грешка при обработването на файловете!') }
    finally { dispatch(stopLoading()) }
  }

  const handleSendTest = () => dispatch(setModal({
    isOpen: true,
    type: 'mailerTestEmail',
    props: {
      onSuccess: (email) => {
        dispatch(startLoading()); dispatch(sendEmails({
          payload: [{ ...data.parsed[0], 'to': email }].map(mapPayload),
          onSuccess: () => Alerts.success({ title: 'Успешно изпратен имейл' })
        }))
      }
    }
  }))

  const handleSend = () => dispatch(setModal({
    isOpen: true,
    type: 'confirmation',
    props: {
      title: 'Внимание',
      children: `Потвърдете, че искате да изпратите ${data?.parsed?.length} имейла!`,
      onClick: () => {
        dispatch(startLoading()); dispatch(sendEmails({
          payload: data?.parsed?.map(mapPayload),
          onSuccess: () => { Alerts.success({ title: 'Успешно изпратени имейли' }); history.push('/') }
        }))
      }
    }
  }))

  const mapPayload = (row) => {
    let newBody = data?.body
    columns.forEach(({ value }) => newBody = newBody.replace(`{{${value}}}`, row?.[value]))
    return { to: row?.['to'], cc: row?.['cc'], bcc: row?.['bcc'], htmlText: newBody, subject: data?.subject }
  }

  return <div className="screen-mailer-container col">
    <div className="screen-mailer-header row">
      <h2>Масови имейли</h2>
      <div className="row row-buttons">
        <div className="icon icon-export export" onClick={handleDownload} />
        <Button.UploadButton text={<div className="icon icon-export import" />} onChange={handleUpload} accept={'.xlsx, .xls'} />
        <Button.Raised onClick={handleSendTest} text="Изпрати тестови имейл" disabled={!data?.parsed?.length || !data?.subject || !data?.body} className="btn-test" />
        <Button.Raised onClick={handleSend} text="Изпрати" disabled={!data?.parsed?.length || !data?.subject || !data?.body} />
      </div>
    </div>
    <div className="screen-mailer-content">
      <div className="instructions col">
        Инструкции:
        <ul>
          <li>Изтеглете шаблона, попълнете го и го качете в системата</li>
          <li>В текстовия редактор може да използвате стойностите като реферирате иманата на колоните</li>
        </ul>
      </div>
      <div className="editor">
        <span>Тема</span>
        <Input.Text data={data?.subject} onChange={({ target: { value } }) => handleChange({ subject: value })} />
        <CKEditor config={editorConfig} onChange={(e) => handleChange({ body: e.editor.getData() })} />
      </div>
      <div className="excel-preview">
        <Shared.Table
          columns={columns}
          headerWidth={columns.length * 300}
          data={data?.parsed}
        />
      </div>
    </div>
  </div>
}

export default Mailer