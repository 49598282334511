import { Button } from '@makedonski/admin-ui'
import { Popover } from "@varld/popover";
import { clientsRedistributeDealers, getUsers, startLoading } from "actions";
import { Inputs, Popup } from "components";
import { uniqBy } from "lodash";
import React, { useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux";
import "./styles.scss"

const RedistributeDealers = ({ close, clients = [], onSuccess = () => { } }) => {
  const dispatch = useDispatch()
  const [data, setData] = useState({})

  const oldDealers = useMemo(() => {
    return uniqBy(clients?.reduce((acc, { dealers = [] }) => [...acc, ...dealers], []), '_id')
      .map(({ fullName, _id }) => ({ value: _id, label: fullName }))
  }, [clients])

  const [dealers, setDealers] = useState([])
  const getUsersPayload = { select: 'fullName coverPhoto', role: 'dealer' }
  const updateDealerts = (fullName) => dispatch(getUsers({
    payload: { ...getUsersPayload, fullName },
    onSuccess: (res) => setDealers((dealers) => [...(dealers || []), res])
  }))
  useEffect(() => { dispatch(getUsers({ payload: getUsersPayload, onSuccess: (res) => setDealers(res) })) }, [])

  return <div className="modal-redistribute-dealers-container">
    <div className="modal-redistribute-dealers-header row">
      <h2>Преразпредели клиенти</h2>
      <div className="icon icon-close" onClick={close} />
    </div>
    <div className="modal-redistribute-dealers-content row">
      <div className="col">
        <span>Премахни търговец:</span>
        <Inputs.Dropdown
          options={oldDealers}
          value={data?.old}
          onChange={({ value }) => setData({ ...data, old: value })}
        />
      </div>
      <div style={{ width: 30 }} />
      <div className="col">
        <span>Добави търговец:</span>
        <Popover popover={({ close }) => (
          <Popup.Select
            hide={close}
            value={data?.new?._id}
            handleChange={(value) => setData({ ...data, new: dealers.find(({ _id }) => _id === value) })}
            options={dealers?.filter(({ _id }) => _id !== data?.old)?.map(({ fullName, _id }) => ({ label: fullName, value: _id }))}
            text={{ main: 'Избери търговец', secondary: 'Търговци' }}
            search
            handleSearch={updateDealerts}
            single
            skipApply
          />
        )}>
          <Inputs.Dropdown
            value={data?.new?._id}
            options={[data?.new]?.map(({ fullName, _id } = {}) => ({ value: _id, label: fullName }))}
            disabled
          />
        </Popover>
      </div>

    </div>
    <div className="modal-redistribute-dealers-footer row">
      <Button.Raised
        text="Преразпредели"
        disabled={!data?.old || !data?.new?._id}
        onClick={() => {
          dispatch(startLoading())
          dispatch(clientsRedistributeDealers({
            payload: { clients: clients?.map(({ _id }) => _id), old: data?.old, new: data?.new?._id },
            onSuccess: () => {
              close()
              onSuccess()
            }
          }))
        }} />,
    </div>
  </div>
}

export default RedistributeDealers