import React, { useEffect, useMemo, useState } from "react"
import { Input } from '@makedonski/admin-ui'
import moment from "moment"
import { useDispatch, useSelector } from "react-redux"
import { getData, getReferenceErpMeasures, getReferenceErpMeasuresFiles, setLoadingText, startLoading, stopLoading } from "actions"
import { Shared, Inputs } from "components"
import { referenceErpMeasuresFields, referenceErpMeasuresFilesFields } from "config/constants"
import { useQuery } from "hooks"
import "./styles.scss"
import { exportBatchMeasures, renderCell } from "utilities"

const ErpMeasures = ({ }) => {
  const dispatch = useDispatch()

  const { startDate, erp, createdAt, handleUrlChangeMultiple } = useQuery()

  const { erps } = useSelector(({ data }) => data)
  const erpName = useMemo(() => erps?.find(({ _id }) => _id === erp)?.name, [erps, erp])
  useEffect(() => { dispatch(getData('erps')) }, [])


  const [itn, setItn] = useState(undefined)
  const [data, setData] = useState([])
  const [files, setFiles] = useState({})
  const { docs, totalDocs, nextPage, hasNextPage } = files || {}

  const fetchFiles = ({ payload = {}, onSuccess, skipLoading } = {}) => {
    dispatch(getReferenceErpMeasuresFiles({
      payload: { erp, monthYear: moment(startDate).format('MM/YYYY'), createdAt, itn, ...payload },
      onSuccess: (res) => {
        if (!skipLoading) dispatch(stopLoading())
        if (onSuccess) onSuccess(res)
        else {
          if (res.page !== 1) setFiles({ ...res, docs: [...docs, ...res.docs] })
          else setFiles(res)
        }
      }
    }))
  }

  const [showInlineLoading, setShowInlineLoading] = useState(false)
  const fetch = () => {
    dispatch(startLoading())
    setShowInlineLoading(true)
    dispatch(getReferenceErpMeasures({
      payload: { from: moment(startDate).startOf('month'), to: moment(startDate).add(1, 'months').startOf('month'), erp },
      onSuccess: (payload) => {
        setData(payload ? [{ _id: 1, ...payload }] : [])
        setShowInlineLoading(false)
      }
    }))
  }

  useEffect(() => { if (erp) fetch() }, [startDate, erp])
  useEffect(() => { if (erp) fetchFiles() }, [startDate, erp, itn, createdAt])


  const exportFiles = (type) => () => {
    dispatch(startLoading())
    fetchFiles({
      payload: { noPagination: true, select: type },
      skipLoading: true,
      onSuccess: ({ docs }) => {
        exportBatchMeasures({
          type: 'erpMeasures',
          files: docs.map((f) => ({ url: f[type] })),
          filename: `${type}-${erpName}-${createdAt ? moment(createdAt).format('DD-MM-YYYY') : moment(startDate).format('MM-YYYY')}`,
          onSuccess: () => {
            dispatch(setLoadingText('Loading...'))
            dispatch(stopLoading())
          }
        })
      }
    })
  }

  return <div className="references-erp-measures-container">
    <div className={`references-erp-measures-statistics ${showInlineLoading && 'row'}`}>
      {showInlineLoading
        ? <span className="inline-loader">Loading...</span>
        : <Shared.Table
          columns={referenceErpMeasuresFields}
          data={data}
          renderCell={(row, field) => {
            if (['lastImport'].includes(field)) return moment(row[field]).format('DD.MM.YYYY | HH:mm')
            else return row?.[field] || "--"
          }}
          fixedWidth
        />}
    </div>
    <div className="references-erp-measures-files-header row">
      <span>ИТН: </span>
      <Input.Text value={itn} onChange={({ target: { value } }) => setItn(value)} />
      <span>Дата:</span>
      <Inputs.DatePicker
        isClearable
        value={createdAt}
        onChange={(createdAt) => handleUrlChangeMultiple({ createdAt })}
      // minDate={moment(startDate).startOf('month').toDate()}
      // maxDate={moment(moment(startDate).endOf('month').toDate()).isSameOrBefore(moment().endOf('day')) ? moment(startDate).endOf('month').toDate() : moment().toDate()}
      />
      <span className="totalDocs"> За дата: {totalDocs} файла</span>
      <div className="row row-exports">
        <span>XLSX: </span>
        <div className="icon icon-export" onClick={exportFiles('xlsxFile')} />
        <span>XML: </span>
        <div className="icon icon-export" onClick={exportFiles('xmlFile')} />
      </div>
    </div>
    <div className="references-erp-measures-files">
      <Shared.Table
        className="files"
        columns={referenceErpMeasuresFilesFields}
        data={docs}
        handlePagination={() => hasNextPage && fetchFiles({ payload: { page: nextPage } })}
        renderCell={renderCell.references.erpMeasuresFiles}
        fixedWidth
      />
    </div>
  </div>
}

export default ErpMeasures