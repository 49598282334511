import React, { useState, useEffect, useRef, forwardRef, useMemo } from 'react'
import { Button } from '@makedonski/admin-ui'
import { Alerts } from '@makedonski/socourt-utilities'
import { Tooltip } from '@varld/popover'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { omit, pick } from 'lodash'
import { getData, createObject, updateObject, getClient, startLoading, stopLoading, removeCloneObject } from 'actions'
import { Client } from 'components'
import { useQuery } from 'hooks'
import { mapExcelToObjects, displayMassCheckError, isValidSingleObject, checkIsDealer } from 'utilities'
import './styles.scss'
import moment from 'moment'

const Objects = forwardRef(
  (
    {
      client,
      data: dataProp,
      isClientProfile,
      isCreate,
      editable: editableProp,
      setEditable,
      setSelectedObject,
      handleSuccess,
    },
    ref
  ) => {
    const isDealer = checkIsDealer()
    const { client: clientParam, clientType } = useQuery()
    const isPotential = useMemo(() => clientParam === 'new', [clientParam])
    const isProducer = useMemo(() => [client?.clientType, clientType].some((v) => v === 'Производител'), [client, clientType])
    const editable = editableProp === undefined ? true : editableProp
    const defaultData = {
      entryEnd: moment().startOf('month').add(9, 'days').add(new Date().getDate() <= 10 ? 0 : 1, 'months').toDate(),
      ...(!isClientProfile && { existingObject: client?.existingClient }),
      ...(isProducer && { isProducer }),
    }
    const initialData = dataProp || [defaultData]
    useEffect(() => { if (!isCreate || !isClientProfile) setData(initialData) }, [dataProp, client])

    const history = useHistory()
    const [data, setData] = useState(initialData)
    const handleChange = (field, value, i) => {
      const newData = [...(data || [])]
      newData.splice(i, 1, { ...data[i], [field]: value })
      if (field === 'erp' && options?.erps?.find(({ _id }) => _id === value)?.name === 'ЕСО') {
        const measureType = options?.measureTypes?.find(({ name }) => name === 'Високо')?._id
        const voltage = options?.voltages?.find(({ name }) => name === 'Високо напрежение')?._id
        newData.splice(i, 1, { ...data[i], [field]: value, measureType, voltage })
      } else if (field === 'erp' && data[i].erp === options?.erps?.find(({ name }) => name === 'ЕСО')?._id) {
        newData.splice(i, 1, { ...data[i], [field]: value, measureType: null, voltage: null })
      }
      setData(newData)
    }

    const handleChangeMultiple = (payload, i) => setData((data) => {
      const newData = [...(data || [])]
      newData.splice(i, 1, { ...newData[i], ...payload })
      return newData
    })

    const { data: options } = useSelector((state) => state)
    const dispatch = useDispatch()
    useEffect(() => {
      const dropdowns = ['voltages', 'seasonings', 'forecasts', 'measureTypes', 'erps', 'profiles', 'versions', 'products']
      dropdowns.forEach((dropdown) => !options?.[dropdown] && dispatch(getData(dropdown)))
    }, [])

    const isClientProducer = client?.clientType !== 'Консуматор' && !client?.producerSettings

    const [showRequired, setShowRequired] = useState(false)
    const isValid = () => {
      let valid = true
      const validated = data.map((object) => isValidSingleObject({ object, data: options, validateProducerSettings: isClientProducer }))
      if (validated.includes(false)) valid = false
      const itns = data.map(({ itn }) => itn)
      if (new Set(itns).size !== itns.length) {
        Alerts.error('Има дублиращи се точки !')
        valid = false
      }
      return valid
    }

    const props = { client, options, handleChangeMultiple, showRequired, editable, setEditable }

    const onClear = () => {
      if (isClientProfile && editableProp) setEditable(false)
      if (isClientProfile && !isCreate) ref.current.data = null
      setData(initialData)
      setShowRequired(false)
    }

    const [addCount, setAddCount] = useState(["0"])
    useEffect(() => {
      if (!addCount.length) {
        dispatch(stopLoading())
        dispatch(getClient({ payload: client?._id, getAllDetails: true }))
        if (handleSuccess) handleSuccess()
        else history.push(client?._id)
      }
    }, [addCount])
    const onSave = () => {
      if (!isValid() && (isCreate || client?.clientType !== 'Консуматор')) {
        setShowRequired(true)
        return
      } else {
        dispatch(startLoading())
        if (isClientProfile && editableProp)
          dispatch(
            updateObject({
              data: omit(data[0], isDealer ? ["existingObject", 'subscriberNumber', 'forecast'] : []),
              onSuccess: (newObject) => {
                setEditable(false)
                dispatch(getClient({ payload: client?._id, getAllDetails: true }))
                setSelectedObject(newObject)
                ref.current.data = null
              },
            })
          )
        else {
          setAddCount(() => [...Array(data.length).keys()])
          data
            ?.map(obj => omit(obj, isDealer ? ["existingObject", 'subscriberNumber', 'forecast'] : []))
            ?.map(({ error, ...object }) => ({
              client: client?._id,
              ...object,
              status: object?.existingObject ? 'active' : !!object.status ? object.status : isPotential ? 'potential' : 'registration',
              isClone: object.status === 'transfer' && object.previousClient && object.previousClient !== object.client && ['active', 'leaving'].includes(object?.previousStatus)
            })).forEach((object, index) => {
              if (object._id) dispatch(updateObject({
                data: object, onSuccess: (newObject) => {
                  setAddCount(addCount => addCount.filter(i => i !== index))
                  if (isClientProfile) {
                    setSelectedObject({ ...newObject, comments: data[0].comments })
                    ref.current.data = null
                  }
                  if (object.isClone && object.existingObject) dispatch(removeCloneObject({ payload: { ...pick(newObject, ["_id", "itn", "client", "activeFrom"]) } }))
                }
              }))
              else dispatch(createObject({
                data: object, onSuccess: (newObject) => {
                  setAddCount(addCount => addCount.filter(i => i !== index))
                  if (isClientProfile) {
                    setSelectedObject({ ...newObject, comments: data[0].comments })
                    ref.current.data = null
                  }
                }
              }))
            })
        }
      }
    }

    const objectsRef = useRef({})
    const [count, setCount] = useState(["0"])
    useEffect(() => {
      if (!count.length) {
        dispatch(stopLoading())
        displayMassCheckError({ objects: data, setShowRequired })
      }
    }, [count])
    const handleMassCheck = () => {
      setCount(Object.entries(objectsRef?.current || {}).filter(([key, value]) => value).map(([key]) => key))
      setData((data) => data.map((obj) => omit(obj, ['_id', "status", "previousStatus", "client", "previousClient", "error"])))
      dispatch(startLoading())
      Object.values(objectsRef?.current || {}).forEach(obj => obj?.handleCheckStart((index) => setCount(count => count.filter(i => i !== index.toString()))))
    }

    if (ref) ref.current = { onClear, onSave, data }

    return (
      <div className="client-forms-objects-container col">
        <div className="client-forms-objects-header row">
          <h2>Информация за обект</h2>
          {isCreate && data.length <= 1 && !isDealer && (
            <Button.Switch
              label="Незабавна активация"
              isOn={!!data?.at(0)?.existingObject}
              onChange={() => handleChange('existingObject', !data?.at(0)?.existingObject, 0)}
            />
          )}
        </div>
        {data?.map((object, i) => {
          const showInvalid = showRequired && !isValidSingleObject({ object, data: options, skipAlerts: true, validateProducerSettings: isClientProducer })
          return <React.Fragment key={`form-${i}`}>
            {isCreate && data.length > 1 && (
              <div className="row row-remove-object">
                <span className="row title">Обект {i + 1}</span>
                {showInvalid && object.error && <Tooltip content={object.error}><span className="invalid">!</span></Tooltip>}
                {!isDealer && <Button.Switch
                  label="Незабавна активация"
                  isOn={!!data?.at(i)?.existingObject}
                  onChange={() => handleChange('existingObject', !data?.at(i)?.existingObject, i)}
                />}
                <Button.Icon
                  name="plus"
                  color="red"
                  onClick={() => {
                    const newData = [...(data || [])]
                    newData.splice(i, 1)
                    setData(newData)
                  }}
                />
              </div>
            )}
            <Client.Forms.Object
              index={i}
              ref={ref => objectsRef.current[i] = ref}
              showInvalid={showInvalid}
              data={object}
              handleChange={(field, value) => handleChange(field, value, i)}
              massCheck={data.length > 1}
              {...props}
            />
            {isClientProducer && object?.isProducer && <Client.Forms.ProducerSettings
              producerSettings={object?.producerSettings}
              setProducerSettings={(newData) => handleChange('producerSettings', newData, i)}
              handleChange={(field, value) => handleChange('producerSettings', { ...object?.producerSettings, [field]: value }, i)}

              versions={options.versions}
              products={options.products}

              showRequired={showRequired}
              editable={editable}
              showInvalid={showInvalid}

              isProfile={isClientProfile}
            />}
          </React.Fragment>
        })}

        {!isClientProfile && (
          <div className="row row-buttons">
            <div className="icon icon-add" onClick={() => setData([...data, defaultData])} />
            <span>Добави друг обект</span>
            {!isDealer && <>
              <Button.UploadButton
                text={<div className="icon icon-add icon-add-mass" />}
                multiple
                accept={'.csv, .xlsx, .xls'}
                onChange={({ target: { files } }) => mapExcelToObjects({
                  files, client, data: options,
                  onSuccess: (parsed) => {
                    setData(parsed)
                    handleMassCheck()
                  }
                })}
              />
              <span>Масово добавяне</span>
            </>}
            <div className="buttons row">
              {handleSuccess && <Button.Raised className="btn-cancel" text="Прескочи" onClick={handleSuccess} />}
              {/* <Button.Raised className="btn-cancel" text="Откажи" onClick={onClear} /> */}
              {data.length > 1 && <Button.Raised text="Масова проверка" className="mass-add" onClick={handleMassCheck} />}
              <Button.Raised className="btn-save" text="Запази и продължи" onClick={onSave} />
            </div>
          </div>
        )}
      </div>
    )
  }
)

export default Objects
