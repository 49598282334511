import React from "react"
import { Inputs } from "components"
import "./styles.scss"

const IntermediatePricing = ({ invoice }) => {
  const { pricing } = invoice || {}

  return <div className="invoices-forms-intermediate-pricing-container">
    {[{ label: 'Данъчна основа', value: 'base' }, { label: 'Данъчна ставка 20% ДДС', value: 'vat' }, { label: 'Стойност за плащане ', value: 'withVat' }]
      .map(({ label, value }) => (
        <div key={`el-${value}`} className="row">
          <p>{label}</p>
          <Inputs.TextLabeled label="лв." value={Number(pricing?.[value] || 0).toFixed(2) ?? ''} disabled />
        </div>)
      )
    }
  </div>
}

export default IntermediatePricing