import React, { useState, useEffect, forwardRef, useMemo } from 'react'
import { Popover } from '@varld/popover'
import { Input, Button } from '@makedonski/admin-ui'
import { Alerts, Headers } from '@makedonski/socourt-utilities'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty, pick, uniqBy } from 'lodash'
import {
  setModal,
  getData,
  getGroups,
  getConnectedGroups,
  getClientsForDropdown,
  updateClient,
  startLoading,
  updateGroup,
  updateConnectedGroup,
  createConnectedGroup,
  uploadFileClient,
  getUsers,
} from 'actions'
import { Inputs, Client, Popup } from 'components'
import { defaultContractSettings, defaultProducerSettings, emailRegex, productsPricesFields } from 'config/constants'
import { useQuery } from 'hooks'
import './styles.scss'
import moment from 'moment'
import { checkIsDealer, isValidProducerSettings, loadDropdownOptions } from 'utilities'
import { URL } from 'config/settings'

const requiredFields = ['type', 'fullName', 'mol', 'city', 'address', 'phoneNumber', 'email']
const requiredContractFields = ['version', 'paymentDays']
const MainProfile = forwardRef(({ data: dataProp, editable: editableProp, setEditable }, ref) => {
  const { currentUser } = useSelector(({ general }) => general) || {}
  const isDealer = checkIsDealer()
  const editable = editableProp === undefined ? true : editableProp
  const initialData = dataProp || {}
  const [data, setData] = useState(initialData)
  const handleChange = (field, value) => setData({ ...data, [field]: value })
  const handlePriceChange = (field, value) => handleChange('price', { ...data?.price, [field]: value })
  const [showRequired, setShowRequired] = useState(false)
  const { businessTypes, products, groups, connectedGroups, clients, versions } = useSelector(({ data }) => data)
  const { inactive } = useQuery()

  useEffect(() => {
    setData(initialData)
  }, [dataProp])

  const dispatch = useDispatch()
  useEffect(() => {
    !businessTypes && dispatch(getData('businessTypes'))
    !products && dispatch(getData('products'))
    !versions && dispatch(getData('versions'))
    !groups && dispatch(getGroups())
    !connectedGroups && dispatch(getConnectedGroups())
    dispatch(getClientsForDropdown({ search: "", getAllDetails: true }))
  }, [editable])

  const isValid = () => {
    if (requiredFields.some((field) => !data?.[field])) {
      Alerts.error('Моля попълнете всички задължителни полета в основна информация')
      return false
    } else if (!data?.eic && !data?.pin) {
      Alerts.error('Моля попълнете ЕИК или ЕГН')
      return false
    } else if (data?.contacts?.length && data?.contacts?.some(({ email, phoneNumber }) => !email && !phoneNumber)) {
      Alerts.error('Моля попълнете всички задължителни полета в допълнителен контакт')
      return false
    } else if ([data?.email, ...(data?.contacts || [])?.map(({ email }) => email)].filter(Boolean).some((val) => !emailRegex.test(val))) {
      Alerts.error('Моля попълнете валиден имейл')
      return false
    } else if (process.env.REACT_APP_PLATFORM === 'Synergon' && isEmpty(data?.dealers)) {
      Alerts.error('Моля добавете търговец')
      return false
    }

    if (data.clientType !== 'Производител') {
      const files = data?.contractSettings?.files?.map(({ type }) => type)
      if (
        !data?.product ||
        productsPricesFields
          .filter(({ types }) => types.includes(products?.find(({ _id }) => data?.product === _id)?.name))
          .map(({ value }) => value)
          .some((field) => [undefined, null].includes(data?.price?.[field]))
      ) {
        Alerts.error('Моля попълнете продукт и всички цени')
        return false
      } else if (
        requiredContractFields.some((field) => !data?.contractSettings?.[field]) ||
        (!data?.contractSettings?.contractDurationMonths && !data?.contractSettings?.deliveryEnd) ||
        (data?.existingClient && !data?.contractSettings?.deliveryStart) ||
        (data?.autoSign && !data.autoSignMonths)
      ) {
        Alerts.error('Моля попълнете всички задължителни полета в документи и договор')
        return false
      } else if (process.env.REACT_APP_PLATFORM !== 'Proakt' && ['letter of attorney', 'contract'].some((val) => !files?.includes(val))) {
        Alerts.error('Моля добавете пълномощно и договор')
        return false
      } else if (process.env.REACT_APP_PLATFORM !== 'Proakt' && data?.contractSettings?.type === 'Комбиниран' && !files?.includes('declaration')) {
        Alerts.error('При комбиниран договор е необходимо да прикачите Декларация')
        return false
      }
    }

    if (data?.clientType !== 'Консуматор' && data?.producerSettings && !data?.producerSettings?.disabled) {
      const { isValid, error } = isValidProducerSettings({ data, products })
      if (!isValid) {
        Alerts.error(error)
        return false
      }
    }

    return true
  }

  const isRequired = (field) => {
    if (!showRequired) return ''
    if (data?.[field] || data?.price?.[field]) return ''
    if (field === 'businessType' && data?.businessType && !isEmpty(data?.businessType)) return ''
    return 'required'
  }
  const isEmail = (email) => showRequired && !emailRegex.test(email) && 'required'

  const onRequired = (value) => setShowRequired(value)
  const onClear = () => {
    if (data?._id) setEditable(false)
    setData(initialData)
    setShowRequired(false)
  }

  const hasPriceOrContractChanged = useMemo(() => {
    return ['type', 'terms', 'paymentDaysType', 'paymentDays'].some(f => dataProp?.contractSettings?.[f] !== data?.contractSettings?.[f]) ||
      ['price', 'product', 'declaration'].some(f => dataProp?.[f] !== data?.[f])
  }, [dataProp, data])

  const isClientInactive = useMemo(() => dataProp?.objects?.every(({ status }) => ['inactive'].includes(status)), [dataProp])
  useEffect(() => { if (isClientInactive) handleChange('priceActiveFrom', hasPriceOrContractChanged ? moment().startOf('month').toDate() : undefined) }, [isClientInactive, hasPriceOrContractChanged])

  const onSave = () => {
    if ((data?.clientType !== 'Консуматор' || inactive) && !isValid()) { setShowRequired(true); return }
    if (hasPriceOrContractChanged && !data.priceActiveFrom) dispatch(setModal({ isOpen: true, type: 'priceActiveFrom', props: { onSaveClient } }))
    else onSaveClient()
  }

  const onSaveClient = ({ priceActiveFrom } = {}) => {
    dispatch(startLoading())

    if (data?.group?.length) {
      const groupsToBeRemovedFrom = dataProp?.group?.filter(({ _id }) => !data?.group?.map(({ _id }) => _id)?.includes(_id))
      if (groupsToBeRemovedFrom.length) {
        groupsToBeRemovedFrom.forEach(({ _id }) => {
          const originalGroup = groups?.find((g) => g._id === _id)
          dispatch(updateGroup({ payload: { _id, clients: originalGroup?.clients?.filter(({ _id }) => _id !== data?._id).map(({ _id }) => _id) } }))
        })
      }
      const groupsToBeAddedTo = data?.group?.filter(({ _id }) => !dataProp?.group?.map(({ _id }) => _id)?.includes(_id))
      if (groupsToBeAddedTo.length) {
        groupsToBeAddedTo.forEach(({ _id }) => {
          const originalGroup = groups?.find((g) => g._id === _id)
          dispatch(updateGroup({ payload: { _id, clients: [...(originalGroup?.clients ?? [])?.map(({ _id }) => _id), data?._id] } }))
        })
      }
    }

    if ((typeof data?.connectedGroup === 'string' || data?.connectedGroup === null) && data?.connectedGroup !== dataProp?.connectedGroup?._id) {
      const originalGroup = connectedGroups?.find(({ _id }) => _id === dataProp?.connectedGroup?._id)
      const newGroup = connectedGroups?.find(({ _id }) => _id === data?.connectedGroup)
      if (dataProp?.connectedGroup?._id) dispatch(updateConnectedGroup({ _id: dataProp?.connectedGroup?._id, clients: originalGroup?.clients?.filter(({ _id }) => _id !== data?._id)?.map(({ _id }) => _id), }))
      if (data?.connectedGroup) dispatch(updateConnectedGroup({ _id: data?.connectedGroup, clients: [...(newGroup?.clients || [])?.map(({ _id }) => _id), data?._id], }))
    }

    const newFiles = data?.contractSettings?.files?.filter((f) => f?.new)
    if (!isEmpty(newFiles)) newFiles.forEach((file) => dispatch(uploadFileClient({ payload: { client: data?._id, ...file }, skipGetClient: true })))

    dispatch(updateClient({
      data: {
        ...data,
        eic: ["Юридическо лице"].includes(data.type) ? data?.eic || data?.pin : null,
        pin: ["Физическо лице", "Битов"].includes(data.type) ? data?.eic || data?.pin : null,
        dealers: data?.dealers?.map(({ _id }) => _id),
        priceActiveFrom
      },
      onSuccess: () => {
        setShowRequired(false)
        setEditable(false)
      },
    }))
  }

  if (ref) ref.current = { onRequired, onClear, onSave }

  const [dealers, setDealers] = useState([])
  const getUsersPayload = { select: 'fullName coverPhoto', role: 'dealer' }
  const updateDealers = (fullName) => dispatch(getUsers({
    payload: { ...getUsersPayload, fullName },
    onSuccess: (res) => setDealers((dealers) => uniqBy([...(dealers || []), ...(res || [])], '_id'))
  }))
  useEffect(() => {
    if (isDealer !== undefined) dispatch(getUsers({
      payload: getUsersPayload, onSuccess: (res) => {
        if (isDealer && inactive && editable && initialData) {
          handleChange('dealers', [pick(currentUser, ['_id', 'fullName'])])
          const dealers = uniqBy([...(res || []), currentUser], '_id')
          setDealers(dealers)
        } else setDealers(res)
      }
    }))
  }, [isDealer, inactive, editable, initialData])

  return (
    <>
      <div className="client-forms-main-profile-container">
        <h2>Основна информация - {data?.clientType}</h2>
        {editable && <>
          <div className="row">
            {dataProp?.clientType !== 'Смесен' && <div className="col">
              <span>Вид клиент</span>
              <Inputs.Dropdown
                options={[dataProp?.clientType, 'Смесен'].map((value) => ({ value, label: value }))}
                value={data?.clientType}
                onChange={({ value }) => {
                  if (dataProp?.clientType === 'Консуматор') setData({ ...data, clientType: value, producerSettings: { ...defaultProducerSettings, version: versions?.find(({ name }) => name === 'Г10.22')?._id } })
                  else if (dataProp?.clientType === 'Производител') setData({ ...data, clientType: value, contractSettings: defaultContractSettings })
                }}
              />
            </div>}
            <div className={`col ${dataProp?.clientType === 'Смесен' && 'col-double'}`}>
              <span>Тип клиент</span>
              <Inputs.Dropdown
                options={['Юридическо лице', 'Физическо лице', 'Битов']
                  .filter((value) => data?.clientType !== 'Производител' || value !== 'Битов')
                  .map((value) => ({ value, label: value }))
                }
                value={data?.type}
                onChange={({ value }) => handleChange('type', value)}
              />
            </div>
            <div className="col">
              <span>ЕИК/ЕГН</span>
              <Input.Text
                value={data?.eic || data?.pin || ''}
                onChange={({ target: { value } }) => {
                  if (!/^\d+$/.test(value) && value !== '') return
                  handleChange('eic', value)
                }}
                inputClassName={`${isRequired('eic')}`}
              />
            </div>
            <div className="col">
              <span>Име на клиент</span>
              <Input.Text
                value={data?.fullName || ''}
                onChange={({ target: { value } }) => handleChange('fullName', value)}
                inputClassName={`${isRequired('fullName')}`}
              />
            </div>
            <div className="col">
              <span>БУЛСТАТ</span>
              <Input.Text
                value={data?.bulstat || ''}
                onChange={({ target: { value } }) => {
                  if (!/^[A-Za-z\d]+$/.test(value) && value !== '') return
                  handleChange('bulstat', value)
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col col-double col-mol">
              <span>МОЛ</span>
              <Input.Text
                value={data?.mol?.map(({ fullName }) => fullName)?.join('; ') ?? ''}
                disabled
              />
              <span
                className="icon icon-edit"
                onClick={() => dispatch(setModal({
                  isOpen: true, type: 'clientEditMOL', props: {
                    client: data,
                    handleChange: (value) => handleChange('mol', value)
                  }
                }))}
              />
            </div>

          </div>
        </>
        }
        <div className="row">
          <div className="col">
            <span>Град</span>
            <Input.Text
              value={data?.city || ''}
              onChange={({ target: { value } }) => handleChange('city', value)}
              inputClassName={`${isRequired('city')}`}
              disabled={!editable}
            />
          </div>
          <div className="col col-double">
            <span>Адрес</span>
            <Input.Text
              value={data?.address || ''}
              onChange={({ target: { value } }) => handleChange('address', value)}
              inputClassName={`${isRequired('address')}`}
              disabled={!editable}
            />
          </div>
          <div className="col">
            <span>Телефон</span>
            <Input.Text
              value={data?.phoneNumber || ''}
              onChange={({ target: { value } }) => handleChange('phoneNumber', value)}
              inputClassName={`${isRequired('phoneNumber')}`}
              disabled={!editable}
            />
          </div>
          <div className="col">
            <span>Имейл</span>
            <Input.Text
              value={data?.email || ''}
              onChange={({ target: { value } }) => handleChange('email', value)}
              inputClassName={`${isRequired('email') || isEmail(data?.email)}`}
              disabled={!editable}
            />
          </div>
        </div>
        <div className="row">
          {!isDealer && <div className="col col-activity">
            <span>Дейности</span>
            <Inputs.Select
              className="activity-container"
              editable={editableProp || editable}
              options={businessTypes}
              value={data?.businessType?.map((obj) => obj?._id || obj)}
              handleChange={(value) => handleChange('businessType', value)}
              showRequired={false}
              createDataType="businessTypes"
              text={{ main: 'Дейности', secondary: 'Избери дейност/и' }}
              search
            />
          </div>}
          <div className="col">
            <span>Клиентски номер</span>
            <Input.Text
              value={data?.clientNumber || ''}
              onChange={({ target: { value } }) => handleChange('clientNumber', value)}
              disabled={!editable}
            />
          </div>
          {editable && !data.clientNumber && <div className="col col-clientNumber-generate">
            <Button.Raised
              className='blue'
              text='Генерирай'
              onClick={async () => {
                const { clientNumber } = await fetch(`${URL}/clientNumber/request`, {
                  method: 'POST',
                  headers: Headers.getWithAuth(),
                  body: JSON.stringify({ "requestedFor": "Clients" }),
                }).then(res => res.json())
                handleChange('clientNumber', clientNumber)
              }}
            />
          </div>}
          <div className={`col col-additional-client ${!editable && 'disabled'}`}>
            <span>Автоматични имейли</span>
            <Button.Switch
              isOn={data?.autoEmails}
              onChange={() => editable && handleChange("autoEmails", !data?.autoEmails)}
            />
          </div>
          {!isDealer && <div className={`col col-additional-client ${!editable && 'disabled'}`}>
            <span>Група клиенти</span>
            <Inputs.DropdownSelect
              async paginate cacheOptions defaultOptions isMulti
              loadOptions={(name) => loadDropdownOptions({ path: '/groups/browse', payload: { name, limit: 10 }, paginate: true })}
              rawValue={data?.group ?? []}
              onChange={(value) => handleChange('group', value)}
              getOptionValue={({ _id }) => _id}
              getOptionLabel={({ name }) => name}
              additional={{ page: 1 }}
              disabled={!editable}
            />
          </div>}
          <div className="col col-connected-client">
            <span>Свързани клиенти</span>
            {data?.connectedGroup ? (
              <div className="row row-client">
                {editable && (
                  <Button.Icon name="plus" color="red" onClick={() => handleChange('connectedGroup', null)} />
                )}
                <span style={{ lineHeight: '38px', margin: '10px 0' }}>
                  {data?.connectedGroup?.name || connectedGroups?.find(({ _id }) => _id === data?.connectedGroup)?.name}
                </span>
                <div
                  className="icon icon-open"
                  onClick={() => dispatch(setModal({
                    isOpen: true, type: 'confirmation', props: {
                      title: `Група ${data?.connectedGroup?.name || connectedGroups?.find(({ _id }) => _id === data?.connectedGroup)?.name}`,
                      children: (
                        <div className="">
                          {(data?.connectedGroup?.clients ||
                            connectedGroups?.find(({ _id }) => _id === data?.connectedGroup).clients
                          )?.map(({ fullName, eic, pin }) => <div className=" row">
                            <p>{fullName} - {eic || pin}</p>
                          </div>)}
                        </div>
                      ),
                      hideButton: true,
                    },
                  }))}
                />
              </div>
            ) : editable ? (
              <Popover
                popover={({ close }) => (
                  <Popup.Select
                    single
                    search
                    handleSearch={(value) => dispatch(getClientsForDropdown({ search: value, getAllDetails: true }))}
                    hide={close}
                    handleChange={(value) => {
                      const client = clients.find(({ _id }) => _id === value)
                      if (!client) return
                      else if (client?.connectedGroup) handleChange('connectedGroup', client?.connectedGroup?._id)
                      else
                        dispatch(
                          setModal({
                            isOpen: true,
                            type: 'connectedClientGroup',
                            props: {
                              onCreate: (name) => {
                                dispatch(
                                  createConnectedGroup({
                                    data: { name, clients: [value] },
                                    onSuccess: (group) => handleChange('connectedGroup', group),
                                  })
                                )
                              },
                            },
                          })
                        )
                    }}
                    options={clients?.map(({ eic, pin, fullName, _id }) => ({
                      label: `${fullName} | ${eic || pin}`,
                      value: _id,
                    }))}
                    text={{ main: 'Търси по име/ЕИК/ЕГН', secondary: 'Клиенти' }}
                  />
                )}
              >
                <div className="icon icon-add" style={{ margin: '14px 0' }} />
              </Popover>
            ) : (
              <div style={{ height: '58px' }} />
            )}
          </div>
        </div>
        {!isDealer && data?.clientType !== 'Производител' && <div className="row">
          <div className="col col-payment-type">
            <Client.Forms.IntermediatePayments
              editable={editable}
              data={data.invoiceSettings}
              setData={(payload) => setData({ ...data, invoiceSettings: { ...data?.invoiceSettings, ...payload } })}
            />
          </div>
        </div>}
        <div className="row">
          <div className={`col col-triple col-merchant ${!editable && 'disabled'}`}>
            <span>Търговци</span>
            <div className="row">
              {editable && (
                <Popover
                  popover={({ close }) => (
                    <Popup.Select
                      hide={close}
                      search
                      handleSearch={updateDealers}
                      value={data?.dealers?.map(({ _id }) => _id)}
                      options={dealers?.map(({ fullName, _id }) => ({ label: fullName, value: _id }))}
                      handleChange={(value) => {
                        const payload = value.map(_id => dealers.find((d) => d._id === _id))
                        handleChange('dealers', payload)
                        // setClientData({ dealers: payload })
                      }}
                      text={{ main: 'Търси по име', secondary: 'Търговци' }}
                    />
                  )}
                >
                  <div className="icon icon-add" />
                </Popover>
              )}
              <div className="row">
                {data?.dealers?.map(({ _id, fullName, coverPhoto }) => (
                  <div key={_id} className="single-person-container row">
                    <div
                      className="profile-image-container"
                      style={{ backgroundImage: `url(${coverPhoto || require('../../../../assets/images/default-user-avatar.jpg')})` }}
                    />
                    <p>{fullName}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {(data?.contacts || editable) && (
        <Client.Forms.AdditionalContact
          clientType={data?.clientType}
          data={data?.contacts}
          handleRemoveContact={(i) => {
            const newContacts = [...(data?.contacts || [])]
            newContacts.splice(i, 1)
            handleChange('contacts', newContacts)
          }}
          handleChangeContact={(field, value, i) => {
            const newContacts = [...(data?.contacts || [])]
            newContacts.splice(i, 1, { ...newContacts?.at(i), [field]: value })
            handleChange('contacts', newContacts)
          }}
          handleChange={(field, value) => handleChange(field, value)}
          showRequired={showRequired}
          editable={editable}
        />
      )}
      {data?.clientType !== 'Производител' && <Client.Forms.DocumentsAndContract
        data={data}
        setData={(payload) => setData({ ...data, ...payload })}
        contractSettings={data?.contractSettings}
        setContractSettings={(newData) => handleChange('contractSettings', newData)}
        handleChange={(field, value) => handleChange('contractSettings', { ...data?.contractSettings, [field]: value })}

        versions={versions}
        products={products}
        handlePriceChange={handlePriceChange}

        showRequired={showRequired}
        editable={editable}
        isProfile
        isInactive={inactive}
      />}
      {data?.clientType !== 'Консуматор' && <Client.Forms.ProducerSettings
        producerSettings={data?.producerSettings}
        setProducerSettings={(newData) => handleChange('producerSettings', newData)}
        handleChange={(field, value) => handleChange('producerSettings', { ...data?.producerSettings, [field]: value })}

        versions={versions}
        products={products}

        showRequired={showRequired}
        editable={editable}

        isClient
        isProfile
        isInactive={inactive}
      />}

      {!isEmpty(data?.clientPricing) && <Client.Forms.ClientPricing data={data?.clientPricing} />}
    </>
  )
})

export default MainProfile
