import { getCommissionsCreatedPayments, getCommissionsCreatedReference, getCommissionsReference, getCommissionsReferenceDetails, setReferencesFields, startLoading } from "actions"
import { Shared } from "components"
import { initialState } from 'reducers/references'
import moment from "moment"
import React, { useCallback, useEffect, useMemo, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import "./styles.scss"
import { commissionsReferenceCreatedDetailsFields, commissionsReferenceDetailsFields, commissionsReferenceFields, commissionsStatuses, referencesCommissionsTypes } from "config/constants"
import { mapClientsSearch, renderCell } from "utilities"
import { useQuery } from "hooks"
import { Popover } from "@varld/popover"

const Commissions = ({ }) => {
  const { type, startDate, endDate, sort: sortParam = '{}', filter: filterParam = '{}', } = useQuery({ type: referencesCommissionsTypes })
  const getMonths = (start, end) => Array.from({ length: moment(end).diff(moment(start), 'month') + 1 }).map((_, index) => moment(start).add(index, 'month').format('MM/YYYY'),);
  commissionsReferenceFields.payments = [
    { label: 'Комисионер', value: 'userFullName', sortable: true },
    { label: 'Дължимо без ДДС', value: 'unpaid', size: 150, sortable: true },
    getMonths(startDate ?? moment().subtract(3, 'months').startOf('month').toDate(), endDate ?? moment().endOf('month').toDate()).map((monthYear) => [
      { label: `${monthYear} - Статус`, value: `${monthYear} - status`, size: 270, sortable: true },
      { label: `${monthYear} - Имейл изпратен`, value: `${monthYear} - emailSent`, size: 190, sortable: true },
      { label: `${monthYear} - Ф/Д`, value: `${monthYear} - uploadedFileDate`, size: 230, sortable: true },
    ]).flat()
  ].flat()


  const dispatch = useDispatch()
  const { reference, totalDocs, hasNextPage, nextPage, showCheckboxes, selectedCheckboxes } = useSelector(({ references }) => references)

  const availableSort = ["consumption", "total", "singleUnitPrice", 'unpaid']
  const availableFilters = {
    userFullName: { type: 'regex' },
    userIsCompany: { type: 'dropdown', values: [{ value: "true", label: "Юридическо лице" }, { value: 'false', label: 'Физическо лице' },], single: true },
    userEmail: { type: 'regex' },
    clientFullName: { type: 'regex' },
    clientIdentifier: { type: 'regex' },
    ...commissionsReferenceFields.payments.filter(({ value }) => value.includes('status')).reduce((acc, { value }) => ({ ...acc, [value]: { type: 'dropdown', values: Object.entries(commissionsStatuses).map(([key, value]) => ({ value: key, label: value })) } }), {}),
    ...commissionsReferenceFields.payments.filter(({ value }) => value.includes('emailSent')).reduce((acc, { value }) => ({ ...acc, [value]: { type: 'date', } }), {}),
    ...commissionsReferenceFields.payments.filter(({ value }) => value.includes('uploadedFileDate')).reduce((acc, { value }) => ({ ...acc, [value]: { type: 'date', } }), {}),
    unpaid: { type: 'number' },
    unpaidWitVat: { type: 'number' }
  }
  const keyMap = {
    "commissionClientMonth.consumption": "consumption",
    "commissionClientMonth.total": "total",
    "commissionClientMonth.singleUnitPrice": "singleUnitPrice"
  }
  const filter = useMemo(() => JSON.parse(filterParam), [filterParam])
  const sort = useMemo(() => JSON.parse(sortParam), [sortParam])
  const searchQuery = useMemo(() => ({ ...mapClientsSearch(sort, filter, undefined, availableFilters) }), [sortParam, filterParam])


  const fetch = useCallback((payload) => {
    dispatch(startLoading())
    if (type === 'generated') dispatch(getCommissionsReference({ payload: { monthYear: startDate ? moment(startDate).format("MM/YYYY") : moment(startDate).subtract(1, 'months').format("MM/YYYY"), ...payload }, }))
    else if (type === 'created') dispatch(getCommissionsCreatedReference({ payload: { ...searchQuery, ...payload } }))
    else if (type === 'payments') dispatch(getCommissionsCreatedPayments({ payload: { from: startDate && moment(startDate), to: endDate && moment(endDate).add(1, 'seconds'), ...searchQuery, ...payload } }))
  }, [type, startDate, endDate, searchQuery])

  const fetchDetails = (row) => {
    if (!row.objects) {
      dispatch(startLoading())
      dispatch(getCommissionsReferenceDetails({ payload: { ids: row.clientCommissions }, client: row._id }))
    }
  }

  useEffect(() => {
    fetch()
    return () => dispatch(setReferencesFields({ initialState }))
  }, [fetch])

  const tableRef = useRef(null)
  const DetailsTable = useCallback(({ row }) => {
    if (type === 'generated') return <Shared.Table
      columns={commissionsReferenceDetailsFields}
      headerWidth={commissionsReferenceDetailsFields.reduce((a, c) => a + (c.size || 300), 0)}
      data={row.original.objects}
      renderCell={renderCell.commissionsReferenceDetails}
      containerStyle={{ maxHeight: 350 }}
    />

    else if (type === 'created') return <Shared.Table
      columns={commissionsReferenceCreatedDetailsFields}
      headerWidth={commissionsReferenceCreatedDetailsFields.reduce((a, c) => a + (c.size || 300), 0)}
      data={row.original.objects}
      renderCell={renderCell.commissionsReferenceCreatedDetails}
      containerStyle={{ maxHeight: 350 }}
    />
  }, [reference])


  const sortingComponents = commissionsReferenceFields[type]?.filter(({ sortable }) => sortable).reduce((acc, { value }) => {
    return {
      ...acc,
      [value]:
        <Popover popover={({ close }) => <Shared.SortingComponent
          hide={close}
          column={value}
          availableSort={availableSort}
          availableFilters={availableFilters}
          keyMap={keyMap}
        />}>
          <div className="icon icon-arrow-down" />
        </Popover>

    }
  }, {})

  return <div className="references-commissions-container">
    <div className="references-commissions-content">
      <Shared.Table
        ref={tableRef}
        columns={commissionsReferenceFields[type]}
        data={reference}
        renderCell={(row, field, options) => renderCell.commissionsReference[type](row, field, {
          ...options,
          toggleRowExpanded: tableRef.current.toggleRowExpanded,
          dispatch,
          fetch,
          fetchDetails
        })}
        headerWidth={commissionsReferenceFields[type]?.reduce((acc, { size = 300 }) => acc + size, 0)}
        maxExpandHeight={550}
        handlePagination={() => hasNextPage && fetch({ page: nextPage })}
        sortingComponent={sortingComponents}
        useCheckboxes={!!showCheckboxes}
        selectedCheckboxes={selectedCheckboxes}
        onCheckboxChange={(value) => dispatch(setReferencesFields({ selectedCheckboxes: value }))}
        disabledCheckboxes={showCheckboxes?.action === 'emails' ? reference?.filter(({ emailSent }) => emailSent)?.map(({ _id }) => _id) : []}
        allowSingle={showCheckboxes?.action === 'deleteCommission'}
      >
        {DetailsTable}
      </Shared.Table>
    </div>
    <div className="references-commissions-footer row">
      <div className="references-commissions-inner-footer row">
        <p className="row">Комисионни: {totalDocs || 0}</p>
        {type === 'payments' && <>
          <p className="row">Дължимо без ДДС: {Number(reference?.[0]?.totalUnpaid ?? 0)?.toFixed(2)} лв.</p>
        </>}
        {showCheckboxes && <p className="row">Избрани: {selectedCheckboxes?.length || 0}</p>}
      </div>
    </div>
  </div>
}

export default Commissions