import React, { useEffect } from 'react'
import { Button } from '@makedonski/admin-ui'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setNoticesField, getNotices } from 'actions'
import { Inputs, Shared } from 'components'
import { useQuery } from 'hooks'
import { noticesFields, noticesTypesButtons } from 'config/constants'
import { renderCell } from 'utilities'
import './styles.scss'

const Notices = ({ }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { notices, nextPage: page } = useSelector((state) => state.notices)

  const { type, handleUrlChangeMultiple, } = useQuery({ type: noticesTypesButtons })


  const fetch = () => {
    dispatch(getNotices({ page, type }))
  }

  useEffect(() => {
    fetch()
    return () => dispatch(setNoticesField({ field: 'notices', value: null }))
  }, [type])

  return (
    <div className="screen-notices-container">
      <div className="screen-notices-header row">
        <Inputs.RoundedButtons
          buttons={noticesTypesButtons}
          value={type}
          onChange={({ value }) => handleUrlChangeMultiple({ 'type': value })}
        />
        <Inputs.SearchMulti
          search={""}
          searchBy={""}
          handleChange={(value) => { }}
          options={[]}
          popupText={{ secondary: ' ' }}
        />

        <div className="row row-buttons">
          <Button.Raised className="btn-other-actions" text="Други действия" />
          <Button.Raised className="btn-generate" text="Експорт" />
        </div>
      </div>
      <div className="screen-notices-content">
        <Shared.Table data={notices} columns={noticesFields} renderCell={renderCell.notices} />
      </div>
    </div>
  )
}

export default Notices
