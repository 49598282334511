import { default as Filter } from './Filter'
import { default as ConsumptionGraph } from './ConsumptionGraph'
import { default as ConsumptionSummary } from './ConsumptionSummary'
import { default as ConsumptionObjects } from './ConsumptionObjects'
import { default as ConsumptionCalculator } from './ConsumptionCalculator'

const Analysis = {
  Filter,
  ConsumptionGraph,
  ConsumptionSummary,
  ConsumptionObjects,
  ConsumptionCalculator,
}

export default Analysis
