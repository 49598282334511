import { setClientsFields } from "actions"
import { notificationsTypesFields } from "config/constants"
import { useQuery } from "hooks"
import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import NotificationsClients from "./NotificationsClients"
import NotificationsGenerated from "./NotificationsGenerated"
import "./styles.scss"

const Notifications = ({ }) => {
    const dispatch = useDispatch()
    const { type } = useQuery({ type: notificationsTypesFields })

    useEffect(() => () => dispatch(setClientsFields({ clients: [], showCheckboxes: false, selectedCheckboxes: [] })), [])


    if (type === 'clients') return <NotificationsClients />
    else if (type === 'notifications') return <NotificationsGenerated />
    else if (type === 'archive') return <NotificationsGenerated />
    else return null
}

export default Notifications