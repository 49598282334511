import React, { useState, useEffect, useCallback } from "react"
import io from "socket.io-client"
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import Filter from './Filter'
import Column from './Column'
import Task from '../Task'
import TaskForm from '../TaskForm'
import { emit, useQuery } from "../helpers"
import "./styles.scss"
import { isEmpty, pick } from "lodash"

const TaskManagement = ({ URL, }) => {
    const { _id, showForm, handleUrlChange } = useQuery()
    const [columns, setColumns] = useState([])
    const [availableFilters, setAvailableFilters] = useState({})
    const [task, setTask] = useState({})

    const [socket, setSocket] = useState(null)
    const socketInit = useCallback(() => {
        const newSocket = io(URL, { query: `token=${localStorage.getItem('token')}`, transports: ['websocket'] })
        if (newSocket) {
            const controller = {
                'tasks/getMany': ({ data, filters }) => {
                    setColumns(data)
                    setAvailableFilters(filters)
                },
                'tasks/getOne': ({ data }) => setTask(data),
                'tasks/create': ({ data }) => {
                    setColumns((columns) => columns.map((c) => c[0]._id !== data.category._id ? c : [c[0], [...c[1], data]]))
                    setTask(data)
                    handleUrlChange({ _id: data._id, showForm: undefined })
                },
                'tasks/updateOne': ({ data }) => {
                    setColumns((columns) => columns.map((c) => c[0]._id !== data.category._id ? c : [c[0], c[1].map((t) => t._id !== data._id ? t : data)]))
                    setTask(data)
                    handleUrlChange({ _id: data._id, showForm: undefined })
                },
                'tasks/archiveOne': () => { },
                'tasks/unarchiveOne': () => { },
                'tasks/reoder': ({ data }) => setColumns((columns) => columns.map((c) => c[0]._id !== data.categoryId ? c : [c[0], data.newOrder.map((_id) => c[1].find((t) => t._id === _id))])),
                'tasks/changeCategory': ({ data }) => setColumns((columns) => columns.map((c) => {
                    if (c[0]._id === data.oldCategoryId) return [c[0], c[1].filter(({ _id }) => _id !== data.task._id)]
                    else if (c[0]._id === data.newCategoryId) return [c[0], data.newOrder.map((_id) => c[1].find((t) => t._id === _id) || data.task)]
                    else return c
                })),
                'tasks/search': () => { },
                'tasks/startWork': () => { },
                'tasks/stopWork': () => { },
                'tasks/toggleStatus': ({ data }) => {
                    setColumns((columns) => columns.map((c) => c[0]._id !== data.category._id ? c : [c[0], c[1].map((t) => t._id !== data._id ? t : data)]))
                    setTask(data)
                },
                'tasks/newActivity': ({ data }) => setTask((task) => ({ ...task, activity: [data, ...(task?.activity || [])] })),

                'categories/getAll': () => { },
                'categories/create': ({ data }) => setColumns((columns) => [...columns, [data, []]]),
                'categories/updateOne': ({ data }) => setColumns((columns) => columns.map(([c, t]) => c._id !== data._id ? [c, t] : [data, t])),
                'categories/deleteOne': ({ data }) => setColumns((columns) => columns.filter(([c]) => c._id !== data._id)),
                'categories/reorder': ({ data }) => setColumns((columns) => data.newOrder.map(_id => columns.find(([c]) => c._id === _id))),

                'comments/create': ({ data }) => setTask((task) => ({ ...task, comments: [data, ...(task?.comments || [])] })),
                'comments/delete': () => { },

                'files/create': ({ data }) => setTask((task) => ({ ...task, files: [data, ...(task?.files || [])] })),
                'files/deleteOne': ({ data }) => setTask((task) => ({ ...task, files: task.files.filter(({ _id }) => _id !== data?._id) })),

                'steps/create': ({ data }) => setTask((task) => ({ ...task, steps: [...(task?.steps || []), data] })),
                'steps/toggle': ({ data }) => setTask((task) => ({ ...task, steps: task?.steps?.map((s) => s._id !== data._id ? s : data) })),
                'steps/deleteOne': ({ data }) => setTask((task) => ({ ...task, steps: task.steps.filter(({ _id }) => _id !== data?._id) })),


                'users/getAll': ({ data }) => setAvailableFilters({ ...availableFilters, assignedTo: data }),
                'tasks/error': ({ message }) => Swal.fire({ title: 'Грешка!', text: message, icon: 'error', confirmButtonText: 'Ок' })

            }

            newSocket.on('tasks', ({ type, payload }) => {
                console.log('Socket in:', { type, payload });
                if (controller[type]) controller[type](payload)
            })
            setSocket(() => newSocket)
        }
    }, [])

    useEffect(() => {
        if (!socket) socketInit()
        return () => { if (socket) socket.disconnect() }
    }, [socket, socketInit])

    useEffect(() => {
        if (!socket) return
        if (_id && _id !== task?._id) {
            if (isEmpty(availableFilters)) emit(socket, { type: 'users/getAll' })
            emit(socket, { type: 'tasks/getOne', payload: { taskId: _id } })
        }
        else if (isEmpty(columns)) emit(socket, { type: 'tasks/getMany' })
    }, [socket, _id])

    const [showNew, setShowNew] = useState(false)
    const [columnName, setColumnName] = useState('')
    const handleCreateColumn = () => {
        emit(socket, { type: 'categories/create', payload: { title: columnName } })
        setColumnName("")
        setShowNew(false)
    }

    const [showFilter, setShowFilter] = useState(false)
    const [filters, setFilters] = useState({})
    useEffect(() => {
        const payload = pick(filters, ['status', 'priority', 'assignedTo', 'dateStart', 'dateEnd'])
        emit(socket, { type: 'tasks/getMany', payload: { filters: payload } })
    }, [filters])

    const reorder = (list, start, end) => {
        const reordered = [...list]
        const [removed] = reordered.splice(start, 1);
        reordered.splice(end, 0, removed);
        return reordered
    }

    const handleColumnsPositioning = ({ type, source, destination, ...r }) => {
        if (!destination) return
        if (source.droppableId === destination.droppableId && source.index === destination.index) return
        if (type === 'COLUMN') {
            const reordered = reorder(columns, source.index, destination.index)
            setColumns(reordered)
            emit(socket, { type: 'categories/reorder', payload: reordered.map(([c]) => c._id) })
            return;
        }

        const [, current] = columns.find(([c]) => c._id === source.droppableId)
        const currentIndex = columns.findIndex(([c]) => c._id === source.droppableId)
        const [, next] = columns.find(([c]) => c._id === destination.droppableId)
        const nextIndex = columns.findIndex(([c]) => c._id === destination.droppableId)
        const target = current[source.index];

        if (source.droppableId === destination.droppableId) { // moving to same list
            const reordered = reorder(current, source.index, destination.index);
            const newColumns = [...columns]
            newColumns.splice(currentIndex, 1, [columns[currentIndex][0], reordered])
            emit(socket, { type: 'tasks/reoder', payload: reordered.map(({ _id }) => _id) })
            setColumns(newColumns)
            return
        } else { //moving to different list
            const newColumns = [...columns]
            const newCurrent = [...current]
            newCurrent.splice(source.index, 1)
            newColumns.splice(currentIndex, 1, [columns[currentIndex][0], newCurrent])
            const newNext = [...next]
            newNext.splice(destination.index, 0, target)
            newColumns.splice(nextIndex, 1, [columns[nextIndex][0], newNext])
            emit(socket, {
                type: "tasks/changeCategory", payload: {
                    oldCategoryId: source.droppableId,
                    newCategoryId: destination.droppableId,
                    taskId: target._id,
                    newOrder: newNext.map(({ _id }) => _id)
                }
            })
            setColumns(newColumns)
        }
    }

    const props = { socket, task, setTask, setShowFilter }

    if (showForm) return <TaskForm {...props} assignees={availableFilters?.assignedTo} />
    else if (_id) return <Task {...props} />
    return <div className="task-management-container row">
        <DragDropContext onDragEnd={handleColumnsPositioning}>
            <Droppable droppableId="board" type="COLUMN" direction="horizontal">
                {(provided) => <div className="row-droppable" ref={provided.innerRef} {...provided.droppableProps}>
                    {columns?.map(([column, tasks], i) => <Column key={column._id} index={i} column={{ ...column, tasks }} {...props} />)}
                    {provided.placeholder}
                    <div className="new-column-container">
                        {showNew ? <div className="new-column-inner-container">
                            <span>Заглавие</span>
                            <input className="input-text-container" value={columnName || ''} onChange={({ target: { value } }) => setColumnName(value)} />
                            <div className="row row-buttons">
                                <div className="raised-button-container cancel" onClick={() => setShowNew(false)} ><span>Откажи</span></div>
                                <div className={`raised-button-container ${!columnName && 'disabled'}`} onClick={handleCreateColumn} ><span>Добави</span></div>
                            </div>
                        </div> : <div className="card btn-add-column" onClick={() => setShowNew(true)}><span>Добави категория</span> </div>}
                    </div>
                </div>}
            </Droppable>
        </DragDropContext>


        <Filter isOpen={showFilter} hide={() => setShowFilter(false)} filters={filters} setFilters={setFilters} assignees={availableFilters?.assignedTo} />
    </div>
}

export default TaskManagement