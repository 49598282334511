export const intermediateInvoicesTypes = {
    GET_INTERMEDIATE_INVOICES: "intermediateInvoices/GET_INTERMEDIATE_INVOICES",
    CREATE_INTERMEDIATE_INVOICES: "intermediateInvoices/CREATE_INTERMEDIATE_INVOICES",
}

export const getIntermediateInvoices = (payload) => ({
    type: intermediateInvoicesTypes.GET_INTERMEDIATE_INVOICES,
    ...payload
})

export const createIntermediateInvoices = (payload) => ({
    type: intermediateInvoicesTypes.CREATE_INTERMEDIATE_INVOICES,
    ...payload
})