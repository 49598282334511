import { updateObject, startLoading } from "actions"
import Inputs from "components/Inputs"
import React from "react"
import { useDispatch } from "react-redux"
import "./styles.scss"

const ObjectIssue = ({ row: { _id, ...row } = {}, field, fetch }) => {
    const dispatch = useDispatch()
    return <div className="row"
        style={{ width: '100%', justifyContent: 'space-between', marginRight: 14 }}
    >
        <Inputs.TextEdit
            type="text"
            value={row?.[field] || ""}
            handleChange={(value) => {
                dispatch(startLoading())
                dispatch(updateObject({
                    data: { _id, [field]: value },
                    onSuccess: () => fetch({ pageOne: true }),
                }))
            }}
        />
        {row?.[field] && <div className="icon icon-delete" onClick={() => {
            dispatch(startLoading())
            dispatch(updateObject({ data: { _id, [field]: null }, onSuccess: () => fetch({ pageOne: true }), }))
        }} />}
    </div>
}

export default ObjectIssue