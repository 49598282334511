import { ofType, ActionsObservable } from 'redux-observable'
import { switchMap, map, catchError } from 'rxjs/operators'
import { Api, Alerts } from '@makedonski/socourt-utilities'
import { userTypes, authTypes, stopLoading } from '../actions'
import { URL } from '../config/settings'

export const getUser = (action$) => action$.pipe(
    ofType(userTypes.GET_USER),
    switchMap(({ payload, onSuccess }) =>
        Api.get(`${URL}/user/${payload}`).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                obs.next(stopLoading())
                if (onSuccess) onSuccess(response?.user)
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                obs.next(stopLoading())
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.complete()
            }))
        )
    ))


export const getUsers = (action$) => action$.pipe(
    ofType(userTypes.GET_USERS),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/user/list`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                obs.next(stopLoading())
                if (onSuccess) onSuccess(response?.users)
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                obs.next(stopLoading())
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.complete()
            }))
        )
    )
)

export const createUser = (action$) => action$.pipe(
    ofType(userTypes.CREATE_USER),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/user`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response?.user)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        )
    )
)


export const updateUser = (action$) => action$.pipe(
    ofType(userTypes.UPDATE_USER),
    switchMap(({ payload, isCurrentUser, onSuccess }) =>
        Api.put(`${URL}/user`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                obs.next(stopLoading())
                if (isCurrentUser) obs.next({ type: authTypes.CHECK_USER })
                if (onSuccess) onSuccess(response?.users)
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                obs.next(stopLoading())
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.complete()
            }))
        )
    )
)


export const getUserRoles = (action$) => action$.pipe(
    ofType(userTypes.GET_USER_ROLES),
    switchMap(({ onSuccess }) =>
        Api.get(`${URL}/roles`).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response?.roles)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                obs.next(stopLoading())
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.complete()
            }))
        )
    )
)


export const getUserFilterPresets = (action$) => action$.pipe(
    ofType(userTypes.GET_USER_FILTER_PRESETS),
    switchMap(({ onSuccess }) =>
        Api.get(`${URL}/filterPreset`).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response?.filterPresets)
                obs.next({ type: userTypes.GET_USER_FILTER_PRESETS_SUCCESS, payload: response?.filterPresets })
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                obs.next(stopLoading())
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.complete()
            }))
        )
    )
)


export const createUserFilterPreset = (action$) => action$.pipe(
    ofType(userTypes.CREATE_USER_FILTER_PRESET),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/filterPreset`, JSON.stringify(payload)).pipe(
            switchMap(() => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess()
                obs.next({ type: userTypes.GET_USER_FILTER_PRESETS })
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                obs.next(stopLoading())
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.complete()
            }))
        )
    )
)


export const applyUserFilterPreset = (action$) => action$.pipe(
    ofType(userTypes.APPLY_USER_FILTER_PRESET),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/filterPreset/apply`, JSON.stringify(payload)).pipe(
            switchMap(() => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess()
                obs.next({ type: userTypes.GET_USER_FILTER_PRESETS })
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                obs.next(stopLoading())
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.complete()
            }))
        )
    )
)

export const deleteUserFilterPreset = (action$) => action$.pipe(
    ofType(userTypes.DELETE_USER_FILTER_PRESET),
    switchMap(({ payload, onSuccess }) =>
        Api.delete(`${URL}/filterPreset`, JSON.stringify(payload)).pipe(
            switchMap(() => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess()
                obs.next({ type: userTypes.GET_USER_FILTER_PRESETS })
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                obs.next(stopLoading())
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.complete()
            }))
        )
    )
)
