import { useEffect, useState } from 'react'
export const usePreviousLocation = (value, didGoBack) => {
  const [current, setCurrent] = useState(value)
  const [prev, setPrev] = useState([null])
  useEffect(() => {
    if (current === value) return
    if (didGoBack) {
      setCurrent(prev[prev.length - 1])
      setPrev((prev) => {
        const newPrev = prev?.slice()
        newPrev.splice(prev.length - 1, 1)
        return newPrev
      })
    } else {
      setPrev((prev) => [...prev, current])
      setCurrent(value)
    }
  }, [value])
  return prev[prev.length - 1]
}
