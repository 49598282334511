import React, { useState, useMemo } from 'react'
import * as Async from 'awaity'
import { Alerts } from '@makedonski/socourt-utilities'
import { Button } from '@makedonski/admin-ui'
import { useDispatch } from 'react-redux'
import { isEmpty } from 'lodash'
import { useHistory } from 'react-router-dom'
import { setModal, startLoading, stopLoading, setDataField, checkImportsExist, setLoadingText } from '../../../actions'
import { Inputs } from '../../'
import { fileParser } from '../../../utilities'
import './styles.scss'

const options = [
  { value: 'confirmations', label: 'Потвърждения' },
  { value: 'comments', label: 'Коментари' },
  { value: 'obligations', label: 'Задължения' },
  { value: 'leaving', label: 'Напускащи' },
]
const erps = ['Златни Пясъци', 'ЕВН', 'Енерго Про', 'ЧЕЗ']

const RegisterImportDocuments = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [data, setData] = useState({ type: options[0].value })
  const handleChange = (field, value) => setData({ ...data, [field]: value })

  const skipConfirmationModal = useMemo(() => !JSON.parse(localStorage.getItem('hideExistingFilesWarning')), [])

  const handleParse = async () => {
    dispatch(startLoading())
    try {
      const files = (
        await Async.reduce(
          [...data?.files],
          async (files, file, i) => {
            dispatch(setLoadingText(`Loading... \n File ${i + 1}/${data?.files?.length}`))
            return [...files, await parseSingleFile(file)]
          },
          []
        )
      ).filter((file) => !isEmpty(file))
      if (!isEmpty(files)) {
        dispatch(setDataField({ field: 'parsed', value: { type: data?.type, erp: data?.erp, files } }))
        history.push(`/file/preview`)
        dispatch(setModal({ isOpen: false }))
      }
    } catch (e) {
      console.log(e)
      Alerts.error('Грешка при обработването на файловете!')
    } finally {
      dispatch(stopLoading())
      dispatch(setLoadingText(`Loading...`))
    }
  }

  const parseSingleFile = async (file) =>
    new Promise((resolve) => {
      fileParser[data?.type](file, data.erp, async (parsedData, rawData) => {
        if (isEmpty(parsedData)) {
          Alerts.error('Грешка!', `Неправилно форматиран файл ${file.name}`)
          return resolve(null)
        } else return resolve({ parsedData, fileName: file.name, rawData })
      })
    })

  return (
    <div className="modal-register-import-documents-container">
      <div className="modal-register-import-documents-header row">
        <h2>Импорт на</h2>
        <Button.Icon
          name="plus"
          size="26px"
          className="btn-close"
          onClick={() => dispatch(setModal({ isOpen: false }))}
        />
      </div>
      <div className="modal-register-import-documents-content">
        <Inputs.RadioButtons buttons={options} value={data?.type} onClick={(value) => handleChange('type', value)} />
        <div className="row">
          <div className="col">
            <span>ЕРП</span>
            <Inputs.Dropdown
              options={erps?.map((erp) => ({ value: erp, label: erp }))}
              value={data?.erp}
              onChange={({ value }) => handleChange('erp', value)}
              autoWidth
            />
          </div>
          <div className={`col col-files ${data?.type}`}>
            <span>Файлове</span>
            <Button.UploadButton
              text={
                <span className="row ripple">
                  Добави файл <div className="icon icon-attach" />
                </span>
              }
              multiple
              accept={'.csv, .xlsx, .xls'}
              onChange={({ target: { files } }) =>
                dispatch(
                  checkImportsExist({
                    payload: { fileName: [...files].map(({ name }) => name) },
                    onSuccess: () => handleChange('files', [...(data?.files || []), ...files]),
                    onFail: (response) =>
                      setData({
                        ...data,
                        files: [...(data?.files || []), ...(files || [])],
                        existingFiles: [...(data?.existingFiles || []), ...(response?.files || [])],
                      }),
                  })
                )
              }
            />
          </div>
        </div>
        <div className="col col-files">
          {!isEmpty(data?.files) &&
            [...data?.files]?.map((file, i) => (
              <div className={` row-file ${data?.existingFiles?.includes(file?.name) && 'exist'}`} key={`file-${i}`}>
                <div className="row">
                  <Button.Icon
                    name="plus"
                    color="red"
                    onClick={() => {
                      const newFiles = [...(data?.files || [])]
                      newFiles.splice(i, 1)
                      handleChange('files', newFiles)
                    }}
                  />
                  <span>{file?.name}</span>
                </div>
                {data?.existingFiles?.includes(file?.name) && <span>Фигурира в системата!</span>}
              </div>
            ))}
        </div>
      </div>
      <div className="modal-register-import-documents-footer">
        <Button.Raised
          text="Импортирай"
          disabled={isEmpty(data?.files) || !data.erp}
          onClick={() => {
            if (!isEmpty(data?.existingFiles) && skipConfirmationModal)
              dispatch(setModal({ isOpen: true, type: 'existingFileWarning', props: { handleParse } }))
            else handleParse()
          }}
        />
      </div>
    </div>
  )
}

export default RegisterImportDocuments
