export const referencesTypes = {
  SET_FIELD: 'reference/SET_FIELD',
  SET_FIELDS: 'reference/SET_FIELDS',

  GET_REFERENCE_INVOICES: 'references/GET_REFERENCE_INVOICES',
  GET_REFERENCE_INVOICES_DIFFERENCES: 'references/GET_REFERENCE_INVOICES_DIFFERENCES',
  GET_REFERENCE_INVOICES_PROGRESS_MISSING: "references/GET_REFERENCE_INVOICES_PROGRESS_MISSING",
  GET_REFERENCE_IMPORTS: 'references/GET_REFERENCE_IMPORTS',
  GET_REFERENCE_CLIENTS_GROUPS: 'references/GET_REFERENCE_CLIENTS_GROUPS',
  GET_REFERENCE_MARKET: 'references/GET_REFERENCE_MARKET',
  GET_REFERENCE_MARKET_AVERAGE: 'references/GET_REFERENCE_MARKET_AVERAGE',
  GET_REFERENCE_NOMENCLATURES: 'references/GET_REFERENCE_NOMENCLATURES',

  GET_REFERENCE_SUCCESS: 'references/GET_REFERENCE_SUCCESS',

  GET_REFERENCE_INVOICES_PREGENERATION: 'references/GET_REFERENCE_INVOICES_PREGENERATION',

  GET_REFERENCE_ERP_MEASURES: 'reference/GET_REFERENCE_ERP_MEASURES',
  GET_REFERENCE_ERP_MEASURES_FILES: 'reference/GET_REFERENCE_ERP_MEASURES_FILES',

  GET_REFERENCE_INTERMEDIATE_INVOICES_PROGRESS: 'reference/GET_REFERENCE_INTERMEDIATE_INVOICES_PROGRESS',
  GET_REFERENCE_INTERMEDIATE_INVOICES_GENERATED: 'reference/GET_REFERENCE_INTERMEDIATE_INVOICES_GENERATED',

  GET_REFERENCE_OBJECTS_IGNORED: 'reference/GET_REFERENCE_OBJECTS_IGNORED',

  GET_REFERENCE_READY_PARTIAL_SUCCESS: 'reference/GET_REFERENCE_READY_PARTIAL_SUCCESS',
}

export const setReferencesField = (payload) => ({
  type: referencesTypes.SET_FIELD,
  payload,
})

export const setReferencesFields = (payload) => ({
  type: referencesTypes.SET_FIELDS,
  payload,
})

export const getReferenceInvoices = (payload) => ({
  type: referencesTypes.GET_REFERENCE_INVOICES,
  ...payload,
})

export const getReferenceImports = (payload) => ({
  type: referencesTypes.GET_REFERENCE_IMPORTS,
  payload,
})

export const getReferenceClientsGroups = (payload) => ({
  type: referencesTypes.GET_REFERENCE_CLIENTS_GROUPS,
  ...payload,
})

export const getReferenceMarket = (payload) => ({
  type: referencesTypes.GET_REFERENCE_MARKET,
  payload,
})

export const getReferenceMarketAverage = (payload) => ({
  type: referencesTypes.GET_REFERENCE_MARKET_AVERAGE,
  ...payload,
})

export const getReferenceNomenclatures = (payload) => ({
  type: referencesTypes.GET_REFERENCE_NOMENCLATURES,
  ...payload,
})

export const getReferenceInvoicesDifferences = (payload) => ({
  type: referencesTypes.GET_REFERENCE_INVOICES_DIFFERENCES,
  ...payload,
})


export const getReferenceInvoicesProgressMissing = (payload) => ({
  type: referencesTypes.GET_REFERENCE_INVOICES_PROGRESS_MISSING,
  ...payload,
})

export const getReferenceInvoicesPregeneration = (payload) => ({
  type: referencesTypes.GET_REFERENCE_INVOICES_PREGENERATION,
  ...payload,
})

export const getReferenceErpMeasures = (payload) => ({
  type: referencesTypes.GET_REFERENCE_ERP_MEASURES,
  ...payload,
})

export const getReferenceErpMeasuresFiles = (payload) => ({
  type: referencesTypes.GET_REFERENCE_ERP_MEASURES_FILES,
  ...payload,
})

export const getReferenceIntermediateInvoicesProgress = (payload) => ({
  type: referencesTypes.GET_REFERENCE_INTERMEDIATE_INVOICES_PROGRESS,
  ...payload,
})

export const getReferenceIntermediateInvoicesGenerated = (payload) => ({
  type: referencesTypes.GET_REFERENCE_INTERMEDIATE_INVOICES_GENERATED,
  ...payload,
})

export const getReferenceObjectsIgnored = (payload) => ({
  type: referencesTypes.GET_REFERENCE_OBJECTS_IGNORED,
  ...payload,
})