import React, { useState, useMemo } from 'react'
import { Button, Input } from '@makedonski/admin-ui'
import { useSelector } from 'react-redux'
import { isEmpty, omit } from 'lodash'
import { Inputs } from 'components'
import { useQuery } from 'hooks'
import './styles.scss'
import moment from 'moment'
import { intermediateInvoicesTypes } from 'config/constants'

const sortButtons = [{ label: 'Възходящи', value: '1' }, { label: 'Низходящи', value: '-1' }]


const SortingComponent = ({ column, type, hide }) => {
  const keyMap = {
    "erp": 'erps',
    "total.consumptionTotal": 'consumptionTotal',
    "total.consumptionTotalAmount": 'consumptionTotalAmount',
    "pricing.deposits": 'deposit',
    "pricing.base": type === 'notEqualizing' ? 'base' : 'totalWithoutVat',
    "results.energyPrice": 'energyPrice',
    "results.energy": 'energy',
    "results.total": 'total',
    "contractSettings.deliveryStart": 'deliveryStart',
    "contractSettings.deliveryEnd": 'deliveryEnd',
  }

  const key = useMemo(() => Object.entries(keyMap).find((arr) => arr.includes(column))?.at(0) || column, [column])
  const { sort: sortParam = '{}', filter: filterParam = '{}', handleUrlChange } = useQuery()
  const { availableSort, availableFilters } = useSelector(({ invoices }) => invoices)
  const [sort, setSort] = useState(JSON.parse(sortParam)[key] || {})
  const [filter, setFilter] = useState(JSON.parse(filterParam) || {})
  const [query, setQuery] = useState('')

  const allowFilter = useMemo(() => Object.keys(availableFilters)?.map((key) => keyMap[key] || key)?.includes(column), [availableFilters])
  const allowSort = useMemo(() => availableSort?.map((key) => keyMap[key] || key)?.includes(column), [availableSort])

  const handleApply = () => {
    if (!isEmpty(filter)) handleUrlChange('filter', JSON.stringify(filter))
    else handleUrlChange('filter', null)
    if (!isEmpty(sort)) handleUrlChange('sort', JSON.stringify({ [key]: sort }))
    else if (isEmpty(omit(JSON.parse(sortParam), key))) handleUrlChange('sort', null)
  }

  const renderFilter = () => {
    const { min, max } = availableFilters[key]
    switch (availableFilters[key].type) {
      case 'regex':
        return (
          <Input.Text
            placeholder="Search:"
            value={filter[key] || ''}
            onChange={({ target: { value } }) => setFilter({ ...filter, [key]: value })}
          />
        )
      case 'date':
        return (
          <Input.DatepickerRange
            selectionRange={filter[key]?.map(({ startDate, endDate, key } = {}) => ({ startDate: moment(startDate).toDate(), endDate: moment(endDate).toDate(), key }))}
            onChange={({ selection }) => setFilter({ ...filter, [key]: [selection] })}
          />
        )
      case 'dropdown':
        return (
          <>
            <Input.Text value={query} onChange={({ target: { value } }) => setQuery(value)} />
            <Inputs.Checkboxes
              buttons={availableFilters[key]?.values
                ?.filter((option) => !query || option?.includes(query))
                ?.map(k => key === "type" ? intermediateInvoicesTypes.find(({ value }) => value === k) : k)}
              value={filter[key]}
              onClick={(value) => isEmpty(value) ? setFilter(omit(filter, key)) : setFilter({ ...filter, [key]: value })}
              col
            />
          </>
        )
      case 'idDropdown':
        return (
          <>
            <Input.Text value={query} onChange={({ target: { value } }) => setQuery(value)} />
            <Inputs.Checkboxes
              buttons={availableFilters[key]?.values
                ?.map(({ _id, name }) => ({ value: _id, label: name }))
                ?.filter(({ label }) => !query || label?.includes(query))}
              value={filter[key]}
              onClick={(value) => isEmpty(value) ? setFilter(omit(filter, key)) : setFilter({ ...filter, [key]: value })}
              col
            />
          </>
        )
      case 'boolean':
        return (
          <Inputs.RadioButtons
            buttons={['Да', 'Не']}
            value={filter[key] === false ? 'Не' : filter[key] === true ? 'Да' : null}
            onClick={(value) => setFilter({ ...filter, [key]: value === 'Да' })}
          />
        )
      case 'number':
      case 'priceRange':
        if (min > max) return null
        return <div className="row">
          <div className="col">
            <span>От:</span>
            <Input.Text
              placeholder={min}
              value={filter?.[key]?.min ?? ''}
              onChange={({ target: { value } }) => setFilter({ ...filter, [key]: { ...(filter?.[key]), min: value === '' ? undefined : value } })}
            />
          </div>
          <div style={{ width: 15 }} />
          <div className="col">
            <span>До:</span>
            <Input.Text
              placeholder={max}
              value={filter?.[key]?.max ?? ''}
              onChange={({ target: { value } }) => setFilter({ ...filter, [key]: { ...(filter?.[key]), max: value === '' ? undefined : value } })}
            />
          </div>
        </div>
      default:
        return null
    }
  }




  return <div className="popup-invoices-table-filters-container">
    {allowSort && (
      <div className="sort">
        <h3>Пореди по</h3>
        <Inputs.RadioButtons col buttons={sortButtons} onClick={(data) => setSort(data)} value={sort} />
      </div>
    )}
    {allowFilter && (
      <div className="filter">
        <h3>Филтрирай по</h3>
        {renderFilter()}
      </div>
    )}
    {(allowSort || allowFilter) && (
      <div className="buttons row">
        <p
          className="btn-clear"
          onClick={() => {
            setFilter(omit(filter, key))
            setSort('')
            setQuery('')
          }}
        >
          Изчисти филтри
        </p>
        <Button.Raised
          text="Задай"
          onClick={() => {
            handleApply()
            hide()
          }}
        />
      </div>
    )}
  </div>

  // const [query, setQuery] = useState(data)
  // const handleChange = (payload) => setQuery({ ...query, ...payload })


  // const { erps } = useSelector(({ data }) => data)
  // const dispatch = useDispatch()
  // useEffect(() => {
  //   if (value === 'erp' && !erps) dispatch(getData('erps'))
  // }, [])


  // const renderSorting = () => {
  //   switch (value) {
  //     case 'invoiceGroup':
  //     case 'consumptionTotal':
  //     case 'consumptionTotalAmount':
  //     case 'deposit':
  //     case 'totalWithoutVat':
  //     case 'invoiceNumber':
  //     case 'invoiceDate':
  //     case 'consecutiveNumber':
  //       return <>
  //         <h3>Пореди по</h3>
  //         <Inputs.RadioButtons
  //           col
  //           buttons={[
  //             { label: 'Възходящи', value: '1' },
  //             { label: 'Низходящи', value: '-1' },
  //           ]}
  //           value={query.sort?.[value]}
  //           onClick={(data) => handleChange({ sort: { [value]: data } })}
  //         />
  //       </>
  //     default:
  //       return null;
  //   }
  // }

  // const renderFilters = (close) => {
  //   switch (value) {
  //     case 'invoiceSent':
  //     case 'pdfFile':
  //       return <Inputs.RadioButtons
  //         col
  //         buttons={['Всички', 'Да', 'Не']}
  //         value={query[value] || 'Всички'}
  //         onClick={(v) => (v === 'Всички' ? setQuery(omit(query, value)) : handleChange({ [value]: v }))}
  //       />
  //     case 'erp':
  //       return <Inputs.Checkboxes
  //         col
  //         buttons={[
  //           { label: 'Всички', value: 'all' },
  //           ...(erps || [])?.map(({ name, _id }) => ({ label: name, value: _id })),
  //         ]}
  //         value={query[value] || ['all']}
  //         onClick={(v) => {
  //           v === ['all'] ? setQuery(omit(query, value)) : handleChange({ [value]: v.filter((a) => a !== 'all') })
  //         }}
  //       />
  //     case 'objects':
  //     case 'invoiceNumber':
  //     case 'consecutiveNumber':
  //       return <Input.Text
  //         placeholder='Търси:'
  //         value={query[value] || ""}
  //         onChange={({ target: { value: v } }) => handleChange({ [value]: v || undefined })}
  //       />
  //     case 'invoiceDate':
  //       return <Input.DatepickerRange
  //         selectionRange={query?.[value] || [{ key: 'selection' }]}
  //         onChange={({ selection: { startDate, endDate, key } }) => handleChange({
  //           [value]: [{
  //             startDate: moment(startDate).startOf('day').toDate(),
  //             endDate: moment(endDate).endOf('day').toDate(),
  //             key
  //           }]
  //         })}
  //       />


  //     default:
  //       return null
  //   }
  // }
  // return (
  //   <div className="popup-invoices-table-filters-container">
  //     <div className="sort">
  //       {renderSorting()}
  //     </div>
  //     {renderFilters()}
  //     <Button.Raised text="Задай" onClick={() => setData(query)} />
  //     <Button.Raised text="Изчисти филтри" className="cancel" onClick={() => setData(omit(query, value))} />

  //   </div>
  // )
}

export default SortingComponent
