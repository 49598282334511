import { Button } from '@makedonski/admin-ui'
import { Inputs } from "components"
import { producersReferencesOptions } from "config/constants"
import moment from "moment"
import React, { useState } from "react"
import { Portal } from "react-overlays"
import { useDispatch } from "react-redux"
import "./styles.scss"
const ProducersReferencesGenerate = ({ close, title, data: dataProp, onClick = () => { } }) => {

  const dispatch = useDispatch()
  const [data, setData] = useState({ type: producersReferencesOptions[0].value, ...dataProp, })

  return <div className="modal-producers-references-generate-container">
    <div className="modal-producers-references-generate-header">
      <h2>{title}</h2>
      <div className="icon icon-close" onClick={close} />
    </div>
    <div className="modal-producers-references-generate-content row">
      <div className="col">
        <span>Тип</span>
        <Inputs.Dropdown
          options={producersReferencesOptions.filter(({ value }) => process.env.REACT_APP_PLATFORM !== 'Synergon' || !['producerAdditionalPeriodData'].includes(value))}
          value={data.type}
          onChange={({ value }) => setData({ ...data, type: value })}
        />
      </div>
      <div style={{ width: 15 }} />
      <div className="col">
        <span>Месец</span>
        <Inputs.DatePicker
          value={moment(data.monthYear, 'MM/YYYY')}
          onChange={(value) => setData({ ...data, monthYear: moment(value).format('MM/YYYY') })}
          dateFormat={'MMMM yyyy'}
          showMonthYearPicker
          showFullMonthYearPicker
          className="month-picker"
          popperContainer={({ children }) => (
            <Portal container={document.getElementById('calendar-portal')}>{children}</Portal>
          )}
        />
      </div>
    </div>
    <div className="modal-producers-references-generate-footer row">
      <Button.Raised
        className='cancel'
        text="Откажи"
        onClick={() => dispatch(setModal({ isOpen: false }))}
      />
      <div style={{ width: 15 }} />
      <Button.Raised
        text="Продължи"
        onClick={() => { onClick(data); close() }}
      />
    </div>
  </div>
}

export default ProducersReferencesGenerate