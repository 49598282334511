import React from 'react'
import { Auth } from '@makedonski/socourt-utilities'
import { withRouter, Route, Switch, Redirect, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  Login,
  Home,
  Clients,
  Tasks,
  ClientCreate,
  Client,
  ObjectsCreate,
  ConsumptionAnalysis,
  References,
  Employee,
  Invoices,
  InvoicesCreate,
  FilePreview,
  Notices,
  ObjectsTransfer,
  AnnexCreate,
  Resources,
  IntermediateInvoicesGenerate,
  IntermediateInvoicesCreate,
  Interests,
  DepositsCreate,
  Support,
  Deposits,
  Notifications,
  Commissions,
  Tutorials,
  ReferencesProducers,
  ProducersReferencesGenerate,
  InterestsForm,
  Mailer,
  ProducersInvoiceForm,
  CommissionsUpload
} from '../../screens'
import { Header, Modal, Overlay, Shared } from '..'
import { useSupportSocket } from '@fidweb/customer-support'
import { SUPPORT_URL } from 'config/settings'
import { updateUser } from 'actions'

const PrivateRoute = ({ ...rest }) => {
  const roles = useSelector(({ general }) => general)?.currentUser?.roles?.map(({ name }) => name) || []
  return (
    <Route
      render={({ location }) => {
        const isRestricted = process.env.REACT_APP_PLATFORM === 'Energy Operations' && location.pathname.startsWith("/clients") && roles.includes('restrictedClients')
        return Auth.isAuthenticated ? isRestricted ? <Redirect to={{ pathname: '/financial', state: { from: location } }} /> : <Route {...rest} /> : <Redirect to={{ pathname: '/login', state: { from: location } }} />
      }}
    />
  )
}

const Routes = () => {
  const dispatch = useDispatch()
  const { loading, loadingText, currentUser } = useSelector((state) => state.general)

  const platformMap = {
    "Synergon": "synergon",
    "Energy Operations": "energy-op",
    "RETC": "energy-retc",
    "Zagora Energy": "energy-zagora",
    "Proakt": "energy-proakt",
    "Energama": "energy-energama",
    "W&S": "energy-ws",
  }

  const socket = useSupportSocket({
    URL: SUPPORT_URL,
    platform: platformMap[process.env.REACT_APP_PLATFORM],
    currentUser: currentUser,
    handleNewUser: (payload) => dispatch(updateUser({ payload, isCurrentUser: true }))
  })

  const { pathname } = useLocation()

  return (
    <>
      {Auth.isAuthenticated && !['/commissions-upload'].includes(pathname) && <Header socket={socket} />}
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/commissions-upload" component={CommissionsUpload} />
        {/* <PrivateRoute exact path="/" component={Home} /> */}
        <Redirect exact path="/" to="/clients" />
        <PrivateRoute exact path="/clients" component={Clients} />
        <PrivateRoute exact path="/clients/create" component={ClientCreate} />
        <PrivateRoute exact path="/clients/transfer/confirm-documents" component={ObjectsTransfer} />
        <PrivateRoute exact path="/clients/:id/create_objects" component={ObjectsCreate} />
        <PrivateRoute exact path="/clients/:id" component={Client} />
        <PrivateRoute exact path="/annex/create" component={AnnexCreate} />
        <PrivateRoute exact path="/resign/create" component={AnnexCreate} />
        <PrivateRoute exact path="/deposits/create" component={DepositsCreate} />
        <PrivateRoute exact path="/tasks" component={Tasks} />
        <PrivateRoute exact path="/consumption_analysis" component={ConsumptionAnalysis} />
        <PrivateRoute exact path="/references" component={References} />
        <PrivateRoute exact path="/references/:type" component={References} />
        <PrivateRoute exact path="/references-producers" component={ReferencesProducers} />
        <PrivateRoute exact path="/references-producers/references-generate" component={ProducersReferencesGenerate} />
        <PrivateRoute exact path="/producers/invoices/form" component={ProducersInvoiceForm} />
        <PrivateRoute exact path="/employee/:id" component={Employee} />
        <Redirect exact path="/financial" to="/financial/invoices" />
        <PrivateRoute exact path="/financial/invoices" component={Invoices} />
        <PrivateRoute exact path="/financial/invoices/create" component={InvoicesCreate} />
        <PrivateRoute exact path="/financial/invoices-intermediate/create" component={IntermediateInvoicesCreate} />
        <PrivateRoute exact path="/financial/notices" component={Notices} />
        <PrivateRoute exact path="/financial/interests" component={Interests} />
        <PrivateRoute exact path="/financial/interests/form" component={InterestsForm} />
        <PrivateRoute exact path="/file/preview" component={FilePreview} />
        <PrivateRoute exact path="/intermediate-invoices/generate" component={IntermediateInvoicesGenerate} />
        <PrivateRoute exact path="/resources" component={Resources} />
        <PrivateRoute exact path="/support" ><Support socket={socket} /></PrivateRoute>
        <PrivateRoute exact path="/financial/deposits" component={Deposits} />
        <PrivateRoute exact path="/financial/notifications" component={Notifications} />
        <PrivateRoute exact path="/financial/commissions" component={Commissions} />
        <PrivateRoute exact path="/tutorials" component={Tutorials} />
        <PrivateRoute exact path="/mailer" component={Mailer} />
      </Switch>
      <Shared.Loader loading={loading} text={loadingText} />
      <Modal />
      <Overlay />
    </>
  )
}
export default withRouter(Routes)
