import { ofType, ActionsObservable } from 'redux-observable'
import { switchMap, catchError, debounceTime } from 'rxjs/operators'
import { Api, Alerts } from '@makedonski/socourt-utilities'
import { groupBy, isEmpty } from 'lodash'
import { referencesTypes, stopLoading } from 'actions'
import { URL } from 'config/settings'
import { referenceInvoicesFields } from 'config/constants'
import { xlsxExport } from "utilities"
import moment from 'moment'

export const getReferenceInvoices = (action$) =>
  action$.pipe(
    ofType(referencesTypes.GET_REFERENCE_INVOICES),
    debounceTime(300),
    switchMap(({ payload: { type, page = 1, limit = 20, ...payload }, onSuccess }) =>
      Api.post(`${URL}/invoices/${type}`, JSON.stringify({ limit, page, ...payload })).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {

            let data
            if (type === 'generated') data = { docs: Object.keys(response.parsed)?.reduce((acc, key) => [...acc, { period: key, ...response.parsed[key] }], []) }
            else if (type === 'not-generated') {
              if (page === 1) obs.next({ type: referencesTypes.GET_REFERENCE_INVOICES_PROGRESS_MISSING, payload })
              data = { ...response.clients, lastErrorUpdate: response.lastErrorUpdate }
            }
            else if (type === 'progress' && !isEmpty(response.results)) data = {
              docs: [
                ...Object.keys(response.results)?.reduce((acc, key) => [...acc, { erp: key, ...response.results[key] }], []),
                {
                  erp: 'ТОТАЛ',
                  allClients: response.allClients,
                  allObjects: response.allObjects,
                  clients: response.invoicedClients,
                  objects: response.invoicedObjects
                }],
            }
            else if (type === 'ready') data = {
              docs: response.clients,
              shouldPreGenerate: [...new Set(response.shouldPreGenerate?.map(({ client }) => client))],
              errors: response.errors,
              failedClients: response.failedClients
            }
            else data = response.invoices



            if (type === 'not-generated' && payload.forExport) xlsxExport({
              fields: referenceInvoicesFields['not-generated-export'],
              data: response.data,
              render: (row, value) => value === 'eic' ? row['ЕИК'] || row['ЕГН'] || '' : row[value] || '',
            })
            else if (type === 'ready' && payload.clientsToCheck) obs.next({ type: referencesTypes.GET_REFERENCE_READY_PARTIAL_SUCCESS, payload: response })
            else obs.next({ type: referencesTypes.GET_REFERENCE_SUCCESS, payload: data })

            if (onSuccess) onSuccess(response)
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            console.log(err);
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const getReferenceImports = (action$) =>
  action$.pipe(
    ofType(referencesTypes.GET_REFERENCE_IMPORTS),
    debounceTime(100),
    switchMap(({ payload: { type, page = 1, limit = 20, ...payload } }) =>
      Api.post(`${URL}/imported-files/search`, JSON.stringify({ limit, page, ...payload })).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({ type: referencesTypes.GET_REFERENCE_SUCCESS, payload: { docs: response.importedFiles } })
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const getReferenceClientsGroups = (action$) => action$.pipe(
  ofType(referencesTypes.GET_REFERENCE_CLIENTS_GROUPS),
  debounceTime(100),
  switchMap(({ payload, onSuccess }) =>
    Api.post(`${URL}/groups/browse`, JSON.stringify(payload)).pipe(
      switchMap(({ response }) => ActionsObservable.create((obs) => {
        obs.next({ type: referencesTypes.GET_REFERENCE_SUCCESS, payload: response.payload })
        if (onSuccess) onSuccess(response.payload)
        obs.next(stopLoading())
        obs.complete()
      })),
      catchError((err) => ActionsObservable.create((obs) => {
        Alerts.error('Error!', err?.response?.error?.message || '')
        obs.next(stopLoading())
        obs.complete()
      }))
    )
  )
)

export const getReferenceMarket = (action$) =>
  action$.pipe(
    ofType(referencesTypes.GET_REFERENCE_MARKET),
    debounceTime(100),
    switchMap(({ payload }) =>
      Api.post(`${URL}/stock/list`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            console.log(response)
            obs.next({
              type: referencesTypes.GET_REFERENCE_SUCCESS,
              payload: { docs: response.stockAvgPrice?.sort((a, b) => new Date(a.date) - new Date(b.date)) },
            })
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const getReferenceMarketAverage = (action$) =>
  action$.pipe(
    ofType(referencesTypes.GET_REFERENCE_MARKET_AVERAGE),
    debounceTime(100),
    switchMap(({ payload, onSuccess }) =>
      Api.post(`${URL}/stock/avg`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response)
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const getReferenceNomenclatures = (action$) =>
  action$.pipe(
    ofType(referencesTypes.GET_REFERENCE_NOMENCLATURES),
    debounceTime(100),
    switchMap(({ onSuccess }) =>
      Api.get(`${URL}/nomenclatures`).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response.nomenclatures)
            obs.next({
              type: referencesTypes.GET_REFERENCE_SUCCESS,
              payload: { docs: response.nomenclatures?.details },
            })
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err?.response?.error?.code !== 'EDM001') Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const getReferenceInvoicesDifferences = (action$) =>
  action$.pipe(
    ofType(referencesTypes.GET_REFERENCE_INVOICES_DIFFERENCES),
    debounceTime(100),
    switchMap(({ payload, onSuccess }) =>
      Api.post(`${URL}/progress`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({ type: referencesTypes.GET_REFERENCE_SUCCESS, payload: { docs: response.progress } })
            if (onSuccess) onSuccess(response?.progress || {})
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const getReferenceInvoicesPregeneration = (action$) =>
  action$.pipe(
    ofType(referencesTypes.GET_REFERENCE_INVOICES_PREGENERATION),
    debounceTime(100),
    switchMap(({ payload, onSuccess }) =>
      Api.post(`${URL}/invoices/list-pregeneration`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response)
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const getReferenceInvoicesProgressMissing = (action$) =>
  action$.pipe(
    ofType(referencesTypes.GET_REFERENCE_INVOICES_PROGRESS_MISSING),
    debounceTime(300),
    switchMap(({ payload, onSuccess }) =>
      Api.post(`${URL}/progress/missing`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({ type: referencesTypes.SET_FIELDS, payload: { errors: response?.missing } })
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const getReferenceErpMeasures = (action$) =>
  action$.pipe(
    ofType(referencesTypes.GET_REFERENCE_ERP_MEASURES),
    debounceTime(300),
    switchMap(({ payload, onSuccess }) =>
      Api.post(`${URL}/imported-files/autoPeriod`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => ActionsObservable.create((obs) => {
          if (onSuccess) onSuccess(response.payload)
          // obs.next(stopLoading())
          obs.complete()
          // if (payload.forExport) Object.entries(response?.payload || {}).forEach(([month, data]) => {
          //   const textFields = ['ИТН', 'Файлове', 'В базата', 'Профил', 'Консумация', 'Записи', 'Последен импорт']
          //   xlsxExport({
          //     fields: Object.keys(data[0]).map(value => ({ value, label: !textFields.includes(value) ? moment(value).format('DD.MM.YYYY | HH:mm') : value })),
          //     data: data,
          //     render: (row, value) => value === 'Последен импорт' ? moment(row[value]).format('DD.MM.YYYY | HH:mm') : row?.[value]?.toString() || '',
          //     fileName: `export-${month}`
          //   })
          // })
        })),
        catchError((err) => ActionsObservable.create((obs) => {
          console.log(err);
          Alerts.error('Error!', err?.response?.error?.message || '')
          obs.next(stopLoading())
          obs.complete()
        }))
      )
    )
  )


export const getReferenceErpMeasuresFiles = (action$) =>
  action$.pipe(
    ofType(referencesTypes.GET_REFERENCE_ERP_MEASURES_FILES),
    debounceTime(300),
    switchMap(({ payload, onSuccess }) =>
      Api.post(`${URL}/imported-files/autoPeriodFiles`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => ActionsObservable.create((obs) => {
          if (onSuccess) onSuccess(response.autoData)
          obs.complete()
        })),
        catchError((err) => ActionsObservable.create((obs) => {
          console.log(err);
          Alerts.error('Error!', err?.response?.error?.message || '')
          obs.next(stopLoading())
          obs.complete()
        }))
      )
    )
  )


export const getReferenceIntermediateInvoicesGenerated = (action$) => action$.pipe(
  ofType(referencesTypes.GET_REFERENCE_INTERMEDIATE_INVOICES_GENERATED),
  debounceTime(300),
  switchMap(({ payload, onSuccess }) =>
    Api.post(`${URL}/invoices/generated`, JSON.stringify(payload)).pipe(
      switchMap(({ response }) => ActionsObservable.create((obs) => {
        if (onSuccess) onSuccess(response.autoData)
        obs.next({ type: referencesTypes.GET_REFERENCE_SUCCESS, payload: { docs: Object.keys(response.parsed)?.reduce((acc, key) => [...acc, { period: key, ...response.parsed[key] }], []) } })
        obs.next(stopLoading())
        obs.complete()
      })),
      catchError((err) => ActionsObservable.create((obs) => {
        console.log(err);
        Alerts.error('Error!', err?.response?.error?.message || '')
        obs.next(stopLoading())
        obs.complete()
      }))
    )
  )
)

export const getReferenceIntermediateInvoicesProgress = (action$) => action$.pipe(
  ofType(referencesTypes.GET_REFERENCE_INTERMEDIATE_INVOICES_PROGRESS),
  debounceTime(300),
  switchMap(({ payload, onSuccess }) =>
    Api.post(`${URL}/invoices/progressAdvanceIntermediate`, JSON.stringify(payload)).pipe(
      switchMap(({ response }) => ActionsObservable.create((obs) => {
        if (onSuccess) onSuccess(response.autoData)
        obs.next({
          type: referencesTypes.GET_REFERENCE_SUCCESS, payload: {
            docs: Object.entries(groupBy(response.payload, ({ type, days }) => type + "-" + days)).reduce((acc, [key, value]) => [...acc, {
              type: key, ...value.reduce((acc2, cur2) => ({ ...acc2, [`consecutiveNumber_${cur2.consecutiveNumber}`]: `${cur2.invoicedClients}/${cur2.totalClients}` }), {})
            }], [])
          }
        })
        obs.next(stopLoading())
        obs.complete()
      })),
      catchError((err) => ActionsObservable.create((obs) => {
        console.log(err);
        Alerts.error('Error!', err?.response?.error?.message || '')
        obs.next(stopLoading())
        obs.complete()
      }))
    )
  )
)

export const getReferenceObjectsIgnored = (action$) => action$.pipe(
  ofType(referencesTypes.GET_REFERENCE_OBJECTS_IGNORED),
  switchMap(({ payload, onSuccess }) =>
    Api.post(`${URL}/clients/objects/ignored`, JSON.stringify(payload)).pipe(
      switchMap(({ response }) => ActionsObservable.create((obs) => {
        if (onSuccess) onSuccess(response.objects)
        obs.next({ type: referencesTypes.GET_REFERENCE_SUCCESS, payload: response.objects })
        obs.next(stopLoading())
        obs.complete()
      })),
      catchError((err) => ActionsObservable.create((obs) => {
        console.log(err);
        Alerts.error('Error!', err?.response?.error?.message || '')
        obs.next(stopLoading())
        obs.complete()
      }))
    )
  )
)