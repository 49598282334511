import React from 'react'
import moment from 'moment'
import { Shared } from 'components'
import { useQuery } from 'hooks'
import './styles.scss'

const Products = ({ reference }) => {
  const { period } = useQuery()
  if (period === 'day')
    return (
      <div className="references-market-tables-products-container card">
        <Shared.CollapsableRow header="Блокови продукти" open>
          <div className="card-table row">
            <div className="fields">
              <p className="placeholder">{'.'}</p>
              {reference.map(({ date }, i) => (
                <div key={`field-${i}`} className="row">
                  <p>{moment(date).format('DD.MM')}</p>
                </div>
              ))}
            </div>
            <div className="data">
              <Shared.Table
                fixedWidth
                rowHeight={62}
                columns={[
                  { label: 'База', value: 'base', size: 150 },
                  { label: 'Пик', value: 'peak', size: 150 },
                  { label: 'Офпик', value: 'offPeak', size: 150 },
                ]}
                data={reference}
                renderCell={(row, column) => row[column]}
              />
            </div>
          </div>
        </Shared.CollapsableRow>
      </div>
    )

  return (
    <div className="references-market-tables-products-container card">
      <Shared.CollapsableRow header="Блокови продукти" open>
        <div className="card-table row">
          <div className="fields">
            <p className="placeholder">{'.'}</p>
            {['База', 'Пик', 'Офпик'].map((field) => (
              <div key={`field-${field}`} className="row">
                <p>{field}</p>
              </div>
            ))}
          </div>
          <div className="data">
            <Shared.Table
              fixedWidth={period !== 'month'}
              rowHeight={62}
              columns={reference?.map(({ date }, i) => ({
                size: 150,
                value: (i + 1).toString(),
                label: moment(date).format('DD.MM'),
              }))}
              data={reference?.reduce(
                (acc, cur, i) => [
                  { ...acc[0], [i + 1]: cur.base },
                  { ...acc[1], [i + 1]: cur.peak },
                  { ...acc[2], [i + 1]: cur.offPeak },
                ],
                [{}, {}, {}]
              )}
              renderCell={(row, column) => row[column]}
            />
          </div>
        </div>
      </Shared.CollapsableRow>
    </div>
  )
}

export default Products
