import React from 'react'
import { Button } from '@makedonski/admin-ui'
import { useDispatch } from 'react-redux'
import { pick } from 'lodash'
import { setModal } from '../../../actions'
import './styles.scss'

const MainInfo = ({ employee, fetch }) => {
  const dispatch = useDispatch()
  const {
    // _id,
    coverPhoto,
    fullName = '',
    roles = [],
    group = '',
    employeeFrom = '— — —',
    workingHours = '-',
  } = employee || {}
  return (
    <div className="employee-main-info-container">
      <div className="employee-main-info-header row">
        <div className="profile-image icon no-active"
          style={{ backgroundImage: `url(${coverPhoto || require('../../../assets/images/default-user-avatar.jpg')})` }}
        />
        <h2>{fullName}</h2>
        {/* {employeeFrom
          ? (<Button.Raised text="Попълни" onClick={() => dispatch(setModal({ isOpen: true, type: 'editEmployee' }))} />)
          : (<div className="icon icon-edit" onClick={() => dispatch(setModal({ isOpen: true, type: 'editEmployee' }))} />)
        } */}
        <div className="row row-buttons" style={{ marginLeft: 'auto' }}>
          <div className="icon icon-edit" style={{ marginRight: 15 }} onClick={() => dispatch(setModal({
            isOpen: true,
            type: 'newEmployee',
            props: {
              employee: {
                ...pick(employee, ['_id', 'firstName', 'lastName', 'email', 'isCompany']),
                roles: employee?.roles?.map(({ _id }) => _id),
                group: employee?.group?._id
              },
              onSuccess: fetch
            }
          }))} />
          <Button.Raised text="Попълни" onClick={() => dispatch(setModal({ isOpen: true, type: 'editEmployee' }))} />
        </div>
      </div>
      <div className="employee-main-info-content row">
        <p>{roles?.map(({ friendlyName }) => friendlyName).join(", ")}</p>
        <p>{group?.name}</p>
      </div>
      <div className="employee-main-info-footer row">
        <p>Служител от: <span>{employeeFrom}</span></p>
        <p>Работно време: <span>{workingHours}</span> часа</p>
      </div>
    </div>
  )
}

export default MainInfo
