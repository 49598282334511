import React from 'react'
import './styles.scss'

const Tasks = ({ value }) => (
  <div className="client-cells-tasks-container row">
    {`${value?.length || '0'} Задач${value?.length === 1 ? 'a' : 'и'}`}
    <div className="icon icon-arrow-right-2" onClick={() => {}} />
  </div>
)

export default Tasks
