import _ from 'lodash'
import moment from 'moment-timezone'
import { parse } from 'papaparse'
import * as XLSX from 'xlsx'
import jschardet from 'jschardet'
import { mapESOClients } from 'config/constants'

const networkFiles = (file, erp, handleResults) => {
  const reader = new FileReader()
  reader.onload = async function () {
    const { encoding } = jschardet.detect(reader.result, { minimumThreshold: 0 })

    const unifyHeaders = (value) => value
      .replaceAll('"', '')
      .replaceAll(/,/gi, '.')
      .replace('Дистрибутор: EIC номер', 'Мрежови оператор: EIC номер')
      .replace('ИТН/Абонат N', 'ИТН/Абонат №')
      .replace('Абонат №', 'ИТН/Абонат №')
      .replace('Обект / Empty', 'Обект/Empty')
      .replace('Обект', 'Обект/Empty')
      .replace('А д р е с', 'Адрес на обекта/Наименование на МП')
      .replace('Адрес на обекта / Наименование на МП', 'Адрес на обекта/Наименование на МП')
      .replace('Адрес на обекта / Наим. на МП', 'Адрес на обекта/Наименование на МП')
      .replace('ЕГН/Булстат', 'Булстат')
      .replace('ЕГН/ЕИК', 'Булстат')
      .replace('Идентификационен код_Точка на отч.', 'Идентификационен код')
      .replace('Фабричен номер електромер', 'Електромер №')
      .replace('Ел.мер ном.', 'Електромер №')
      .replace('Разлика', 'Разлика. квтч')
      .replace('Разлика.. квтч показания. квтч', 'Разлика. квтч')
      .replace('Разлика.. квтч.. квтч', 'Разлика. квтч')
      .replace('Разлика.. квтч', 'Разлика. квтч')
      .replace('Разлика показания. квтч', 'Разлика. квтч')
      .replace('Конст.', 'Константа')
      .replace('Корекция.квтч', 'Корекция. квтч')
      .replace('Приспаднати.кВтч', 'Приспаднати. квтч')
      .replace('Общо количество  .', 'Общо количество квтч.')
      .replace('Общо колич.кВтч', 'Общо количество квтч.')
      .replace('Общо колич. кВтч', 'Общо количество квтч.')
      .replace('Общо колич..кВтч', 'Общо количество квтч.')
      .replace('Общо количество. квтч.', 'Общо количество квтч.')
      .replace('Единична цена (лв./кВтч)', 'Единична цена (лв./кВт/ден)/ (лв./кВтч)')
      .replace('Стойностлв.', 'Стойност лв')
      .replace('Стойност лв.', 'Стойност лв')
      .replace('Стойност. лв.', 'Стойност лв')
      .replace('Стойност. лв', 'Стойност лв')
      .replace('Корекц.към факт.', 'Корекция към фактура')
      .replace('Основание за изд.', 'Основание за издаване')
      .replace('Основание за издаване:', 'Основание за издаване')

    const handleParsedData = ({ data }) => {
      const rows = [...data]
      let headersData = []
      do headersData = rows.shift()
      while (headersData?.filter(Boolean).length < 5)
      const allRows = rows.map((el) => el.reduce((acc, val, i) => {
        if (
          erp === 'ЕВН' &&
          headersData[i] === 'Тарифа/Услуга' &&
          ["Достъп до разпределителната мрежа на НН", "Достъп до разпределителната мрежа на Ср.Н"].includes(val)
        ) return ({ ...acc, [headersData[i]]: [val, el?.at(14)].join(" ") })
        if (
          erp === 'Енерго Про' &&
          headersData[i] === 'Тарифа/Услуга' &&
          ['Достъп', 'Достъп ПМ'].includes(val)
        ) return ({ ...acc, [headersData[i]]: [val, el?.at(14)].join(' ') })
        return ({ ...acc, [headersData[i]]: val })
      }, {}))
      const groupedByITN = _.groupBy(allRows, 'Идентификационен код')
      const totalGrouped = {}
      Object.keys(groupedByITN).forEach((item) => {
        const grouped = _.groupBy(groupedByITN[item], 'Съдържание')
        totalGrouped[item] = grouped
      })
      return [_.omit(totalGrouped, ['undefined', '']), { fields: headersData, data: allRows }]
    }

    const [parsedData, rawData] = await new Promise((res) => parse(file, {
      encoding: encoding === 'KOI8-R' ? 'windows-1251' : encoding,
      delimiter: '";"',
      quoteChar: '',
      transform: (value) => unifyHeaders(value),
      complete: (value) => res(handleParsedData(value))
    }))
    if (_.isEmpty(Object.keys(parsedData))) {
      const [parsedData, rawData] = await new Promise((res) => parse(file, {
        encoding: encoding === 'KOI8-R' ? 'windows-1251' : encoding,
        delimiter: ';',
        quoteChar: '"',
        transform: (value) => unifyHeaders(value),
        complete: (value) => res(handleParsedData(value))
      }))
      handleResults(parsedData, rawData)
    } else handleResults(parsedData, rawData)

  }
  reader.readAsBinaryString(file)
}

const periodFiles = (file, erp, handleResults) => {
  if (erp === 'Златни Пясъци') parsePeriodZLSheet(file, handleResults)
  else {
    const reader = new FileReader()
    reader.onload = function (e) {
      const data = e.target.result
      const wb = XLSX.read(data, { type: 'binary', cellDates: true })
      const results = wb.SheetNames.map((sheet, i) => ({
        sheet: XLSX.utils.sheet_to_json(wb.Sheets[sheet], { header: 1 }),
        sheetName: wb.SheetNames[i],
      }))
      const parsed = _.flatten(
        results
          .filter(({ sheet }) => !_.isEmpty(sheet))
          .map((sheet) => parsePeriodSheet(sheet, erp, results.length !== 1))
      )
      handleResults(parsed)
    }
    reader.readAsBinaryString(file)
  }
}

const parsePeriodSheet = ({ sheet, sheetName }, erp, isMultiPage) => {
  const dataParse = [...sheet]
  let parsed = {}
  let headersData
  if (isMultiPage || erp === 'ЧЕЗ') {
    const formatsIndex = erp === 'ЧЕЗ' ? 3 : 2
    const sumIndex = erp === 'Енерго Про' ? 1 : 0
    headersData = sheet.shift()
    parsed = sheet.map((row) => {
      const data = [...row.slice(formatsIndex + 1, row.length - sumIndex)]
      if (data.length < headersData.length - formatsIndex - 1) {
        for (let i = 0; i < headersData.length - formatsIndex - 1; i++) {
          if ([undefined, null].includes(data[i])) data.push(0)
        }
      }
      return {
        name: row[0],
        itn: row[1],
        formats: [headersData[formatsIndex], row[formatsIndex]],
        data: data
          .map((val, n) => ({
            period: moment(headersData[n + formatsIndex + 1], 'DD.MM.YYYY HH:mm'),
            activeD: erp === 'ЧЕЗ' && row[2].includes('Rec') ? 0 : val ?? 0,
            activeR: erp === 'ЧЕЗ' && row[2].includes('Del') ? 0 : val ?? 0,
          }))
          .filter(
            ({ period, activeD, activeR }) => moment(period).isValid() && ![activeD, activeR].includes(undefined)
          ),
      }
    })
  } else {
    let name = '',
      itn = '',
      elemHeaders = '',
      formats = ''
    if (erp !== 'ЕСО') {
      name = dataParse.shift()
      itn = dataParse.shift()
      elemHeaders = dataParse.shift()
      formats = dataParse.shift()
    }
    parsed.name = name[0] || 'Няма извлечено име'
    parsed.itn = erp ===
      'ЕСО'
      ? mapESOClients[sheetName]
      : typeof itn?.at(0) === 'string'
        ? erp === 'Енерго Про' && itn?.at(0)?.includes(":")
          ? itn?.at(0)?.split(':')?.at(1)?.trim()
          : itn?.at(0)?.split(':')?.at(0)?.trim()
        : itn?.at(0)
    parsed.elemHeaders = _.flatten(elemHeaders)
    parsed.formats = _.flatten(formats)
    parsed.data = dataParse
      .map((el) => ({
        period: moment(el[0], 'DD.MM.YYYY HH:mm'),
        activeD: el[1] ?? 0,
        activeR: el[2] ?? 0,
      }))
      .filter(({ period, activeD, activeR }) => moment(period).isValid() && ![activeD, activeR].includes(undefined))

    if (erp === 'ЕСО' && dataParse?.[0]?.[5] === "MANUAL OVERRIDE") {
      parsed.name = "MANUAL OVERRIDE"
      parsed.itn = dataParse?.[1]?.[4]
    }

    parsed = [parsed]
  }
  parsed = parsed
    .map((object) => {
      const total = object.data.map((el) => el.activeD).reduce((a, b) => a + Number(b || 0), 0)
      const from = object.data[0]?.period
      const to = object.data[object.data.length - 1]?.period
      return { ...object, total, from, to }
    })
    .filter(({ data }) => !_.isEmpty(data))
  return parsed
}

const parsePeriodZLSheet = (file, handleResults) => {
  parse(file, {
    encoding: 'windows-1251',
    delimiter: ';;',
    complete: (results) => {
      const data = [...(results?.data || [])]
      let rows = [...data.slice(8, data.length)]
      if (_.isEmpty(rows)) handleResults([])
      else {
        rows = rows[0].map((val, index) => rows.map((row) => row[index]))
        const headersData = rows.shift()
        const parsed = rows
          .map((row) => ({
            name: row[0],
            itn: row[2].replace(';', ''),
            formats: [headersData[4].replace(';', '').replaceAll("'", '')],
            data: [...row.slice(5, row.length)].map((val, n) => ({
              period: moment(headersData[n + 5].split(';')[0], 'YYYY-MM-DD HH:mm:ssZ'),
              activeD: Number((val || '0')?.replace(';', '')?.replace(',', '.')),
              activeR: 0,
            })),
          }))
          .map((object) => ({
            ...object,
            total: object.data.map((el) => el.activeD).reduce((a, b) => a + Number(b || 0), 0),
            from: object.data[0].period,
            to: object.data[object.data.length - 1].period,
          }))
        handleResults(parsed)
      }
    },
  })
}

const registrationFiles = (file, erp, handleResults) => {
  const reader = new FileReader()
  reader.onload = function (e) {
    const data = e.target.result
    const wb = XLSX.read(data, { type: 'binary', cellDates: true })
    const results = wb.SheetNames.map((sheet) => XLSX.utils.sheet_to_json(wb.Sheets[sheet], { header: 1 }))
    // prettier-ignore
    const parsed = _.flatten(results.filter((arr) => !_.isEmpty(arr)).map((sheet) => parseConfirmationSheet(sheet, erp)))
    handleResults(parsed)
  }
  reader.readAsBinaryString(file)
}

const parseConfirmationSheet = (data, erp) => {
  let sheet = [...data]
  if (erp === 'Енерго Про') {
    const index = sheet.findIndex((row, i) => _.isEmpty(sheet[i]) && !_.isEmpty(sheet?.[i - 1]))
    if (index !== -1) sheet = sheet.slice(0, index)
  }
  return genericParser(sheet)
}

const genericParser = (sheet) => {
  let first
  do first = sheet.shift()
  while (first?.filter(Boolean).length < 2)
  const parsed = sheet.map((el) => el.reduce((acc, cur, i) => ({ ...acc, [first[i]]: cur }), {}))
  while (!_.isEmpty(parsed) && Object.keys(parsed.at(-1) || {}).filter((val) => val).length < 2) parsed.pop()
  return parsed
}

const marketFiles = (file, handleResults) => {
  const reader = new FileReader()
  reader.onload = function (e) {
    const data = e.target.result
    const wb = XLSX.read(data, { type: 'binary', raw: true })
    const results = wb.SheetNames.map((sheet) => {
      const result = XLSX.utils.sheet_to_json(wb.Sheets[sheet], { header: 1 })
      return result.map((row, i) =>
        i == 0
          ? row
          : [
            moment(row?.at(0), 'YYYY-MM-DD').toDate(),
            +row?.at(1),
            +row?.at(2)?.replace(',', '')?.replace(' ', ''),
            +row?.at(3)?.replace(',', '')?.replace(' ', ''),
            +row?.at(4)?.replace(',', '.')?.replace(' ', ''),
          ]
      )
    })
    // const [parsed, rawData] = results?.at(0)?.at(0)?.includes('Date')
    //   ? parseMonthlyMarketSheet(results)
    //   : parseWeeklyMarketSheet(results)
    const [parsed, rawData] = parseMonthlyMarketSheet(results)
    handleResults(parsed, rawData)
  }
  reader.readAsBinaryString(file)
}

// const parseWeeklyMarketSheet = (results) => {
//   const [rawData] = results.map((sheet) => ({
//     fields: [...sheet[0]].map((cell, i) => cell || i),
//     data: sheet
//       .filter((row, i) => ![0].includes(i))
//       .map((row) => row.reduce((acc, cur, i) => ({ ...acc, [sheet[0][i] || i]: cur }), {})),
//   }))
//   const parsed = results.map((sheet) => {
//     const headers = sheet.shift()
//     const sheetData = sheet
//       .filter((row, i) => ![2, 6].includes(i))
//       .map((row, i) => (row[0] ? row : [sheet[i + 1][0], ...row.splice(1, row.length - 1)]))
//     const parsedSheet = headers.reduce(
//       (acc, cur, i) =>
//         cur
//           ? {
//               ...acc,
//               [cur]: sheetData
//                 .filter((row) => row?.at(0) !== '(1-8 & 21-24)')
//                 .reduce(
//                   (acc, row) => ({
//                     ...acc,
//                     [row[0]?.trim()?.replace('Off-Peak', 'Off-Peak (1-8 & 21-24)')]: row[1]
//                       ? {
//                           ...acc[row[0]],
//                           [row[1]]: typeof row[i] === 'number' ? row[i] : Number(row[i].replace(' ', '')),
//                         }
//                       : typeof row[i] === 'number'
//                       ? row[i]
//                       : Number(row[i].replace(' ', '')),
//                   }),
//                   {}
//                 ),
//             }
//           : acc,
//       {}
//     )
//     return parsedSheet
//   })
//
//   return [parsed, rawData]
// }

const parseMonthlyMarketSheet = (results) => {
  const [rawData] = results.map((sheet) => ({
    fields: [...sheet[0]].map((cell, i) => cell || i),
    data: sheet
      .filter((row, i) => ![0, sheet.length - 1].includes(i))
      .map((row) => row.reduce((acc, cur, i) => ({ ...acc, [sheet[0][i] || i]: cur }), {})),
  }))
  let stats = _.mapKeys(_.groupBy(rawData?.data, 'Date'), (value, key) => moment(key).format('DD/MM/YYYY'))
  stats = Object.keys(stats).reduce((acc, key) => {
    const day = stats[key]
    const avgPrice = day.reduce((acc, cur) => acc + cur['Price (BGN)'], 0) / 2400
    const peak = [...day.slice(8, 20)]
    const offpeak = [...day.slice(0, 8), ...day.slice(20)]
    return {
      ...acc,
      [key]: {
        'Prices (BGN/MWh)': avgPrice,
        'Volume (MWh)': day.reduce((acc, cur) => acc + cur['Volume'], 0),
        'Base (01-24)': avgPrice,
        'Peak (9-20)': peak.reduce((acc, cur) => acc + cur['Price (BGN)'], 0) / 1200,
        'Off-Peak (1-8 & 21-24)': offpeak.reduce((acc, cur) => acc + cur['Price (BGN)'], 0) / 1200,
      },
    }
  }, {})
  let temp = rawData?.data
    ?.map((row) => ({
      ...row,
      Date: row.Hour === 24 ? moment(row.Date).add(1, 'days').format('DD/MM/YYYY') : moment(row.Date).format('DD/MM/YYYY'),
      Hour: row.Hour === 24 ? `0 -- 1` : `${row.Hour + 1} -- ${row.Hour + 2}`,
    }))
    .filter((row) => moment(rawData?.data?.at(0).Date).format('DD/MM/YYYY') !== row?.Date)
  const groupedByDate = _.groupBy(temp, 'Date')
  temp = Object.keys(groupedByDate).reduce((acc, date) => {
    return {
      ...acc,
      [date]: { data: groupedByDate[date].map((time) => ({ 'BGN/MWh': time['Price (BGN)'] / 100, MWh: time.Volume })) },
    }
  }, {})
  const parsed = Object.keys(groupedByDate).reduce((acc, date) => {
    return { ...acc, [date]: { ...temp[date], ...stats[date] } }
  }, {})
  return [parsed, rawData]
}

const nomenclaturesFiles = (file, handleResults) => {
  const reader = new FileReader()
  reader.onload = function (e) {
    const data = e.target.result
    const wb = XLSX.read(data, { type: 'binary' })
    const results = wb.SheetNames.map((sheet) => XLSX.utils.sheet_to_json(wb.Sheets[sheet], { header: 1, raw: false }))
    // prettier-ignore
    const parsed = _.flatten(results.filter((arr) => !_.isEmpty(arr)).map((sheet) => {
      const first = sheet.shift().map((header) => header?.trim() || '')
      return sheet.map((el) => el.reduce((acc, cur, i) => ({ ...acc, [first[i]]: cur.toString().trim() }), {}))
    }))
    handleResults(parsed)
  }
  reader.readAsBinaryString(file)
}

const customIndividualDataFiles = (file, handleResults) => {
  const reader = new FileReader()
  reader.onload = function (e) {
    const data = e.target.result
    const wb = XLSX.read(data, { type: 'binary' })
    const results = wb.SheetNames.map((sheet) => XLSX.utils.sheet_to_json(wb.Sheets[sheet], { header: 1, raw: false }))
    // prettier-ignore
    const parsed = _.flatten(results.filter((arr) => !_.isEmpty(arr)).map((sheet) => {
      const first = sheet.shift()
      const second = sheet.shift()
      return sheet.map((el) => el.reduce((acc, cur, i) => ({ ...acc, [`${first[i]} - ${second[i]}`]: cur.toString().trim() }), {}))
    }))
    handleResults(parsed)
  }
  reader.readAsBinaryString(file)
}

const createObjects = (file, handleResults) => {
  const reader = new FileReader()
  reader.onload = function (e) {
    const data = e.target.result
    const wb = XLSX.read(data, { type: 'binary', cellDates: true })
    const results = wb.SheetNames.map((sheet) => XLSX.utils.sheet_to_json(wb.Sheets[sheet], { header: 1 }))
    handleResults(genericParser(results?.at(0)))
  }
  reader.readAsBinaryString(file)
}

export const fileParser = {
  network: networkFiles,
  period: periodFiles,
  stpData: periodFiles,
  confirmations: registrationFiles,
  comments: registrationFiles,
  obligations: registrationFiles,
  leaving: registrationFiles,
  market: marketFiles,
  stp: registrationFiles,
  nomenclatures: nomenclaturesFiles,
  createObjects: createObjects,
  deposits: nomenclaturesFiles,
  interests: nomenclaturesFiles,
  invoicePayments: nomenclaturesFiles,
  interestsPaymentsOld: nomenclaturesFiles,
  interestsPayments: nomenclaturesFiles,
  massEditInvoices: nomenclaturesFiles,
  manualOverride: nomenclaturesFiles,
  stpSummarized: nomenclaturesFiles,
  esoData: nomenclaturesFiles,
  customIndividualData: customIndividualDataFiles,
  producerForecast: nomenclaturesFiles,
  customGroupData: nomenclaturesFiles,
  customProducerPricing: nomenclaturesFiles,
  ajurNumbers: nomenclaturesFiles,
  default: nomenclaturesFiles
}
