import { Button } from "@makedonski/admin-ui"
import React, { useCallback, useEffect, useMemo, useRef } from "react"
import { Alerts, Headers } from "@makedonski/socourt-utilities";
import "./styles.scss"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import moment from "moment"
import { useCollapsable, useQuery } from "hooks"
import { getTextForConfirmationModal, handleSendInvoice, mapClientsSearch, renderCell } from "utilities"
import { getInvoicesFilters, producersInvoicesBrowse, setModal, setProducersFields, startLoading, stopLoading } from "actions"
import { debounce, groupBy, isEmpty } from "lodash"
import { invoicesSearchOptions, producersInvoicesFields, producersReferencesOptions } from "config/constants"
import { Popover } from "@varld/popover"
import { Inputs, Shared } from "components"
import { URL } from "config/settings";

const Invoices = ({ TypeSelector }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { showCheckboxes, selectedCheckboxes, docs, totalDocs, nextPage, hasNextPage, enableSortFor, filters } = useSelector(({ producers }) => producers) || {}

    const { monthYear = moment().subtract(1, 'months').format('MM/YYYY'), sort: sortParam = '{}', filter: filterParam = '{}', searchBy = '', handleUrlChangeMultiple } = useQuery()
    const filter = useMemo(() => JSON.parse(filterParam), [filterParam])
    const sort = useMemo(() => JSON.parse(sortParam), [sortParam])
    const hasAvailableFilters = useMemo(() => !isEmpty(filters), [filters])
    const searchQuery = useMemo(() => ({ ...mapClientsSearch(sort, filter, undefined, filters) }), [sortParam, filterParam, hasAvailableFilters])

    const fetch = useCallback(({ getFilters, ...payload } = {}) => {
        dispatch(startLoading())
        if (!hasAvailableFilters && getFilters) dispatch(getInvoicesFilters({ payload: { type: 'producer' } }))
        else dispatch(producersInvoicesBrowse({ payload: { monthYear, ...searchQuery, ...payload, type: 'producer' } }))
    }, [dispatch, monthYear, searchQuery, hasAvailableFilters])

    const fetchDebounced = debounce(fetch, 300)
    useEffect(() => { fetch({ getFilters: true }) }, [fetch])

    const handlePregenerate = async () => {
        try {
            dispatch(startLoading())
            const selected = selectedCheckboxes.map((_id) => docs.find((doc) => doc._id === _id))
            const grouped = groupBy(selected, 'statsType')
            const response = await Promise.all((Object.entries(grouped ?? {}).map(([type, docs]) => new Promise(async (resolve, reject) => {
                try {
                    const response = await window.fetch(`${URL}/producers/generation`, {
                        method: 'POST',
                        headers: Headers.getWithAuth(),
                        body: JSON.stringify({ monthYear, type, ids: docs?.map(({ objects }) => objects.join(",")) }),
                    })
                    if (!response.ok) resolve({ success: false, error: (await response.json()).error, type, docs })
                    else resolve({ success: true })
                } catch (error) { return resolve({ success: false, error, type, docs }) }
            }))))
            const errors = response.filter(({ error }) => error).map(({ error, type, docs }) => ({ text: `${error?.message} - ${producersReferencesOptions.find(({ value }) => value === type)?.label}`, inner: docs?.map(({ clientData }) => clientData?.itn)?.join(' | ') }))
            if (errors.length) dispatch(setModal({ isOpen: true, type: 'confirmation', props: { title: 'Грешка', children: getTextForConfirmationModal(errors), }, }))
            else Alerts.success({ title: 'Успешно прегенериране!' })
        } catch (error) { Alerts.error(error?.message) }
        finally {
            dispatch(stopLoading())
            dispatch(setProducersFields({ showCheckboxes: false, selectedCheckboxes: [] }))
            fetch()
        }
    }

    const handleSend = () => handleSendInvoice({
        selected: selectedCheckboxes,
        onSuccess: () => {
            dispatch(setProducersFields({ showCheckboxes: false, selectedCheckboxes: [] }))
            dispatch(setModal({ isOpen: true, type: 'confirmation', props: { title: 'Успешно изпратени фактури ', onClick: fetch, onRequestClose: fetch } }))
        },
        dispatch,
    })

    const collapsableRef = useRef(null)
    const [isExpanded, setExpanded] = useCollapsable(collapsableRef)

    const sortingComponents = producersInvoicesFields?.filter(({ sortable }) => sortable).reduce((acc, { value }) => {
        return {
            ...acc,
            [value]: <Popover popover={({ close }) => <Shared.SortingComponent
                hide={close}
                column={value}
                availableSort={enableSortFor}
                availableFilters={filters}
                keyMap={{ 'pricing.base': 'base', 'pricing.withVat': 'withVat', }}
            />}>
                <div className="icon icon-arrow-down" />
            </Popover>

        }
    }, {})

    return <div className="references-producers-invoices-container">
        <div className="references-producers-invoices-header row">
            <TypeSelector />
            <Inputs.DatePicker
                customInput={<div className='row'>
                    <h2 className="row">{moment(monthYear, 'MM/YYYY').format('м. MMMM')}</h2>
                    <div className="icon icon-calendar-custom" />
                </div>}
                className="month-picker"
                showMonthYearPicker
                showFullMonthYearPicker
                value={moment(monthYear, 'MM/YYYY')}
                onChange={(monthYear) => handleUrlChangeMultiple({ 'monthYear': moment(monthYear).format('MM/YYYY') })}
                minDate={moment().subtract(10, 'years').toDate()}
                maxDate={moment().add(1, 'months').endOf('month').toDate()}
            />
            <div>
                <Inputs.SearchMulti
                    search={filter?.[searchBy] || ''}
                    searchBy={searchBy || 'eic'}
                    handleChange={({ search, searchBy: newSearchBy }) => {
                        const newFilter = { ...filter, [searchBy]: undefined, [newSearchBy]: search || undefined }
                        handleUrlChangeMultiple({
                            searchBy: search ? newSearchBy : undefined,
                            filter: Object.values(newFilter).filter(Boolean).length ? JSON.stringify(newFilter) : undefined
                        })
                    }}
                    options={invoicesSearchOptions}
                    popupText={{ secondary: ' ' }}
                />
            </div>
            {showCheckboxes
                ? <div className="row row-buttons">
                    <Button.Raised text='Откажи' className="btn-other-actions" onClick={() => dispatch(setProducersFields({ showCheckboxes: false, selectedCheckboxes: [] }))} />
                    {showCheckboxes?.action === 'pregenerate' && <Button.Raised text="Прегенерирай" onClick={handlePregenerate} />}
                    {showCheckboxes?.action === 'send' && <Button.Raised text="Изпрати" onClick={handleSend} />}
                </div>
                : <div className="row row-buttons">
                    <Popover
                        popover={({ close }) => <div className="popup-send-container">
                            <div className="row">
                                <h4>Изберете действие</h4>
                                <Button.Icon name="plus" onClick={close} />
                            </div>
                            <p onClick={() => history.push('/producers/invoices/form')}>Ръчно създаване на фактура</p>
                            {process.env.REACT_APP_PLATFORM !== 'Synergon' && <p onClick={() => { fetch({ pagination: false }); dispatch(setProducersFields({ showCheckboxes: { action: 'send' } })); close() }}>Изпрати</p>}
                            <p onClick={() => { fetch({ pagination: false }); dispatch(setProducersFields({ showCheckboxes: { action: 'pregenerate' } })); close() }}>Прегенерирай</p>
                            <p onClick={() => setExpanded(!isExpanded)}>Експорти</p>
                            <div className="collapsable-exports" ref={collapsableRef}>
                                <p onClick={() => { fetch({ forExport: 'pdfFile', pagination: false, file: true, select: 'pdfFile', sort: { createdAt: -1 } }); close() }}>PDF-и</p>
                                <p onClick={() => { fetch({ exportExcel: true, pagination: false, }); close() }}>Excel 1</p>
                            </div>
                            <p onClick={() => { handleUrlChangeMultiple({ search: null, searchBy: null, sort: null, filter: null, }); close() }}>Изчисти всички филтри</p>
                        </div>}
                    >
                        <Button.Raised text="Други действия" className="btn-other-actions" />
                    </Popover>
                </div>}
        </div>
        <div className="references-producers-invoices-content">
            <Shared.Table
                columns={producersInvoicesFields}
                data={docs}
                renderCell={(row, field, options) => renderCell.producersInvoices(row, field, { ...options, dispatch, fetch })}
                handlePagination={() => hasNextPage && fetchDebounced({ page: nextPage })}
                useCheckboxes={!!showCheckboxes}
                selectedCheckboxes={selectedCheckboxes}
                onCheckboxChange={(value) => dispatch(setProducersFields({ selectedCheckboxes: value }))}
                disabledCheckboxes={
                    ['send'].includes(showCheckboxes?.action) ? docs?.filter(({ invoiceSent, pdfFile, deletedAt }) => invoiceSent || !pdfFile || deletedAt).map(({ _id }) => _id) :
                        ['pregenerate'].includes(showCheckboxes?.action) ? docs?.filter(({ deletedAt }) => deletedAt).map(({ _id }) => _id) :
                            []
                }
                sortingComponent={sortingComponents}
                outlines={{ ...(docs || []).reduce((acc, { _id, deletedAt }) => deletedAt ? ({ ...acc, [_id]: '#fa4444' }) : acc, {}) }}
            />
        </div>
        <div className="references-producers-invoices-footer row">
            <div className="references-producers-invoices-inner-footer row">
                <p className="row">Общо: {totalDocs || 0}</p>
                {showCheckboxes && <p className="row">Избрани: {selectedCheckboxes?.length || 0} фактури</p>}
            </div>
        </div>
    </div>
}

export default Invoices