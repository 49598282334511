import React, { useState, useRef, useEffect, useMemo } from 'react'
import { Popover } from '@varld/popover'
import { Button } from '@makedonski/admin-ui'
import moment from 'moment'
import { isEmpty } from 'lodash'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getInvoicesFilters, setInvoicesFields, getInvoices, setModal, startLoading, exportAjur, exportEasypay } from 'actions'
import { Inputs, Shared } from 'components'
import { SortingComponent } from '../Invoices'
import { depositsLeavingFields, invoicesSearchOptions } from 'config/constants'
import { useQuery, useCollapsable } from 'hooks'
import { renderCell, handleSendInvoice, handleUploadedData, mapClientsSearch } from 'utilities'
import "./styles.scss"

const Deposits = ({ }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const tableRef = useRef()
  const {
    invoices,
    nextPage,
    hasNextPage,
    amountMax,
    amountMin,
    totalDocs,
    totalWithoutVat,
    showCheckboxes,
    selectedCheckboxes,
    availableFilters,
    availableSort,
  } = useSelector((state) => state.invoices)

  const {
    type,
    month: monthParam = moment().format('MM.YYYY'),
    searchBy = '',
    filter: filterParam = '{}',
    sort: sortParam = '{}',
    handleUrlChangeMultiple,
  } = useQuery({ type: [{ value: 'leaving' }] })
  const month = useMemo(() => moment(monthParam, 'MM.YYYY').toDate(), [monthParam])

  const filter = useMemo(() => JSON.parse(filterParam), [filterParam])
  const sort = useMemo(() => JSON.parse(sortParam), [sortParam])

  const hasAvailableFilters = useMemo(() => !isEmpty(availableFilters), [availableFilters])
  const searchQuery = useMemo(() => {
    return { month, type, enableSortFor: availableSort, filters: availableFilters, ...mapClientsSearch(sort, filter, type, availableFilters) }
  }, [hasAvailableFilters, sortParam, filterParam, month, type,])

  const fetch = ({ getFilters, ...payload } = {}) => {
    dispatch(startLoading())
    if (!hasAvailableFilters && getFilters) dispatch(getInvoicesFilters())
    else dispatch(getInvoices({ page: 1, ...searchQuery, ...payload }))
  }


  useEffect(() => {
    fetch({ getFilters: true })
    return () => dispatch(setInvoicesFields({ invoices: null, showCheckboxes: false, selectedCheckboxes: [] }))
  }, [searchQuery])

  const collapsableRef = useRef()
  const [isExpanded, setExpanded] = useCollapsable(collapsableRef)

  // const handleExport = {
  //   ajur: () => dispatch(setModal({
  //     type: 'confirmation', isOpen: true, props: {
  //       title: "Генериране на документи - Ажур",
  //       children: '',
  //       buttons: <div className="row">
  //         <Button.Raised text='Избери фактури' onClick={() => {
  //           fetch({ pagination: false })
  //           dispatch(setInvoicesFields({ showCheckboxes: { action: 'ajur' } }))
  //           dispatch(setModal({ isOpen: false }))
  //         }} />
  //         <div style={{ width: 30 }} />
  //         <Button.Raised text='Избери период' onClick={() => dispatch(setModal({ type: "exportAjur", isOpen: true, props: { type, month } }))} />
  //       </div>
  //     }
  //   })),
  //   easypay: () => dispatch(setModal({
  //     type: 'confirmation', isOpen: true, props: {
  //       title: "Генериране на документи - EasyPay",
  //       children: '',
  //       buttons: <div className="row">
  //         <Button.Raised text='Избери фактури' onClick={() => {
  //           fetch({ pagination: false })
  //           dispatch(setInvoicesFields({ showCheckboxes: { action: 'easyPay' } }))
  //           dispatch(setModal({ isOpen: false }))
  //         }} />
  //         <div style={{ width: 30 }} />
  //         <Button.Raised text='Избери период' onClick={() => dispatch(setModal({ type: "exportEasypay", isOpen: true, props: { type, month } }))} />
  //       </div>
  //     }
  //   }))
  // }

  const handleExport = {
    ajur: () => {
      fetch({ pagination: false })
      dispatch(setInvoicesFields({ showCheckboxes: { action: 'ajur' } }))
    },
    easypay: () => {
      fetch({ pagination: false })
      dispatch(setInvoicesFields({ showCheckboxes: { action: 'easyPay' } }))
    }
  }

  const handleSendInvoiceInitial = () => handleSendInvoice({
    selected: selectedCheckboxes,
    onSuccess: () => {
      dispatch(setInvoicesFields({ showCheckboxes: false, selectedCheckboxes: [] }))
      dispatch(setModal({ isOpen: true, type: 'confirmation', props: { title: 'Успешно изпратени фактури ', onClick: fetch, onRequestClose: fetch } }))
    },
    dispatch,
  })

  const handleExportAjur = () => {
    dispatch(startLoading())
    dispatch(exportAjur({
      payload: { query: { _id: { $in: [...selectedCheckboxes] } } },
      onSuccess: () => dispatch(setInvoicesFields({ showCheckboxes: false, selectedCheckboxes: [] }))
    }))
  }

  const handleExportEasyPay = () => {
    dispatch(startLoading())
    dispatch(exportEasypay({
      payload: { query: { _id: { $in: [...selectedCheckboxes] } } },
      onSuccess: () => dispatch(setInvoicesFields({ showCheckboxes: false, selectedCheckboxes: [] }))
    }))
  }


  const sortingComponents = depositsLeavingFields.filter(({ sortable }) => sortable).reduce((acc, { value }) => {
    return {
      ...acc,
      [value]:
        <Popover popover={({ close }) => <SortingComponent hide={close} column={value} type={type} />}>
          <div className="icon icon-arrow-down" />
        </Popover>

    }
  }, {})

  return <div className="screen-deposits-container">
    <div className="screen-deposits-header row">
      <Inputs.DatePicker
        customInput={<div className='row'>
          <h2 className="row">{moment(month).format('м. MMMM')}</h2>
          <div className="icon icon-calendar-custom" />
        </div>}
        className="month-picker"
        showMonthYearPicker
        showFullMonthYearPicker
        value={month}
        onChange={(date) => handleUrlChangeMultiple({ month: moment(date).format('MM.YYYY') })}
        minDate={moment().subtract(10, 'years').toDate()}
        maxDate={moment().endOf('month').toDate()}
      />
      <Inputs.SearchMulti
        search={filter?.[searchBy] || ''}
        searchBy={searchBy || 'eic'}
        handleChange={({ search, searchBy: newSearchBy }) => {
          const newFilter = { ...filter, [searchBy]: undefined, [newSearchBy]: search || undefined }
          handleUrlChangeMultiple({
            searchBy: search ? newSearchBy : undefined,
            filter: Object.values(newFilter).filter(Boolean).length ? JSON.stringify(newFilter) : undefined
          })
        }}
        options={invoicesSearchOptions}
        popupText={{ secondary: ' ' }}
      />
      <div className="row row-buttons">
        {showCheckboxes ? (
          <Button.Raised
            className="btn-other-actions"
            text="Откажи"
            onClick={() => {
              tableRef.current.toggleAllRowsSelected(false)
              dispatch(setInvoicesFields({ showCheckboxes: false, selectedCheckboxes: [] }))
            }}
          />
        ) : (
          <Popover
            popover={({ close }) => (
              <div className="popup-send-container">
                <div className="row row-buttons">
                  <h4>Изберете действие</h4>
                  <Button.Icon name="plus" onClick={close} />
                </div>
                <p onClick={() => dispatch(setInvoicesFields({ showCheckboxes: { action: 'send' } }))}>Изпрати</p>
                <p onClick={() => setExpanded(!isExpanded)}>Експорти</p>
                <div className="collapsable-exports" ref={collapsableRef}>
                  <p onClick={() => {
                    handleExport['ajur']()
                    close()
                  }}>Ажур</p>
                  <p onClick={() => {
                    handleExport['easypay']()
                    close()
                  }}>EasyPay</p>
                </div>
                <p onClick={() => {
                  handleUrlChangeMultiple({ search: null, searchBy: null, sort: null, filter: null, })
                  close()
                }}>Изчисти всички филтри</p>
              </div>
            )}
          >
            <Button.Raised className="btn-other-actions" text="Други действия" />
          </Popover>
        )}
        {showCheckboxes ? <Button.Raised
          disabled={isEmpty(selectedCheckboxes)}
          text={showCheckboxes?.action === 'send' ? 'Изпрати' : ['ajur', 'easyPay'].includes(showCheckboxes?.action) ? "Генерирай" : ''}
          onClick={() => {
            if (showCheckboxes?.action === 'send') handleSendInvoiceInitial()
            else if (showCheckboxes?.action === 'ajur') handleExportAjur()
            else if (showCheckboxes?.action === 'easyPay') handleExportEasyPay()
          }}
        /> : <Button.UploadButton
          className="btn-generate"
          text="Качи файлове"
          multiple
          accept={'.csv, .xlsx, .xls'}
          onChange={({ target: { files } }) => handleUploadedData({ files, dispatch, history, type: 'deposits' })}
        />}
      </div>
    </div>
    <div className="screen-deposits-content">
      <Shared.Table
        ref={tableRef}
        data={invoices}
        columns={depositsLeavingFields}
        renderCell={(row, field, options) => renderCell.depositsLeaving(row, field, { ...options, fetch })}
        useCheckboxes={!!showCheckboxes}
        selectedCheckboxes={selectedCheckboxes}
        disabledCheckboxes={['send'].includes(showCheckboxes?.action) ? invoices?.filter(({ invoiceSent, pdfFile, deletedAt }) => invoiceSent || !pdfFile || deletedAt).map(({ _id }) => _id) : []}
        onCheckboxChange={(value) => dispatch(setInvoicesFields({ selectedCheckboxes: value }))}
        sortingComponent={sortingComponents}
        handlePagination={() => hasNextPage && fetch({ page: nextPage, amountMin, amountMax, totalWithoutVat })}
        outlines={{ ...(invoices || []).reduce((acc, { _id, deletedAt }) => deletedAt ? ({ ...acc, [_id]: '#fa4444' }) : acc, {}) }}
      />
    </div>
    <div className="screen-deposits-footer row">
      <div className="screen-deposits-inner-footer row">
        <p className="row">Общо: {totalDocs || 0} фактури</p>
        {showCheckboxes && <p className="row">Избрани: {selectedCheckboxes?.length || 0} фактури</p>}
        <p className="row">
          Сума без ДДС:{' '}
          {(totalWithoutVat || 0).toFixed(2)} лв.
        </p>
      </div>
    </div>
  </div>
}

export default Deposits