import React, { useState } from 'react'
import moment from 'moment'
import { Portal } from 'react-overlays'
import { Button } from '@makedonski/admin-ui'
import { useDispatch } from 'react-redux'
import { setModal } from 'actions'
import { Inputs } from 'components'
import './styles.scss'

const options = [{
    label: 'Авансова фактура на 10 дни',
    value: 'advance-10',
    options: [
        { label: 'Първа фактура', value: '1' },
        { label: 'Втора фактура', value: '2' },
        { label: 'Трета фактура', value: '3' }
    ]
},
{
    label: 'Авансова фактура на 15 дни',
    value: 'advance-15',
    options: [
        { label: 'Първа фактура', value: '1' },
        { label: 'Втора фактура', value: '2' }
    ]
},
{
    label: 'Авансова фактура на 30 дни',
    value: 'advance-30',
    options: [
        { label: 'Първа фактура', value: '1' }

    ]
},
{
    label: 'Междинна фактура на 10 дни',
    value: 'intermediate-10',
    options: [
        { label: 'Първа фактура', value: '1' },
        { label: 'Втора фактура', value: '2' },
        { label: 'Трета фактура', value: '3' }
    ]
},
{
    label: 'Междинна фактура на 15 дни',
    value: 'intermediate-15',
    options: [
        { label: 'Първа фактура', value: '1' }
        // { label: 'Втора фактура', value: '2' },
    ]
}]

const IntermediateInvoicesPrice = ({
    title,
    data: dataProp,
    onClick = () => { },
    validate,
}) => {
    const dispatch = useDispatch()
    const [data, setData] = useState({ type: 'advance-10', consecutiveNumber: '1', advanceFirstDate: '1', ...dataProp, })
    return <div className="modal-intermediate-invoices-price-container">
        <div className="modal-intermediate-invoices-price-header">
            <h2>{title}</h2>
            <Button.Icon
                name="plus"
                size="26px"
                className="btn-close"
                onClick={() => dispatch(setModal({ isOpen: false }))}
            />
        </div>
        <div className="row row-options">
            <Inputs.RadioButtons col buttons={options} value={data.type} onClick={(value) => setData({ ...data, type: value, consecutiveNumber: '1' })} />
            <Inputs.RadioButtons col buttons={options?.find(({ value }) => value === data.type).options} value={data.consecutiveNumber} onClick={(value) => setData({ ...data, consecutiveNumber: value })} />
            <div>{data?.type.includes('advance') && data?.consecutiveNumber === '1' && <Inputs.RadioButtons
                col buttons={[{ label: '1-во число', value: '1' }, { label: '25-то число', value: '25' },]} value={data.advanceFirstDate} onClick={(value) => setData({ ...data, advanceFirstDate: value })}
            />}</div>
        </div>
        <div className="row row-percentLastMonth">
            <div className="col">
                <span>Процент предходен месец</span>
                <Button.Switch isOn={data?.percentLastMonth} onChange={() => setData({ ...data, percentLastMonth: !data?.percentLastMonth })} />
            </div>
        </div>
        <div className="modal-intermediate-invoices-price-content row">
            <div className="col">
                <span>Цена</span>
                <Inputs.TextLabeled
                    value={data.text || ''}
                    label={data?.percentLastMonth ? "%" : "лв./МВтч"}
                    onChange={({ target: { value } }) => {
                        if (data?.percentLastMonth && value !== '' && (!/^\d{0,3}$/.test(value) || Number(value) > 100)) return
                        if (!/^\d{0,4}(\.||\.\d{0,2})?$/.test(value) && value !== '') return
                        setData({ ...data, text: value })
                    }}
                />
            </div>
            <div className="spacer"></div>
            <div className="col">
                <span>Месец</span>
                <Inputs.DatePicker
                    value={moment(data.date, 'DD.MM.YYYY')}
                    onChange={(value) => setData({ ...data, date: moment(value).format('DD.MM.YYYY') })}
                    dateFormat={'MMMM yyyy'}
                    showMonthYearPicker
                    showFullMonthYearPicker
                    className="month-picker"
                    popperContainer={({ children }) => (
                        <Portal container={document.getElementById('calendar-portal')}>{children}</Portal>
                    )}
                />
            </div>
        </div>
        <div className="modal-intermediate-invoices-price-footer row">
            <Button.Raised
                className='cancel'
                text="Откажи"
                onClick={() => dispatch(setModal({ isOpen: false }))}
            />
            <div className="spacer"></div>
            <Button.Raised
                text="Продължи"
                onClick={() => {
                    if (onClick) {
                        if (validate && !validate(data)) return
                        else onClick(data)
                    }
                    dispatch(setModal({ isOpen: false }))
                }}
            />
        </div>
    </div>
}

export default IntermediateInvoicesPrice



