import { useState, useEffect, useCallback } from 'react'

export function useSwipeScroll({ sliderRef, reliants = [], momentumVelocity = 0 }) {
  const [hasSwiped, setHasSwiped] = useState(false)

  const init = useCallback(() => {
    const slider = sliderRef.current
    let isDown = false
    let isSelecting = true
    let startX
    let scrollLeft
    let maxWalk = 0

    slider.addEventListener('mousedown', (e) => {
      isDown = true
      slider.classList.add('active')
      startX = e.pageX - slider.offsetLeft
      scrollLeft = slider.scrollLeft
      cancelMomentumTracking()
    })

    slider.addEventListener('mouseleave', () => {
      maxWalk = 0
      isSelecting = true
      setHasSwiped(false)
      slider.classList.remove('active')
    })

    slider.addEventListener('mouseup', () => {
      isDown = false
      slider.classList.remove('active')
      beginMomentumTracking()
      setTimeout(() => {
        maxWalk = 0
        isSelecting = true
        setHasSwiped(false)
      }, 0)
    })

    slider.addEventListener('mousemove', (e) => {
      if (!isDown || document.getSelection().toString().length) return
      if (!isSelecting) e.preventDefault()
      const x = e.pageX - slider.offsetLeft
      const walk = x - startX
      maxWalk = Math.abs(walk) > maxWalk ? Math.abs(walk) : maxWalk
      if (maxWalk < 25) return
      let prevScrollLeft = slider.scrollLeft
      slider.scrollLeft = scrollLeft - walk
      velX = slider.scrollLeft - prevScrollLeft
      if (slider.scrollLeft - prevScrollLeft && !hasSwiped) {
        setHasSwiped(true)
        isSelecting = false
      }
    })

    // Momentum
    let velX = 0
    let momentumID

    slider.addEventListener('wheel', () => {
      cancelMomentumTracking()
    })

    function beginMomentumTracking() {
      cancelMomentumTracking()
      momentumID = requestAnimationFrame(momentumLoop)
    }
    function cancelMomentumTracking() {
      cancelAnimationFrame(momentumID)
    }
    function momentumLoop() {
      slider.scrollLeft += velX
      velX *= momentumVelocity
      if (Math.abs(velX) > 0.5) {
        momentumID = requestAnimationFrame(momentumLoop)
      }
    }
  })

  useEffect(() => {
    init()
  }, [...reliants])

  return { hasSwiped }
}
