import React, { useState, useEffect } from 'react'
import { Input, Button } from '@makedonski/admin-ui'
import { Portal } from 'react-overlays'
import moment from 'moment'
import { Inputs } from '../../'
import './styles.scss'
import { combineDateAndTime } from 'utilities'

const TextEdit = ({ value = '', type = 'text', handleChange, onDelete, options, getOptions, disabled, showTime, validation }) => {
  const [showEdit, setShowEdit] = useState(false)
  const [data, setData] = useState(false)

  useEffect(() => {
    if (!options && type === 'dropdown' && showEdit) getOptions()
  }, [options, type, showEdit])

  return (
    <div className="inputs-text-edit-container row">
      {!value && !showEdit ? (
        <p>--</p>
      ) : showEdit ? (
        type === 'text' ? (
          <Input.Text options={options} value={data || ''} onChange={({ target: { value } }) => {
            if (validation && !validation(value)) return
            setData(value)
          }} />
        ) : type === 'dropdown' ? (
          <Inputs.Dropdown options={options} value={data?._id || data} onChange={({ value }) => setData(value)} />
        ) : type === 'date' ? (
          <Inputs.DatePicker
            value={data}
            onChange={(value) => showTime ? setData(combineDateAndTime(value)) : setData(value)}
            popperContainer={({ children }) => (
              <Portal container={document.getElementById('calendar-portal')}>{children}</Portal>
            )}
          />
        ) : type === 'monthDate' ? (
          <Inputs.DatePicker
            value={data}
            popperContainer={({ children }) => (
              <Portal container={document.getElementById('calendar-portal')}>{children}</Portal>
            )}
            onChange={(value) => setData(moment(value).startOf('month').toDate())}
            dateFormat={'MMMM yyyy'}
            showMonthYearPicker
            showFullMonthYearPicker0
            className="month-picker"
          />
        ) : null
      ) : ['date', 'monthDate'].includes(type) ? (
        <p>{moment(value).format(showTime ? 'DD.MM.YYYY | HH:mm' : 'DD.MM.YYYY')}</p>
      ) : (
        <p>{value?.name || value || '--'}</p>
      )}
      {showEdit ? (
        <div className="row row-buttons">
          <Button.Icon
            name="plus"
            color="red"
            size={25}
            onClick={() => {
              setShowEdit(false)
              setData('')
            }}
          />
          <div
            className={`icon icon-check ${!data && 'disabled'} `}
            onClick={() => {
              if (!data) return
              if (handleChange) handleChange(data)
              setShowEdit(false)
              setData('')
            }}
          />
        </div>
      ) : !disabled && (
        <div className="row row-buttons">
          <div
            className="icon icon-edit"
            onClick={() => {
              setShowEdit(true)
              if (value) setData(value?._id || value)
            }}
          />
          {onDelete && <Button.Icon name="plus" color="red" size={25} onClick={() => onDelete()} />}
        </div>
      )}
    </div>
  )
}

export default TextEdit
