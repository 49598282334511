import React, { useState } from "react"
import { Inputs } from "components"
import { Button } from '@makedonski/admin-ui'
import "./styles.scss"
import { Portal } from "react-overlays"

const InputDate = ({ close, title = "", text = "", defaultValue = "", onSuccess = () => { } }) => {
    const [state, setState] = useState(defaultValue)
    return <div className="modal-input-date-container">
        <div className="modal-input-date-header row">
            <h2>{title}</h2>
            <div className="icon icon-close" onClick={close} />
        </div>
        <div className="modal-input-date-content">
            {text && <p style={{ textAlign: 'center' }}>{text}</p>}
            <Inputs.DatePicker
                value={state}
                onChange={setState}
                popperContainer={({ children }) => <Portal container={document.getElementById('calendar-portal')}>{children}</Portal>}
            />
        </div>
        <div className="modal-input-date-footer row">
            <Button.Raised onClick={() => onSuccess(state, close)} text="Продължи" disabled={!state} />
        </div>
    </div>
}

export default InputDate