import React from 'react'
import { Button } from '@makedonski/admin-ui'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { sendDocumentsToERP, setModal, setOverlay } from 'actions'

import './styles.scss'

const DocumentsERP = ({ client, fetch }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const documents = client?.files?.filter(({ type }) => type === 'erp-registration')

  const handleSendDocumentsToERP = ({ hideSuccess } = {}) => {
    dispatch(
      sendDocumentsToERP({
        data: { toProcess: [{ client: client?._id }] },
        onSuccess: () => {
          if (!hideSuccess)
            dispatch(
              setModal({
                isOpen: true,
                type: 'confirmation',
                props: {
                  title: 'Изпратени документи',
                  children: 'Успешно генерирани и изпратени документи към ЕРП',
                  onClick: () => fetch(),
                },
              })
            )
        },
      })
    )
  }

  return (
    <div className="client-cells-documents-erp-container row">
      {documents?.length ? (
        <>
          Да
          <div
            className="icon icon-arrow-right-2"
            onClick={() =>
              dispatch(
                setOverlay({
                  isOpen: true,
                  type: 'document',
                  props: { title: 'Документи към ЕРП', type: `erps`, documents },
                })
              )
            }
          />
        </>
      ) : (
        <Button.Raised
          text="Генерирай"
          onClick={() => {
            if (client?.objects?.some(({ status }) => status === 'transfer'))
              dispatch(
                setModal({
                  isOpen: true,
                  type: 'confirmation',
                  props: {
                    title: 'Точки за пререгистрация',
                    children:
                      'Сред избраните точки има такива със статус "Пререгистрация". Моля продължете към избор на точки, за които да се генерират документи или продължете без тези обекти',
                    buttons: (
                      <div className="row" style={{ justifyContent: 'space-between' }}>
                        <Button.Raised
                          text="Избери обекти"
                          onClick={() => {
                            handleSendDocumentsToERP({ hideSuccess: true })
                            dispatch(setModal({ isOpen: false }))
                            history.push(`/clients/transfer/confirm-documents?ids=${client?._id}`)
                          }}
                        />
                        <div style={{ width: 20, height: 20 }}></div>
                        <Button.Raised text="Продължи без" onClick={() => handleSendDocumentsToERP()} />
                      </div>
                    ),
                  },
                })
              )
            else handleSendDocumentsToERP()
          }}
        />
      )}
    </div>
  )
}

export default DocumentsERP
