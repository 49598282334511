import React, { useState } from 'react'
import { Button } from "@makedonski/admin-ui"
import { isEmpty } from 'lodash'
import { Inputs } from '../../'
import './styles.scss'
import { useQuery } from 'hooks'
import moment from 'moment'
import { Portal } from 'react-overlays'

const Filter = ({ clientsData, selectedObjects, exclude, fetch }) => {
  const [expanded, setExpanded] = useState({})

  const {
    from = moment().subtract(1, 'months').startOf('month').format('DD.MM.YYYY'),
    to = moment().startOf('month').format('DD.MM.YYYY'),
    tempType,
    type = 'hours',
    handleUrlChangeMultiple
  } = useQuery()


  const startDate = moment(from, 'DD.MM.YYYY').isValid() ? moment(from, 'DD.MM.YYYY').toDate() : null
  const endDate = moment(to, 'DD.MM.YYYY').isValid() ? moment(to, 'DD.MM.YYYY').subtract(1, 'days').toDate() : null

  return (
    <div className="analysis-filter-container">
      <h4>Анализ на потреблението</h4>

      {/* <Inputs.DatePicker
        value={moment(from, 'DD.MM.YYYY').isValid() ? moment(from, 'DD.MM.YYYY').toDate() : null}
        startDate={moment(from, 'DD.MM.YYYY').isValid() ? moment(from, 'DD.MM.YYYY').toDate() : null}
        endDate={moment(to, 'DD.MM.YYYY').isValid() ? moment(to, 'DD.MM.YYYY').subtract(1, 'days').toDate() : null}
        onChange={([startDate, endDate]) => handleUrlChangeMultiple({
          from: moment(startDate).startOf('month').format('DD.MM.YYYY'),
          to: moment(endDate).add(1, 'month').startOf('month').format('DD.MM.YYYY'),
        })}
        inline
        selectsRange
        showMonthYearPicker
        showFullMonthYearPicker
      /> */}

      <Button.Raised onClick={fetch} text='Анализ' />

      <h4>Изглед</h4>
      <Inputs.Dropdown
        options={[
          { label: 'Година', value: 'years' },
          { label: 'Тримесечие', value: 'quarters' },
          { label: 'Месец', value: 'months' },
          { label: 'Ден', value: 'days' },
          { label: 'Час', value: 'hours' },
          { label: '15 Минути', value: 'minutes' },
        ]}
        value={tempType ?? type}
        onChange={({ value }) => handleUrlChangeMultiple({ tempType: value })}
      />

      <h4>Период</h4>
      <div className="row" style={{ width: "100%", justifyContent: 'space-between' }}>
        <div className="col">
          <span>От:</span>
          <Inputs.DatePicker
            value={startDate} startDate={startDate} endDate={endDate}
            onChange={(startDate) => handleUrlChangeMultiple({ from: moment(startDate).startOf('month').format('DD.MM.YYYY'), to })}
            selectsStart
            dateFormat="MMMM yyyy" showMonthYearPicker showFullMonthYearPicker
            popperContainer={({ children }) => (<Portal container={document.getElementById('calendar-portal')}>{children}</Portal>)}
          />
        </div>
        <div className="col">
          <span>До:</span>
          <Inputs.DatePicker
            value={endDate} startDate={startDate} endDate={endDate}
            onChange={(endDate) => handleUrlChangeMultiple({ to: moment(endDate).add(1, 'month').startOf('month').format('DD.MM.YYYY'), from })}
            selectsEnd
            dateFormat="MMMM yyyy" showMonthYearPicker showFullMonthYearPicker
            popperContainer={({ children }) => (<Portal container={document.getElementById('calendar-portal')}>{children}</Portal>)}
          />
        </div>
      </div>

      {clientsData?.length && <h4>Клиенти</h4>}
      {clientsData?.map(({ _id, fullName, objects }, i) => (
        <div key={`client-${i}`} className="single-client-container">
          {clientsData?.length && (
            <div className="single-client-header row">
              <Inputs.Checkboxes
                buttons={[{ label: ' ', value: objects?.length ?? 0 }]}
                value={[objects.filter(({ _id }) => selectedObjects.includes(_id)).length > 0 ? objects?.length : 0]}
                onClick={() => handleUrlChangeMultiple({
                  exclude: isEmpty(objects.filter(({ _id }) => exclude.includes(_id)))
                    ? [...(exclude), ...objects?.map(({ _id }) => _id)]
                    : (exclude).filter((_id) => !objects?.map(({ _id }) => _id).includes(_id))
                })}
              />
              <p className="client">{fullName}</p>
              <div
                className={`icon icon-arrow-right ${!expanded?.[_id] && 'show'}`}
                onClick={() => { setExpanded({ ...expanded, [_id]: !expanded?.[_id] }) }}
              />
            </div>
          )}
          <div className={`single-client-content ${!expanded?.[_id] && 'show'}`}>
            {/* <p className="client">Обекти</p> */}
            {!isEmpty(objects) ? (
              <Inputs.Checkboxes
                value={selectedObjects}
                onClick={(selected) => handleUrlChangeMultiple({
                  exclude: clientsData
                    ?.reduce((acc, { objects }) => [...acc, ...objects.map(({ _id }) => _id)], [])
                    ?.filter((_id) => !selected.includes(_id))
                })}
                buttons={objects?.map(({ _id, itn, measureType, STPMeasure }) => ({ value: _id, label: [itn, measureType?.name, STPMeasure].filter(Boolean).join(" - "), }))}
                col
              />
            ) : (
              'Клиента няма обекти'
            )}
          </div>
        </div>
      ))
      }
    </div >
  )
}

export default Filter
