import React from 'react'
import { Input } from '@makedonski/admin-ui'
import { Inputs } from '../../../'
import './styles.scss'
import { bn, mAdd } from 'utilities'

const Deposits = ({ invoice }) => {
  const { pricing } = invoice || {}
  const { extras = {} } = pricing || {}
  return <div className="invoices-forms-deposits-container">
    <div className="invoices-forms-deposits-header row">
      <h2>Депозити: </h2>

    </div>
    <div className="invoices-forms-deposits-content">
      <div className="invoices-forms-deposits-form-header row">
        {["Наименование", "Стойност"].map(label => <span key={`deposits-label-${label}`}>{label}</span>)}
      </div>
      {Object.entries(extras)?.map(([key, value], i) => (
        <div key={`deposits-${i}`} className="invoices-forms-deposits-single row">
          <div className="col"><Input.Text value={key} disabled /></div>
          <div className="col"><Inputs.TextLabeled label='лв.' value={value} disabled /></div>
        </div>
      ))}
    </div>
    <div className="invoices-forms-deposits-footer row">
      <p>{`Стойност на депозити`}</p>
      <Inputs.TextLabeled
        label="лв."
        value={Object.values(extras).reduce((acc, cur) => mAdd(acc, bn(cur)), 0) ?? '0'}
        disabled
      />
    </div>
  </div>
}

export default Deposits
