import React, { useState } from "react"
import { Draggable } from 'react-beautiful-dnd'
import Popup from 'reactjs-popup';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import TasksList from "./TasksList"
import { useQuery, emit } from "../helpers"
import "./styles.scss"
import 'reactjs-popup/dist/index.css';
import 'sweetalert2/src/sweetalert2.scss'

const Column = ({ index, column, setShowFilter, socket }) => {
    const { _id, title } = column || {}
    const { handleUrlChange } = useQuery()

    const [editTitle, setEditTitle] = useState(title)

    return <Draggable draggableId={_id} index={index}>
        {(provided) => <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            aria-label={`${_id} quote list`}
            className="task-management-column-container card">
            <div {...provided.dragHandleProps} className="task-management-column-header row">
                <h2>{title}</h2>
                <div className="row row-buttons">
                    <div className="tasks-icon tasks-icon-plus" onClick={() => handleUrlChange({ category: _id, showForm: true })} />
                    <div className="tasks-icon tasks-icon-filter" onClick={() => setShowFilter(true)} />
                    <Popup trigger={<div className="tasks-icon tasks-icon-settings"></div>} position="bottom center" nested>
                        {close => <div className="task-management-popup-column-container">
                            <div className="task-management-popup-column-header row">
                                <h2>Моля изберете</h2>
                                <div className="tasks-icon tasks-icon-close" onClick={close} />
                            </div>
                            <Popup
                                modal
                                trigger={<div className="row row-action"><p>Редактирай име</p><div className="tasks-icon tasks-icon-pen"></div></div>}
                                onClose={() => setEditTitle(title)}
                            >
                                {closeModal => <div className="task-management-modal-edit-column-container">
                                    <div className="task-management-modal-edit-column-header row ">
                                        <h2>Редактирай име на категория</h2>
                                        <div className="tasks-icon tasks-icon-close" onClick={closeModal} />
                                    </div>
                                    <div className="col">
                                        <span>Заглавие</span>
                                        <input
                                            className="input-text-container"
                                            value={editTitle || ""}
                                            onChange={({ target: { value } }) => setEditTitle(value)}
                                        />
                                    </div>
                                    <div className="row">
                                        <div
                                            className={`raised-button-container ${title === editTitle && 'disabled'}`}
                                            onClick={() => {
                                                emit(socket, { type: 'categories/updateOne', payload: { id: _id, title: editTitle } })
                                                close()
                                            }}
                                        >Запази</div>
                                    </div>
                                </div>}

                            </Popup>

                            <div className="line" />
                            <div className="row row-action" onClick={() => Swal.fire({
                                title: 'Сигурни ли сте?',
                                text: "Категорията ще бъде изтрита завинаги!",
                                icon: 'warning',
                                showCancelButton: true,
                                reverseButtons: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                cancelButtonText: 'Не',
                                confirmButtonText: 'Да'
                            }).then((result) => {
                                if (result.isConfirmed) emit(socket, { type: 'categories/deleteOne', payload: { categoryId: _id } })
                            })}><p>Изтрий категория</p><div className="tasks-icon tasks-icon-delete"></div></div>
                        </div>}
                    </Popup>
                </div>
            </div>
            <TasksList column={column} />
        </div>}
    </Draggable>


}

export default Column