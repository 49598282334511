import { default as Empoyees } from './Empoyees'
import { default as Reports } from './Reports'
import { default as Market } from './Market'
import { default as Invoices } from './Invoices'
import { default as Imports } from './Imports'
import { default as Nomenclatures } from './Nomenclatures'
import { default as IntermediateInvoices } from './IntermediateInvoices'
import { default as ErpMeasures } from './ErpMeasures'
import { default as Commissions } from './Commissions'
import { default as CompensationSettings } from './CompensationSettings'
import { default as InvoiceNumber } from './InvoiceNumber'
import { default as ObjectsIgnored } from './ObjectsIgnored'
import { default as ClientsGroups } from './ClientsGroups'

const References = {
  Empoyees,
  Reports,
  Market,
  Invoices,
  Imports,
  Nomenclatures,
  IntermediateInvoices,
  ErpMeasures,
  Commissions,
  CompensationSettings,
  InvoiceNumber,
  ObjectsIgnored,
  ClientsGroups
}

export default References
