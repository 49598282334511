import { commissionsTypes, referencesTypes } from '../actions'
import { pick } from 'lodash'

export const initialState = {
  reference: [],
  hasNextPage: false,
  nextPage: 1,
  totalDocs: 0,

  showCheckboxes: false,
  selectedCheckboxes: [],
  selectedGroups: []
}

const references = (state = initialState, { type, payload }) => {
  switch (type) {
    case referencesTypes.SET_FIELD:
      return { ...state, [payload?.field]: payload?.value }
    case referencesTypes.SET_FIELDS:
      return { ...state, ...payload }
    case referencesTypes.GET_REFERENCE_SUCCESS:
      const newData = payload?.page > 1 ? [...(state.reference || []), ...(payload?.docs || [])] : payload?.docs
      return {
        ...state,
        ...pick(
          payload,
          [
            'hasNextPage',
            'lastErrorUpdate',
            'nextPage',
            'shouldPreGenerate',
            'errors',
            "failedClients",
            'totalDocs'
          ]),
        reference: newData,
      }
    case commissionsTypes.GET_COMMISSIONS_REFERENCE_DETAILS_SUCCESS:
      const index = state.reference.findIndex(({ _id }) => _id === payload.client)
      const newReference = [...(state.reference || [])]
      newReference.splice(index, 1, { ...(newReference[index] || {}), objects: payload.clientCommissions })
      return { ...state, reference: newReference }
    case referencesTypes.GET_REFERENCE_READY_PARTIAL_SUCCESS:
      return {
        ...state,
        reference: [...state.reference, ...payload.clients],
        ...pick(payload, ['errors', 'failedClients'])
      }
    default:
      return state
  }
}

export default references
