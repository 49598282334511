import { Button } from '@makedonski/admin-ui'
import { Inputs } from "components"
import React, { useState } from "react"
import { Portal } from 'react-overlays'
import "./styles.scss"

const GenerateInvoices = ({ onConfirm, close }) => {
    const [helperDate, setHelperDate] = useState()
    return <div className="modal-generate-invoices-container">
        <div className="modal-generate-invoices-header row">
            <h2>Моля изберете начална дата на падеж</h2>
            <div className="icon icon-close" onClick={close} />
        </div>
        <div className="modal-generate-invoices-content row">
            <Inputs.DatePicker
                popperContainer={({ children }) => (
                    <Portal container={document.getElementById('calendar-portal')}>{children}</Portal>
                )}
                value={helperDate || new Date()}
                onChange={(value) => setHelperDate(value)} />
            <Button.Raised text="Потвърди" onClick={() => {
                onConfirm(helperDate)
                close()
            }} />
        </div>

    </div>
}

export default GenerateInvoices