import React, { useState, useMemo, useRef } from "react"
import { isEmpty } from "lodash"
import Inputs from "../Inputs"
import "./styles.scss"
import { emit, useCollapsable } from "../helpers"


const Checklist = ({ task, socket }) => {
    const { _id, completedAt, steps: checklist } = task || {}

    const completed = useMemo(() => checklist?.reduce((acc, { isCompleted }) => (isCompleted ? acc + 1 : acc), 0), [checklist])

    const [text, setText] = useState("")

    const collapsableRef = useRef(null)
    const [isExpanded, setExpanded] = useCollapsable(collapsableRef)

    return <div className="task-management-checklist-container col">
        <div className="task-management-checklist-header card ">
            <div
                className="task-management-checklist-progress"
                style={{ width: `${isEmpty(checklist) ? 0 : (completed / checklist.length) * 100}%` }}
            />
            <div className="row ">
                <p>Прогрес ({completed} / {checklist.length})</p>
                {!completedAt && <div className="tasks-icon-plus-white-container row card" onClick={() => setExpanded(!isExpanded)}>
                    <div className={`tasks-icon tasks-icon-plus-white ${isExpanded && 'rotate'}`} />
                </div>}
            </div>
        </div>
        <div className="task-management-checklist-content">
            {checklist?.map(({ _id, isCompleted, text }) => <div key={_id} className="single-checklist-item row">
                <Inputs.Checkboxes
                    buttons={["true"]}
                    value={isCompleted.toString()}
                    onClick={() => emit(socket, { type: 'steps/toggle', payload: { stepId: _id } })}
                />
                <span>{text}</span>
                <div className="tasks-icon tasks-icon-delete" onClick={() => emit(socket, { type: 'steps/deleteOne', payload: { stepId: _id } })} />
            </div>)}
        </div>
        <div className="task-management-checklist-footer row" ref={collapsableRef}>
            <input
                className="input-text-container"
                value={text}
                placeholder="Добави задача"
                onChange={({ target: { value } }) => setText(value)}
            />
            <div className={`raised-button-container clear`} onClick={() => {
                setExpanded(false)
                setText("")
            }}>Откажи</div>
            <div
                className={`raised-button-container ${!text && 'disabled'}`}
                onClick={() => {
                    emit(socket, { type: 'steps/create', payload: { task: _id, text } })
                    setExpanded(false)
                    setText("")
                }}
            >Добави</div>
        </div>
    </div >
}

export default Checklist