import React from "react"
import { Button } from '@makedonski/admin-ui'
import moment from 'moment'
import { useDispatch } from "react-redux"
import { startLoading, updateClient, updateObject } from "actions"
import { Client } from 'components'
import { useQuery } from 'hooks'
import "./styles.scss"

const text = { contractReceivedDate: "Получен", executionDate: 'Входиран', dpiGenerated: 'Изготвен', dpiSigned: "Подписан", objectedDate: 'Възрази' }

const ExecutionDate = ({ client, field, fetch }) => {
  const dispatch = useDispatch()

  const { type } = useQuery()
  // if (type !== 'register') return moment(client?.[field]).format('DD.MM.YYYY | HH:mm')

  if (!client?.[field]) return <div className="client-cells-execution-date-container">
    <Button.Raised text={text[field]} onClick={() => {
      const objects = client.objects.filter(({ status }) => ['registration', 'preregistration', 'transfer'].includes(status))
      dispatch(startLoading())
      if (field === 'executionDate' && objects.length) {
        objects.forEach(({ _id }, i) => dispatch(updateObject({
          data: { _id, status: 'documents-sent', documentsSentDate: moment() },
          onSuccess: () => {
            if (i !== objects.length - 1) return
            fetch({ pageOne: true })
          }
        })))
      } else {
        dispatch(updateClient({
          data: { _id: client?._id, [field]: moment() },
          onSuccess: () => fetch({ pageOne: true }),
        }))
      }
    }} />
  </div>

  return <Client.Cells.EditCell {...{ client, field, fetch }} showTime />
}

export default ExecutionDate