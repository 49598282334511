export const registerTypes = {
  DOCUMENTS_SEND_TO_ERP: 'register/DOCUMENTS_SEND_TO_ERP',
  IMPORT_CONFIRMATIONS_FROM_ERP: 'register/IMPORT_CONFIRMATIONS_FROM_ERP',
  IMPORT_COMMENTS_FROM_ERP: 'register/IMPORT_COMMENTS_FROM_ERP',
  IMPORT_OBLIGATIONS_FROM_ERP: 'register/IMPORT_OBLIGATIONS_FROM_ERP',
  SEND_OBLIGATIONS_EMAILS: 'register/SEND_OBLIGATIONS_EMAILS',
  REGISTER_CONFIRM_STATUSES: 'register/REGISTER_CONFIRM_STATUSES',
}

export const sendDocumentsToERP = (payload) => ({
  type: registerTypes.DOCUMENTS_SEND_TO_ERP,
  payload,
})

export const importConfirmationsFromErp = (payload) => ({
  type: registerTypes.IMPORT_CONFIRMATIONS_FROM_ERP,
  ...payload,
})
export const importCommentsFromErp = (payload) => ({
  type: registerTypes.IMPORT_COMMENTS_FROM_ERP,
  ...payload,
})
export const importObligationsFromErp = (payload) => ({
  type: registerTypes.IMPORT_OBLIGATIONS_FROM_ERP,
  ...payload,
})

export const sendObligationsEmails = (payload) => ({
  type: registerTypes.SEND_OBLIGATIONS_EMAILS,
  ...payload,
})

export const confirmStatusesRegister = (payload) => ({
  type: registerTypes.REGISTER_CONFIRM_STATUSES,
  ...payload,
})
