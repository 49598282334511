import { ofType, ActionsObservable } from 'redux-observable'
import { switchMap, catchError } from 'rxjs/operators'
import { from } from 'rxjs'
import { Alerts } from '@makedonski/socourt-utilities'
import { apisTypes, stopLoading } from '../actions'
import { APIS_API, APIS_CREDENTIALS } from '../config/settings'

const Api = {
  fetchToken: () => {
    const request = fetch(`${APIS_API}/account/token`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(APIS_CREDENTIALS),
    }).then((response) => response.json())
    return from(request)
  },
  fetchClient: (credentials, data) => {
    const request = fetch(`${APIS_API}/Data/Fetch/1`, {
      method: 'POST',
      headers: {
        Authorization: `bearer ${credentials.accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify([{ ident: data }]),
    }).then((response) => response.json())
    return from(request)
  },
}

export const startApisFetch = (action$) =>
  action$.pipe(
    ofType(apisTypes.START_APIS_FETCH),
    switchMap(({ payload }) =>
      Api.fetchToken().pipe(
        switchMap((response) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: apisTypes.FETCH_CLIENT_INFO,
              payload: { ...payload, credentials: response },
            })
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const fetchClientInfo = (action$) =>
  action$.pipe(
    ofType(apisTypes.FETCH_CLIENT_INFO),
    switchMap(({ payload: { credentials, data, onSuccess } }) =>
      Api.fetchClient(credentials, data).pipe(
        switchMap((response) =>
          ActionsObservable.create((obs) => {
            if (!response.length) Alerts.error('Няма данни за лицето!')
            else if (onSuccess) onSuccess(response)
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )
