import { ofType, ActionsObservable } from 'redux-observable'
import { switchMap, catchError } from 'rxjs/operators'
import { Api, Alerts } from '@makedonski/socourt-utilities'
import { intermediateInvoicesTypes, stopLoading } from '../actions'
import { URL } from '../config/settings'

export const getIntermediateInvoices = (action$) =>
    action$.pipe(
        ofType(intermediateInvoicesTypes.GET_INTERMEDIATE_INVOICES),
        switchMap(({ payload, onSuccess }) =>
            Api.post(`${URL}/invoices/advanceIntermediateReady`, JSON.stringify(payload)).pipe(
                switchMap(({ response }) =>
                    ActionsObservable.create((obs) => {
                        if (onSuccess) onSuccess(response.results)
                        obs.next(stopLoading())
                        obs.complete()
                    })
                ),
                catchError((err) =>
                    ActionsObservable.create((obs) => {
                        Alerts.error('Error!', err?.response?.error?.message || '')
                        obs.next(stopLoading())
                        obs.complete()
                    })
                )
            )
        )
    )

export const createIntermediateInvoices = (action$) =>
    action$.pipe(
        ofType(intermediateInvoicesTypes.CREATE_INTERMEDIATE_INVOICES),
        switchMap(({ payload, onSuccess }) =>
            Api.post(`${URL}/invoices/generationAdvanceIntermediate`, JSON.stringify(payload)).pipe(
                switchMap(({ response }) =>
                    ActionsObservable.create((obs) => {
                        if (onSuccess) onSuccess(response.invoices)
                        obs.next(stopLoading())
                        obs.complete()
                    })
                ),
                catchError((err) =>
                    ActionsObservable.create((obs) => {
                        Alerts.error('Error!', err?.response?.error?.message || '')
                        obs.next(stopLoading())
                        obs.complete()
                    })
                )
            )
        )
    )
