import React from 'react'
import { Popover } from '@varld/popover'
import { SingleShortcut } from './'
import { Popup } from '../../'
import './styles.scss'

const Shortcuts = ({}) => {
  const { shortcuts = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}] } = {}

  return (
    <div className="card-shortcuts-container">
      <div className="card-shortcuts-header row">
        <h3>Бързи достъпи</h3>
        <Popover className="header-settings" popover={({ close }) => <Popup.FilterShortcuts hide={close} />}>
          <div className="icon icon-filter" />
        </Popover>

        <div className="icon icon-add ripple" />
      </div>
      <div className="card-shortcuts-content row">
        {shortcuts?.map((shortcut, i) => (
          <SingleShortcut key={`shortcut-${i}`} />
        ))}
      </div>
    </div>
  )
}

export default Shortcuts
