import { default as Main } from './Main'
import { default as Client } from './Client'
import { default as Deposits } from './Deposits'
import { default as Object } from './Object'
import { default as Total } from './Total'
import { default as Pricing } from './Pricing'
import { default as InvoiceGroup } from './InvoiceGroup'
import { default as Intermediate } from './Intermediate'
import { default as IntermediatePricing } from './IntermediatePricing'
import { default as Leaving } from './Leaving'
import { default as Compensations } from './Compensations'
import { default as Interests } from './Interests'

const Forms = {
  Main,
  Client,
  Deposits,
  Total,
  Pricing,
  Object,
  InvoiceGroup,
  Intermediate,
  IntermediatePricing,
  Leaving,
  Compensations,
  Interests
}

export default Forms
