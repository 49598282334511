import React, { useEffect, useMemo } from 'react'
import { nanoid } from 'nanoid'
import { Button } from '@makedonski/admin-ui'
import { Headers, Alerts } from '@makedonski/socourt-utilities'
import moment from 'moment'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty, flatten } from 'lodash'
import {
  setModal,
  exportInvoicesGeneratedStats,
  startLoading,
  getReferenceInvoices,
  stopLoading,
  getReferenceObjectsIgnored,
  setReferencesFields,
  getCommissionsReference,
  sendCommissions,
  getCommissionsCreatedReference,
  getReferenceNomenclatures,
  exportInvoicesAIGeneratedStats,
  deleteCommission,
} from 'actions'
import { Inputs, References as ReferencesComp } from 'components'
import { URL } from 'config/settings'
import {
  referencesTypes,
  referencesReportsTypes,
  referencesReportsLength,
  referencesInvoicesTypes,
  referencesInvoicesLength,
  referenceMarketLength,
  referenceInvoicesFields,
  referencesIntermediateInvoicesTypes,
  referencesCommissionsTypes,
  invoiceNumberTypes,
  referenceIgnoredObjectsFields,
  commissionsReferenceCreatedFields,
  referenceNomenclaturesFields,
  invoicesSearchOptions,
} from 'config/constants'
import { useQuery } from 'hooks'
import { handleUploadedData, xlsxExport, renderCell, generateInvoiceInitial } from 'utilities'
import './styles.scss'

const References = ({ }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { type } = useParams()

  const { roles } = useSelector(({ general }) => general?.currentUser) || {}
  const permissions = useMemo(() => roles?.reduce((acc, { permissions }) => ({
    ...acc,
    ...Object.fromEntries(Object.entries(permissions).map(([key, value]) => {
      const values = flatten(roles.map(({ permissions }) => permissions[key]))
      return [key, values]
    }))
  }), {}), [roles])
  const referencesTypesFiltered = useMemo(() => {
    return referencesTypes.filter(({ value }) => !permissions || ['all', value].some(p => permissions?.references?.includes(p)))
  }, [permissions])

  //prettier-ignore
  const { filter: filterParam = '{}', searchBy = '', type: subType, length, startDate, endDate, byMonth, period, showErrors, erp, forceOneHour, exportSTP, handleUrlChange, handleUrlChangeMultiple, clear } = useQuery()
  const filter = useMemo(() => JSON.parse(filterParam), [filterParam])

  const { reference, errors, showCheckboxes, selectedCheckboxes, selectedGroups } = useSelector((state) => state.references)
  useEffect(() => {
    if (!referencesTypesFiltered.map(({ value }) => value).includes(type)) history.push('/references')
  }, [referencesTypesFiltered, type])


  // fetched in src/components/references/ErpMeasures
  const { erps } = useSelector(({ data }) => data)

  return (
    <div className={`screen-references-container ${type}`}>
      {showErrors ? (
        <div className="screen-references-header row" style={{}}>
          <h2>Преглед грешки</h2>
          <div
            className="icon icon-export"
            onClick={() =>
              xlsxExport({
                fields: referenceInvoicesFields.errors,
                data: errors,
                render: (row, value) => {
                  if (['eic'].includes(value)) return row?.client?.[value] || row?.client?.pin || ''
                  else if (['fullName'].includes(value)) return row?.client?.[value]
                  else if (['itn', 'error'].includes(value)) return row?.[value]
                  else row?.client?.[value] || row?.[value] || ''
                },
              })
            }
          />
        </div>
      ) : (
        <div className="screen-references-header row">
          <h2>Справка за</h2>
          <Inputs.Dropdown
            options={referencesTypesFiltered}
            value={type}
            onChange={({ value }) => history.push(`/references/${value}`)}
          />
          {type === 'employees' && (
            <Button.Raised
              text="Добави потребител"
              className="btn-add-employee"
              onClick={() => dispatch(setModal({ isOpen: true, type: 'newEmployee' }))}
            />
          )}
          {type === 'reports' && (
            <>
              <Inputs.RoundedButtons
                buttons={referencesReportsTypes}
                value={subType}
                onChange={({ value }) => {
                  clear()
                  handleUrlChangeMultiple({ type: value, length: 'all' })
                }}
              />
              <Inputs.RadioButtons
                buttons={referencesReportsLength}
                value={length}
                //prettier-ignore
                onClick={(value) => {
                  handleUrlChange('length', value)
                  if (value === 'all') handleUrlChangeMultiple({ startDate: null, endDate: null, byMonth: null })
                  else if (value === 'year') handleUrlChangeMultiple({ startDate: new Date(), endDate: new Date(), byMonth: null })
                  else if (value === 'month') handleUrlChangeMultiple({ startDate: new Date(), endDate: new Date(), byMonth: 'false' })
                }}
              />
              {length === 'year' ? (
                <Inputs.DatePicker
                  selected={startDate ? new Date(startDate) : null}
                  onChange={(value) => handleUrlChange('startDate', value)}
                  maxDate={new Date()}
                  dateFormat="yyyy"
                  showYearPicker
                  yearItemNumber={3}
                  className="year-picker"
                />
              ) : (
                length === 'month' && (
                  <Inputs.DatePicker
                    selected={startDate ? new Date(startDate) : null}
                    startDate={startDate ? new Date(startDate) : null}
                    endDate={endDate ? new Date(endDate) : null}
                    //prettier-ignore
                    onChange={([startDate, endDate]) => handleUrlChangeMultiple({ startDate, endDate })}
                    selectsRange
                    dateFormat={'MM/yyyy'}
                    showMonthYearPicker
                    showFullMonthYearPicker
                    shouldCloseOnSelect={false}
                    className="month-picker"
                  >
                    <Inputs.RadioButtons
                      //prettier-ignore
                      buttons={[{ label: 'Общо', value: 'false' }, { label: 'По месец', value: 'true' }]}
                      value={byMonth}
                      onClick={(value) => handleUrlChange('byMonth', value)}
                    />
                  </Inputs.DatePicker>
                )
              )}
              <div className="icon icon-export" />
            </>
          )}
          {type === 'invoices' && (
            <>
              <Inputs.RoundedButtons
                buttons={referencesInvoicesTypes}
                value={subType}
                onChange={({ value }) => {
                  if (value === 'generated') clear()
                  handleUrlChangeMultiple({ type: value, length: value === 'generated' ? 'month' : null })
                }}
              />
              {subType && (
                <>
                  {subType === 'generated' && (
                    <Inputs.RadioButtons
                      buttons={referencesInvoicesLength}
                      value={length}
                      //prettier-ignore
                      onClick={(value) => {
                        handleUrlChange('length', value)
                        if (value === 'all') handleUrlChangeMultiple({ startDate: null, endDate: null })
                        else if (['month', 'period'].includes(value)) handleUrlChangeMultiple({ startDate: new Date(), endDate: new Date() })
                      }}
                    />
                  )}
                  {[undefined, 'month'].includes(length) && (
                    <Inputs.DatePicker
                      selected={startDate ? new Date(startDate) : moment().subtract(1, 'months').toDate()}
                      onChange={(value) => handleUrlChange('startDate', value)}
                      maxDate={new Date()}
                      dateFormat={'MMMM'}
                      showMonthYearPicker
                      showFullMonthYearPicker
                      className="month-picker"
                    />
                  )}
                  <div className="row row-buttons">
                    {subType === 'generated' && length === 'month' && (
                      <div
                        className="icon icon-export"
                        onClick={() => {
                          dispatch(startLoading())
                          dispatch(
                            exportInvoicesGeneratedStats({
                              payload: {
                                from: moment(startDate).subtract(!startDate ? 1 : 0, 'months').startOf("month").toDate(),
                                to: moment(startDate).subtract(!startDate ? 1 : 0, 'months').endOf("month").add(1, 'milliseconds').toDate(),
                              },
                            })
                          )
                        }}
                      />
                    )}
                    {subType === 'not-generated' && (
                      <div
                        className="icon icon-export"
                        onClick={() => {
                          dispatch(startLoading())
                          dispatch(
                            getReferenceInvoices({
                              payload: {
                                type: "not-generated",
                                forExport: true,
                                from: moment(startDate).subtract(!startDate ? 1 : 0, 'months').startOf("month").toDate(),
                                to: moment(startDate).subtract(!startDate ? 1 : 0, 'months').endOf("month").add(1, 'milliseconds').toDate(),
                              },
                            })
                          )
                        }}
                      />
                    )}
                    {subType === 'ready' && (
                      <div
                        className="icon icon-export"
                        onClick={() => {
                          xlsxExport({
                            fields: referenceInvoicesFields.ready,
                            data: reference,
                            render: renderCell.references.invoices.readyExport,
                          })
                        }}
                      />
                    )}
                    {subType === 'differences' && (
                      <div
                        className="icon icon-export"
                        onClick={() =>
                          xlsxExport({
                            fields: referenceInvoicesFields.differences,
                            data: reference,
                            render: renderCell.references.invoices.differencesExport,
                          })
                        }
                      />
                    )}
                    <Button.Raised
                      className="btn-invoices-generate"
                      text="Генерирай"
                      disabled={subType !== 'ready' || isEmpty(selectedGroups)}
                      onClick={() => {
                        if (subType === 'ready') generateInvoiceInitial({
                          ids: selectedGroups,
                          from: moment(startDate).subtract(!startDate ? 1 : 0, 'months').startOf('month').toDate(),
                          to: moment(startDate).subtract(!startDate ? 1 : 0, 'months').startOf('month').add(1, 'months').toDate(),
                          onSuccess: () => history.push(`/financial/invoices?type=equalizing&month=${moment(startDate).subtract(!startDate ? 1 : 0, 'months').startOf('month').format('MM.YYYY')}`)
                        })
                      }}
                    />
                  </div>
                </>
              )}
            </>
          )}
          {type === "intermediateInvoices" && (
            <>
              <Inputs.RoundedButtons
                buttons={referencesIntermediateInvoicesTypes}
                value={subType}
                onChange={({ value }) => {
                  if (value === 'generated') clear()
                  handleUrlChangeMultiple({ type: value, length: value === 'generated' ? 'month' : null })
                }}
              />
              {subType && <>
                {subType === 'generated' && (
                  <Inputs.RadioButtons
                    buttons={referencesInvoicesLength}
                    value={length}
                    //prettier-ignore
                    onClick={(value) => {
                      handleUrlChange('length', value)
                      if (value === 'all') handleUrlChangeMultiple({ startDate: null, endDate: null })
                      else if (['month', 'period'].includes(value)) handleUrlChangeMultiple({ startDate: new Date(), })
                    }}
                  />
                )}
                {[undefined, 'month'].includes(length) && (
                  <Inputs.DatePicker
                    selected={startDate ? new Date(startDate) : moment().toDate()}
                    onChange={(value) => handleUrlChange('startDate', value)}
                    maxDate={new Date()}
                    dateFormat={'MMMM'}
                    showMonthYearPicker
                    showFullMonthYearPicker
                    className="month-picker"
                  />
                )}
              </>}
              <div className="row row-buttons">
                {subType === 'generated' && length === 'month' && <div className="icon icon-export" onClick={() => {
                  dispatch(startLoading())
                  dispatch(exportInvoicesAIGeneratedStats({
                    payload: {
                      from: moment(startDate).startOf("month").toDate(),
                      to: moment(startDate).endOf("month").add(1, 'milliseconds').toDate(),
                    },
                  }))
                }} />}
                <Button.Raised text='Генерирай' onClick={() => dispatch(setModal({
                  isOpen: true, type: 'intermediateInvoicesPrice', props: {
                    title: 'Настройка единична цена',
                    data: { date: startDate || moment().format('DD.MM.YYYY') },
                    validate: ({ text }) => text?.length,
                    onClick: ({ text: price, date: month, type, consecutiveNumber, advanceFirstDate, percentLastMonth }) => {
                      const url = new URLSearchParams()
                      url.append('type', type)
                      url.append('consecutiveNumber', consecutiveNumber)
                      url.append('advanceFirstDate', advanceFirstDate)
                      url.append('price', price)
                      url.append('monthYear', moment(month, 'DD.MM.YYYY').format('MM/YYYY'))
                      url.append('status', 'active')
                      if (percentLastMonth) url.append('percentLastMonth', percentLastMonth)
                      history.push('/intermediate-invoices/generate?' + url.toString())
                    }
                  }
                }))} />
              </div>
            </>
          )}
          {type === 'imports' && (
            <>
              <Inputs.RadioButtons
                buttons={referencesInvoicesLength}
                value={length}
                onClick={(value) => {
                  handleUrlChange('length', value)
                  if (value === 'all') handleUrlChangeMultiple({ startDate: null })
                  else if (['month', 'period'].includes(value)) handleUrlChangeMultiple({ startDate: moment().startOf('month').format('MM/YYYY') })
                }}
              />
              {['month'].includes(length) && (
                <Inputs.DatePicker
                  selected={startDate ? moment(startDate, 'MM/YYYY').toDate() : new Date()}
                  onChange={(value) => handleUrlChange('startDate', moment(value).format('MM/YYYY'))}
                  maxDate={new Date()}
                  dateFormat={'MM.yyyy'}
                  showMonthYearPicker
                  showFullMonthYearPicker
                  className="month-picker"
                />
              )}
            </>
          )}
          {type === 'market' && (
            <>
              <Inputs.RadioButtons
                buttons={referenceMarketLength}
                value={period}
                onClick={(value) =>
                  handleUrlChangeMultiple({
                    period: value,
                    startDate: moment().startOf(value).toDate(),
                    endDate: moment().endOf(value).toDate(),
                  })
                }
              />
              {period === 'year' ? (
                <Inputs.DatePicker
                  selected={new Date(startDate || moment().toDate())}
                  onChange={(value) => handleUrlChangeMultiple({ startDate: moment(value).startOf(period).toDate() })}
                  maxDate={new Date()}
                  dateFormat="yyyy"
                  showYearPicker
                  yearItemNumber={3}
                  className="year-picker"
                />
              ) : period === 'month' ? (
                <Inputs.DatePicker
                  selected={new Date(startDate || moment().toDate())}
                  onChange={(value) => handleUrlChangeMultiple({ startDate: moment(value).startOf(period).toDate() })}
                  maxDate={new Date()}
                  dateFormat={'MM.yyyy'}
                  showMonthYearPicker
                  showFullMonthYearPicker
                />
              ) : (
                <Inputs.DatePicker
                  selected={new Date(startDate || moment().toDate())}
                  onChange={(value) => handleUrlChangeMultiple({ startDate: moment(value).startOf(period).toDate() })}
                  maxDate={new Date()}
                />
              )}
              <Button.UploadButton
                text="Качи файлове"
                multiple
                accept={'.csv, .xlsx, .xls'}
                onChange={({ target: { files } }) => handleUploadedData({ files, dispatch, history, type })}
              />
            </>
          )}
          {type === 'nomenclatures' && (
            <div className='row row-buttons'>
              <div
                className="icon icon-export"
                onClick={() => dispatch(getReferenceNomenclatures({
                  onSuccess: ({ details }) => xlsxExport({
                    fields: [{ label: "ЕРП", value: 'erp' }, ...referenceNomenclaturesFields],
                    data: details,
                    render: renderCell.references.nomenclatures,
                  })
                }))}
              />
              <Button.UploadButton
                text="Качи файлове"
                multiple
                accept={'.csv, .xlsx, .xls'}
                onChange={({ target: { files } }) => handleUploadedData({ files, dispatch, history, type })}
              />
            </div>
          )}
          {type === 'erp-measures' && (
            <>
              <Inputs.RoundedButtons
                buttons={erps?.map(({ _id, name }) => ({ label: name, value: _id })) || []}
                value={erp}
                onChange={({ value }) => handleUrlChangeMultiple({ erp: value })}
              />
              <Inputs.DatePicker
                selected={new Date(startDate || moment().toDate())}
                onChange={(value) => handleUrlChangeMultiple({ startDate: moment(value).startOf(period).toDate(), createdAt: undefined })}
                maxDate={new Date()}
                dateFormat={'MM.yyyy'}
                showMonthYearPicker
                showFullMonthYearPicker
              />
              <div className="row row-export">
                <span>Експорт СТП:</span>
                <Button.Switch
                  isOn={exportSTP}
                  onChange={() => handleUrlChangeMultiple({ exportSTP: exportSTP ? undefined : true })}
                />
                <span>Експорт на час:</span>
                <Button.Switch
                  isOn={forceOneHour}
                  onChange={() => handleUrlChangeMultiple({ forceOneHour: forceOneHour ? undefined : true })}
                />
                <div className="icon icon-export" onClick={async () => {
                  try {
                    dispatch(startLoading())
                    const response = await fetch(`${URL}/imported-files/autoPeriod`, {
                      method: 'POST',
                      headers: Headers.getWithAuth(),
                      body: JSON.stringify({
                        from: moment(startDate).startOf('month'),
                        to: moment(startDate).add(1, 'months').startOf('month'),
                        erp,
                        forExport: true,
                        detailed: true,
                        forceOneHour,
                        exportSTP
                      }),
                    })
                    if (!response.ok) throw new Error('Грешка')
                    const blob = await response.blob()
                    const uril = window.URL.createObjectURL(blob)
                    let link = document.createElement("a");
                    link.href = uril;
                    link.download = `export-${moment(startDate).format("MM/YYYY")}-${nanoid()}.xlsx`.replaceAll('/', '-').replaceAll('_', '-');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  }
                  catch (error) { Alerts.error(error?.message) }
                  finally { dispatch(stopLoading()) }
                }} />
              </div>
            </>
          )}
          {type === 'commissions' && (
            <>
              <Inputs.RoundedButtons
                buttons={referencesCommissionsTypes}
                value={subType}
                onChange={({ value }) => {
                  clear()
                  handleUrlChangeMultiple({ type: value })
                  dispatch(setReferencesFields({ showCheckboxes: false, selectedCheckboxes: [] }))
                }}
              />
              {subType === 'generated' && <div className="row row-options">
                <Inputs.DatePicker
                  selected={startDate ? moment(startDate).toDate() : moment().subtract(1, 'months').toDate()}
                  onChange={(value) => {
                    handleUrlChange('startDate', value)
                    dispatch(setReferencesFields({ showCheckboxes: false, selectedCheckboxes: [] }))
                  }}
                  maxDate={new Date()}
                  dateFormat={'MMMM'}
                  showMonthYearPicker
                  showFullMonthYearPicker
                  className="month-picker"
                />
                {showCheckboxes
                  ? <div className='row row-buttons'>
                    <Button.Raised className="btn-cancel" text="Откажи"
                      onClick={() => dispatch(setReferencesFields({ showCheckboxes: false, selectedCheckboxes: [] }))}
                    />
                    <Button.Raised text="Изпрати"
                      onClick={() => {
                        dispatch(startLoading())
                        dispatch(sendCommissions({
                          payload: { ids: selectedCheckboxes }, onSuccess: () => {
                            dispatch(getCommissionsReference({ payload: { monthYear: startDate ? moment(startDate).format("MM/YYYY") : moment().subtract(1, 'months').format('MM/YYYY') }, }))
                            dispatch(setReferencesFields({ showCheckboxes: false, selectedCheckboxes: [] }))
                          }
                        }))
                      }}
                    />
                  </div>
                  : <Button.Raised text="Изпрати"
                    onClick={() => {
                      dispatch(startLoading())
                      dispatch(getCommissionsReference({ payload: { monthYear: startDate ? moment(startDate).format("MM/YYYY") : moment().subtract(1, 'months').format('MM/YYYY'), noPagination: true }, }))
                      dispatch(setReferencesFields({ showCheckboxes: { action: 'emails' } }))
                    }}
                  />
                }
              </div>}
              {subType === 'created' && (showCheckboxes
                ? <div className="row row-buttons">
                  <Button.Raised className="btn-cancel" text="Откажи"
                    onClick={() => dispatch(setReferencesFields({ showCheckboxes: false, selectedCheckboxes: [] }))}
                  />
                  <Button.Raised text="Изтрий"
                    disabled={!selectedCheckboxes.length}
                    onClick={() => dispatch(setModal({
                      isOpen: true, type: 'deleteCommission', props: {
                        onSuccess: (monthYear) => {
                          dispatch(startLoading())
                          dispatch(deleteCommission({
                            payload: { _id: selectedCheckboxes[0], monthYear },
                            onSuccess: () => {
                              dispatch(setReferencesFields({ showCheckboxes: false, selectedCheckboxes: [] }))
                              dispatch(getCommissionsCreatedReference())
                            }
                          }))
                        }
                      }
                    }))
                    }
                  />
                </div> : <div className="row row-buttons">
                  <Button.Raised text="Изтрий"
                    onClick={() => {
                      dispatch(startLoading())
                      dispatch(getCommissionsCreatedReference({ payload: { noPagination: true }, }))
                      dispatch(setReferencesFields({ showCheckboxes: { action: 'deleteCommission' } }))
                    }}
                  />
                  <div
                    className='icon icon-export'
                    onClick={() => {
                      dispatch(startLoading())
                      dispatch(getCommissionsCreatedReference({
                        payload: { noPagination: true }, onSuccess: ({ docs }) => xlsxExport({
                          fields: commissionsReferenceCreatedFields.filter(({ value }) => !['objects'].includes(value)),
                          data: docs,
                          render: renderCell.commissionsReference.createdExport,
                        })
                      }))
                    }}
                  />
                </div>)}
              {subType === 'payments' && <div className='row' style={{ marginLeft: 'auto' }}>
                <Inputs.DatePicker
                  selected={startDate ? moment(startDate).toDate() : moment().subtract(3, 'months').startOf('month').toDate()}
                  onChange={(value) => {
                    handleUrlChange('startDate', moment(value).startOf('month').toDate())
                    dispatch(setReferencesFields({ showCheckboxes: false, selectedCheckboxes: [] }))
                  }}
                  maxDate={new Date()}
                  dateFormat={'MMMM'}
                  showMonthYearPicker
                  showFullMonthYearPicker
                  className="month-picker"
                />
                <Inputs.DatePicker
                  selected={endDate ? moment(endDate).toDate() : moment().endOf('month').toDate()}
                  onChange={(value) => {
                    handleUrlChange('endDate', moment(value).endOf('month').toDate())
                    dispatch(setReferencesFields({ showCheckboxes: false, selectedCheckboxes: [] }))
                  }}
                  maxDate={new Date()}
                  dateFormat={'MMMM'}
                  showMonthYearPicker
                  showFullMonthYearPicker
                  className="month-picker"
                />
              </div>}
            </>
          )}
          {type === 'compensationSettings' && (
            <div
              className="icon icon-add"
              onClick={() => dispatch(setModal({
                isOpen: true, type: "compensationSettings", props: {
                  onSuccess: () => {
                    dispatch(setModal({ isOpen: false }))
                    handleUrlChangeMultiple({ toFetch: true })
                  }
                }
              }))}
            />
          )}
          {type === 'invoiceNumber' && (
            <>
              <Inputs.Dropdown
                options={invoiceNumberTypes}
                value={subType}
                onChange={({ value }) => {
                  clear()
                  handleUrlChangeMultiple({ type: value })
                }}
              />
              {subType === 'ajur' && <div className='row' style={{ marginLeft: 'auto' }}>
                <Inputs.DatePicker
                  selected={startDate ? new Date(startDate) : moment().subtract(1, 'months').toDate()}
                  onChange={(value) => handleUrlChange('startDate', value)}
                  maxDate={new Date()}
                  dateFormat={'MMMM'}
                  showMonthYearPicker
                  showFullMonthYearPicker
                  className="month-picker"
                />
                <Button.UploadButton
                  text="Импорт" accept={'.csv, .xlsx, .xls'}
                  onChange={({ target: { files } }) => {
                    handleUploadedData({
                      files,
                      dispatch,
                      history,
                      type: 'ajurNumbers',
                      additionalProps: {
                        startDate: startDate ? new Date(startDate) : moment().subtract(1, 'months').toDate()
                      },
                    })
                  }} />
              </div>}
            </>

          )}
          {type === 'objectsIgnored' && (
            <div
              className="icon icon-export"
              onClick={() => {
                dispatch(startLoading())
                dispatch(getReferenceObjectsIgnored({
                  payload: { pagination: false },
                  onSuccess: ({ docs }) => xlsxExport({
                    fields: referenceIgnoredObjectsFields,
                    data: docs,
                    render: renderCell.references.objectsIgnoredExport,
                  })
                }))
              }}
            />
          )}
          {type === 'clientsGroups' && <>
            <Inputs.SearchMulti
              search={filter?.[searchBy] || ''}
              searchBy={searchBy || 'eic'}
              handleChange={({ search, searchBy: newSearchBy }) => {
                const newFilter = { ...filter, [searchBy]: undefined, [newSearchBy]: search || undefined }
                handleUrlChangeMultiple({
                  searchBy: search ? newSearchBy : undefined,
                  filter: Object.values(newFilter).filter(Boolean).length ? JSON.stringify(newFilter) : undefined
                })
              }}
              options={invoicesSearchOptions}
              popupText={{ secondary: ' ' }}
            />
            <div className="row row-buttons">
              <div className="icon icon-add" onClick={() => dispatch(setModal({ isOpen: true, type: 'clientsGroupsForm' }))} />
            </div>
          </>}
        </div>
      )}
      <div className="screen-references-content row">
        {!type && <p className="empty">Изберете вида справка, която искате да визуализирате</p>}
        {type === 'employees' ? (
          <ReferencesComp.Empoyees />
        ) : type === 'reports' ? (
          <ReferencesComp.Reports />
        ) : type === 'market' ? (
          <ReferencesComp.Market />
        ) : type === 'invoices' ? (
          <ReferencesComp.Invoices />
        ) : type === 'imports' ? (
          <ReferencesComp.Imports />
        ) : type === 'nomenclatures' ? (
          <ReferencesComp.Nomenclatures />
        ) : type === 'intermediateInvoices' ? (
          <ReferencesComp.IntermediateInvoices />
        ) : type === 'erp-measures' ? (
          <ReferencesComp.ErpMeasures />
        ) : type === 'commissions' ? (
          <ReferencesComp.Commissions />
        ) : type === 'compensationSettings' ? (
          <ReferencesComp.CompensationSettings />
        ) : type === 'invoiceNumber' ? (
          <ReferencesComp.InvoiceNumber />
        ) : type === 'objectsIgnored' ? (
          <ReferencesComp.ObjectsIgnored />
        ) : type === 'clientsGroups' ? (
          <ReferencesComp.ClientsGroups />
        ) : null}
      </div>
    </div>
  )
}

export default References
