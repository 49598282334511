import { ofType, ActionsObservable } from 'redux-observable'
import { switchMap, catchError } from 'rxjs/operators'
import { Api, Alerts } from '@makedonski/socourt-utilities'
import { registerTypes, stopLoading } from '../actions'
import { URL } from '../config/settings'

export const sendDocumentsToERP = (action$) =>
  action$.pipe(
    ofType(registerTypes.DOCUMENTS_SEND_TO_ERP),
    switchMap(({ payload: { data, onSuccess } }) =>
      Api.post(`${URL}/clients/registration/send-files`, JSON.stringify(data)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next(stopLoading())
            if (onSuccess) onSuccess(response)
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )


export const importObligationsFromErp = (action$) =>
  action$.pipe(
    ofType(registerTypes.IMPORT_OBLIGATIONS_FROM_ERP),
    switchMap(({ payload, onSuccess }) =>
      Api.post(`${URL}/clients/registration/obligations`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next(stopLoading())
            if (onSuccess) onSuccess(response)
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const sendObligationsEmails = (action$) => action$.pipe(
  ofType(registerTypes.SEND_OBLIGATIONS_EMAILS),
  switchMap(({ payload, onSuccess }) =>
    Api.post(`${URL}/clients/registration/obligations/send`, JSON.stringify(payload)).pipe(
      switchMap(({ response }) => ActionsObservable.create((obs) => {
        if (onSuccess) onSuccess(response)
        obs.next(stopLoading())
        obs.complete()
      })),
      catchError((err) => ActionsObservable.create((obs) => {
        Alerts.error('Error!', err?.response?.error?.message || '')
        obs.next(stopLoading())
        obs.complete()
      }))
    )
  )
)

export const importConfirmationsFromERP = (action$) =>
  action$.pipe(
    ofType(registerTypes.IMPORT_CONFIRMATIONS_FROM_ERP),
    switchMap(({ payload, onSuccess }) =>
      Api.post(`${URL}/clients/registration/receive-files`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response)
            else obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            console.log(err)
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const importCommentsFromERP = (action$) =>
  action$.pipe(
    ofType(registerTypes.IMPORT_COMMENTS_FROM_ERP),
    switchMap(({ payload, onSuccess }) =>
      Api.post(`${URL}/comments/import`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response.payload)
            else obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const confirmStatusesRegister = (action$) =>
  action$.pipe(
    ofType(registerTypes.REGISTER_CONFIRM_STATUSES),
    switchMap(({ payload, onSuccess }) =>
      Api.post(`${URL}/clients/registration/confirm`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response)
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )
