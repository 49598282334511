import React, { useEffect, useState } from "react"
import { Button } from "@makedonski/admin-ui"
import "./styles.scss"
import { uploadFile } from "utilities"
import { getCommission, startLoading, stopLoading, updateCommissionSimple } from "actions"
import { useDispatch } from "react-redux"
import { useQuery } from "hooks"

const CommissionsUpload = ({ }) => {
  const dispatch = useDispatch()
  const [commission, setCommission] = useState()
  const [file, setFile] = useState()

  const { _id } = useQuery()
  useEffect(() => {
    if (_id) {
      dispatch(startLoading())
      dispatch(getCommission({ payload: _id, onSuccess: setCommission }))
    }
  }, [_id, dispatch])


  const handleUpload = async (files) => {
    dispatch(startLoading())
    const { location } = await uploadFile({ file: files[0], createId: true })
    setFile(location)
    dispatch(stopLoading())
  }

  const handleUpdate = () => {
    dispatch(startLoading())
    dispatch(updateCommissionSimple({ payload: { _id, uploadedFile: file, uploadedFileDate: new Date(), status: 'uploadedFile' }, onSuccess: setCommission, stopLoading: true }))
  }

  return <div className="screen-commissions-upload-container col">
    <div className="screen-commissions-upload-header row">
      <img alt="Logo" src={process.env.REACT_APP_LOGO ? process.env.REACT_APP_LOGO : require('../../assets/images/logo.png')} />
    </div>
    <div className="screen-commissions-upload-content row">
      <div className="col col-preview">
        Справка
        <object data={commission?.xlsxUrl} type="application/pdf" width="100%" height="100%">
          <p>Alternative text - include a link <a href="http://africau.edu/images/default/sample.pdf">to the PDF!</a></p>
        </object>
      </div>
      <div style={{ width: '2vw' }} />
      <div className="col">
        {commission?.uploadedFile
          ? <>
            Качен файл
            <object data={commission?.uploadedFile} type="application/pdf" width="100%" height="100%">
              <p>Alternative text - include a link <a href="http://africau.edu/images/default/sample.pdf">to the PDF!</a></p>
            </object>
          </>
          : file
            ? <>
              {file.split('/')[file.split('/').length - 1]}
              < Button.Raised text="Потвърди" onClick={handleUpdate} />
            </>
            : <Button.UploadButton
              text="Качи документ"
              onChange={({ target: { files } }) => handleUpload(files)}
            />
        }
      </div>
    </div>
  </div >
}

export default CommissionsUpload