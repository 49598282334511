import { Alerts, Api } from "@makedonski/socourt-utilities"
import { mergedObjectsTypes, stopLoading } from "actions"
import { URL } from "config/settings"
import { ofType, ActionsObservable } from "redux-observable"
import { catchError, switchMap } from "rxjs/operators"



export const getMergedObjects = (action$) => action$.pipe(
    ofType(mergedObjectsTypes.GET_MERGED_OBJECTS),
    switchMap(({ payload, onSuccess }) =>
        Api.get(`${URL}/mergedObjects/${payload}`).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            })
            )
        )
    )
)

export const createMergedObjects = (action$) => action$.pipe(
    ofType(mergedObjectsTypes.CREATE_MERGED_OBJECTS),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/mergedObjects`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            })
            )
        )
    )
)

export const updateMergedObjects = (action$) => action$.pipe(
    ofType(mergedObjectsTypes.UPDATE_MERGED_OBJECTS),
    switchMap(({ payload, onSuccess }) =>
        Api.put(`${URL}/mergedObjects`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            })
            )
        )
    )
)

export const deleteMergedObjects = (action$) => action$.pipe(
    ofType(mergedObjectsTypes.DELETE_MERGED_OBJECTS),
    switchMap(({ payload, onSuccess }) =>
        Api.delete(`${URL}/mergedObjects`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            })
            )
        )
    )
)

