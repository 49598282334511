import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'

import general from './general'
import data from './data'
import clients from './clients'
import invoices from './invoices'
import objects from './objects'
import notices from './notices'
import references from './references'
import annex from './annex'
import commissions from './commissions'
import producers from './producers'

export default combineReducers({
  routerReducer,
  general,
  data,
  clients,
  invoices,
  objects,
  notices,
  references,
  annex,
  commissions,
  producers
})
