import { ofType, ActionsObservable } from 'redux-observable'
import { switchMap, catchError } from 'rxjs/operators'
import { Api, Alerts } from '@makedonski/socourt-utilities'
import { offersTypes, clientsTypes, stopLoading } from '../actions'
import { URL } from '../config/settings'

export const createOffer = (action$) =>
  action$.pipe(
    ofType(offersTypes.CREATE_OFFER),
    switchMap(({ payload: { data, onSuccess } }) =>
      Api.post(`${URL}/offers`, JSON.stringify(data)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response.offer)
            obs.next({
              type: clientsTypes.UPLOAD_FILE_CLIENT,
              payload: {
                url: `${URL}/offers/file/${response.offer._id}`,
                name: `Oferta`,
                client: response.offer.client._id,
              },
            })
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )
