import Shared from "components/Shared"
import { modalEmailStatsFields, modalEmailStatsViewsFields } from "config/constants"
import { EMAIL_STATS_PASSWORD, EMAIL_STATS_USERNAME, EMAIL_STATS_URL } from "config/settings"
import moment from "moment"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { renderCell } from "utilities"
import "./styles.scss"

const EmailStats = ({ close, data }) => {
    const tableRef = useRef(null)
    const [docs, setDocs] = useState([])

    const fetchData = useCallback(async ({ identifier } = {}) => {
        const { payload: { data } } = await (await fetch(`${EMAIL_STATS_URL}/manage/stats`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Basic " + window.btoa(`${EMAIL_STATS_USERNAME}:${EMAIL_STATS_PASSWORD}`)
            },
            body: JSON.stringify({ identifier })
        })).json()
        setDocs((docs) => [...docs, ...data?.map((o) => ({ ...o, objects: o._views }))])
    }, [])

    useEffect(() => { data.forEach(fetchData) }, [data, fetchData])

    const ViewsTable = useCallback(({ row }) => <Shared.Table
        columns={modalEmailStatsViewsFields}
        data={row?.original?._views}
        renderCell={(row, field) => field === 'date' ? moment(row?.field).format('DD.MM.YYYY | HH:mm') : row?.[field]}
        containerStyle={{ maxHeight: 50 }}
        fixedWidth
    />, [])

    return <div className="modal-email-stats-container">
        <div className="modal-email-stats-header row">
            <h2>Статистика имейл</h2>
            <div className="icon icon-close" onClick={close} />
        </div>
        <div className="modal-email-stats-content">
            <Shared.Table
                ref={tableRef}
                fixedWidth
                columns={modalEmailStatsFields}
                data={docs}
                renderCell={(row, field) => renderCell.modalEmailStats(row, field, {
                    toggleRowExpanded: tableRef.current.toggleRowExpanded
                })}
                maxExpandHeight={150}
            >{ViewsTable}</Shared.Table>
        </div>
    </div >
}

export default EmailStats