import React, { useState, useEffect } from 'react'
import { Button } from '@makedonski/admin-ui'
import { useDispatch } from 'react-redux'
import { getUsersGroup } from 'actions'
import './styles.scss'
import { NavLink } from 'react-router-dom'

const UsersGroups = ({ _id, hide }) => {
    const dispatch = useDispatch()
    const [group, setGroup] = useState()
    useEffect(() => { dispatch(getUsersGroup({ payload: _id, onSuccess: (data) => setGroup(data) })) }, [])

    return (
        <div className="card-users-groups-container">
            <div className="card-users-groups-header row">
                <h3>{group?.name}</h3>
                <Button.Icon name="plus" size={28} onClick={hide} />
            </div>
            <div className="card-users-groups-content">
                <div className="border"></div>
                <div className="users-container">
                    <p>Служители:</p>
                </div>
                {group?.users?.map(({ _id, fullName }, i) => (
                    <div key={`user-${i}`} className="users-container row">
                        <NavLink to={`/employee/${_id}`}>
                            <div
                                className="icon icon-folder-custom"
                                onClick={() => dispatch(setOverlay({ isOpen: false }))}
                            />
                        </NavLink>
                        <p>{fullName}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default UsersGroups
