import { ofType, ActionsObservable } from 'redux-observable'
import { switchMap, catchError } from 'rxjs/operators'
import { Api, Alerts } from '@makedonski/socourt-utilities'
import { invoiceGroupsTypes, stopLoading } from 'actions'
import { URL } from '../config/settings'

export const getInvoiceGroupByClient = (action$) =>
  action$.pipe(
    ofType(invoiceGroupsTypes.GET_INVOICE_GROUPS_BY_CLIENT),
    switchMap(({ payload, onSuccess }) =>
      Api.get(`${URL}/invoiceGroups/${payload}`).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response?.invoiceGroups)
            obs.next({
              type: invoiceGroupsTypes.GET_INVOICE_GROUPS_BY_CLIENT_SUCCESS,
              payload: response?.invoiceGroups,
            })
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const createInvoiceGroup = (action$) =>
  action$.pipe(
    ofType(invoiceGroupsTypes.CREATE_INVOICE_GROUP),
    switchMap(({ payload, onSuccess }) =>
      Api.post(`${URL}/invoiceGroups`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response?.invoiceGroup)
            obs.next({
              type: invoiceGroupsTypes.GET_INVOICE_GROUPS_BY_CLIENT,
              payload: response.invoiceGroup.client._id,
            })
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            const message = err?.response?.error?.message
            if (message?.includes('duplicate key error collection'))
              Alerts.error('Моля изберете друго име!', 'Вече съществува група с това име за клента.' || '')
            else Alerts.error('Error!', message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )
export const updateInvoiceGroup = (action$) =>
  action$.pipe(
    ofType(invoiceGroupsTypes.UPDATE_INVOICE_GROUP),
    switchMap(({ payload, onSuccess }) =>
      Api.put(`${URL}/invoiceGroups`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response?.invoiceGroup)
            obs.next({
              type: invoiceGroupsTypes.GET_INVOICE_GROUPS_BY_CLIENT,
              payload: response.invoiceGroup.client._id,
            })
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )
export const deleteInvoiceGroup = (action$) =>
  action$.pipe(
    ofType(invoiceGroupsTypes.DELETE_INVOICE_GROUP),
    switchMap(({ payload, onSuccess }) =>
      Api.delete(`${URL}/invoiceGroups`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: invoiceGroupsTypes.GET_INVOICE_GROUPS_BY_CLIENT,
              payload: response.invoiceGroup.client._id,
            })
            if (onSuccess) onSuccess(response?.invoiceGroup)
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )
