import React, { useMemo } from "react"
import Select from 'react-select'
import AsyncSelect from 'react-select/async';
import { AsyncPaginate } from 'react-select-async-paginate';

const DropdownSelect = ({ async, paginate, disabled, invalid, fixedHight = true, options, rawValue, value, isMulti, ...props }) => {
  const selectedValue = useMemo(() => {
    if (isMulti) return value?.map((value) => options?.find((opt) => opt?.value === value)) ?? []
    else return options?.find((opt) => opt?.value === value) ?? {}
  }, [isMulti, options, value])

  const styles = {
    container: (provided) => ({ ...provided, pointerEvents: 'auto' }),
    multiValueRemove: (provided) => ({ ...provided, pointerEvents: disabled ? 'none' : 'auto' }),
    control: (provided) => ({
      ...provided,
      height: fixedHight ? 36 : 'auto',
      minHeight: fixedHight ? 36 : 'auto',
      borderRadius: 6,
      borderColor: invalid ? '#fa4444' : 'rgba(0,52,112,0.3)',
      '&:hover': { borderColor: invalid ? '#fa4444' : 'rgba(0,52,112,0.3)' },
      boxShadow: 'none',
    }),
    menu: (provided) => ({ ...provided, margin: 0 }),
    menuPortal: (provided) => ({ ...provided, zIndex: 100 }),
    valueContainer: (provider) => fixedHight ? { ...provider, flexWrap: 'no-wrap', overflow: 'auto' } : provider,
    multiValue: (provider) => fixedHight ? { ...provider, minWidth: 'none' } : provider
  }

  return <div className="inputs-dropdown-select-container" style={{ margin: '10px 0' }}>
    {async && paginate
      ? <AsyncPaginate
        styles={styles}
        menuPlacement="auto"
        options={options}
        value={rawValue || selectedValue}
        isMulti={isMulti}
        isDisabled={disabled}
        menuPortalTarget={document.body}
        {...props} />
      : async
        ? <AsyncSelect
          styles={styles}
          menuPlacement="auto"
          options={options}
          value={rawValue || selectedValue}
          isMulti={isMulti}
          isDisabled={disabled}
          menuPortalTarget={document.body}
          {...props} />
        : <Select
          styles={styles}
          menuPlacement="auto"
          options={options}
          value={selectedValue}
          isMulti={isMulti}
          isDisabled={disabled}
          menuPortalTarget={document.body}
          {...props} />
    }
  </div>
}

export default DropdownSelect