import React from 'react'
import { Input } from '@makedonski/admin-ui'
import { Inputs } from '../../../'
import './styles.scss'
import moment from 'moment'

const Main = ({ invoice, handleChange, showRequired }) => {
  const isRequired = (field) => showRequired && !invoice?.[field] && 'required'

  return (
    <div className="invoices-forms-main-container" style={{ zIndex: (invoice?.objects?.length ?? 0) + 8 }}>
      <div className="invoices-forms-main-header row">
        <h2>Основна информация</h2>
      </div>
      <div className="invoices-forms-main-content">
        <div className="row">
          <div className="col">
            <span>За месец</span>
            <Inputs.DatePicker
              value={invoice?.invoicePeriodStart || ''}
              onChange={(date) => handleChange({
                invoicePeriodStart: moment(date).startOf('month').toDate(),
                invoicePeriodEnd: moment(date).add(1, 'months').startOf('month').toDate()
              })}
              dateFormat={'MMMM yyyy'}
              className={`month-picker ${isRequired('invoicePeriodStart')}`}
              showMonthYearPicker
              showFullMonthYearPicker
              disabled={invoice?._id}
            />
          </div>
          {/* <div className="col">
            <span>№ на фактура</span>
            <Input.Text
              value={invoice?.invoiceNumber || ''}
              onChange={({ target: { value } }) => handleChange({ invoiceNumber: value })}
              inputClassName={`${isRequired('invoiceNumber')}`}
            />
          </div> */}
          <div className="col">
            <span>Дата на издаване</span>
            <Inputs.DatePicker
              value={invoice?.invoiceDate || ''}
              onChange={(date) => handleChange({ invoiceDate: date })}
              className={`${isRequired('invoiceDate')}`}
              disabled={invoice?._id}
            />
          </div>
          <div className="col">
            <span>Дата на падеж</span>
            <Inputs.DatePicker
              value={invoice?.dateOfPayment || ''}
              onChange={(date) => handleChange({ dateOfPayment: date })}
              className={`${isRequired('dateOfPayment')}`}
            />
          </div>
          {/* <div className="col">
            <span>Доставка до</span>
            <Inputs.DatePicker
              value={invoice?.deliveryEnd || ''}
              onChange={(date) => handleChange({ deliveryEnd: date })}
              className={`${isRequired('deliveryEnd')}`}
            />
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default Main
