import React, { useState, useMemo, useEffect, useRef, forwardRef } from 'react'
import { Input, Button } from '@makedonski/admin-ui'
import { isEmpty, pick } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { startLoading, createContract, updateClient, getData } from 'actions'
import { Inputs, Client, Shared } from 'components'
import { productsPricesFields, clientsObjectsFields } from 'config/constants'
import { renderCell } from 'utilities'
import './styles.scss'

//prettier-ignore
const requiredFields = ["type", "version", "contractDate", "contractNumber", "daysNotice", "maturity", "deliveryStart", "deliveryEnd", "paymentDaysType", "paymentDays", "priceType"]
//prettier-ignore
const clientRequiredFields = ['fullName', 'bulstat', 'city', 'address', 'phoneNumber', 'email', 'product']
//prettier-ignore
const contractSettingsFields = ['type', 'version', 'contractDate', 'contractNumber', 'maturity', 'contractDurationMonths', 'deliveryEnd', 'autoSign', 'paymentDaysType', 'paymentDays', 'terms']

const Contract = forwardRef(({ client: clientProp, handleSuccess, data: dataProp, editable: editableProp }, ref) => {
  const editable = editableProp === undefined ? true : editableProp

  const clientRef = useRef()
  const [clientEdit, setClientEdit] = useState()
  const client = clientEdit || clientProp
  console.log(client);
  const handleClientChange = (field, value) => setClientEdit({ ...client, [field]: value })
  const handlePriceChange = (field, value) => handleClientChange('price', { ...client?.price, [field]: value })

  const initialData = useMemo(
    () =>
      dataProp || {
        client: client?._id,
        type: 'Комбиниран',
        contractDate: new Date(),
        maturity: 'Фиксиран край',
        contractDurationMonths: null,
        paymentDaysType: 'Календарни',
        paymentDays: 7,
        priceType: 'Фиксирана',
        autoSign: false,
        terms: false,
        article1: false,
        erpGenerateDeclaration: false,
        penalty: false,
        deposit: false,
        erp: [...new Set(client?.objects?.map(({ erp }) => erp?._id) || [])],
        objects: client?.objects?.map(({ _id }) => _id),
        intermediateInvoicing: false,
      },
    [dataProp, clientProp]
  )

  const [data, setData] = useState(initialData)
  const handleChange = (field, value) => setData({ ...data, [field]: value })
  useEffect(() => {
    setData(initialData)
  }, [initialData])

  useEffect(() => {
    setData({
      ...initialData,
      ...pick(client?.contractSettings, contractSettingsFields),
      version: client?.contractSettings?.version?._id,
    })
  }, [clientProp])

  const { data: options } = useSelector((state) => state)
  const dispatch = useDispatch()
  useEffect(() => {
    const dropdowns = ['versions', 'erps', 'products']
    dropdowns.forEach((dropdown) => !options?.[dropdown] && dispatch(getData(dropdown)))
  }, [])

  const [showRequired, setShowRequired] = useState(false)
  const isRequired = (field) =>
    showRequired && !data?.[field] && !data?.priceMetaData?.[field] && !client?.[field] && 'required'
  const isValid = () => {
    if (requiredFields.some((field) => !data?.[field])) return false
    if (!data?.contractDurationMonths && !data?.deliveryEnd) return false
    if (data?.priceType === 'Индексирана' && !data?.priceMetaData?.market) return false
    if (isEmpty(data?.erp)) return false
    if (clientEdit) {
      if (clientRequiredFields.some((field) => !clientEdit?.[field])) return false
      if (!clientEdit.eic && !clientEdit.pin) return false
      if (
        productsPricesFields
          .filter(({ types }) => types.includes(options?.products?.find(({ _id }) => client?.product === _id)?.name))
          .map(({ value }) => value)
          .some((field) => !client?.price?.[field])
      )
        return false
    }
    return true
  }

  const onClear = () => {
    setData(initialData)
    setShowRequired(false)
    clientRef.current.onRequired(false)
  }
  const onSave = () => {
    if (!isValid()) {
      setShowRequired(true)
      clientRef.current.onRequired(true)
      return
    } else {
      const payload = { data: { ...data, product: client?.product }, onSuccess: (data) => handleSuccess(data) }
      dispatch(startLoading())
      if (clientEdit) dispatch(updateClient({ data: clientEdit, onSuccess: () => dispatch(createContract(payload)) }))
      else dispatch(createContract(payload))
    }
  }

  const version = useMemo(
    () => options?.versions?.find(({ _id }) => _id === data?.version)?.name?.split(' ')[0],
    [data?.version]
  )
  useEffect(() => {
    if (['2.19', '6.20', '6.20.1'].includes(version)) handleChange('terms', true)
  }, [version])

  return (
    <div className="client-forms-contract-container">
      <h2>Основна информация</h2>
      <div className="row row-contract-type">
        <div className="col col-radio-buttons">
          <span>Тип договор</span>
          <Inputs.RadioButtons
            buttons={['Комбиниран', 'Стандартен']}
            value={data?.type}
            onClick={(value) => setData({ ...data, type: value, version: null })}
            disabled={!editable}
          />
        </div>
        <div className="col">
          <span>Версия договор</span>
          <Inputs.Dropdown
            options={options?.versions
              ?.filter(({ type }) => type === data?.type)
              ?.map(({ _id, name }) => ({ value: _id, label: name }))}
            onChange={({ value }) => handleChange('version', value)}
            value={data?.version}
            className={`${isRequired('version')}`}
            disabled={!editable}
          />
        </div>
        <div className="col">
          <span>Дата на договор</span>
          <Inputs.DatePicker
            value={data?.contractDate}
            onChange={(value) => handleChange('contractDate', value)}
            className={`${isRequired('contractDate')}`}
          />
        </div>
        <div className="col">
          <span>№ на договор</span>
          <Input.Text
            value={data?.contractNumber || ''}
            onChange={({ target: { value } }) => handleChange('contractNumber', value)}
            inputClassName={`${isRequired('contractNumber')}`}
            disabled={!editable}
          />
        </div>
        <div className="col">
          <span>Дни предизвестие</span>
          <Input.Text
            value={data?.daysNotice || ''}
            onChange={({ target: { value } }) => {
              if (!/^\d+$/.test(value) && value !== '') return
              handleChange('daysNotice', value)
            }}
            inputClassName={`${isRequired('daysNotice')}`}
            disabled={!editable}
          />
        </div>
      </div>
      <div className="row row-contract-length">
        <div className="col col-radio-buttons">
          <span>Вид срочност на договор</span>
          <Inputs.RadioButtons
            buttons={['Фиксиран край', 'Брой месеци']}
            value={data?.maturity}
            onClick={(value) => setData({ ...data, maturity: value, contractDurationMonths: null, deliveryEnd: null })}
            disabled={!editable}
          />
        </div>
        <div className="col">
          <span>Срок на договор</span>
          {data?.maturity === 'Брой месеци' && (
            <Input.Text
              value={data?.contractDurationMonths || ''}
              onChange={({ target: { value } }) => {
                if (!/^\d+$/.test(value) && value !== '') return
                handleChange('contractDurationMonths', value)
              }}
              inputClassName={`${isRequired('contractDurationMonths')}`}
              disabled={!editable}
            />
          )}
        </div>
        <div className="col">
          <span>Начало на доставка</span>
          <Inputs.DatePicker
            value={data?.deliveryStart}
            onChange={(value) => handleChange('deliveryStart', value)}
            className={`${isRequired('deliveryStart')}`}
            disabled={!editable}
          />
        </div>
        <div className="col">
          <span>Край на доставка</span>
          <Inputs.DatePicker
            value={data?.deliveryEnd}
            onChange={(value) => handleChange('deliveryEnd', value)}
            className={`${isRequired('deliveryEnd')}`}
            disabled={!editable}
          />
        </div>
        <div className="col">
          <span>Автоматично преподписване</span>
          <Button.Switch isOn={data?.autoSign} onChange={() => editable && handleChange('autoSign', !data?.autoSign)} />
        </div>
      </div>
      <div className="row row-contract-expire">
        <div className="col col-radio-buttons">
          <span>Дни падеж</span>
          <Inputs.RadioButtons
            buttons={['Работни', 'Календарни']}
            value={data?.paymentDaysType}
            onClick={(data) => handleChange('paymentDaysType', data)}
            disabled={!editable}
          />
        </div>
        <div className="col">
          <span></span>
          <Input.Text
            value={data?.paymentDays || ''}
            onChange={({ target: { value } }) => {
              if (!/^\d+$/.test(value) && value !== '') return
              handleChange('paymentDays', value)
            }}
            inputClassName={`${isRequired('paymentDays')}`}
            disabled={!editable}
          />
        </div>
        <div className="col">
          <span>Общи условия</span>
          <Button.Switch isOn={data?.terms} onChange={() => editable && handleChange('terms', !data?.terms)} />
        </div>
        <div className="col">
          <span>Приложение 1</span>
          <Button.Switch isOn={data?.article1} onChange={() => editable && handleChange('article1', !data?.article1)} />
        </div>
        <div className="col">
          <span>Генериране декларация ЕРП</span>
          <Button.Switch
            isOn={data?.erpGenerateDeclaration}
            onChange={() => editable && handleChange('erpGenerateDeclaration', !data?.erpGenerateDeclaration)}
          />
        </div>
        <div className="col">
          <span>Неустойка</span>
          <Button.Switch isOn={data?.penalty} onChange={() => editable && handleChange('penalty', !data?.penalty)} />
        </div>
        <div className="col">
          <span>Депозит</span>
          <Button.Switch isOn={data?.deposit} onChange={() => editable && handleChange('deposit', !data?.deposit)} />
        </div>
      </div>
      <div className="row row-price-type">
        <div className="col col-radio-buttons">
          <span>Вид цена</span>
          <Inputs.RadioButtons
            buttons={['Фиксирана', 'Индексирана']}
            value={data?.priceType}
            onClick={(data) => handleChange('priceType', data)}
            disabled={!editable}
          />
        </div>
        <div className="col">
          <span>ЕРП</span>
          <Inputs.Select
            className="erps-container"
            editable={isEmpty(client?.objects) && editable}
            options={options?.erps}
            value={data?.erp}
            handleChange={(value) => handleChange('erp', value)}
            showRequired={showRequired}
            text={{ main: 'ЕРП-та', secondary: 'Избери ЕРП/та' }}
            search
          />
        </div>
        {data?.priceType === 'Фиксирана' ? (
          <div className="col">
            <span>Продукт</span>
            <Inputs.Dropdown
              options={options?.products?.map(({ _id, name }) => ({ label: name, value: _id }))}
              onChange={({ value }) => {
                const product = options?.products?.find(({ _id }) => _id === value)?.name
                setClientEdit({ ...client, product: value, price: product === 'Борса' ? { market: 0 } : null })
              }}
              value={client?.product?._id || client?.product}
              className={`${isRequired('product')}`}
              disabled={!editable}
            />
          </div>
        ) : (
          <div className="col">
            <span>Цена борса</span>
            <Inputs.TextLabeled
              value={data?.priceMetaData?.market || ''}
              onChange={({ target: { value } }) => {
                if (!/^\d+(\.||\.\d{0,2})?$/.test(value) && value !== '') return
                handleChange('priceMetaData', { market: value })
              }}
              inputClassName={`${isRequired('market')}`}
              disabled={!editable}
            />
          </div>
        )}
      </div>
      {data?.priceType === 'Фиксирана' && (
        <div className="row">
          <Client.Forms.ProductPrices
            products={options?.products}
            data={client}
            handleChange={handlePriceChange}
            showRequired={showRequired}
            editable={editable}
          />
        </div>
      )}

      <h2>За клиента</h2>
      <Client.Forms.Main
        ref={clientRef}
        handleChange={(field, value) => null && handleClientChange(field, value)}
        data={client}
      />

      {!isEmpty(client?.objects) && (
        <>
          <h2>Обекти</h2>
          <Shared.Table
            fixedWidth
            columns={clientsObjectsFields}
            data={client?.objects}
            renderCell={renderCell.objects}
          />
        </>
      )}

      <div className="row row-buttons">
        <div className="icon icon-add" />
        <span>Търговец</span>
        <div className="buttons row">
          <Button.Raised className="btn-cancel" text="Откажи" onClick={onClear} />
          <Button.Raised className="btn-save" text="Запази и продължи" onClick={onSave} />
        </div>
      </div>
    </div>
  )
})

export default Contract
