import React, { useState, useEffect, useMemo, useRef } from 'react'
import * as Async from 'awaity'
import * as XLSX from 'xlsx'
import { Button } from '@makedonski/admin-ui'
import { Auth, Headers, Alerts } from '@makedonski/socourt-utilities'
import moment from 'moment-timezone'
import { useSelector, useDispatch } from 'react-redux'
import { isEmpty, isDate, chunk, flatten, partition, mapKeys, groupBy, omit, mapValues, pick, isNaN } from 'lodash'
import { useHistory } from 'react-router-dom'
import {
  startLoading,
  stopLoading,
  setLoadingText,
  setModal,
  setDataFields,
  getObjectsCount,
  updateInvoiceProgress,
  importConfirmationsFromErp,
  importCommentsFromErp,
  importMarketData,
  createNomenclature,
  importLeaving,
  getReferenceInvoicesDifferences,
  checkNetworkImportsExist,
  deleteNetworkImport,
  importObligationsFromErp,
  importDeposits,
  confirmInterests,
  confirmInterestsPayments,
  sendObligationsEmails,
  updateInvoiceMany,
  importProducersData,
  importAjurNumbers,
  exportAjurNumbers,
  importInvoicePayments,
  importInterestsPayments,
} from 'actions'
import { Shared, Inputs } from 'components'
import { URL } from 'config/settings'
import {
  invoicesEqualizingFields,
  filePreviewMissingFields,
  filePreviewPeriodFields,
  filePreviewProgressFields,
} from 'config/constants'
import {
  renderCell,
  scaleNumber,
  combineDateAndTime,
  handleSendInvoice,
  addBusinessDays,
  xlsxExport,
  getTextForConfirmationModal,
  mDivide
} from 'utilities'
import './styles.scss'

const typeMap = {
  network: 'Мрежови данни',
  period: 'Почасови данни',
  stpData: 'СТП данни',
  invoices: 'Генерирани фактури',
  progress: 'Сравнение данни',
  confirmations: 'Списък с потвърждения',
  comments: 'Списък с коментари',
  obligations: 'Списък със задължения',
  leaving: 'Списък с напускащи',
  market: 'Борса',
  stp: 'СТП Коефициент',
  nomenclatures: 'Номенклатури',
  deposits: 'Депозити',
  interests: 'Лихви',
  invoicePayments: 'Плащания по фактури',
  interestsPaymentsOld: 'Плащания по лихви',
  interestsPayments: 'Плащания по лихви',
  massEditInvoices: 'Фактури - Масова редакция',
  manualOverride: 'Презаписване на данни',
  stpSummarized: 'СТП обобщени данни',
  esoData: "Цени на ЕСО",
  producerForecast: "Прогноза",
  customGroupData: "Цени на група",
  customIndividualData: 'Цени индивидуални',
  customProducerPricing: "Почасова цена клиент",
  ajurNumbers: "Номерация Ажур"
}

const FilePreview = ({ }) => {
  const tableRef = useRef(null)
  const { parsed, showCheckboxes, selectedCheckboxes } = useSelector((state) => state.data)
  const { type, erp, stp, files = [], startDate } = parsed || {}

  const history = useHistory()
  const dispatch = useDispatch()
  useEffect(() => () => dispatch(setDataFields({ parsed: null, showCheckboxes: false, selectedCheckboxes: [] })), [])

  const [selected, setSelected] = useState({})

  // prettier-ignore
  const itns = useMemo(() => [...new Set(flatten(files?.map(
    ({ rawData, parsedData }) => rawData ? rawData?.data?.map((r) => r['Идентификационен код']) : parsedData?.map(({ itn }) => itn)
  )))], [files]
  )
  const [stats, setStats] = useState({})
  const [filter, setFilter] = useState(null)
  useEffect(() => {
    if (isEmpty(files)) history.goBack()
    else if (type === 'invoices') {
      const [pregenerated, generated] = partition(flatten(data), ({ pregenerated }) => pregenerated)
      setStats({ ...stats, pregenerated, generated })
    } else if (isEmpty(itns.filter(Boolean))) return
    else {
      dispatch(
        getObjectsCount({
          payload: { erp, itns },
          onSuccess: ({ missing, imported }) => setStats({ ...stats, missing, imported }),
        })
      )
    }
  }, [files])

  useEffect(() => {
    if (type !== 'network') return
    if (!isEmpty(itns)) {
      const lastDate = data.reduce((maxDate, row) => {
        const date = moment(row?.['Отчетен период до'], 'DD.MM.YYYY')
        return date.isAfter(maxDate) ? date : maxDate
      }, moment(data?.at(0)?.['Отчетен период до'], 'DD.MM.YYYY')).toDate()
      const from = moment.tz(lastDate, 'Europe/Sofia').startOf('month').add(1, 'h')
      const to = moment.tz(lastDate, 'Europe/Sofia').endOf('month').add(1, 'day').startOf('month')
      dispatch(
        checkNetworkImportsExist({
          payload: { from, to, itns },
          onSuccess: ({ existingData }) =>
            existingData &&
            Alerts.confirm(
              {
                title: 'Повторен импорт',
                text: 'Данните ще бъдат дублирани. Искате ли да се презапишат?',
                confirm: 'Презаписване',
                cancel: 'Дублиране',
              },
              (isDelete) => isDelete && dispatch(deleteNetworkImport({ payload: { from, to, itns } }))
            ),
        })
      )
    }

    // deleteNetworkImport
  }, [itns])

  const fields = useMemo(() => {
    if (filter === 'missing') return filePreviewMissingFields
    else if (type === 'invoices') return [{ label: 'Прегенерирана', value: 'pregenerated', size: 200 }, ...invoicesEqualizingFields]
    else if (['period', 'stpData'].includes(type)) return filePreviewPeriodFields
    else if (type === 'progress') return filePreviewProgressFields
    else if (files?.[0]?.rawData) return files[0].rawData.fields.map((value) => ({ label: value, value }))
    return Object.keys(
      files.reduce((acc, file) => ({ ...acc, ...file.parsedData.reduce((acc, row) => ({ ...acc, ...row }), {}) }), {})
    ).map((value) => ({ label: value, value }))
  }, [type, files, filter])

  const data = useMemo(() => {
    if (isEmpty(fields)) return []
    else if (filter === 'missing') return stats.missing
    else if (['invoices'].includes(type)) return files
    else if (['progress'].includes(type)) return files.map((f) => Object.keys(selected).includes(f._id) ? { ...f, newValueKey: selected[f._id] } : f)
    return flatten(files.map(({ parsedData, rawData }) =>
      rawData
        ? rawData.data.filter((row) => !filter || stats[filter].includes(row.itn || row['Идентификационен код']))
        : parsedData.filter((row) => !filter || stats[filter].includes(row.itn || row['Идентификационен код']))
    ))
  }, [fields, filter, selected])

  const total = useMemo(() => {
    if (!data || ['period', 'stpData'].includes(type)) return data?.reduce((sum, row) => sum + row?.total || 0, 0)
    else if (type === 'invoices') return data?.reduce((sum, { pricing }) => sum + pricing?.withVatAndInterests || pricing?.withVat || 0, 0)
    const field = 'Стойност лв'
    return data.reduce((acc, cur) => ([field].includes(cur[field]) ? acc + 0 : acc + Number(cur[field] || 0)), 0)
  }, [data])

  const consumption = useMemo(() => {
    if (!data || ['period', 'stpData'].includes(type)) return data?.reduce((sum, row) => sum + row?.total || 0, 0)
    const field = 'Количество (кВтч/кВАрч)'
    // prettier-ignore
    const valid = ['Пренос през електропреносната мрежа', 'Пренос през преносната мрежа', 'Пренос ВН', 'Пренос и достъп през ЕПМ-ВН']
    return data
      .filter((row) => valid.includes(row['Тарифа/Услуга']))
      .reduce((acc, cur) => ([field].includes(cur[field]) ? acc + 0 : acc + Number(cur[field] || 0)), 0)
  }, [data])

  const month = useMemo(() => {
    if (isEmpty(data)) return ''
    const firstDate = moment(data?.at(0)?.['Отчетен период до'] || data?.at(0)?.from, 'DD.MM.YYYY')
    return data.reduce((maxDate, row) => {
      const date = moment(row?.['Отчетен период до'] || row?.from, 'DD.MM.YYYY')
      return date.isAfter(maxDate) ? date : maxDate
    }, firstDate).toDate()
  }, [])


  const handleUpload = async () => {
    dispatch(startLoading())
    try {
      // prettier-ignore
      const uploaded = await Async.reduce([...files], async (data, file, i) => [...data, await uploadSingleFile(file, i)], [])
      if (uploaded.some(({ error }) => error)) {
        dispatch(stopLoading())
        Alerts.error('Грешка при импортирането на файлове!', uploaded.map(({ error }) => error).filter(Boolean).join(',\n'))
      }
      else if (['period', 'stpData'].includes(type)) {
        const lastDate = moment.tz(files?.at(0)?.parsedData?.at(0)?.from, 'Europe/Sofia')
        const from = moment.tz(lastDate, 'Europe/Sofia').startOf('month').add(1, 'h')
        const to = moment.tz(lastDate, 'Europe/Sofia').endOf('month').add(1, 'day').startOf('month')
        dispatch(
          getReferenceInvoicesDifferences({
            payload: { itns, erp, from, to },
            onSuccess: (progress) => {
              setFilter(null)
              if (!isEmpty(progress)) dispatch(setDataFields({ parsed: { type: 'progress', erp, files: progress } }))
              else dispatch(setModal(summaryModal()))
            },
          })
        )
      } else dispatch(setModal(summaryModal()))
    } catch (e) {
      console.log(e)
      Alerts.error('Грешка при импортирането на файловете!')
    } finally {
      if (!['period', 'stpData'].includes(type)) dispatch(stopLoading())
      dispatch(setLoadingText('Loading...'))
    }
  }

  const uploadSingleFile = async ({ parsedData, fileName }, i) =>
    new Promise(async (resolve) => {
      try {
        if (type === 'network') {
          let lastDate
          Object.values(parsedData).forEach((item) => {
            ; (item['Техническа част'] || item['Разпределение']).forEach((obj) => {
              Object.entries(obj).forEach(([key, value]) => {
                if (key === 'Отчетен период до') {
                  const itemDate = moment.tz(value, 'D.M.YYYY', 'Europe/Sofia')
                  if (!lastDate || itemDate.isAfter(lastDate)) {
                    lastDate = itemDate
                  }
                }
              })
            })
          })
          const chunks = chunk(Object.keys(parsedData), 100)
          const transmittedChunks = await Async.reduce(
            chunks,
            async (transmittedChunks, chunk, n) => {
              // prettier-ignore
              const text = `Loading... \n File ${i + 1}/${files.length} ${chunks.length > 1 ? `${scaleNumber(n, 0, chunks.length, 0, 100).toFixed(2)}%` : ''}`
              dispatch(setLoadingText(text))
              const payload = {}
              chunk.forEach((client) => (payload[client] = parsedData[client]))
              return [...transmittedChunks, await uploadChunk(payload, fileName, lastDate)]
            },
            []
          )
          return resolve(transmittedChunks)
        } else if (['period', 'stpData'].includes(type)) {
          const chunks = chunk(parsedData, 100)
          const transmittedChunks = await Async.reduce(
            chunks,
            async (transmittedChunks, chunk, n) => {
              // prettier-ignore
              const text = `Loading... \n File ${i + 1}/${files.length} ${chunks.length > 1 ? `${scaleNumber(n, 0, chunks.length, 0, 100).toFixed(2)}%` : ''}`
              dispatch(setLoadingText(text))
              return [
                ...transmittedChunks,
                await uploadChunk(
                  chunk?.map((itn) => ({
                    ...itn,
                    manualOverride: itn?.name === 'MANUAL OVERRIDE' ? true : undefined,
                  })),
                  fileName
                ),
              ]
            },
            []
          )
          return resolve(transmittedChunks)
        } else resolve(null)
      } catch (e) {
        return resolve({ error: e })
      }
    })

  const uploadChunk = async (payload, fileName, lastDate) =>
    new Promise(async (resolve, reject) => {
      const url = `${URL}/parser/${type === 'network' ? 'upload-network' : 'period-data'}`
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: Auth.isAuthenticated ? Headers.getWithAuth() : Headers.get(),
          body: JSON.stringify({ data: payload, erp, type, fileName, lastDate }),
        })
        const { error, ...res } = await response.json()
        if (error) throw new Error()
        else return resolve(res)
      } catch (e) {
        return reject(fileName)
      }
    })

  const handleConfirm = () => {
    const [period, network] = partition(
      data.map((obj) => ({ ...obj, valueKey: selected[obj._id] || obj.valueKey })),
      ({ valueKey }) => valueKey === 'periodValue'
    )
    if (isEmpty(selected)) dispatch(setModal(summaryModal({ Почасови: period.length, Мрежови: network.length })))
    else {
      dispatch(
        updateInvoiceProgress({
          payload: { updates: Object.keys(selected).map((_id) => ({ _id, valueKey: selected[_id] })) },
          onSuccess: () => dispatch(setModal(summaryModal({ Почасови: period.length, Мрежови: network.length }))),
        })
      )
    }
  }

  const handleConfirmations = () => {
    const itnFields = ['Идентификатор на точката на измерване /номер място на доставка/', 'TO', 'УИН', 'Точка на доставка']
    const payload = data?.map((row) => row[itnFields?.filter((f) => row[f])])
    dispatch(
      importConfirmationsFromErp({
        payload: { erp, itns: payload, date: date || moment().add(1, 'months').startOf('month').toDate() },
        onSuccess: ({ toReceived, toReceivedMissing, notFound }) => dispatch(setModal({
          isOpen: true,
          type: 'confirmation',
          props: {
            title: 'Потвърждение на клиенти',
            children: <div style={{ width: '100%', maxHeight: 300, overflow: "auto" }}>
              {getTextForConfirmationModal([
                { text: "Потвърдени в системата:", inner: `${toReceived?.length ?? 0} точки` },
                { text: `Непотвърдени: - ${toReceivedMissing?.length ?? 0} точки - `, inner: `${toReceivedMissing?.join(", ") || ""}` },
                { text: `Ненамерени: - ${notFound?.length ?? 0} точки - `, inner: `${notFound?.join(", ") || ""}` },
              ])}
            </div>,
            onClick: () => history.goBack(),
          },
        })),
      })
    )
  }

  const handleComments = () => {
    const payload = data?.map((row) => ({ itn: row['УИН'] || row['ИТН'], comment: row['КОМЕНТАР'] }))
    dispatch(importCommentsFromErp({ payload: { comments: payload }, onSuccess: () => history.goBack() }))
  }

  const handleObligations = () => {
    const payload = { erp, itns: data?.map((row) => row[['Точка на доставка', 'УИН']?.filter((f) => row[f])]).filter(Boolean) }
    if (erp === 'ЕВН') payload.clients = data?.map((row) => row[['ЕИК']?.filter((f) => row[f])])
    dispatch(startLoading())
    dispatch(importObligationsFromErp({
      payload, onSuccess: ({ obligations, notFoundClients } = {}) => {
        let [found, notFound] = partition(obligations, ({ found }) => found)
        dispatch(setModal({
          isOpen: true,
          type: 'confirmation',
          props: {
            title: 'Импортиране на задължения',
            children: <div style={{ width: '100%', maxHeight: 300, overflow: "auto" }}>
              {getTextForConfirmationModal([
                { text: "Задължения:", inner: erp === 'ЕВН' ? `${[...new Set(found?.map(({ client }) => client))].length} клиента` : `${found?.length} точки` },
                { text: "Ненамерени:", inner: erp === 'ЕВН' ? `${notFoundClients?.map(({ eic }) => eic)?.join(", ") || `0 клиенти`}` : `${notFound?.map(({ itn }) => itn)?.join(", ") || `0 точки`}` },
              ])}
            </div>,
            buttons: <div className="row" style={{ justifyContent: 'space-between' }}>
              <Button.Raised text="Към регистрации" className="cancel" onClick={() => {
                dispatch(setModal({ isOpen: false }))
                history.goBack()
              }} />
              <div style={{ heigh: 1, width: 10 }}></div>
              <Button.Raised text="Изпрати имейли" onClick={() => dispatch(setModal({
                isOpen: true, type: 'obligationsDate', props: {
                  onSuccess: (date) => {
                    dispatch(startLoading())
                    dispatch(sendObligationsEmails({
                      payload: { erp, itns: found?.map(({ itn }) => itn), date }, onSuccess: () => dispatch(setModal({
                        isOpen: true, type: 'confirmation',
                        props: { title: 'Успешно изпратени имейли', onClick: () => history.goBack(), onRequestClose: () => history.goBack() }
                      }))
                    }))
                  }
                }
              }))} />
            </div>
          },
        }))
      }
    }))
  }

  const [isFinalLeaving, setIsFinalLeaving] = useState(false)
  const [objectionsDate, setObjectionsDate] = useState(addBusinessDays(moment(), 1).toDate())
  const handleLeaving = () => {
    const itnFields = ['Идентификатор на точката на измерване /номер място на доставка/', 'TO', 'УИН', 'Точка на доставка']
    const reasonFields = ['Причина за напускане', 'Причина', 'Вид смяна', 'Смяна име']
    const objects = data?.map((row) => {
      const reasonForLeaving = row[reasonFields?.filter((f) => row[f])]
      return {
        itn: row[itnFields?.filter((f) => row[f])],
        reasonForLeaving: ["смяна собственик / ползвател (титуляр по договор)", "Да"].includes(reasonForLeaving) ? "Смяна титуляр"
          : ["смяна Доставчик/КБГ"].includes(reasonForLeaving) || !reasonForLeaving ? "Смяна доставчик" : "",
      }
    })
    dispatch(startLoading())
    dispatch(importLeaving({
      payload: { erp, objects, isFinal: isFinalLeaving, date: date || moment().add(1, 'months').startOf('month').toDate(), objectionsDate },
      onSuccess: (leaving) => {
        const [found, notFound] = partition(leaving, ({ found }) => found)
        dispatch(setModal({
          isOpen: true,
          type: 'confirmation',
          props: {
            title: 'Напускащи клиенти',
            children: <div style={{ width: '100%', maxHeight: 300, overflow: "auto" }}>
              {getTextForConfirmationModal([
                { text: "Напускащи:", inner: `${found?.length} точки` },
                { text: "Ненамерени:", inner: `${notFound?.map(({ itn }) => itn)?.join(", ") || `0 точки`}` },
              ])}
            </div>,
            onClick: () => {
              history.push(`/clients?type=leaving`)
              // if (isFinalLeaving) history.push(`/clients?type=leaving`)
              // else {
              //   const url = new URLSearchParams()
              //   url.append('ids', found.filter(({ allAreLeaving }) => allAreLeaving).map(({ client }) => client))
              //   url.append('monthYear', moment(date || objectionsDate).format('MM/YYYY'))
              //   history.push(`/deposits/create?` + url.toString())
              // }
            },
          },
        }))
      },
    }))
  }

  const handleSTP = async () => {
    dispatch(startLoading())
    const profiles = {
      'Food_Agriculture': 'ERM_B1',
      'High Tech': 'ERM_B2',
      'Low Tech': 'ERM_B3',
      'Retail_Daily Business': 'ERM_B4',
      'Evening Business': 'ERM_B5',
      Households_Urban: 'ERM_H1',
      Households_Rural: 'ERM_H2',
      'Public lighting': 'ERM_S1',
    }
    let payload = data?.map((row) => {
      if (erp === 'ЧЕЗ') {
        return {
          date: moment.tz(combineDateAndTime(row['Дата'], row['Час']), 'Europe/Sofia').format(),
          [profiles[Object.keys(profiles).find((p) => ![undefined, null].includes(row[p]))]]:
            row[Object.keys(profiles).find((p) => ![undefined, null].includes(row[p]))],
        }
      } else {
        const obj = mapKeys(row, (value, key) => (['Време', 'Дата и Час', 'tm'].includes(key) ? 'date' : key))
        return { ...obj, date: moment.tz(moment(obj.date).add(30, 'seconds').startOf('minute'), 'Europe/Sofia').format() }
      }
    })
    const is15MinutesInterval = payload.slice(0, 10).some(({ date }) => moment(date).format("mm") === '15')
    payload = groupBy(payload, ({ date }) => moment(date).format('DD.MM.YYYY'))
    const totalGrouped = {}
    Object.keys(payload).forEach((item, n) => {
      const profiles = Object.keys(payload[item].reduce((a, c) => ({ ...a, ...c }), {})).filter(k => k !== 'date')
      const grouped = erp === 'ЕВН'
        ? profiles.reduce((acc, p) => ({ ...acc, [p]: payload[item].map(o => pick(o, [p, 'date'])) }), {})
        : groupBy(payload[item], (o) => Object.keys(omit(o, 'date')).at(0))
      totalGrouped[item] = mapValues(grouped, (value) => value.map(({ date, ...o }, i) => {
        if (is15MinutesInterval) return { ...o, date: moment.tz(moment(date), 'Europe/Sofia').startOf("day").add(15 * i + (n === 0 ? 15 : 0), 'minutes').format() }
        return { ...o, date: moment.tz(moment(date), 'Europe/Sofia').startOf("day").add(i, 'hours').format() }
      }))
    })
    payload = flatten(Object.keys(totalGrouped).reduce((acc, key) => [...acc, ...Object.values(totalGrouped[key])], []))
    payload = groupBy(payload, ({ date }) => date)
    payload = Object.keys(payload).reduce((acc, key) => [...acc, {
      date: key,
      profiles: payload[key].reduce((acc2, cur) => ({ ...acc2, ...omit(cur, 'date') }), {})
    }], [])
    const chunks = chunk(payload, 1000)
    try {
      await Async.reduce([...chunks], async (chunks, chunk) => [...chunks, await sendSTPCoefficient(chunk)], [])
      dispatch(setModal({
        isOpen: true,
        type: 'confirmation',
        props: { title: 'СТП Коефициент', children: 'Успешно качване на файл', onClick: () => history.goBack() },
      }))
    } catch (e) {
      console.log(e)
      Alerts.error('Грешка при изпращането на СТП коефициентите!')
    } finally {
      dispatch(stopLoading())
    }
  }

  const sendSTPCoefficient = (chunk) =>
    new Promise(async (resolve, reject) => {
      const url = `${URL}/stp-data/upload`
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: Auth.isAuthenticated ? Headers.getWithAuth() : Headers.get(),
          body: JSON.stringify({ erp, data: chunk }),
        })
        const { error } = await response.json()
        if (error) throw new Error()
        else return resolve()
      } catch (e) {
        console.log(e)
        return reject()
      }
    })

  const handleMarket = () => {
    const payload = flatten(files?.map(({ parsedData }) => parsedData)).reduce((acc, cur) => ({ ...acc, ...cur }), {})
    dispatch(startLoading())
    dispatch(
      importMarketData({
        payload: { data: payload },
        onSuccess: () => dispatch(setModal({
          isOpen: true,
          type: 'confirmation',
          props: { title: 'Борса', children: 'Успешно качване на файл', onClick: () => history.goBack() },
        })),
      })
    )
  }

  const [date, setDate] = useState()
  const handleNomenclatures = () => {
    const keyMap = {
      ЕРП: 'erp',
      Код: 'code',
      Наименование: 'name',
      'Изписани в мрежовите фактури': 'nameInInvoice',
      Цена: 'price',
      undefined: '',
    }
    const payload = data.map((row) => mapKeys(row, (value, key) => keyMap[key]))
    dispatch(
      createNomenclature({
        payload: { validFrom: date, details: payload },
        onSuccess: () => history.goBack(),
      })
    )
  }

  const handleDeposits = () => {
    const keyMap = {
      'Име на фирма': 'fullName',
      'ЕИК': 'eic',
      "Фактура номер/Кр. дата": 'description',
      "Сума": 'amount',
    }
    const payload = data.map((row) => mapKeys(row, (value, key) => keyMap[key]))
    dispatch(startLoading())
    dispatch(importDeposits({ payload, onSuccess: (res) => onDepositsAndInterestsSuccess(res) }))
  }

  const handleInterests = () => {
    const keyMap = {
      'ЕИК': 'eic',
      'Сума': 'amount',
    }
    const payload = data.map((row) => mapKeys(row, (value, key) => keyMap[key]))

    dispatch(setModal({
      isOpen: true, type: 'inputText', props: {
        title: "Текст във фактура",
        defaultValue: "Неустойка за просрочено плащане към дата",
        onSuccess: (description) => {
          dispatch(startLoading())
          dispatch(confirmInterests({ payload: payload.map((p) => ({ ...p, description })), onSuccess: (res) => onDepositsAndInterestsSuccess(res) }))
        }
      }
    }))
  }

  const onDepositsAndInterestsSuccess = ({ data }) => {
    const [errors, successful] = partition(data, ({ error }) => error)
    dispatch(setModal({
      type: 'confirmation', isOpen: true, props: {
        title: `Импорт на ${typeMap[type].toLowerCase()}`,
        children: <div className="col" style={{ alignItems: 'center' }}>
          <p>Успешно импортирани: {successful.length}</p>
          <div className="row">
            <p>Грешки: {errors.length}</p>
            <div
              style={{ height: 35, width: 35, border: "2px solid #002157", backgroundColor: "#ffffff", borderRadius: 6, backgroundSize: "70%", marginLeft: 15 }}
              className="icon icon-export"
              onClick={() => xlsxExport({
                fields: [{ label: 'ЕИК/ЕГН', value: 'eic' }, { label: 'Грешка', value: 'error' }],
                data: errors,
                render: (row, field) => row?.[field] || "-"
              })}
            />
          </div>
        </div>,
        onClick: () => {
          history.goBack()
          dispatch(setModal({ isOpen: false }))
        },
        onRequestClose: () => {
          history.goBack()
          dispatch(setModal({ isOpen: false }))
        }
      }
    }))
  }

  const handleInterestsPaymentsOld = () => {
    const keyMap = {
      'ЕИК': 'eic',
      // 'Контрагент': '',
      // 'Контрагент Име': '',
      '№ документ/удостоверение': 'documentNumber',
      'Дата документ/удостоверение': 'documentDate',
      'Сума в лева': 'amount',
    }
    const payload = data.map((row) => mapKeys(row, (value, key) => keyMap[key]))
    dispatch(startLoading())
    dispatch(confirmInterestsPayments({ payload, onSuccess: (res) => onDepositsAndInterestsSuccess({ data: res }) }))
  }

  const handleMassEditInvoices = () => {
    const keyMap = { 'Номер фактура': 'invoiceNumber', 'ИТН': 'itn', 'Цена': 'newPrice' }
    const payload = data.map((row) => mapKeys(row, (value, key) => keyMap[key]))
    dispatch(startLoading())
    dispatch(updateInvoiceMany({
      payload,
      onSuccess: () => dispatch(setModal({
        isOpen: true,
        type: 'confirmation',
        props: {
          title: 'Успешна редакция на фактури',
          onClick: () => history.goBack(),
          onRequestClose: () => history.goBack()
        },
      })),
    }))
  }

  const handleInvoicePayments = () => {
    const keyMap = { 'Сума': 'amount', 'Аналитична сметка': 'details', 'Документ No / дата': 'documentNumberAndDate' }
    const payload = data.map((row) => mapKeys(row, (value, key) => keyMap[key]))
      .map(({ documentNumberAndDate, details, amount }) => ({
        amount: Number(amount?.replace(/,/g, "")),
        // documentNumber: documentNumberAndDate.split('/')[0].trim(),
        documentDate: moment.tz(documentNumberAndDate.split('/')[1].trim(), 'DD.MM.YYYY', 'Europe/Sofia').endOf('day').format(),
        ajur: details?.split(';')?.[0]?.trim() ?? '',
        invoiceNumber: details?.split(';')?.[2]?.trim() ?? '',
      }))
    dispatch(startLoading())
    dispatch(importInvoicePayments({ payload, onSuccess: ({ errors = [], noErrors = [] }) => onInvoiceAndInterestsPaymentsSuccess({ errors, noErrors }) }))
  }
  const handleInterestsPayments = () => {
    const keyMap = { 'Контрагент': 'ajur', 'Контрагент Име': 'fullName', '№ документ/удостоверение': 'documentNumber', 'Дата документ/удостоверение': 'documentDate', 'Сума лв.': 'amount' }
    const payload = data.map((row) => mapKeys(row, (value, key) => keyMap[key])).map(({ amount, ...row }) => ({
      ...row,
      amount: Number(amount?.replace(/,/g, "")),
    }))
    dispatch(startLoading())
    dispatch(importInterestsPayments({ payload, onSuccess: ({ errors = [], noErrors = [] }) => onInvoiceAndInterestsPaymentsSuccess({ errors, noErrors }) }))
  }

  const onInvoiceAndInterestsPaymentsSuccess = ({ errors, noErrors }) => {
    dispatch(setModal({
      type: 'confirmation', isOpen: true, props: {
        title: `Импорт на ${typeMap[type].toLowerCase()}`,
        children: <div className="col" style={{ alignItems: 'center' }}>
          <p>Успешно импортирани: {noErrors.length}</p>
          <div className="row">
            <p>Грешки: {errors.length}</p>
            <div
              style={{ height: 35, width: 35, border: "2px solid #002157", backgroundColor: "#ffffff", borderRadius: 6, backgroundSize: "70%", marginLeft: 15 }}
              className="icon icon-export"
              onClick={() => xlsxExport({
                fields: [{ label: 'АЖУР/ЕИК/ЕГН', value: 'ajur' }, { label: 'Номер фактура', value: 'invoiceNumber' }, { label: 'Грешка', value: 'error' }],
                data: errors,
                render: (row, field) => row?.[field] || "-"
              })}
            />
          </div>
        </div>,
        onClick: () => { history.goBack(); dispatch(setModal({ isOpen: false })) },
        onRequestClose: () => { history.goBack(); dispatch(setModal({ isOpen: false })) }
      }
    }))
  }

  const handleAjurNumbers = () => {
    dispatch(startLoading())
    dispatch(exportAjurNumbers({
      payload: { invoicePeriodStart: moment(startDate).startOf('month') },
      onSuccess: (missingAjurNumbers) => {
        const existingAjurNumbers = data.map((r) => r?.['ЕИК'])
        const [toUpdateRaw, toGenerate] = partition(missingAjurNumbers, ({ eic, pin }) => existingAjurNumbers.includes(pin ?? eic))
        const toUpdate = toUpdateRaw.map((obj) => ({ ...obj, ajur: data.find((r) => r?.['ЕИК'] === (obj?.pin ?? obj?.eic))?.['Контрагент'] }))

        const lastAjurInFile = data.reduce((acc, r) => {
          const ajur = Number(r?.['Контрагент'])
          if (ajur >= 1000000) return acc
          else if (ajur > acc) return ajur
          else return acc
        }, 0)
        dispatch(importAjurNumbers({
          payload: { toGenerate, toUpdate, lastAjurInFile },
          onSuccess: ({ toGenerateResults, toUpdateResults }) => dispatch(setModal({
            isOpen: true,
            type: 'confirmation',
            props: {
              title: 'Успешно импортиране',
              children: <div className='col' style={{ alignItems: 'center', width: '100%', maxHeight: 300, overflow: "auto" }}>
                {getTextForConfirmationModal([
                  { text: "Редактирани:", inner: `${toUpdateResults?.length}` },
                  { text: "Генерирани:", inner: `${toGenerateResults?.length}` },
                ])}
                <div className="row">
                  <p>Експорт</p>
                  <div
                    className="icon icon-export"
                    style={{ marginLeft: 14, border: '1px solid #002157', borderRadius: '6px', backgroundSize: '60%', height: 30, width: 30, }}
                    onClick={() => xlsxExport({
                      fields: [{ label: 'ЕИК/ЕГН', value: 'eic' }, { label: 'Име клиент', value: 'fullName' }, { label: 'Контрагент', value: 'ajur' },],
                      data: [...(toUpdateResults ?? []), ...(toGenerateResults ?? [])],
                      render: (row, value) => value === 'eic' ? row?.pin || row?.eic || '' : row[value] || '',
                    })} />
                </div>
              </div>,
              onClick: () => history.push(`/references/invoiceNumber?type=ajur`),
              onRequestClose: () => history.push(`/references/invoiceNumber?type=ajur`)
            },
          })),
        }))

      }
    }))

  }

  const handleManualOverride = async () => {
    dispatch(startLoading())
    try {
      const payload = data.map((row) => {
        const temp = Object.entries(omit(row, ['ИТН', 'Качен на', 'Файл', 'Профил', 'Консумация',])).map(([key, value]) => ({
          period: String(key).includes("|") ? moment(key, 'DD.MM.YYYY | HH:mm') : moment(key),
          activeD: ['Производител'].includes(row['Профил']) ? 0 : value,
          activeR: ['Производител'].includes(row['Профил']) ? value : 0,
        }))
        return {
          manualOverride: true,
          itn: row['ИТН'],
          STPMeasure: ['Производител'].includes(row['Профил']) ? 'Производител' : !['Почасов'].includes(row['Профил']) ? row['Профил'] : undefined,
          from: temp[0].period,
          data: temp
        }
      })
      const chunks = chunk(payload, 1)
      await Async.reduce(chunks, async (transmittedChunks, chunk, n) => {
        const text = `Loading... \n Точка ${n + 1}/${chunks.length}`
        dispatch(setLoadingText(text))
        return [...transmittedChunks, await new Promise(async (resolve, reject) => {
          const url = `${URL}/parser/period-data`
          try {
            const response = await fetch(url, {
              method: 'POST',
              headers: Auth.isAuthenticated ? Headers.getWithAuth() : Headers.get(),
              body: JSON.stringify({ data: chunk, erp, type: 'period' }),
            })
            const { error, ...res } = await response.json()
            if (error) throw new Error()
            else return resolve(res)
          } catch (e) {
            return reject()
          }
        }),]
      }, [])
      dispatch(setModal({
        isOpen: true, type: 'confirmation',
        props: { title: 'Успешно качен файл', onClick: () => history.goBack(), onRequestClose: () => history.goBack() }
      }))
    } catch (error) {
      console.log(error)
      Alerts.error('Грешка при импортирането на файловете!')
    } finally {
      dispatch(stopLoading())
      dispatch(setLoadingText('Loading...'))
    }
  }

  const handleProducerData = (path) => {
    try {
      dispatch(startLoading())
      dispatch(importProducersData({
        path,
        payload: { data },
        onSuccess: () => dispatch(setModal({
          isOpen: true, type: 'confirmation',
          props: { title: 'Успешно качен файл', onClick: () => history.goBack(), onRequestClose: () => history.goBack() }
        }))
      }))
    } catch (error) {
      console.log(error)
      Alerts.error('Грешка при импортирането на файловете!')
    }
  }

  const handleSTPSummarized = async () => {
    dispatch(startLoading())
    try {
      const keyMap = {
        '№ на място на доставка': 'itn',
        'Тип': 'profile',
        'Количество, kWh': 'total',

        'ИТН': 'itn',
        'Количество': 'total',
        'Профил': 'profile',

        'MP Z ID': 'itn',
        'Profile': 'profile',
        'Sum': 'total',

        'УИН': 'itn',
        'Тип СТП': 'profile',
        'kWh': 'total',
      }
      const payload = data.map((row) => {
        const newRow = mapKeys(row, (_, key) => keyMap[key])
        if (!newRow.profile && erp === 'Златни Пясъци') newRow.profile = 'ERP_ZP2_st'
        return newRow
      })
      const chunks = chunk(payload, 250)
      await Async.reduce(chunks, async (transmittedChunks, chunk, n) => {
        const text = `Loading... \n ${scaleNumber(n, 0, chunks.length, 0, 100).toFixed(2)}%`
        dispatch(setLoadingText(text))
        return [...transmittedChunks, await new Promise(async (resolve, reject) => {
          const url = `${URL}/parser/stp-summarized`
          try {
            const response = await fetch(url, {
              method: 'POST',
              headers: Headers.getWithAuth(),
              body: JSON.stringify({ data: chunk, erp, month: date || moment().subtract(1, 'months').startOf('month').toDate(), }),
            })
            const { error, ...res } = await response.json()
            if (error) throw new Error()
            else return resolve(res)
          } catch (e) {
            return reject()
          }
        }),]
      }, [])
      dispatch(setModal({
        isOpen: true, type: 'confirmation',
        props: { title: 'Успешно качен файл', onClick: () => history.goBack(), onRequestClose: () => history.goBack() }
      }))
    } catch (error) {
      console.log(error)
      Alerts.error('Грешка при импортирането на файловете!')
    } finally {
      dispatch(stopLoading())
      dispatch(setLoadingText('Loading...'))
    }
  }

  const handleSend = () =>
    handleSendInvoice({
      selected: selectedCheckboxes,
      onSuccess: (sent) => dispatch(setModal(
        summaryModal({
          Изпратени: sent.length,
          Неизпратени: data?.length - sent?.length,
        }))),
      dispatch,
    })

  const handleButton = {
    progress: handleConfirm,
    network: handleUpload,
    period: handleUpload,
    stpData: handleUpload,
    confirmations: handleConfirmations,
    comments: handleComments,
    obligations: handleObligations,
    leaving: handleLeaving,
    stp: handleSTP,
    market: handleMarket,
    nomenclatures: handleNomenclatures,
    deposits: handleDeposits,
    interests: handleInterests,
    invoicePayments: handleInvoicePayments,
    invoices: handleSend,
    interestsPaymentsOld: handleInterestsPaymentsOld,
    interestsPayments: handleInterestsPayments,
    massEditInvoices: handleMassEditInvoices,
    manualOverride: handleManualOverride,
    stpSummarized: handleSTPSummarized,
    producerForecast: () => handleProducerData('upload-producer-forecast'),
    esoData: () => handleProducerData('upload-eso-data'),
    customGroupData: () => handleProducerData('upload-custom-group-data'),
    customIndividualData: () => handleProducerData('upload-custom-individual-data'),
    customProducerPricing: () => handleProducerData('upload-custom-producer-pricing'),
    ajurNumbers: handleAjurNumbers,
    undefined: () => { },
  }

  const summaryModal = (data) => ({
    isOpen: true,
    type: 'confirmation',
    props: {
      title: 'Обобщение',
      children: getTextForConfirmationModal(type === 'progress' ? [
        { text: 'С почаови данни:', inner: `${data?.['Почасови']} точки` },
        { text: 'С мрежови данни:', inner: `${data?.['Мрежови']} точки` },
      ] : type === 'invoices' ? [
        { text: 'Изпратени:', inner: `${data?.['Изпратени']} фактури` },
        { text: 'Неизпратени:', inner: `${data?.['Неизпратени']} фактури` },
      ] : [{ text: 'Успешно качен файл' }]),
      buttons: (
        <div className="row" style={{ justifyContent: 'space-around' }}>
          <Button.Raised text='Към "Фактури"' className="no-flex" onClick={() => {
            history.goBack()
            dispatch(setModal({ isOpen: false }))
          }} />
          <Button.Raised text='Към "Прогрес"' className="no-flex" onClick={() => {
            history.push('/references/invoices?type=progress&length=all')
            dispatch(setModal({ isOpen: false }))
          }} />
        </div>
      ),
    },
  })

  const fileLength = useMemo(() => data?.filter(row => !isEmpty(row))?.length, [data])
  return (
    <div className="screens-file-preview-container">
      <div className="screens-file-preview-header row">
        <h2>{typeMap[type]}</h2>
        {erp && (<p>ЕРП: <span>{erp}</span></p>)}
        {stp && (<p>Профил: <span>{stp}</span></p>)}
        {isDate(month) && !isNaN(month.getTime()) && (
          <p className="month">Месец: <span>{moment(month).format('MMMM')}</span></p>
        )}
        <div className="row row-buttons">
          {['period', 'stpData'].includes(type) && (
            <div
              className="icon icon-export"
              onClick={() => {
                const wb = XLSX.utils.book_new()
                const headers = fields.map(({ value }) => value)
                const formattedData = data.map((row) =>
                  Object.keys(row)
                    .filter((key) => headers.includes(key))
                    .sort((a, b) => headers.indexOf(a) - headers.indexOf(b))
                    .map((key) => renderCell.filePreview(row, key))
                )
                XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet([fields.map(({ label }) => label), ...formattedData]))
                XLSX.writeFile(wb, 'export.xlsx')
              }}
            />
          )}
          {['nomenclatures'].includes(type) && (
            <div className="row">
              <span>В сила от:</span>
              <Inputs.DatePicker value={date} onChange={(value) => setDate(value)} />
            </div>
          )}
          {['confirmations'].includes(type) && (
            <div className="row">
              <span>В сила от:</span>
              <Inputs.DatePicker
                value={date || moment().add(1, 'months').startOf('month').toDate()}
                onChange={(value) => setDate(moment(value).startOf('month').toDate())}
                dateFormat={'MMMM yyyy'}
                showMonthYearPicker
                showFullMonthYearPicker
                className="month-picker"
              />
            </div>
          )}
          {['leaving'].includes(type) && (
            <>
              <div className="row row-is-final-leaving">
                <span>Финален:</span>
                <Inputs.Checkboxes buttons={[{ value: 'true', label: '' }]} value={isFinalLeaving ? 'true' : 'false'} onClick={() => setIsFinalLeaving(!isFinalLeaving)} />
              </div>
              {isFinalLeaving ?
                <div className="row">
                  <span>В сила от:</span>
                  <Inputs.DatePicker
                    value={date || moment().add(1, 'months').startOf('month').toDate()}
                    onChange={(value) => setDate(moment(value).startOf('month').toDate())}
                    dateFormat={'MMMM yyyy'}
                    showMonthYearPicker
                    showFullMonthYearPicker
                    className="month-picker"
                  />
                </div>
                :
                <div className="row">
                  <span>Крайна дата възражения:</span>
                  <Inputs.DatePicker
                    value={objectionsDate}
                    onChange={(value) => setObjectionsDate(value)}
                  />
                </div>
              }
            </>
          )}
          {['stpSummarized'].includes(type) && (
            <div className="row">
              <span>Месец:</span>
              <Inputs.DatePicker
                value={date || moment().subtract(1, 'months').startOf('month').toDate()}
                onChange={(value) => setDate(moment(value).startOf('month').toDate())}
                dateFormat={'MMMM yyyy'}
                showMonthYearPicker
                showFullMonthYearPicker
                className="month-picker"
              />
            </div>
          )}
          <Button.Raised
            text={type === 'invoices' ? 'Изпрати' : 'Потвърди'}
            disabled={type === 'invoices' && isEmpty(selectedCheckboxes)}
            onClick={handleButton[type]}
          />
        </div>
      </div>
      <div className={`screens-file-preview-content ${type}`}>
        <Shared.Table
          ref={tableRef}
          className={type}
          columns={fields}
          data={data}
          renderCell={(row, field) =>
            type === 'invoices'
              ? renderCell.invoices(row, field)
              : renderCell.filePreview(row, field, {
                selected: {
                  data: selected,
                  set: setSelected,
                  editing: '',
                  setEditing: () => { },
                  edited: {},
                  setEdited: () => { },
                }
              })
          }
          headerWidth={fields?.reduce((acc, { size = 300 } = {}) => acc + size, 0)}
          fixedWidth={['period', 'stpData', 'progress', 'missing', 'interests', 'deposits', 'comments', 'interestsPayments', 'interestsPaymentsOld'].includes(type, filter)}
          useCheckboxes={!!showCheckboxes}
          selectedCheckboxes={selectedCheckboxes}
          onCheckboxChange={(value) => dispatch(setDataFields({ selectedCheckboxes: value }))}
        />
      </div>
      {!["market",
        "stp",
        "nomenclatures",
        "deposits",
        "interests"].includes(type) && (
          <div className="screens-file-preview-footer row">
            {type === 'network' ? (
              <>
                <p>Обща сума: {Number(stats?.total ?? total).toFixed(3) ?? '--'} лв.</p>
                <p className={`${filter === null && 'active'}`} onClick={() => setFilter(null)}>
                  Импортирани: {itns?.length ?? '--'}
                </p>
                <p className={`row ${filter === 'imported' && 'active'}`} onClick={() => setFilter('imported')}>
                  Фигуриращи: {stats?.imported?.length ?? <span className="inline-loader">Loading...</span>}
                </p>
                <p className={`row ${filter === 'missing' && 'active'}`} onClick={() => setFilter('missing')}>
                  Липсващи: {stats?.missing?.length ?? <span className="inline-loader">Loading...</span>}
                </p>
                <p>Общо потребление: {consumption ?? '--'}</p>
              </>
            ) : ['period', 'stpData'].includes(type) ? (
              <>
                <p>Общо потребление: {Number(stats?.total ?? total).toFixed(3) ?? '--'} кВтч.</p>
                <p className={`${filter === null && 'active'}`} onClick={() => setFilter(null)}>
                  Импортирани: {itns?.length ?? '--'}
                </p>
                <p className={`${filter === 'imported' && 'active'}`} onClick={() => setFilter('imported')}>
                  Фигуриращи: {stats?.imported?.length ?? <span className="inline-loader">Loading...</span>}
                </p>
                <p className={`${filter === 'missing' && 'active'}`} onClick={() => setFilter('missing')}>
                  Липсващи: {stats?.missing?.length ?? <span className="inline-loader">Loading...</span>}
                </p>
              </>
            ) : type === 'invoices' ? (
              <>
                <p>Обща сума: {Number(stats?.total ?? total).toFixed(3) ?? '--'} лв.</p>
                <p>Нови: {stats?.generated?.length ?? '--'}</p>
                <p>Прегенерирани: {stats?.pregenerated?.length ?? '--'}</p>
              </>
            ) : type === 'progress' ? (
              <p>С разминаване: {fileLength} точки</p>
            ) : type === 'confirmations' ? (
              <p>Потвърдени: {fileLength} точки</p>
            ) : type === 'comments' ? (
              <p>Коментари: {fileLength} точки</p>
            ) : type === 'obligations' ? (
              <p>Със задължения: {fileLength} точки</p>
            ) : type === 'leaving' ? (
              <p>Напускащи: {fileLength} точки</p>
            ) : type === 'manualOverride' ? (
              <p>Общо: {fileLength} точки</p>
            ) : type === 'stpSummarized' ? (
              <p>Общо: {fileLength} точки</p>
            ) : null}
          </div>
        )}
    </div>
  )
}

export default FilePreview
