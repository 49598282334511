export const apisTypes = {
  START_APIS_FETCH: 'apis/START_APIS_FETCH',
  FETCH_CLIENT_INFO: 'apis/FETCH_CLIENT_INFO',
}

export const startApisFetch = (payload) => ({
  type: apisTypes.START_APIS_FETCH,
  payload,
})

export const fetchClientInfo = (payload) => ({
  type: apisTypes.FETCH_CLIENT_INFO,
  payload,
})
