import React from 'react'
import { useLocation } from 'react-router-dom'
import { groupBy } from 'lodash'
import { useQuery } from 'hooks'
import './styles.scss'

const InvoiceGroups = ({ client, toggleRowExpanded = () => { }, isExpanded }) => {
  const { pathname } = useLocation()
  const { handleUrlChangeMultiple } = useQuery()


  return (
    <div className="client-cells-invoice-groups-container row">
      {Object.keys(groupBy(client?.objects, ({ invoiceGroup }) => invoiceGroup?._id || 'notGrouped')).length}
      <div
        className={`icon icon-arrow-down ${isExpanded && 'rotate'}`}
        onClick={() => {
          if (pathname?.includes('/references/invoices')) handleUrlChangeMultiple({ expand: 'invs' })
          toggleRowExpanded(client?._id)
        }}
      />
    </div>
  )
}

export default InvoiceGroups
