import React from 'react'
import { Inputs } from '../../../'
import { intermediateInvoicesTypes } from 'config/constants'
import './styles.scss'

const IntermediatePayments = ({ data, setData, editable }) => {
  return (
    <div className="client-forms-intermediate-payments-container row">
      <div className={`col col-double ${!editable && 'col-switch disabled'}`}>
        <span>Вид фактуриране</span>
        <Inputs.RadioButtons
          col
          buttons={['Веднъж месечно', 'Междинно', "Авансово"]}
          value={data?.intermediateInvoicing === false ? 'Веднъж месечно'
            : data?.intermediateInvoicingType?.includes('intermediate') ? 'Междинно'
              : data?.intermediateInvoicingType?.includes('advance') ? 'Авансово' : ''}
          onClick={(value) => {
            if (!editable) return
            if (value === 'Веднъж месечно') setData({ intermediateInvoicing: false, intermediateInvoicingType: null, advanceFirstDate: 1 })
            else if (value === 'Междинно') setData({ intermediateInvoicing: true, intermediateInvoicingType: 'intermediate-10', advanceFirstDate: 1 })
            else if (value === 'Авансово') setData({ intermediateInvoicing: true, intermediateInvoicingType: 'advance-10', advanceFirstDate: 1 })
          }} />
      </div>
      {data?.intermediateInvoicing && (
        <div className={`col col-double ${!editable && 'col-switch disabled'}`}>
          <span>Вид междинно фактуриране</span>
          <Inputs.RadioButtons
            col
            buttons={intermediateInvoicesTypes.filter(({ value }) => value.includes(data.intermediateInvoicingType.split('-').at(0)))}
            value={data?.intermediateInvoicingType}
            onClick={(value) => {
              if (!editable) return
              setData({ intermediateInvoicingType: value, advanceFirstDate: 1 })
            }}
            disabled={!editable}
          />
        </div>
      )}
      <div className={`col col-double ${!editable && 'col-switch disabled'}`}>
        {data?.intermediateInvoicing && data?.intermediateInvoicingType?.includes('advance') && (<>
          <span>Дата на фактуриране</span>
          <Inputs.RadioButtons
            col
            buttons={[{ label: '1-во число', value: 1 }, { label: '25-то число', value: 25 },]}
            value={data?.advanceFirstDate}
            onClick={(value) => {
              if (!editable) return
              setData({ advanceFirstDate: value })
            }}
            disabled={!editable}
          />
        </>)}
      </div>
    </div>
  )
}

export default IntermediatePayments
