import { ofType, ActionsObservable } from 'redux-observable'
import { switchMap, catchError } from 'rxjs/operators'
import { Api, Alerts } from '@makedonski/socourt-utilities'
import { saveAs } from 'file-saver';
import { exportsTypes, stopLoading } from '../actions'
import { URL } from '../config/settings'
import { exportCustomsExcel, renderCell, xlsxExport } from 'utilities'
import iconv from 'iconv-lite'
import { omit } from 'lodash';

export const exportAjur = (action$) => action$.pipe(
    ofType(exportsTypes.EXPORT_AJUR),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/ajur/generate`, JSON.stringify(payload)).pipe(
            switchMap(({ response: { RDET, RTOT, cancelled } }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess()
                if (RDET) {
                    var blob = new Blob([iconv.encode(RDET, 'win1251')], { type: 'text/plain;charset=windows-1251' })
                    saveAs(blob, `RDET.eid`);
                }
                if (RTOT) {
                    var blob = new Blob([iconv.encode(RTOT, 'win1251')], { type: 'text/plain;charset=windows-1251' })
                    saveAs(blob, `RTOT.eid`);
                }
                if (cancelled) {
                    var blob = new Blob([iconv.encode(cancelled, 'win1251')], { type: 'text/plain;charset=windows-1251' })
                    saveAs(blob, `Cancelled.txt`);
                }
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        )
    )
)

export const exportAjurAlternative = (action$) => action$.pipe(
    ofType(exportsTypes.EXPORT_AJUR_ALTERNATIVE),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/ajur/generateAlternative`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess()
                Object.entries(omit(response, 'success')).forEach(([key, value]) => {
                    var blob = new Blob([iconv.encode(value, 'win1251')], { type: 'text/plain;charset=windows-1251' })
                    saveAs(blob, `${key}.txt`);
                })
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        )
    )
)

export const exportAjurConvertor = (action$) => action$.pipe(
    ofType(exportsTypes.EXPORT_AJUR_CONVERTOR),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/ajur/epayToAlternative`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess()
                Object.entries(omit(response, 'success')).forEach(([key, value]) => {
                    var blob = new Blob([iconv.encode(value, 'win1251')], { type: 'text/plain;charset=windows-1251' })
                    saveAs(blob, `${key}.txt`);
                })
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        )
    )
)

export const exportAjurNumbers = (action$) => action$.pipe(
    ofType(exportsTypes.EXPORT_AJUR_NUMBERS),
    switchMap(({ payload: { forExport, ...payload }, onSuccess }) =>
        Api.post(`${URL}/ajur/downloadMissing`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response.missing)
                if (forExport && response?.missing?.length) xlsxExport({
                    fields: [
                        { label: '_id', value: '_id' },
                        { label: 'Име клиент', value: 'fullName' },
                        { label: 'ЕИК/ЕГН', value: 'eic' },
                        { label: 'Булстат', value: 'bulstat' },
                        { label: 'Ажур', value: 'ajur' },
                    ],
                    data: response?.missing,
                    render: (row, field, options) => {
                        if (field === 'eic') return row?.pin ?? row?.eic ?? ""
                        else return renderCell.default(row, field, options)
                    }
                })
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        )
    )
)

export const exportAjurCompensations = (action$) => action$.pipe(
    ofType(exportsTypes.EXPORT_AJUR_COMPENSATIONS),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/ajur/generateCompensations`, JSON.stringify(payload)).pipe(
            switchMap(({ response: { RDET, RTOT, cancelled } }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess()
                if (RDET) {
                    var blob = new Blob([iconv.encode(RDET, 'win1251')], { type: 'text/plain;charset=windows-1251' })
                    saveAs(blob, `RDET.eid`);
                }
                if (RTOT) {
                    var blob = new Blob([iconv.encode(RTOT, 'win1251')], { type: 'text/plain;charset=windows-1251' })
                    saveAs(blob, `RTOT.eid`);
                }
                if (cancelled) {
                    var blob = new Blob([iconv.encode(cancelled, 'win1251')], { type: 'text/plain;charset=windows-1251' })
                    saveAs(blob, `Cancelled.txt`);
                }
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        )
    )
)

export const exportCustoms = (action$) => action$.pipe(
    ofType(exportsTypes.EXPORT_CUSTOMS),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/customs/generate`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess()
                exportCustomsExcel(response)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        )
    )
)

export const exportEasypay = (action$) => action$.pipe(
    ofType(exportsTypes.EXPORT_EASYPAY),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/invoices/getForEPay`, JSON.stringify(payload)).pipe(
            switchMap(({ response: { file } }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess()
                if (file) {
                    var blob = new Blob([iconv.encode(file, 'win1251')], { type: 'text/plain;charset=windows-1251' })
                    saveAs(blob, `easypay.csv`);
                }
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        )
    )
)   