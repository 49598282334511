import { ofType, ActionsObservable } from 'redux-observable'
import { switchMap, catchError } from 'rxjs/operators'
import { Api, Alerts } from '@makedonski/socourt-utilities'
import { connectedGroupsTypes, dataTypes, stopLoading } from '../actions'
import { URL } from '../config/settings'

export const getConnectedGroups = (action$) =>
  action$.pipe(
    ofType(connectedGroupsTypes.GET_CONNECTED_GROUPS),
    switchMap(() =>
      Api.get(`${URL}/connected-groups`).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({ type: dataTypes.GET_DATA_SUCCESS, payload: { type: 'connectedGroups', data: response.groups } })
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const getConnectedGroup = (action$) =>
  action$.pipe(
    ofType(connectedGroupsTypes.GET_CONNECTED_GROUP),
    switchMap(({ payload: { data, onSuccess } }) =>
      Api.get(`${URL}/connected-groups/${data}`).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response.group)
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const createConnectedGroup = (action$) =>
  action$.pipe(
    ofType(connectedGroupsTypes.CREATE_CONNECTED_GROUP),
    switchMap(({ payload: { data, onSuccess } }) =>
      Api.post(`${URL}/connected-groups`, JSON.stringify(data)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response.group._id)
            obs.next({ type: connectedGroupsTypes.GET_CONNECTED_GROUPS })
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const updateConnectedGroup = (action$) =>
  action$.pipe(
    ofType(connectedGroupsTypes.UPDATE_CONNECTED_GROUP),
    switchMap(({ payload }) =>
      Api.put(`${URL}/connected-groups`, JSON.stringify(payload)).pipe(
        switchMap(() =>
          ActionsObservable.create((obs) => {
            obs.next({ type: connectedGroupsTypes.GET_CONNECTED_GROUPS })
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const deleteConnectedGroup = (action$) =>
  action$.pipe(
    ofType(connectedGroupsTypes.DELETE_CONNECTED_GROUP),
    switchMap(({ payload }) =>
      Api.delete(`${URL}/connected-groups`, JSON.stringify(payload)).pipe(
        switchMap(() =>
          ActionsObservable.create((obs) => {
            obs.next({ type: connectedGroupsTypes.GET_CONNECTED_GROUPS })
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )
