import React, { useState } from 'react'
import { Button, Input } from '@makedonski/admin-ui'
import { isEmpty } from 'lodash'
import { Inputs } from '../../'
import './styles.scss'

const FilterTasksBoard = ({ hide }) => {
  const clients = [
    { _id: '1', companyName: 'aaa' },
    { _id: '2', companyName: 'bbb' },
    { _id: '3', companyName: 'aaa' },
    { _id: '4', companyName: 'bbb' },
    { _id: '5', companyName: 'aaa' },
    { _id: '6', companyName: 'bbb' },
    { _id: '7', companyName: 'aaa' },
    { _id: '8', companyName: 'bbb' },
    { _id: '9', companyName: 'aaa' },
    { _id: '10', companyName: 'bbb' },
  ]
  const [selected, setSelected] = useState([])

  return (
    <div className="popup-filter-tasks-board-container">
      <div className="row">
        <h4>Тип задачи</h4>
        <Button.Icon name="plus" onClick={hide} />
      </div>
      <Inputs.RadioButtons
        buttons={['Всички задачи', 'Администрция', 'Търговци', 'По потребител']}
        value="Всички задачи"
        col
      />

      <h4 className="add-margin">Крайна дата</h4>
      <Inputs.RadioButtons
        buttons={[
          'Крайна дата тази седмица',
          'Крайна дата утре',
          'Конкретна дата',
        ]}
        col
      />
      <h4 className="add-margin">Клиент</h4>
      <Input.Text placeholder="Търси" />

      <div className="scroll-container">
        {!isEmpty(clients) && (
          <Inputs.Checkboxes
            buttons={[{ label: 'View All', value: clients?.length || 0 }]}
            value={[selected?.length]}
            onClick={() => {
              if (isEmpty(selected)) setSelected(clients.map(({ _id }) => _id))
              else setSelected(null)
            }}
          />
        )}
        <Inputs.Checkboxes
          buttons={clients?.map(({ _id, companyName }) => ({
            value: _id,
            label: companyName,
          }))}
          value={selected}
          onClick={(value) => setSelected(value)}
          col
        />
      </div>

      <div className="row row-footer">
        <p className="clear" onClick={() => {}}>
          Изчисти филтри
        </p>
        <Button.Raised text="Задай" className="btn-apply" onClick={() => {}} />
      </div>
    </div>
  )
}

export default FilterTasksBoard
