import React, { useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setOverlay } from '../../actions'
import { Card } from '../'
import { useOnClickOutside } from '../../hooks'
import './styles.scss'

const Overlay = ({ }) => {
  const { isOpen, type, props: allProps } = useSelector((state) => state.general.overlay)
  const dispatch = useDispatch()

  const hide = () => dispatch(setOverlay({ isOpen: false }))
  const containerRef = useRef()
  useOnClickOutside(containerRef, () => hide(), isOpen)

  const props = { ...allProps, hide }

  const render = () => {
    switch (type) {
      case 'history':
        return <Card.ClientHistory {...props} />
      case 'files':
        return <Card.ClientFileSystem {...props} />
      case 'comments':
        return <Card.Comments {...props} />
      case 'clientComments':
        return <Card.ClientComments {...props} />
      case 'document':
        return <Card.ClientDocumentPreview {...props} />
      case 'invoice':
        return <Card.Invoice {...props} />
      case 'clientGroups':
        return <Card.ClientGroups {...props} />
      case 'usersGroups':
        return <Card.UsersGroups {...props} />
      default:
        return null
    }
  }

  return (
    <div className={`overlay-container ${isOpen && 'open'}`}>
      <div className="overlay-inner-container" ref={containerRef}>
        {render()}
      </div>
    </div>
  )
}

export default Overlay
