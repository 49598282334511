import React from 'react'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { useLocation } from 'react-router-dom'
import { useQuery } from 'hooks'
import { allRegistrationStatuses } from 'config/constants'
import './styles.scss'

const Objects = ({ value, client, toggleRowExpanded = () => { }, toggleFrozenRowExpanded, isExpanded, ...options }) => {
  const { pathname } = useLocation()
  const { handleUrlChangeMultiple } = useQuery()
  const { showCheckboxes, lockedColumn } = useSelector((state) => state.clients)
  return (
    <div className="client-cells-objects-container row">
      {isEmpty(value) ? '--' : (value?.filter(({ status }) => options?.type !== 'register' || allRegistrationStatuses.includes(status))?.length || '--')}
      {!isEmpty(value) && !showCheckboxes && lockedColumn?.value !== 'objects' && (
        <div
          className={`icon icon-arrow-down ${isExpanded && 'rotate'}`}
          onClick={() => {
            if (pathname?.includes('/references/invoices')) handleUrlChangeMultiple({ expand: 'objs' })
            toggleRowExpanded(client?._id)
            if (toggleFrozenRowExpanded) toggleFrozenRowExpanded(client?._id)
          }}
        />
      )}
    </div>
  )
}

export default Objects
