import React from 'react'
import { Button } from '@makedonski/admin-ui'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setOverlay, updateClient, getClient } from '../../../actions'
import './styles.scss'
import { checkIsDealer } from 'utilities'

const ClientOptions = ({ hide, client }) => {
  const isDealer = checkIsDealer()
  const { _id, isDetailsConfirmed } = client || {}
  const dispatch = useDispatch()
  const history = useHistory()
  return (
    <div className="popup-client-options-container">
      <div className="row">
        <h4>Преглед на</h4>
        <Button.Icon name="plus" onClick={hide} />
      </div>
      <p
        onClick={() => {
          dispatch(setOverlay({ isOpen: true, type: 'history', props: { client } }))
          hide()
        }}
      >
        История на дейността
      </p>
      <p
        onClick={() => {
          dispatch(setOverlay({ isOpen: true, type: 'clientComments', props: { client } }))
          hide()
        }}
      >
        Коментари
      </p>
      {/*<p
        onClick={() => {
          dispatch(setOverlay({ isOpen: true, type: 'files', props: { client } }))
          hide()
        }}
      >
        Файлове
      </p>*/}
      {!isDealer && <>
        <h4>Действия</h4>
        {!isDetailsConfirmed && <p onClick={() => dispatch(updateClient({
          data: { _id, isDetailsConfirmed: true },
          onSuccess: () => {
            dispatch(getClient({ payload: _id, getAllDetails: true }))
            hide()
          }
        }))}>Потвърди детайли</p>}
        <p onClick={() => history.push(`/annex/create?tab=main&eic=${client?.eic || client?.pin}`)}>Създай Анекс</p>
        <p onClick={() => history.push(`/resign/create?tab=main&eic=${client?.eic || client?.pin}`)}>Преподписване</p>
        <p onClick={() => history.push(`/consumption_analysis?clients=${client?._id}`)}>Анализ на потребление</p>
      </>}
    </div >
  )
}

export default ClientOptions
