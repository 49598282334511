import { ofType, ActionsObservable } from 'redux-observable'
import { switchMap, mergeMap, catchError } from 'rxjs/operators'
import { Api, Alerts } from '@makedonski/socourt-utilities'
import { commentsTypes, stopLoading } from '../actions'
import { URL } from '../config/settings'

export const createComment = (action$) =>
  action$.pipe(
    ofType(commentsTypes.CREATE_COMMENT),
    mergeMap(({ payload: { data, onSuccess } }) =>
      Api.post(`${URL}/comments`, JSON.stringify(data)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response.comment)
            if (data?.refType === 'Clients')
              obs.next({ type: commentsTypes.CREATE_COMMENT_CLIENT_SUCCESS, payload: response.comment })
            if (data?.refType === 'Invoices')
              obs.next({ type: commentsTypes.CREATE_COMMENT_INVOICE_SUCCESS, payload: response.comment })
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const updateComment = (action$) =>
  action$.pipe(
    ofType(commentsTypes.UPDATE_COMMENT),
    switchMap(({ payload: { data, onSuccess } }) =>
      Api.put(`${URL}/comments`, JSON.stringify(data)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response.comment)
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const deleteComment = (action$) =>
  action$.pipe(
    ofType(commentsTypes.DELETE_COMMENT),
    switchMap(({ payload, onSuccess }) =>
      Api.delete(`${URL}/comments`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response.comment)
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )
