import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getData, getReferenceImports, setReferencesFields, startLoading } from 'actions'
import { Shared } from 'components'
import { useQuery } from 'hooks'
import { referenceImportsFields, referencesInvoicesLength } from 'config/constants'
import { mapClientsSearch, renderCell } from 'utilities'
import './styles.scss'
import { Popover } from '@varld/popover'

const Imports = ({ }) => {
  const dispatch = useDispatch()
  const { reference } = useSelector((state) => state.references)

  const { erps } = useSelector(({ data }) => data)
  useEffect(() => { !erps && dispatch(getData('erps')) }, [])

  const { startDate: month, sort: sortParam = '{}', filter: filterParam = '{}', } = useQuery({ length: referencesInvoicesLength })
  const availableSort = ["imported", "existing", "missing", "noPrice", "totalConsumption", "missingConsumption", "totalPrice", "createdAt",]
  const availableFilters = {
    name: { type: 'regex' },
    uploadedFrom: { type: 'regex' },
    erp: { type: "idDropdown", values: erps, single: true },
  }
  const keyMap = {}
  const filter = useMemo(() => JSON.parse(filterParam), [filterParam])
  const sort = useMemo(() => JSON.parse(sortParam), [sortParam])
  const searchQuery = useMemo(() => ({ ...mapClientsSearch(sort, filter, undefined, availableFilters) }), [sortParam, filterParam])

  const fetch = useCallback(() => {
    dispatch(startLoading())
    dispatch(getReferenceImports({ month, ...searchQuery }))
  }, [month, searchQuery, dispatch])

  useEffect(() => {
    fetch()
    return () => dispatch(setReferencesFields({ reference: [] }))
  }, [dispatch, fetch])



  const sortingComponents = referenceImportsFields['consumption']?.filter(({ sortable }) => sortable).reduce((acc, { value }) => {
    return {
      ...acc,
      [value]:
        <Popover popover={({ close }) => <Shared.SortingComponent
          hide={close}
          column={value}
          availableSort={availableSort}
          availableFilters={availableFilters}
          keyMap={keyMap}
        />}>
          <div className="icon icon-arrow-down" />
        </Popover>

    }
  }, {})


  return (
    <div className="references-imports-container">
      <Shared.Table
        // fixedWidth
        columns={referenceImportsFields['consumption']}
        data={reference}
        renderCell={(row, value) => renderCell.references.imports(row, value, { fetch })}
        sortingComponent={sortingComponents}
      />
    </div>
  )
}

export default Imports
