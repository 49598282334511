import { Input, Button } from '@makedonski/admin-ui'
import { setModal, startLoading, stopLoading } from 'actions'
import { Client, Inputs } from 'components'
import { balancingMethodOptions, documentTypesProducers, invoicingMethodOptions } from "config/constants"
import moment from 'moment'
import React from "react"
import { Portal } from 'react-overlays'
import { useDispatch } from "react-redux"
import { checkIsDealer, uploadFile } from "utilities"
import "./styles.scss"

const ProducerSettings = ({
    producerSettings: data,
    setProducerSettings: setData,
    handleChange,

    versions,
    products,

    showRequired,
    editable,
    isClient,
    isProfile,
    isInactive,

    showInvalid
}) => {

    const dispatch = useDispatch()
    const isRequired = (field) => showRequired && !data?.[field] && 'required'
    const isDealer = checkIsDealer()

    const handleFiles = (type, file) => {
        let newFiles
        const index = data?.files?.findIndex((file) => file?.type === type)
        if (index || index !== -1) {
            newFiles = (data?.files || []).filter((file) => file?.type !== type)
            if (file !== null) newFiles = [...newFiles, file]
        } else[...(data?.files || []), file]
        handleChange('files', newFiles)
    }
    const handleUpload = (files, field) => {
        dispatch(startLoading())
        uploadFile({ file: files[0], createId: true }).then(({ location }) => {
            dispatch(stopLoading())
            handleFiles(field, { url: location, name: files[0].name, type: field, new: isProfile })
        })
    }

    if (isProfile && !data) return <div className={`client-forms-producer-settings-container`}>
        <div className={`client-forms-producer-settings-inner-container ${showInvalid && 'required'}`}>
            <div className="client-forms-producer-settings-header row">
                <h2>Производство</h2>
                {isClient && <div className="row">
                    <span>Приложими за всички точки</span>
                    <Button.Switch onChange={() => editable && dispatch(setModal({ isOpen: true, type: 'producerSettingsFromObject', props: { onSuccess: setData } }))} />
                </div>}
            </div>
        </div>
    </div>


    return <div className={`client-forms-producer-settings-container`}>
        <div className={`client-forms-producer-settings-inner-container ${showInvalid && 'required'}`}>
            <div className="client-forms-producer-settings-header row">
                <h2>Производство</h2>
                {isClient && <div className="row">
                    <span>Приложими за всички точки</span>
                    <Button.Switch isOn={!data?.disabled} onChange={() => editable && handleChange('disabled', !data?.disabled)} />
                </div>}
            </div>
            {!data?.disabled && <>
                {(!isProfile || isInactive || editable) && <div className="row row-files">
                    {documentTypesProducers.map(({ value, label }, i) => {
                        const file = data?.files?.find((file) => file?.type === value)
                        return <div key={`documents-field-${i}`} className="col">
                            <span>{label}</span>
                            {file ? (
                                <div className="row row-file">
                                    {editable && <Button.Icon name="plus" color="red" onClick={() => handleFiles(value, null)} />}
                                    <span>{file.name}</span>
                                </div>
                            ) : (
                                <Button.UploadButton
                                    text={<Input.DragAndDrop handleDrop={(files) => handleUpload(files, value)}>
                                        <span className="row ripple">Добави файл <div className="icon icon-attach" /></span>
                                    </Input.DragAndDrop>}
                                    className={`${!editable && 'disabled'} ${['letter of attorney', 'contract', 'protocol'].includes(value) && isRequired(value)}`}
                                    multiple
                                    accept="*"
                                    onChange={({ target: { files } }) => handleUpload(files, value)}
                                    disabled={!editable}
                                />
                            )}
                        </div>
                    })}
                </div>}
                <div className="row">
                    <div className="col">
                        <span>Версия договор</span>
                        <Inputs.Dropdown
                            options={versions
                                ?.filter(({ forProducer }) => forProducer)
                                ?.map(({ _id, name }) => ({ value: _id, label: name }))}
                            onChange={({ value }) => handleChange('version', value)}
                            value={data?.version?._id || data?.version}
                            className={`${isRequired('version')}`}
                            disabled={!editable}
                        />
                    </div>
                    <div className="col">
                        <span>Дата на договор</span>
                        <Inputs.DatePicker
                            value={data?.contractDate}
                            onChange={(value) => handleChange('contractDate', value)}
                            className={`${isRequired('contractDate')}`}
                            disabled={!editable}
                            popperContainer={({ children }) => <Portal container={document.getElementById('calendar-portal')}>{children}</Portal>}
                        />
                    </div>
                    <div className="col">
                        <span>№ на договор</span>
                        <Input.Text
                            value={data?.contractNumber || ''}
                            onChange={({ target: { value } }) => handleChange('contractNumber', value)}
                            /*inputClassName={`${isRequired('contractNumber')}`}*/
                            disabled={!editable}
                        />
                    </div>
                    <div className="col">
                        <span>Начало на доставка</span>
                        <Inputs.DatePicker
                            value={data?.deliveryStart}
                            onChange={(value) => handleChange('deliveryStart', moment(value).startOf('day').toDate())}
                            minDate={moment().subtract(10, 'y').toDate()}
                            className={`${isRequired('deliveryStart')}`}
                            disabled={!editable || isDealer}
                            popperContainer={({ children }) => <Portal container={document.getElementById('calendar-portal')}>{children}</Portal>}
                        />
                    </div>
                    {<div className="col">
                        <span>Край на договор</span>
                        <Inputs.DatePicker
                            value={data?.deliveryEnd}
                            onChange={(value) => handleChange('deliveryEnd', moment(value).endOf('month').toDate())}
                            minDate={moment().add(1, 'months').startOf('month').toDate()}
                            className={`month-picker ${isRequired('deliveryEnd')}`}
                            showMonthYearPicker
                            showFullMonthYearPicker
                            disabled={!editable}
                            popperContainer={({ children }) => <Portal container={document.getElementById('calendar-portal')}>{children}</Portal>}
                        />
                    </div>}
                </div>
                <div className="row">
                    <div className="col">
                        <span>Падеж производство</span>
                        <Inputs.TextLabeled
                            label="число"
                            value={data?.paymentDays || ''}
                            onChange={({ target: { value } }) => handleChange('paymentDays', value)}
                            inputClassName={`${isRequired('paymentDays')}`}
                            disabled={!editable}
                        />
                    </div>
                    <div className={`col col-switch ${!editable && 'disabled'}`}>
                        <span>Общи условия</span>
                        <Button.Switch isOn={data?.terms} onChange={() => editable && handleChange('terms', !data?.terms)} />
                    </div>
                    <div className={`col col-switch ${!editable && 'disabled'}`}>
                        <span>Предизвестие</span>
                        <div className="row row-auto-sign">
                            <Button.Switch
                                isOn={data?.notice}
                                onChange={() => {
                                    if (!editable) return
                                    setData({ ...data, notice: !data?.notice, noticeMonths: null })
                                    setTimeout(() => { document.getElementsByClassName('focus-notice')[0]?.focus() }, 0)
                                }}
                            />
                            {data?.notice && <Inputs.TextLabeled
                                label="мес."
                                value={data?.noticeMonths || ''}
                                onChange={({ target: { value } }) => {
                                    if (!/^\d{0,2}$/.test(value) && value !== '') return
                                    handleChange('noticeMonths', value)
                                }}
                                inputClassName={`focus-notice ${isRequired('noticeMonths')}`}
                                disabled={!editable}
                            />}
                        </div>
                    </div>

                    <div className={`col col-switch ${!editable && 'disabled'}`}>
                        <span>Автоматично преподписване</span>
                        <div className="row row-auto-sign">
                            <Button.Switch
                                isOn={data?.autoSign}
                                onChange={() => {
                                    if (!editable) return
                                    setData({ ...data, autoSign: !data?.autoSign, autoSignMonths: null })
                                    setTimeout(() => { document.getElementsByClassName('focus-auto-sign')[0]?.focus() }, 0)
                                }}
                            />
                            {data?.autoSign && <Inputs.TextLabeled
                                label="мес."
                                value={data?.autoSignMonths || ''}
                                onChange={({ target: { value } }) => {
                                    if (!/^\d{0,2}$/.test(value) && value !== '') return
                                    handleChange('autoSignMonths', value)
                                }}
                                inputClassName={`focus-auto-sign ${isRequired('autoSignMonths')}`}
                                disabled={!editable}
                            />}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <span>Продукт</span>
                        <Inputs.Dropdown
                            options={products?.filter(({ forProducer }) => forProducer)?.map(({ _id, name }) => ({ label: name, value: _id }))}
                            onChange={({ value }) => {
                                const product = products?.find(({ _id }) => _id === value)?.name
                                setData({
                                    ...data,
                                    product: value,
                                    price: product === 'Борса минус с Под и Таван на цената' ? { marketIsPercent: true } : null,
                                    balancingMethod: product === 'Борса (без надбавка)' && data?.balancingMethod === 'Включено' ? null : data?.balancingMethod
                                })
                            }}
                            value={data?.product?._id || data?.product}
                            className={`${showRequired && !data?.product && 'required'}`}
                            disabled={!editable}
                        />
                    </div>
                    <Client.Forms.ProductPrices
                        products={products}
                        data={data}
                        handleChange={(field, value) => handleChange('price', { ...data?.price, [field]: value })}
                        showRequired={showRequired}
                        editable={editable}
                    />
                </div>
                <div className="row">
                    <div className="col">
                        <span>Вид Фактуриране</span>
                        <Inputs.Dropdown
                            options={invoicingMethodOptions.map((value) => ({ value, label: value }))}
                            value={data?.invoicingMethod}
                            onChange={({ value }) => handleChange("invoicingMethod", value)}
                            disabled={!editable}
                            className={`${showRequired && !data?.invoicingMethod && 'required'}`}
                        />
                    </div>
                    <div className="col">
                        <span>Балансиране</span>
                        <Inputs.Dropdown
                            options={balancingMethodOptions
                                .filter((value) => products?.find(({ _id }) => _id === data?.product)?.name !== 'Борса (без надбавка)' || value !== 'Включено')
                                .map((value) => ({ value, label: value }))
                            }
                            value={data?.balancingMethod}
                            onChange={({ value }) => setData({ ...data, balancingMethod: value, balancingCeiling: null, balancingFloor: null })}
                            disabled={!editable}
                            className={`${showRequired && !data?.balancingMethod && 'required'}`}
                        />
                    </div>
                    {['Под и Таван в лв./МВТч',].includes(data?.balancingMethod) && <div className="col">
                        <span>Под</span>
                        <Inputs.TextLabeled
                            label="лв./МВтч"
                            value={data?.balancingFloor ?? ""}
                            onChange={({ target: { value } }) => {
                                if (!/^\d{0,3}(\.||\.\d{0,2})?$/.test(value) && value !== '') return
                                handleChange('balancingFloor', value)
                            }}
                            disabled={!editable}
                            inputClassName={`${isRequired('balancingFloor')}`}
                        />
                    </div>}
                    {['Фикс в лв. / МВТч', 'Таван в лв./МВТч', 'Под и Таван в лв./МВТч',].includes(data?.balancingMethod) && <div className="col">
                        <span>{['Таван в лв./МВТч', 'Под и Таван в лв./МВТч'].includes(data?.balancingMethod) ? 'Таван' : 'Фикс'}</span>
                        <Inputs.TextLabeled
                            label="лв./МВтч"
                            value={data?.balancingCeiling ?? ""}
                            onChange={({ target: { value } }) => {
                                if (!/^\d{0,3}(\.||\.\d{0,2})?$/.test(value) && value !== '') return
                                handleChange('balancingCeiling', value)
                            }}
                            disabled={!editable}
                            inputClassName={`${isRequired('balancingCeiling')}`}
                        />
                    </div>}
                </div>
            </>}
        </div>
    </div >
}

export default ProducerSettings