import { ofType, ActionsObservable } from 'redux-observable'
import { switchMap, catchError } from 'rxjs/operators'
import { Api, Alerts } from '@makedonski/socourt-utilities'
import { contractsTypes, clientsTypes, stopLoading } from '../actions'
import { URL } from '../config/settings'

export const createContract = (action$) =>
  action$.pipe(
    ofType(contractsTypes.CREATE_CONTRACT),
    switchMap(({ payload: { data, onSuccess } }) =>
      Api.post(`${URL}/contracts`, JSON.stringify(data)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response.contract)
            obs.next({
              type: clientsTypes.UPLOAD_FILE_CLIENT,
              payload: {
                url: `${URL}/contracts/file/${response.contract._id}`,
                name: `Dogovor№${response.contract.contractNumber}`,
                client: response.contract.client._id,
              },
            })
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )
export const updateContract = (action$) =>
  action$.pipe(
    ofType(contractsTypes.UPDATE_CONTRACT),
    switchMap(({ payload: { data, onSuccess } }) =>
      Api.put(`${URL}/contracts`, JSON.stringify(data)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response.contract)
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )
