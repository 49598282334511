import React from 'react'
import moment from 'moment'
import './styles.scss'

const SingleNotification = ({ notification }) => {
  const { _id, fullName, createdAt, user, text, currentUser } = notification || {}

  return (
    <div className="single-notification-container">
      <p>
        {createdAt
          ? moment(createdAt).format('HH:mm | DD.MM.YYYY')
          : '12:45 | 12.03.2021'}
      </p>
      <div className="single-notification-inner-container row">
        <div
          className="profile-image"
          style={{
            backgroundImage: `url(${
              user?.coverPhoto ||
              require('../../../../assets/images/default-user-avatar.jpg')
            })`,
          }}
        />
        <div className="text">
          <p>
            <span className="bold">
              {_id === currentUser?._id ? 'You' : fullName || 'Ивана Петрова'}{' '}
            </span>{' '}
            {text ||
              'създаде Нова Оферта №24414,за клиент: ИМЕ НА ФИРМА | Име на обект'}
          </p>
        </div>
      </div>
    </div>
  )
}

export default SingleNotification
