import { default as Main } from './Main'
import { default as Products } from './Products'
import { default as Hourly } from './Hourly'
import { default as Graph } from './Graph'

const MarketTables = {
  Main,
  Products,
  Hourly,
  Graph,
}

export default MarketTables
