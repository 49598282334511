import React, { useState } from 'react'
import { Button, Input } from '@makedonski/admin-ui'
import { useDispatch } from 'react-redux'
import { setModal } from 'actions'
import './styles.scss'

const ClientEditMOL = ({ client, handleChange: handleChangeProp }) => {
  const dispatch = useDispatch()
  const [data, setData] = useState(client?.mol || [])
  const handleChange = (payload, i) => {
    const newData = [...(data || [])]
    newData.splice(i, 1, { ...data?.at(i), ...payload })
    setData(newData)
  }

  const [showRequired, setShowRequired] = useState(false)
  const isValid = () => {
    let valid = true
    data.forEach((item, i) => {
      if (['fullName', 'role'].some((field) => !item[field])) valid = false
    })
    return valid
  }

  return (
    <div className="modal-client-edit-MOL-container">
      <div className="modal-client-edit-MOL-header row">
        <h2>Редакция на МОЛ</h2>
        <Button.Icon name="plus" size={28} onClick={() => dispatch(setModal({ isOpen: false }))} />
      </div>
      <div className="modal-client-edit-MOL-content">
        {data?.map((mol, i) => (
          <div key={`mol-${i}`} className="single-mol row">
            <div className="col col-double">
              <span>Име и фамилия</span>
              <Input.Text
                value={data?.at(i)?.fullName}
                onChange={({ target: { value } }) => handleChange({ fullName: value }, i)}
                inputClassName={showRequired && !data?.at(i)?.fullName && 'required'}
              />
            </div>
            <div className="col">
              <span>Позиция</span>
              <Input.Text
                value={data?.at(i)?.role}
                onChange={({ target: { value } }) => handleChange({ role: value }, i)}
                inputClassName={showRequired && !data?.at(i)?.role && 'required'}
              />
            </div>
            {data.length > 1 && (
              <Button.Icon
                name="plus"
                color="red"
                onClick={() => {
                  const newData = [...(data || [])]
                  newData.splice(i, 1)
                  setData(newData)
                }}
              />
            )}
          </div>
        ))}
      </div>
      <div className="modal-client-edit-MOL-footer">
        <div className="row">
          <div className="icon icon-add" onClick={() => setData([...data, {}])} />
          <span>Добави МОЛ</span>
        </div>
        <Button.Raised
          text="Запази"
          onClick={() => {
            if (!isValid()) {
              setShowRequired(true)
              return
            } else {
              handleChangeProp(data)
              dispatch(setModal({ isOpen: false }))
            }
          }}
        />
      </div>
    </div>
  )
}

export default ClientEditMOL
