import React, { useState } from 'react'
import { Input, Button } from '@makedonski/admin-ui'
import { useDispatch } from 'react-redux'
import { login, startLoading } from 'actions'
import { Inputs } from 'components'
import './styles.scss'

const Login = ({ }) => {
  const [data, setData] = useState({})
  const handleChange = (field, { target: { value } }) => setData({ ...data, [field]: value })

  const dispatch = useDispatch()
  const handleLogin = () => {
    dispatch(startLoading())
    dispatch(login(data))
  }

  return (
    <div className="login-screen-container">
      <Input.Text
        name="email"
        placeholder="Email"
        value={data?.email || ''}
        onChange={(e) => handleChange('email', e)}
        type="email"
        autoComplete="username"
      />
      <Inputs.Password
        placeholder="Password"
        value={data?.password || ''}
        onChange={(e) => handleChange('password', e)}
        onKeyDown={({ key }) => key === 'Enter' && handleLogin()}
      />
      <Button.Raised text="Login" onClick={() => handleLogin()} disabled={!data?.email || !data?.password} />
    </div>
  )
}

export default Login
