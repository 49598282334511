import React, { useState, useEffect, forwardRef } from 'react'
import { Alerts, Headers } from '@makedonski/socourt-utilities'
import { Popover } from '@varld/popover'
import { Input, Button } from '@makedonski/admin-ui'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { isEmpty, isArray, pick, uniqBy } from 'lodash'
import {
  getData,
  createClient,
  updateClient,
  startApisFetch,
  startLoading,
  setModal,
  getClientsForDropdown,
  getConnectedGroups,
  createConnectedGroup,
  createComment,
  checkClientExists,
  stopLoading,
  getUsers,
} from '../../../../actions'
import { Inputs, Client, Popup } from '../../../'
import { emailRegex, IBANRegex, producerBanksOptions, productsPricesFields, defaultContractSettings, defaultProducerSettings } from '../../../../config/constants'
import { URL } from 'config/settings'
import './styles.scss'
import { checkIsDealer, isValidProducerSettings, loadDropdownOptions } from 'utilities'
import { useQuery } from 'hooks'

const requiredFields = ['type', 'fullName', 'mol', 'city', 'address', 'phoneNumber', 'email']
const requiredContractFields = ['version', 'paymentDays']
const Main = forwardRef(
  ({ handleChange: handleChangeProp, handleSuccess, data: dataProp, editable: editableProp, setEditable }, ref) => {
    const { clientType } = useQuery()

    const editable = editableProp === undefined ? true : editableProp
    const defaultValues = {
      clientType,
      ...(clientType !== 'Производител' && { contractSettings: defaultContractSettings }),
      ...(clientType !== 'Консуматор' && { producerSettings: defaultProducerSettings }),
    }
    const initialData = dataProp || defaultValues
    const [data, setData] = useState(initialData)
    const handleChange = handleChangeProp ? handleChangeProp : (field, value) => setData({ ...data, [field]: value })
    const handlePriceChange = (field, value) => handleChange('price', { ...data?.price, [field]: value })
    const [showRequired, setShowRequired] = useState(false)
    const { currentUser } = useSelector(({ general }) => general) || {}
    const { businessTypes, products, versions, connectedGroups, clients } = useSelector(({ data }) => data)
    const isDealer = checkIsDealer()

    useEffect(() => {
      setData(initialData)
    }, [dataProp])

    useEffect(() => {
      if (clientType !== 'Консуматор' && versions && !data?.producerSettings?.version) {
        setData({ ...data, producerSettings: { ...data.producerSettings, version: versions?.find(({ name }) => name === 'Г10.22')?._id, } })
      }
    }, [versions, data])

    const dispatch = useDispatch()
    useEffect(() => {
      !businessTypes && dispatch(getData('businessTypes'))
      !products && dispatch(getData('products'))
      !versions && dispatch(getData('versions'))
      !connectedGroups && dispatch(getConnectedGroups())
      dispatch(getClientsForDropdown({ search: "", getAllDetails: true }))
    }, [])

    const isValid = () => {
      if (requiredFields.some((field) => !data?.[field])) {
        Alerts.error('Моля попълнете всички задължителни полета в основна информация')
        return false
      } else if (!data?.eic && !data?.pin) {
        Alerts.error('Моля попълнете ЕИК или ЕГН')
        return false
      } else if (data?.contacts?.length && data?.contacts?.some(({ email, phoneNumber }) => !email && !phoneNumber)) {
        Alerts.error('Моля попълнете всички задължителни полета в допълнителен контакт')
        return false
      } else if ([data?.email, ...(data?.contacts || [])?.map(({ email }) => email)].filter(Boolean).some((val) => !emailRegex.test(val))) {
        Alerts.error('Моля попълнете валиден имейл')
        return false
      } else if (process.env.REACT_APP_PLATFORM === 'Synergon' && isEmpty(data?.dealers)) {
        Alerts.error('Моля добавете търговец')
        return false
      }

      if (data.clientType !== 'Производител') {
        const files = data?.contractSettings?.files?.map(({ type }) => type)
        if (
          !data?.product ||
          productsPricesFields
            .filter(({ types }) => types.includes(products?.find(({ _id }) => data?.product === _id)?.name))
            .map(({ value }) => value)
            .some((field) => [undefined, null].includes(data?.price?.[field]))
        ) {
          Alerts.error('Моля попълнете продукт и всички цени')
          return false
        } else if (
          requiredContractFields.some((field) => !data?.contractSettings?.[field]) ||
          (!data?.contractSettings?.contractDurationMonths && !data?.contractSettings?.deliveryEnd) ||
          (data?.existingClient && !data?.contractSettings?.deliveryStart) ||
          (data?.autoSign && !data.autoSignMonths)
        ) {
          Alerts.error('Моля попълнете всички задължителни полета в документи и договор')
          return false
        } else if (process.env.REACT_APP_PLATFORM !== 'Proakt' && ['letter of attorney', 'contract'].some((val) => !files?.includes(val))) {
          Alerts.error('Моля добавете пълномощно и договор')
          return false
        } else if (process.env.REACT_APP_PLATFORM !== 'Proakt' && data?.contractSettings?.type === 'Комбиниран' && !files?.includes('declaration')) {
          Alerts.error('При комбиниран договор е необходимо да прикачите Декларация')
          return false
        }
      }

      if (
        process.env.REACT_APP_PLATFORM === 'Synergon' &&
        data?.clientType !== 'Консуматор' &&
        (!data?.invoiceSettings?.producerBank || !IBANRegex.test(data?.invoiceSettings?.producerIBAN))
      ) {
        Alerts.error('Невалидна Банка/IBAN')
        return false
      }

      if (data?.clientType !== 'Консуматор' && data?.producerSettings && !data?.producerSettings?.disabled) {
        const { isValid, error } = isValidProducerSettings({ data, products })
        if (!isValid) {
          Alerts.error(error)
          return false
        }
      }

      return true
    }

    const isRequired = (field) => showRequired && (!data?.[field] && !data?.price?.[field] || data?.businessType && isEmpty(data?.businessType)) && 'required'
    const isEmail = (email) => showRequired && !emailRegex.test(email) && 'required'
    const isIBAN = (IBAN) => IBAN && showRequired && !IBANRegex.test(IBAN) && 'required'

    const onRequired = (value) => setShowRequired(value)
    const onClear = () => {
      if (data?._id) setEditable(false)
      setData(initialData)
      setShowRequired(false)
    }
    const onSave = async () => {
      if (!isValid()) {
        setShowRequired(true)
        return
      } else {
        dispatch(startLoading())
        const payload = {
          ...data,
          producerSettings: data?.producerSettings?.disabled ? undefined : data?.producerSettings,
          dealers: data?.dealers?.map(({ _id }) => _id)
        }

        if (payload?._id) dispatch(updateClient({ data: payload, onSuccess: () => setEditable(false) }))
        else {
          if (payload.type === 'Юридическо лице') payload.clientNumber = payload.eic
          else {
            const generatedNumber = await fetch(`${URL}/clientNumber/request`, {
              method: 'POST',
              headers: Headers.getWithAuth(),
              body: JSON.stringify({ "requestedFor": "Clients" }),
            }).then(res => res.json())
            payload.clientNumber = generatedNumber.clientNumber
          }
          dispatch(createClient({
            data: payload,
            onSuccess: (newClient) => {
              if (payload?.comments) dispatch(createComment({ data: { refType: 'Clients', ref: newClient?._id, text: payload?.comments } }))
              handleSuccess(newClient)
            },
          }))
        }
      }
    }

    if (ref) ref.current = { onRequired, onClear, onSave }

    const [businessDetails, setBusinessDetails] = useState()
    const history = useHistory()
    const startClientFetch = () => {
      dispatch(startLoading())
      dispatch(
        checkClientExists({
          data: data?.eic,
          exact: true,
          onSuccess: (clients) => {
            if (isEmpty(clients)) dispatch(startApisFetch({ data: data?.eic, onSuccess: (response) => handleClientFetch(response) }))
            else {
              dispatch(stopLoading())
              if (
                isDealer &&
                clients?.at(0)?.dealers?.every(({ _id }) => _id !== currentUser?._id) &&
                clients?.at(0)?.status !== 'inactive'
              ) Alerts.error('Нямате достъп до този клиент')
              else Alerts.success(
                clients?.at(0)?.status === 'active'
                  ? {
                    title: `Активен клиент`,
                    text: `Този клиент е част от Балансиращата ни група. Можете да ${isDealer ? "" : "редактирате базовата информация и да "}добавите нови обекти.`,
                  }
                  : {
                    title: `Неактивен клиент`,
                    text: `Този клиент не получава енергия в момента. Можете да редактирате базовата информация и да добавите нови обекти и да прикачите нов договор`,
                  },
                () => {
                  const url = new URLSearchParams()
                  if (clients?.at(0)?.status !== 'active') url.append('inactive', "true")
                  if (!isDealer || clients?.at(0)?.status !== 'active') url.append('edit', "true")
                  history.push(`/clients/${clients[0]._id}?` + url.toString())
                }
              )
            }
          },
        })
      )
    }
    const handleClientFetch = ([{ identifier, name, legalFormShort, managers, owners, vat, addresses, wayOfRepresentation = { missing: '1' } }]) => {
      try {
        wayOfRepresentation = Object.keys(wayOfRepresentation || {}).find((key) => !!parseInt(wayOfRepresentation[key]))
        if (!managers) managers = owners
        const addressToBeUsed = addresses?.find(({ addressType }) => addressType === 1) || addresses?.find(({ addressType }) => addressType === 0) || vat?.address
        let address = [["housingEstate", "жк "], ["street"], ["streetNumber"], ["block", "бл."], ["entrance", "вх."], ["floor", "ет."], ["apartment", 'ап.']]
          .reduce((acc, [field, prefix = '']) => addressToBeUsed?.[field] ? [...acc, { field, label: `${prefix}${addressToBeUsed?.[field]}` }] : acc, [])
        address = address.reduce((acc, { field, label }) => {
          if (['street'].includes(field)) return [...acc, [label, address.find(({ field }) => field === 'streetNumber')?.label].filter(Boolean).join(" ")]
          else if (['streetNumber'].includes(field)) return acc
          else return [...acc, label]
        }, [])
        setBusinessDetails({ [wayOfRepresentation]: true, managers })
        setData({
          ...data,
          fullName: (legalFormShort === 'ЕТ' ? [legalFormShort, name] : [name, legalFormShort]).join(' '),
          bulstat: vat ? `BG${identifier}` : '',
          mol: managers.length === 1 || ['jointly'].includes(wayOfRepresentation) ? managers.map(({ name, role }) => ({ fullName: name, role })) : undefined,
          city: addressToBeUsed?.settlement,
          address: !isEmpty(address) ? address.join(', ') : addressToBeUsed?.posCode || '-'
        })
      } catch (error) {
        console.log(error);
      }
    }

    const [dealers, setDealers] = useState([])
    const getUsersPayload = { select: 'fullName coverPhoto', role: 'dealer' }
    const updateDealers = (fullName) => dispatch(getUsers({
      payload: { ...getUsersPayload, fullName },
      onSuccess: (res) => setDealers((dealers) => uniqBy([...(dealers || []), ...(res || [])], '_id'))
    }))
    useEffect(() => {
      if (isDealer !== undefined) dispatch(getUsers({
        payload: getUsersPayload, onSuccess: (res) => {
          if (isDealer) {
            handleChange('dealers', [pick(currentUser, ['_id', 'fullName'])])
            const dealers = uniqBy([...(res || []), currentUser], '_id')
            setDealers(dealers)
          } else setDealers(res)
        }
      }))
    }, [isDealer])

    return (
      <>
        <div className="client-forms-main-container">
          <div className="client-forms-main-header row ">
            <h2>Основна информация</h2>
            {!isDealer && <Button.Switch
              label="Незабавна активация"
              isOn={!!data?.existingClient}
              onChange={() =>
                setData({
                  ...data,
                  existingClient: !data?.existingClient,
                  contractSettings: {
                    ...data?.contractSettings,
                    deliveryStart: data?.existingClient ? null : data?.contractSettings?.deliveryStart || null,
                    deliveryEnd: data?.contractSettings?.maturity === "Брой месеци" ? null : data?.contractSettings?.deliveryEnd
                  },
                })
              }
            />}
          </div>
          <div className="row">
            <div className="col col-double">
              <span>Тип клиент</span>
              <Inputs.Dropdown
                options={['Юридическо лице', 'Физическо лице', 'Битов'].filter((value) => clientType !== 'Производител' || value !== 'Битов').map((value) => ({ value, label: value }))}
                value={data?.type}
                onChange={({ value }) => handleChange('type', value)}
                className={`${isRequired('type')}`}
              />
            </div>
            <div className="col">
              <span>ЕИК/ЕГН</span>
              <Input.Text
                value={data?.eic || ''} onChange={({ target: { value } }) => {
                  if (!/^\d+$/.test(value) && value !== '') return
                  handleChange('eic', value)
                }}
                inputClassName={`${isRequired('eic')}`}
                disabled={!editable}
                onKeyDown={({ key }) => key === 'Enter' && startClientFetch()}
              />
            </div>
            {editable && (
              <div className="col">
                <span></span>
                <Button.Raised text="Извлечи данни" disabled={!data?.eic} onClick={() => startClientFetch()} />
              </div>
            )}
            <div className="col">
              <span>Име на клиент</span>
              <Input.Text
                value={data?.fullName || ''}
                onChange={({ target: { value } }) => handleChange('fullName', value)}
                inputClassName={`${isRequired('fullName')}`}
                disabled={!editable}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <span>БУЛСТАТ</span>
              <Input.Text
                value={data?.bulstat || ''}
                onChange={({ target: { value } }) => {
                  if (!/^[A-Za-z\d]+$/.test(value) && value !== '') return
                  handleChange('bulstat', value)
                }}
                disabled={!editable}
              />
            </div>
            <div className="col col-mol">
              <span>МОЛ</span>
              {!businessDetails || businessDetails.jointly || businessDetails.managers.length === 1 ? (
                <Input.Text
                  value={data?.mol?.map(({ fullName }) => fullName)?.join(', ') || ''}
                  onChange={({ target: { value } }) => handleChange('mol', [{ fullName: value, role: 'управител' }])}
                  inputClassName={`${isRequired('mol')}`}
                  disabled={businessDetails || !editable}
                />
              ) : (
                <>
                  <div className="business-details row">
                    {businessDetails['jointly']
                      ? 'Заедно'
                      : businessDetails['severally']
                        ? 'Поотделно'
                        : businessDetails['otherWay']
                          ? 'Друго'
                          : 'Липсва Инф.'}
                    <div
                      className="icon icon-info-custom"
                      onClick={() =>
                        dispatch(
                          setModal({
                            isOpen: true,
                            type: 'confirmation',
                            props: {
                              title: 'Допълнителна информация',
                              children: (
                                <div className="">
                                  <span>Управители:</span>
                                  {businessDetails?.managers?.map(({ name, role, indentType, indent }, i) => (
                                    <p key={`manager-${i}`} style={{ fontWeight: 'bold' }}>
                                      {name} - {role} - {indentType} - {indent}
                                    </p>
                                  ))}
                                </div>
                              ),
                              hideButton: true,
                            },
                          })
                        )
                      }
                    />
                  </div>
                  <Inputs.Select
                    editable={editableProp || editable}
                    handleChange={(value) => {
                      if (businessDetails?.severally) value = [value]
                      const managers = value
                        .map((name) => businessDetails?.managers.find((manager) => name === manager.name))
                        .map(({ name, role }) => ({ fullName: name, role }))
                      handleChange('mol', managers)
                    }}
                    value={data?.mol?.map(({ fullName }) => fullName)}
                    options={businessDetails?.managers?.map(({ name, role }) => ({
                      _id: name,
                      label: `${name} - ${role}`,
                      name,
                    }))}
                    showRequired={showRequired}
                    text={{ main: 'МОЛ', secondary: 'Избери лице/а' }}
                    single={businessDetails?.severally}
                    useTooltip
                  />
                </>
              )}
            </div>
            <div className="col">
              <span>Град</span>
              <Input.Text
                value={data?.city || ''}
                onChange={({ target: { value } }) => handleChange('city', value)}
                inputClassName={`${isRequired('city')}`}
                disabled={!editable}
              />
            </div>
            <div className="col col-double">
              <span>Адрес</span>
              <Input.Text
                value={data?.address || ''}
                onChange={({ target: { value } }) => handleChange('address', value)}
                inputClassName={`${isRequired('address')}`}
                disabled={!editable}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <span>Телефон</span>
              <Input.Text
                value={data?.phoneNumber || ''}
                onChange={({ target: { value } }) => {
                  if (!/^\+?\d*$/.test(value) && value !== '') return
                  handleChange('phoneNumber', value)
                }}
                inputClassName={`${isRequired('phoneNumber')}`}
                disabled={!editable}
              />
            </div>
            <div className="col">
              <span>Имейл</span>
              <Input.Text
                value={data?.email || ''}
                onChange={({ target: { value } }) => handleChange('email', value?.trim())}
                inputClassName={`${isRequired('email') || isEmail(data?.email)}`}
                disabled={!editable}
              />
            </div>
            {!isDealer && clientType !== 'Производител' && <div className="col col-activity">
              <span>Дейности</span>
              <Inputs.Select
                className="activity-container"
                editable={editableProp || editable}
                options={businessTypes}
                value={data?.businessType}
                handleChange={(value) => handleChange('businessType', value)}
                createDataType="businessTypes"
                text={{ main: 'Дейности', secondary: 'Избери дейност/и' }}
                search
              />
            </div>}
            {!isDealer && <div className={`col col-additional-client ${!editable && 'disabled'}`}>
              <span>Група клиенти</span>
              <Inputs.DropdownSelect
                async paginate cacheOptions defaultOptions isMulti
                loadOptions={(name) => loadDropdownOptions({ path: '/groups/browse', payload: { name, limit: 10 }, paginate: true })}
                rawValue={data?.group ?? []}
                onChange={(value) => handleChange('group', value)}
                getOptionValue={({ _id }) => _id}
                getOptionLabel={({ name }) => name}
                additional={{ page: 1 }}
                disabled={!editable}
              />
            </div>}
            <div className="col col-connected-client">
              <span>Свързани клиенти</span>
              <div className="add-icon-container row">
                {data?.connectedGroup ? (
                  <div className="row row-client">
                    <Button.Icon name="plus" color="red" onClick={() => handleChange('connectedGroup', null)} />
                    <span>{connectedGroups?.find(({ _id }) => _id === data?.connectedGroup)?.name}</span>
                    <div
                      className="icon icon-open"
                      onClick={() => dispatch(setModal({
                        isOpen: true, type: 'confirmation', props: {
                          title: `Група ${connectedGroups?.find(({ _id }) => _id === data?.connectedGroup)?.name}`,
                          children: (
                            <div className="">
                              {connectedGroups
                                ?.find(({ _id }) => _id === data?.connectedGroup).clients
                                ?.map(({ fullName, eic, pin }) => <div className="">
                                  <p>{fullName} - {eic || pin}</p>
                                </div>)}
                            </div>
                          ),
                          hideButton: true
                        },
                      }))}
                    />
                  </div>
                ) : (
                  <Popover
                    popover={({ close }) => (
                      <Popup.Select
                        single
                        search
                        handleSearch={(value) => dispatch(getClientsForDropdown({ search: value, getAllDetails: true }))}
                        hide={close}
                        handleChange={(value) => {
                          const client = clients.find(({ _id }) => _id === value)
                          if (!client) return
                          else if (client?.connectedGroup) handleChange('connectedGroup', client?.connectedGroup?._id)
                          else
                            dispatch(
                              setModal({
                                isOpen: true,
                                type: 'connectedClientGroup',
                                props: {
                                  onCreate: (name) => {
                                    dispatch(
                                      createConnectedGroup({
                                        data: { name, clients: [value] },
                                        onSuccess: (group) => handleChange('connectedGroup', group),
                                      })
                                    )
                                  },
                                },
                              })
                            )
                        }}
                        options={clients?.map(({ eic, pin, fullName, _id }) => ({
                          label: `${fullName} | ${eic || pin}`,
                          value: _id,
                        }))}
                        text={{ main: 'Търси по име/ЕИК/ЕГН', secondary: 'Клиенти' }}
                      />
                    )}
                  >
                    <div className="icon icon-add" />
                  </Popover>
                )}
              </div>
            </div>
          </div>
          {clientType !== 'Консуматор' && <div className="row">
            <div className="col">
              <span>Банка</span>
              <Inputs.Dropdown
                options={producerBanksOptions.map((value) => ({ label: value, value }))}
                onChange={({ value }) => handleChange('invoiceSettings', { ...data?.invoiceSettings, producerBank: value })}
                value={data?.invoiceSettings?.producerBank}
                disabled={!editable}
                className={`${showRequired && !data?.invoiceSettings?.producerBank && 'required'}`}
              />
            </div>
            <div className="col col-double">
              <span>IBAN</span>
              <Input.Text
                value={data?.invoiceSettings?.producerIBAN ?? ""}
                onChange={({ target: { value } }) => handleChange('invoiceSettings', { ...data?.invoiceSettings, producerIBAN: value })}
                disabled={!editable}
                inputClassName={`${(showRequired && !data?.invoiceSettings?.producerIBAN && 'required') || isIBAN(data?.invoiceSettings?.producerIBAN)}`}
              />
            </div>
          </div>}
          <div className="row">
            <div className={`col col-triple col-merchant ${!editable && 'disabled'}`}>
              <span>Търговци</span>
              <div className="row">
                {editable && (
                  <Popover
                    popover={({ close }) => (
                      <Popup.Select
                        hide={close}
                        search
                        handleSearch={updateDealers}
                        value={data?.dealers?.map(({ _id }) => _id)}
                        options={dealers?.map(({ fullName, _id }) => ({ label: fullName, value: _id }))}
                        handleChange={(value) => {
                          const payload = [...new Set(value.map(_id => dealers.find((d) => d._id === _id)))]
                          handleChange('dealers', payload)
                          // setClientData({ dealers: payload })
                        }}
                        text={{ main: 'Търси по име', secondary: 'Търговци' }}
                      />
                    )}
                  >
                    <div className="icon icon-add" />
                  </Popover>
                )}
                <div className="row">
                  {data?.dealers?.map(({ _id, fullName, coverPhoto }) => (
                    <div key={_id} className="single-person-container row">
                      <div
                        className="profile-image-container"
                        style={{ backgroundImage: `url(${coverPhoto || require('../../../../assets/images/default-user-avatar.jpg')})` }}
                      />
                      <p>{fullName}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Client.Forms.AdditionalContact
          clientType={data?.clientType}
          data={data?.contacts}
          handleRemoveContact={(i) => {
            const newContacts = [...(data?.contacts || [])]
            newContacts.splice(i, 1)
            handleChange('contacts', newContacts)
          }}
          handleChangeContact={(field, value, i) => {
            const newContacts = [...(data?.contacts || [])]
            newContacts.splice(i, 1, { ...newContacts?.at(i), [field]: value })
            handleChange('contacts', newContacts)
          }}
          handleChange={(field, value) => handleChange(field, value)}
          showRequired={showRequired}
          editable={editable}
        />
        {clientType !== 'Производител' && < Client.Forms.DocumentsAndContract
          data={data}
          setData={(payload) => setData({ ...data, ...payload })}
          contractSettings={data?.contractSettings}
          setContractSettings={(newData) => handleChange('contractSettings', newData)}
          handleChange={(field, value) => handleChange('contractSettings', { ...data?.contractSettings, [field]: value })}

          versions={versions}
          products={products}
          handlePriceChange={handlePriceChange}

          showRequired={showRequired}
          editable={editable}
        />}
        {clientType !== 'Консуматор' && <Client.Forms.ProducerSettings
          producerSettings={data?.producerSettings}
          setProducerSettings={(newData) => handleChange('producerSettings', newData)}
          handleChange={(field, value) => handleChange('producerSettings', { ...data?.producerSettings, [field]: value })}

          versions={versions}
          products={products}

          showRequired={showRequired}
          editable={editable}

          isClient
        />}
        <div className="client-forms-main-footer row">
          <div className="col col-double">
            <span>Коментар към клиент</span>
            <Input.Text
              value={(isArray(data?.comments) && !isEmpty(data?.comments) ? data?.comments[0].text : data?.comments) || ''}
              onChange={({ target: { value } }) => handleChange('comments', value)}
              disabled={!editable}
            />
          </div>
          {editable && <Button.Raised className="btn-save" text="Запази и продължи" onClick={onSave} />}
        </div>
      </>
    )
  }
)

export default Main
