import React, { useMemo, useEffect } from 'react'
import { Input, Button } from '@makedonski/admin-ui'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { startLoading, stopLoading } from 'actions'
import { Client, Inputs } from 'components'
import { checkIsDealer, uploadFile } from 'utilities'
import { documentTypes } from 'config/constants'
import './styles.scss'

const DocumentsAndContract = ({
  data: client,
  setData: setClientData,
  contractSettings: data,
  setContractSettings: setData,
  handleChange,

  versions,
  products,
  handlePriceChange,

  showRequired,
  editable,
  isProfile,
  isInactive
}) => {
  const dispatch = useDispatch()
  const isRequired = (field) => showRequired && !data?.[field] && 'required'
  const isDealer = checkIsDealer()

  const handleFiles = (type, file) => {
    let newFiles
    const index = data?.files?.findIndex((file) => file?.type === type)
    if (index || index !== -1) {
      newFiles = (data?.files || []).filter((file) => file?.type !== type)
      if (file !== null) newFiles = [...newFiles, file]
    } else[...(data?.files || []), file]
    handleChange('files', newFiles)
  }
  const handleUpload = (files, field) => {
    dispatch(startLoading())
    uploadFile({ file: files[0], createId: true }).then(({ location }) => {
      dispatch(stopLoading())
      handleFiles(field, { url: location, name: files[0].name, type: field, new: isProfile })
    })
  }

  const version = useMemo(() => {
    return versions?.find(({ _id }) => _id === data?.version)?.name?.split(' ')[0]
  }, [data?.version])
  useEffect(() => {
    if (['2.19', '6.20', '6.20.1'].includes(version)) handleChange('terms', true)
  }, [version])

  useEffect(() => {
    if (client?.existingClient && data?.maturity === 'Брой месеци') {
      handleChange('deliveryEnd',
        data?.deliveryStart && data?.contractDurationMonths
          ? moment(data?.deliveryStart).add((data?.contractDurationMonths || 1) - 1, 'months').endOf('month').toDate()
          : null
      )
    }
  }, [data?.contractDurationMonths, data?.deliveryStart])


  return (
    <div className="client-forms-documents-and-contract-container">
      <h2>Документи и договор</h2>
      {(!isProfile || isInactive || editable) && (
        <div className="row row-files">
          {documentTypes
            // .filter(({ value }) => !isInactive || ['letter of attorney', 'contract'].includes(value))
            .map(({ value, label }, i) => {
              const file = data?.files?.find((file) => file?.type === value)
              return (
                <div key={`documents-field-${i}`} className="col">
                  <span>{label}</span>
                  {file ? (
                    <div className="row row-file">
                      {editable && <Button.Icon name="plus" color="red" onClick={() => handleFiles(value, null)} />}
                      <span>{file.name}</span>
                    </div>
                  ) : (
                    <Button.UploadButton
                      text={
                        <Input.DragAndDrop handleDrop={(files) => handleUpload(files, value)}>
                          <span className="row ripple">
                            Добави файл <div className="icon icon-attach" />
                          </span>
                        </Input.DragAndDrop>
                      }
                      className={`${!editable && 'disabled'} ${['letter of attorney', 'contract'].includes(value) && isRequired(value)
                        } ${['declaration'].includes(value) && data?.type === 'Комбиниран' && isRequired(value)}`}
                      multiple
                      accept="*"
                      onChange={({ target: { files } }) => handleUpload(files, value)}
                      disabled={!editable}
                    />
                  )}
                </div>
              )
            })}
        </div>
      )}
      <div className="row">
        <div className="col">
          <span>Продукт</span>
          <Inputs.Dropdown
            options={products?.filter(({ forProducer }) => !forProducer)?.map(({ _id, name }) => ({ label: name, value: _id, }))}
            onChange={({ value }) => {
              const product = products?.find(({ _id }) => _id === value)?.name
              setClientData({ ...client, product: value, price: product === 'Борса' ? { market: 0 } : null })
            }}
            value={client?.product?._id || client?.product}
            className={`${showRequired && !client?.product && 'required'}`}
            disabled={!editable}
          />
        </div>
        <Client.Forms.ProductPrices
          products={products}
          data={client}
          handleChange={handlePriceChange}
          showRequired={showRequired}
          editable={editable}
        />
      </div>
      <div className="row row-contract-type">
        <div className="col col-radio-buttons">
          <span>Тип договор</span>
          <Inputs.RadioButtons
            buttons={['Комбиниран', 'Стандартен']}
            value={data?.type}
            onClick={(value) => setClientData({ contractSettings: { ...data, type: value, version: null }, declaration: false })}
            disabled={!editable}
          />
        </div>
        <div className="row">
          {isDealer && data?.type === 'Стандартен' && <div className={`col col-small col-switch col-declaration-dealer ${!editable && 'disabled'}`}>
            <span>Декларация</span>
            <Button.Switch
              isOn={client?.declaration}
              onChange={() => editable && setClientData({ declaration: !client?.declaration })}
            />
          </div>}

          <div className="col">
            <span>Версия договор</span>
            <Inputs.Dropdown
              options={versions
                ?.filter(({ type }) => type === data?.type)
                ?.map(({ _id, name }) => ({ value: _id, label: name }))}
              onChange={({ value }) => handleChange('version', value)}
              value={data?.version?._id || data?.version}
              className={`${isRequired('version')}`}
              disabled={!editable}
            />
          </div>
          {!isDealer && <div className="col">
            <span>Дата на договор</span>
            <Inputs.DatePicker
              value={data?.contractDate}
              onChange={(value) => handleChange('contractDate', value)}
              /*className={`${isRequired('contractDate')}`}*/
              disabled={!editable}
            />
          </div>}
          {!isDealer && <div className="col">
            <span>№ на договор</span>
            <Input.Text
              value={data?.contractNumber || ''}
              onChange={({ target: { value } }) => handleChange('contractNumber', value)}
              /*inputClassName={`${isRequired('contractNumber')}`}*/
              disabled={!editable}
            />
          </div>}
        </div>
      </div>
      <div className="row row-contract-length">
        <div className="col col-radio-buttons">
          <span>Вид срочност на договор</span>
          <Inputs.RadioButtons
            buttons={['Фиксиран край', 'Брой месеци']}
            value={data?.maturity}
            onClick={(value) => setData({ ...data, maturity: value, contractDurationMonths: null, deliveryEnd: null })}
            disabled={!editable}
          />
        </div>
        <div className="row">
          {(client?.existingClient || (client?.contractSettings?.deliveryStart && (!isDealer || data?.maturity !== 'Брой месеци'))) && (
            <div className="col">
              <span>Начало на доставка</span>
              <Inputs.DatePicker
                value={data?.deliveryStart}
                onChange={(value) => handleChange('deliveryStart', moment(value).startOf('month').toDate())}
                minDate={moment().subtract(10, 'y').toDate()}
                className={`month-picker ${isRequired('deliveryStart')}`}
                showMonthYearPicker
                showFullMonthYearPicker
                disabled={!editable || isDealer}
              />
            </div>
          )}
          {data?.maturity === 'Брой месеци' && (
            <div className="col">
              <span>Срок на договор</span>
              <Input.Text
                value={data?.contractDurationMonths || ''}
                onChange={({ target: { value } }) => {
                  if (!/^\d+$/.test(value) && value !== '') return
                  setClientData({
                    contractSettings: {
                      ...data,
                      contractDurationMonths: value,
                      deliveryEnd: value && data.deliveryStart
                        ? moment(data.deliveryStart).startOf('month').add(value, 'months').subtract(1, 'months').endOf('month').toDate()
                        : null
                    }
                  })
                }}
                inputClassName={`${isRequired('contractDurationMonths')}`}
                disabled={!editable}
              />
            </div>
          )}
          {(data?.maturity !== 'Брой месеци' || client?.existingClient || isProfile) && (!isInactive || data?.maturity !== 'Брой месеци') && (
            <div className="col">
              <span>Край на доставка</span>
              <Inputs.DatePicker
                value={data?.deliveryEnd}
                onChange={(value) => handleChange('deliveryEnd', moment(value).endOf('month').toDate())}
                minDate={moment().add(1, 'months').startOf('month').toDate()}
                className={`month-picker ${isRequired('deliveryEnd')}`}
                showMonthYearPicker
                showFullMonthYearPicker
                disabled={!editable || (data?.maturity === 'Брой месеци' && client?.existingClient) || (data?.maturity === 'Брой месеци' && isProfile)}
              />
            </div>
          )}
        </div>
      </div>
      <div className="row row-contract-expire">
        {!isDealer && <div className="col col-radio-buttons">
          <span>Дни падеж</span>
          <Inputs.RadioButtons
            buttons={['Работни', 'Календарни', 'Ден от месеца']}
            value={data?.paymentDaysType}
            onClick={(data) => handleChange('paymentDaysType', data)}
            disabled={!editable}
          />
        </div>}
        <div className="row">
          {!isDealer && <>
            <div className="col">
              <span></span>
              <Input.Text
                value={data?.paymentDays || ''}
                onChange={({ target: { value } }) => {
                  if (!/^\d+$/.test(value) && value !== '') return
                  handleChange('paymentDays', value)
                }}
                inputClassName={`${isRequired('paymentDays')}`}
                disabled={!editable}
              />
            </div>
            <div className={`col col-small col-switch ${!editable && 'disabled'}`}>
              <span>Общи условия</span>
              <Button.Switch isOn={data?.terms} onChange={() => editable && handleChange('terms', !data?.terms)} />
            </div>
            {!isDealer && <div className={`col col-small col-switch ${!editable && 'disabled'}`}>
              <span>Неустойка</span>
              <Button.Switch isOn={data?.penalty} onChange={() => editable && handleChange('penalty', !data?.penalty)} />
            </div>}
            {data?.type === 'Стандартен' && <div className={`col col-small col-switch ${!editable && 'disabled'}`}>
              <span>Декларация</span>
              <Button.Switch
                isOn={client?.declaration}
                onChange={() => editable && setClientData({ declaration: !client?.declaration })}
              />
            </div>}
          </>}
          <div className={`col col-double col-switch ${!editable && 'disabled'}`}>
            <span>Автоматично преподписване</span>
            <div className="row row-auto-sign">
              <Button.Switch
                isOn={data?.autoSign}
                onChange={() => {
                  if (editable) {
                    setData({ ...data, autoSign: !data?.autoSign, autoSignMonths: null })
                    setTimeout(() => { document.getElementsByClassName('focus')[0]?.focus() }, 0)

                  }
                }}
              />
              {data?.autoSign && (
                <Inputs.TextLabeled
                  label="мес."
                  value={data?.autoSignMonths || ''}
                  onChange={({ target: { value } }) => {
                    if (!/^\d{0,2}$/.test(value) && value !== '') return
                    handleChange('autoSignMonths', value)
                  }}
                  inputClassName={`focus ${isRequired('autoSignMonths')}`}
                  disabled={!editable}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DocumentsAndContract
