export const clientsTypes = {
  SET_FIELD: 'clients/SET_FIELD',
  SET_FIELDS: 'clients/SET_FIELDS',

  GET_CLIENTS_FILTERS: 'clients/GET_CLIENTS_FILTERS',
  GET_CLIENTS_FILTERS_SUCCESS: 'clients/GET_CLIENTS_FILTERS_SUCCESS',

  GET_CLIENTS_START: 'clients/GET_CLIENTS_START',
  GET_CLIENTS: 'clients/GET_CLIENTS',
  GET_CLIENTS_SUCCESS: 'clients/GET_CLIENTS_SUCCESS',

  GET_CLIENT: 'clients/GET_CLIENT',
  GET_CLIENT_SUCCESS: 'clients/GET_CLIENT_SUCCESS',

  CREATE_CLIENT: 'clients/CREATE_CLIENT',
  UPDATE_CLIENT: 'clients/UPDATE_CLIENT',

  GET_FILES_CLIENT: 'clients/GET_FILES_CLIENT',
  GET_FILES_CLIENT_SUCCESS: 'clients/GET_FILES_CLIENT_SUCCESS',
  UPLOAD_FILE_CLIENT: 'clients/UPLOAD_FILE_CLIENT',
  UPDATE_FILE_CLIENT: 'clients/UPDATE_FILE_CLIENT',
  DELETE_FILE_CLIENT: 'clients/DELETE_FILE_CLIENT',

  CHECK_CLIENT_EXISTS: 'clients/CHECK_CLIENT_EXISTS',

  EXPORT_CLIENTS: 'clients/EXPORT_CLIENTS',

  CLIENTS_REDISTRIBUTE_DEALERS: 'clients/CLIENTS_REDISTRIBUTE_DEALERS',
  MASS_EDIT_CLIENTS_PRICE: 'clients/MASS_EDIT_CLIENTS_PRICE',
}

export const setClientsField = (payload) => ({
  type: clientsTypes.SET_FIELD,
  payload,
})

export const setClientsFields = (payload) => ({
  type: clientsTypes.SET_FIELDS,
  payload,
})

export const getClientsStart = (payload) => ({
  type: clientsTypes.GET_CLIENTS_START,
  payload,
})

export const getClientsFilters = (payload) => ({
  type: clientsTypes.GET_CLIENTS_FILTERS,
  ...payload,
})

export const getClient = (payload) => ({
  type: clientsTypes.GET_CLIENT,
  ...payload,
})

export const createClient = (payload) => ({
  type: clientsTypes.CREATE_CLIENT,
  payload,
})

export const updateClient = (payload) => ({
  type: clientsTypes.UPDATE_CLIENT,
  payload,
})

export const getFilesClient = (payload) => ({
  type: clientsTypes.GET_FILES_CLIENT,
  payload,
})

export const uploadFileClient = (payload) => ({
  type: clientsTypes.UPLOAD_FILE_CLIENT,
  ...payload,
})

export const updateFileClient = (payload) => ({
  type: clientsTypes.UPDATE_FILE_CLIENT,
  payload,
})

export const deleteFileClient = (payload) => ({
  type: clientsTypes.DELETE_FILE_CLIENT,
  payload,
})

export const checkClientExists = (payload) => ({
  type: clientsTypes.CHECK_CLIENT_EXISTS,
  payload,
})

export const exportClients = (payload) => ({
  type: clientsTypes.EXPORT_CLIENTS,
  ...payload,
})

export const clientsRedistributeDealers = (payload) => ({
  type: clientsTypes.CLIENTS_REDISTRIBUTE_DEALERS,
  ...payload,
})

export const massEditClientsPrice = (payload) => ({
  type: clientsTypes.MASS_EDIT_CLIENTS_PRICE,
  ...payload,
})
