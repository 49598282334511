import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { setReferencesFields, getReferenceNomenclatures } from 'actions'
import { Shared } from 'components'
import { referenceNomenclaturesFields } from 'config/constants'
import { renderCell } from 'utilities'
import './styles.scss'

const Nomenclatures = ({ }) => {
  const dispatch = useDispatch()
  const { reference } = useSelector((state) => state.references)
  const [validFrom, setValidFrom] = useState()

  useEffect(() => {
    dispatch(getReferenceNomenclatures({ onSuccess: (response) => setValidFrom(response.validFrom) }))
    return () => dispatch(setReferencesFields({ reference: [] }))
  }, [])

  return (
    <div className="references-nomenclatures-container">
      <div className="references-nomenclatures-content">
        <Shared.Table
          fixedWidth
          columns={referenceNomenclaturesFields}
          data={reference}
          renderCell={renderCell.references.nomenclatures}
        />
      </div>
      <div className="row row-date">{validFrom && <p>В сила от: {moment(validFrom).format('DD.MM.YYYY')}</p>}</div>
    </div>
  )
}

export default Nomenclatures
