import React, { useState } from "react"
import { Input, Button } from '@makedonski/admin-ui'
import "./styles.scss"

const InputText = ({ close, title = "", text = "", defaultValue = "", onSuccess = () => { } }) => {
    const [state, setState] = useState(defaultValue)
    return <div className="modal-input-text-container">
        <div className="modal-input-text-header row">
            <h2>{title}</h2>
            <div className="icon icon-close" onClick={close} />
        </div>
        <div className="modal-input-text-content">
            {text && <p style={{ textAlign: 'center' }}>{text}</p>}
            <Input.Text value={state} onChange={({ target: { value } }) => setState(value)} />
        </div>
        <div className="modal-input-text-footer row">
            <Button.Raised onClick={() => onSuccess(state)} text="Продължи" disabled={!state} />
        </div>
    </div>
}

export default InputText