import { Alerts, Api } from "@makedonski/socourt-utilities"
import { mailerTypes, stopLoading } from "actions"
import { URL } from "config/settings"
import { ofType, ActionsObservable } from "redux-observable"
import { catchError, switchMap } from "rxjs/operators"



export const sendEmails = (action$) => action$.pipe(
    ofType(mailerTypes.SEND_EMAILS),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/mailer/send`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            })
            )
        )
    )
)