import React, { useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Button } from '@makedonski/admin-ui'
import { useSelector, useDispatch } from 'react-redux'
import { setClientsField, setModal } from '../../../actions'
import { clientsFields as allFields } from '../../../config/constants'
import { useQuery } from '../../../hooks'
import './styles.scss'

const ClientsColumns = ({}) => {
  const dispatch = useDispatch()
  const { clientsFields: currentFields } = useSelector((state) => state.clients)
  const { type: clientsType = 'list' } = useQuery()

  const [fields, setFields] = useState({
    visible: currentFields.filter(({ includedIn }) => !includedIn || includedIn.includes(clientsType)),
    hidden: allFields
      .filter(({ includedIn }) => !includedIn || includedIn.includes(clientsType))
      .filter(({ value }) => !currentFields.map(({ value }) => value).includes(value)),
  })

  const handleDrag = ({ source, destination }) => {
    if (!destination) return
    if (source.droppableId === destination.droppableId)
      setFields({
        ...fields,
        [source.droppableId]: reorderItems(fields[source.droppableId], source.index, destination.index),
      })
    else setFields(moveItems(fields[source.droppableId], fields[destination.droppableId], source, destination))
  }

  const reorderItems = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const moveItems = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source)
    const destClone = Array.from(destination)
    const [removed] = sourceClone.splice(droppableSource.index, 1)

    destClone.splice(droppableDestination.index, 0, removed)

    const result = {}
    result[droppableSource.droppableId] = sourceClone
    result[droppableDestination.droppableId] = destClone

    return result
  }

  const patchTransform = (isDragging, style) => {
    if (!isDragging || !style.transform) return style
    return { ...style, transform: `${style?.transform} rotate(5deg)` }
  }

  return (
    <div className="modal-clients-columns-container">
      <div className="modal-clients-columns-header row">
        <h2>Настройки колони</h2>
        <Button.Icon name="plus" size={28} onClick={() => dispatch(setModal({ isOpen: false }))} />
      </div>
      <div className="modal-clients-columns-content">
        <DragDropContext onDragEnd={(a) => handleDrag(a)}>
          <h3>Активни колони</h3>
          <Droppable
            droppableId={'visible'}
            direction="horizontal"
            renderClone={(provided, { isDragging }, rubric) => (
              <div
                className="clients-columns-dragable-element"
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={patchTransform(isDragging, provided.draggableProps.style)}
              >
                <p>{fields.visible[rubric.source.index].label}</p>
              </div>
            )}
          >
            {(dragProvided) => (
              <div className="scroll-container row" {...dragProvided.droppableProps} ref={dragProvided.innerRef}>
                {fields?.visible?.map(({ value, label }, i) => (
                  <Draggable key={`element-${i}`} draggableId={`single-element-${value}-${i}`} index={i}>
                    {(provided, { isDragging }) => (
                      <div
                        className="clients-columns-dragable-element"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={patchTransform(isDragging, provided.draggableProps.style)}
                      >
                        <p>{label}</p>
                      </div>
                    )}
                  </Draggable>
                ))}
                {dragProvided.placeholder}
              </div>
            )}
          </Droppable>

          <h3>Скрити колони</h3>
          <Droppable
            droppableId={'hidden'}
            direction="horizontal"
            renderClone={(provided, { isDragging }, rubric) => (
              <div
                className="clients-columns-dragable-element"
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={patchTransform(isDragging, provided.draggableProps.style)}
              >
                <p>{fields.hidden[rubric.source.index].label}</p>
              </div>
            )}
          >
            {(dragProvided) => (
              <div className="scroll-container row" {...dragProvided.droppableProps} ref={dragProvided.innerRef}>
                {fields?.hidden?.map(({ value, label }, i) => (
                  <Draggable key={`element-${i}`} draggableId={`single-element-hidden-${value}-${i}`} index={i}>
                    {(provided, { isDragging }) => (
                      <div
                        className="clients-columns-dragable-element hidden"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={patchTransform(isDragging, provided.draggableProps.style)}
                      >
                        <p>{label}</p>
                      </div>
                    )}
                  </Draggable>
                ))}
                {dragProvided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <Button.Raised
        text="Запази"
        onClick={() => {
          dispatch(setClientsField({ field: 'clientsFields', value: fields.visible }))
          dispatch(setModal({ isOpen: false }))
        }}
      />
    </div>
  )
}

export default ClientsColumns
