import { create, all } from 'mathjs';
const mathjs = create(all);
mathjs.config({ number: 'BigNumber' })

export const bn = (value) => {
    if (isNaN(value)) return 0
    return mathjs.bignumber(value || 0)
}
export const toNumber = (value) => mathjs.number(value || 0)

export const mAdd = (val1, val2) => toNumber(mathjs.add(bn(val1 || 0), bn(val2 || 0)))
export const mSubtract = (val1, val2) => toNumber(mathjs.subtract(bn(val1 || 0), bn(val2 || 0)))
export const mMultiply = (val1, val2) => toNumber(mathjs.multiply(bn(val1 || 0), bn(val2 || 0)))
export const mDivide = (val1, val2) => toNumber(mathjs.divide(bn(val1 || 0), bn(val2 || 0)))
