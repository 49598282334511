import React from 'react'
import moment from 'moment'
import { flatten, times } from 'lodash'
import { Shared } from 'components'
import { useQuery } from 'hooks'
import './styles.scss'

const Hourly = ({ reference }) => {
  const { period } = useQuery()
  if (period === 'year') return null
  if (period === 'day')
    return (
      <div className="references-market-tables-hourly-container card">
        <Shared.CollapsableRow header="Часови продукти" open>
          <div className="card-table card-table-hours row">
            <div className="fields">
              <p className="placeholder">{'.'}</p>
              {times(2).map((field, i) => (
                <div key={`field-${field}`} className="row">
                  <p>{i % 2 ? 'Цена' : 'К-во'}</p>
                </div>
              ))}
            </div>
            <div className="data">
              <Shared.Table
                rowHeight={62}
                columns={reference[0]?.data?.map(({ from, to }, i) => ({
                  size: 150,
                  value: (i + 1)?.toString(),
                  label: `${moment(from).format('H')}-${moment(to).format('H')}`,
                }))}
                data={[
                  reference[0]?.data?.reduce((acc, cur, n) => ({ ...acc, [n + 1]: cur?.price }), {}),
                  reference[0]?.data?.reduce((acc, cur, n) => ({ ...acc, [n + 1]: cur?.volume }), {}),
                ]}
                renderCell={(row, column) => row[column]}
              />
            </div>
          </div>
        </Shared.CollapsableRow>
      </div>
    )

  return (
    <div className="references-market-tables-hourly-container card">
      <Shared.CollapsableRow header="Часови продукти" open>
        <div className="card-table card-table-hours row">
          <div className="hours">
            <p className="placeholder">{'.'}</p>
            {times(24).map((field) => (
              <div key={`field-${field}`} className="row">
                <p>
                  {field}-{field + 1}{' '}
                </p>
                <div className="bracket" />
              </div>
            ))}
          </div>
          <div className="fields">
            <p className="placeholder">{'.'}</p>
            {times(48).map((field, i) => (
              <div key={`field-${field}`} className="row">
                <p>{i % 2 ? 'Цена' : 'К-во'}</p>
              </div>
            ))}
          </div>
          <div className="data">
            <Shared.Table
              fixedWidth={period !== 'month'}
              rowHeight={62}
              columns={reference?.map(({ date }, i) => ({
                size: 150,
                value: (i + 1).toString(),
                label: moment(date).format('DD.MM'),
              }))}
              data={flatten(
                times(24).map((row, i) => [
                  times(reference?.length || 0)?.reduce(
                    (acc, cur, n) => ({ ...acc, [n + 1]: reference?.[n]?.data?.[i]?.price }),
                    {}
                  ),
                  times(reference?.length || 0)?.reduce(
                    (acc, cur, n) => ({ ...acc, [n + 1]: reference?.[n]?.data?.[i]?.volume }),
                    {}
                  ),
                ])
              )}
              renderCell={(row, column) => row[column]}
            />
          </div>
        </div>
      </Shared.CollapsableRow>
    </div>
  )
}

export default Hourly
