export const noticesTypes = {
  SET_FIELD: 'notices/SET_FIELD',
  SET_FIELDS: 'notices/SET_FIELDS',

  GET_NOTICES: 'notices/GET_NOTICES',
  GET_NOTICES_SUCCESS: 'notices/GET_NOTICES_SUCCESS',
  SEARCH_NOTICES: 'notices/SEARCH_NOTICES',
}

export const setNoticesField = (payload) => ({
  type: noticesTypes.SET_FIELD,
  payload,
})

export const setNoticesFields = (payload) => ({
  type: noticesTypes.SET_FIELDS,
  payload,
})

export const getNotices = (payload) => ({
  type: noticesTypes.GET_NOTICES,
  payload,
})

export const searchNotices = (payload) => ({
  type: noticesTypes.SEARCH_NOTICES,
  ...payload,
})
