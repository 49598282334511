import { objectsTypes } from '../actions'
const initialState = {
  showCheckboxes: false,
  selectedCheckboxes: [],
}

const objects = (state = initialState, { type, payload }) => {
  switch (type) {
    case objectsTypes.SET_FIELD:
      return { ...state, [payload?.field]: payload?.value }
    case objectsTypes.SET_FIELDS:
      return { ...state, ...payload }
    default:
      return state
  }
}

export default objects
