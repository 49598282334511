import { Inputs } from "components"
import { Button } from '@makedonski/admin-ui'
import React, { useState } from "react"
import "./styles.scss"
import moment from "moment"
import { Portal } from "react-overlays"

const PriceActiveFrom = ({ close, onSaveClient }) => {
    const [date, setDate] = useState(moment().startOf('month').toDate())

    return <div className="modal-price-active-from-container col">
        <div className="modal-price-active-from-header row">
            <h2>Дата влизане в сила</h2>
            <div className="icon icon-close" onClick={close} />
        </div>
        <div className="modal-price-active-from-content">
            <Inputs.DatePicker
                value={date}
                onChange={(value) => setDate(moment(value).startOf('month').toDate())}
                maxDate={new Date()}
                dateFormat={'MMMM yyyy'}
                showMonthYearPicker
                showFullMonthYearPicker
                className="month-picker"
                popperContainer={({ children }) => <Portal container={document.getElementById('calendar-portal')}>{children}</Portal>}
            />
        </div>
        <div className="modal-price-active-from-footer">
            <Button.Raised text="Потвърди" onClick={() => { onSaveClient({ priceActiveFrom: date }); close() }} />
        </div>
    </div>
}

export default PriceActiveFrom