import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getUser, startLoading } from 'actions'
import { Employee as EmployeeComp, Card } from 'components'
import './styles.scss'

const Employee = ({ }) => {

  const dispatch = useDispatch()
  const { id } = useParams()
  const [employee, setEmployee] = useState({})
  const fetch = () => {
    dispatch(startLoading())
    dispatch(getUser({ payload: id, onSuccess: (res) => setEmployee(res) }))
  }
  useEffect(() => { fetch() }, [])



  const tabs = ['Активност', 'Отпуски']
  const [selectedTab, setSelectedTab] = useState(tabs[0])
  const renderMiddleContent = () => {
    switch (selectedTab) {
      case 'Активност':
        return <Card.SingleNotification />
      case 'Отпуски':
        return <EmployeeComp.Leave />
      default:
        return null
    }
  }

  return (
    <div className="screen-employee-container row">
      <div className="screen-employee-left col">
        <EmployeeComp.MainInfo fetch={fetch} employee={employee} />
        <EmployeeComp.Documents fetch={fetch} />
      </div>
      <div className="screen-employee-middle">
        <div className="screen-employee-middle-navigation row">
          {tabs?.map((tab, i) => (
            <div key={`tab-${i}`} className={`single-tab ${selectedTab === tab && 'selected'}`} onClick={() => setSelectedTab(tab)}>
              {tab}
            </div>
          ))}
        </div>
        <div className="screen-employee-middle-content">{renderMiddleContent()}</div>
      </div>
      <div className="screen-employee-right">
        <EmployeeComp.Tasks />
      </div>
    </div>
  )
}

export default Employee
