import { ofType, ActionsObservable } from 'redux-observable'
import { switchMap, mergeMap, catchError } from 'rxjs/operators'
import { Api, Alerts } from '@makedonski/socourt-utilities'
import { omit, isEmpty } from 'lodash'
import { objectsTypes, commentsTypes, stopLoading } from '../actions'
import { URL } from '../config/settings'

//prettier-ignore
const excludedField = ['comments', 'createdAt', 'updatedAt', '__v', 'id',
  "oldId", "employeeOldId", "clientOldId", 'statusOld',
  'region', 'municipality', 'neighborhood', 'postCode', 'subscriberNumberOld', "inputNumber",]

export const createObject = (action$) =>
  action$.pipe(
    ofType(objectsTypes.CREATE_OBJECT),
    mergeMap(({ payload: { data, onSuccess } }) =>
      Api.post(`${URL}/clients/objects`, JSON.stringify(data)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            //prettier-ignore
            if (!isEmpty(data.comments)) obs.next({ type: commentsTypes.CREATE_COMMENT, payload: { data: { refType: 'Objects', ref: response.object._id, text: data.comments[0].text } } })
            if (onSuccess) onSuccess(response.object)
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            const message = err?.response?.error?.message
            if (message === 'Съществуваща точка' && data?.previousClient)
              obs.next({ type: objectsTypes.UPDATE_OBJECT, payload: { data, onSuccess } })
            else Alerts.error(message, data?.itn || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const updateObject = (action$) =>
  action$.pipe(
    ofType(objectsTypes.UPDATE_OBJECT),
    mergeMap(({ payload: { data, onSuccess } }) =>
      Api.put(`${URL}/clients/objects`, JSON.stringify(omit(data, excludedField))).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            //prettier-ignore
            if (!isEmpty(data.comments)) {
              if (data.comments[0]?._id) obs.next({ type: commentsTypes.UPDATE_COMMENT, payload: { data: { _id: data.comments[0]._id, text: data.comments[0].text } } })
              else obs.next({ type: commentsTypes.CREATE_COMMENT, payload: { data: { refType: 'Objects', ref: response.object._id, text: data.comments[0].text } } })
            }
            if (onSuccess) onSuccess(response.object)
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const getObjectsDocumentsMissing = (action$) =>
  action$.pipe(
    ofType(objectsTypes.GET_OBJECTS_DOCUMENTS_MISSING),
    mergeMap(({ payload, onSuccess }) =>
      Api.post(`${URL}/clients/objectsDocumentsMissing`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response?.objects || [])
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error(err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const checkObjectExists = (action$) =>
  action$.pipe(
    ofType(objectsTypes.CHECK_OBJECT_EXISTS),
    mergeMap(({ payload, onSuccess }) =>
      Api.post(`${URL}/clients/search/itn`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response?.object)
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            console.log(err)
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )


export const removeCloneObject = (action$) => action$.pipe(
  ofType(objectsTypes.REMOVE_CLONE_OBJECT),
  mergeMap(({ payload, onSuccess }) =>
    Api.post(`${URL}/clients/objects/removeClone`, JSON.stringify(payload)).pipe(
      switchMap(({ response }) => ActionsObservable.create((obs) => {
        if (onSuccess) onSuccess(response?.object)
        obs.complete()
      })),
      catchError((err) => ActionsObservable.create((obs) => {
        console.log(err)
        Alerts.error('Error!', err?.response?.error?.message || '')
        obs.next(stopLoading())
        obs.complete()
      }))
    )
  )
)