import { default as Datepicker } from './Datepicker'
import { default as RadioButtons } from './RadioButtons'
import { default as UnderlinedTab } from './UnderlinedTab'
import { default as Checkboxes } from './Checkboxes'

const Inputs = {
    Datepicker,
    RadioButtons,
    UnderlinedTab,
    Checkboxes
}

export default Inputs