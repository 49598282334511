export const mergedObjectsTypes = {
    GET_MERGED_OBJECTS: 'mergedObjects/GET_MERGED_OBJECTS',
    GET_MERGED_OBJECTS_SUCCESS: 'mergedObjects/GET_MERGED_OBJECTS_SUCCESS',
    CREATE_MERGED_OBJECTS: 'mergedObjects/CREATE_MERGED_OBJECTS',
    UPDATE_MERGED_OBJECTS: 'mergedObjects/UPDATE_MERGED_OBJECTS',
    DELETE_MERGED_OBJECTS: 'mergedObjects/DELETE_MERGED_OBJECTS',
}

export const createMergedObjects = (payload) => ({
    type: mergedObjectsTypes.CREATE_MERGED_OBJECTS,
    ...payload
})

export const updateMergedObjects = (payload) => ({
    type: mergedObjectsTypes.UPDATE_MERGED_OBJECTS,
    ...payload
})

export const deleteMergedObjects = (payload) => ({
    type: mergedObjectsTypes.DELETE_MERGED_OBJECTS,
    ...payload
})


