import moment from "moment"
import React from "react"
import { useQuery } from "../helpers"
import "./styles.scss"

const mapPriority = {
  'Нисък': '#00CB5E',
  'Среден': '#FFB612',
  'Висок': '#FA4444',
  'completed': '#8E8E8E'
}

const Task = ({ task }) => {
  const { handleUrlChange } = useQuery()
  const { _id, title, dateEnd, priority, completedAt, assignedTo } = task || {}

  return <div
    className="task-management-task-single-container card"
    style={{ borderColor: mapPriority[completedAt ? 'completed' : priority] }}
    onClick={() => handleUrlChange({ _id })}
  >
    <h2>{title}</h2>
    <p>Срок:
      <span> {moment(dateEnd).format('DD.MM.YYYY')}</span>
      {moment(dateEnd).isBefore(moment()) && !completedAt && <span className="orange"> (просрочен с {moment().diff(moment(dateEnd), 'days')} дни)</span>}
    </p>
    {completedAt && <p>Завършена на:
      <span className={moment(completedAt).isBefore(moment(dateEnd)) ? 'green' : undefined}> {moment(completedAt).format('DD.MM.YYYY')}</span>
      {moment(completedAt).isAfter(moment(dateEnd)) && <span className="orange"> (просрочен с {moment(completedAt).diff(moment(dateEnd), 'days')} дни)</span>}
    </p>}
    <div className="row row-assignees">
      <div className="scroll-container row">
        {assignedTo.slice(0, 2).map(({ coverPhoto, fullName, _id }) =>
          <div key={_id} className="single-assignee row">
            <div
              className="tasks-icon"
              style={{ backgroundImage: `url(${coverPhoto?.icon || require('../assets/default-user-avatar.jpg')})` }}
            />
            <p><span>{fullName}</span></p>
          </div>
        )}
      </div>
      {assignedTo.length > 2 && <div className="additional-assignees row" title={assignedTo.slice(2).map(({ fullName }) => fullName).join("\n")}>+{assignedTo.length - 2}</div>}
    </div>
  </div>
}

export default Task