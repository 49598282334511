
import React, { useState } from 'react'
import moment from 'moment'
import { Portal } from 'react-overlays'
import { Button, Input } from '@makedonski/admin-ui'
import { useDispatch } from 'react-redux'
import { setModal } from 'actions'
import { Inputs } from 'components'
import './styles.scss'

const ObjectStatus = ({
    title,
    textInput,
    dateInput = true,
    options,
    data: dataProp,
    onClick = () => { },
    validate
}) => {
    const dispatch = useDispatch()
    const [data, setData] = useState(dataProp || {})
    return (
        <div className="modal-object-status-container">
            <div className="modal-object-status-header">
                <h2>{title}</h2>
                <Button.Icon
                    name="plus"
                    size="26px"
                    className="btn-close"
                    onClick={() => dispatch(setModal({ isOpen: false }))}
                />
            </div>
            <div className="modal-object-status-content row">
                <div className="col">
                    <span>Причина</span>
                    {textInput ? <Input.Text
                        value={data.text || ''}
                        onChange={({ target: { value } }) => setData({ ...data, text: value })}
                    /> : <Inputs.Dropdown
                        options={options || []}
                        value={data.dropdown}
                        onChange={({ value }) => setData({ ...data, dropdown: value })}
                    />}
                </div>
                {dateInput && <>
                    <div className="spacer"></div>
                    <div className="col">
                        <span>Влиза в сила от</span>
                        <Inputs.DatePicker
                            value={data.date}
                            onChange={(value) => setData({ ...data, date: moment(value).startOf('month').toDate() })}
                            dateFormat={'MMMM yyyy'}
                            showMonthYearPicker
                            showFullMonthYearPicker
                            className="month-picker"
                            popperContainer={({ children }) => (
                                <Portal container={document.getElementById('calendar-portal')}>{children}</Portal>
                            )}
                        />
                    </div>
                </>}
            </div>
            <div className="modal-object-status-footer row">
                <Button.Raised
                    className='cancel'
                    text="Откажи"
                    onClick={() => dispatch(setModal({ isOpen: false }))}
                />
                <div className="spacer"></div>
                <Button.Raised
                    text="Продължи"
                    onClick={() => {
                        if (onClick) {
                            if (validate && !validate(data)) return
                            else onClick(data)
                        }
                        dispatch(setModal({ isOpen: false }))
                    }}
                />
            </div>
        </div >
    )
}

export default ObjectStatus
