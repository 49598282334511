export const nomenclaturesTypes = {
  CREATE_NOMENCLATURE: 'nomenclatures/CREATE_NOMENCLATURE',
  GET_NOMENCLATURES: 'nomenclatures/GET_NOMENCLATURES',
}

export const createNomenclature = (payload) => ({
  type: nomenclaturesTypes.CREATE_NOMENCLATURE,
  ...payload,
})

export const getNomenclatures = (payload) => ({
  type: nomenclaturesTypes.GET_NOMENCLATURES,
  ...payload,
})
