import React, { useState, useEffect, useMemo, useRef } from 'react'
import { Popover } from '@varld/popover'
import { Button } from '@makedonski/admin-ui'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { isEmpty, sortBy } from 'lodash'
import { setClientsFields, getClient, setModal, ignoreObjectForMonth } from '../../actions'
import { Client as ClientComp, Popup, Shared } from '../../components'
import { clientsStatus, notificationsGeneratedTableFields } from 'config/constants'
import { useQuery } from '../../hooks'
import { checkIsDealer, getClientStatus, renderCell } from 'utilities'
import './styles.scss'

const Client = ({ }) => {
  const tabs = ['Данни за клиента', 'Промени по договор', 'Данни за обекти', 'Документи', 'Фактури']
  const [selectedTab, setSelectedTab] = useState(tabs[0])
  const [selectedObject, setSelectedObject] = useState(null)
  const [editable, setEditable] = useState(false)

  const formRef = useRef(null)
  const formAnnexMainRef = useRef(null)

  const { id } = useParams()
  const { client } = useSelector((state) => state.clients)
  const isDealer = checkIsDealer()

  useEffect(() => {
    if (selectedTab === 'Данни за обекти' && selectedObject) setSelectedObject(client?.objects.find(({ _id }) => _id === selectedObject._id))
  }, [client])

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getClient({ payload: id, getAllDetails: true }))
    return () => dispatch(setClientsFields({ client: null, clients: [] }))
  }, [])

  useEffect(() => {
    setEditable(false)
  }, [selectedTab, selectedObject])

  const { edit, handleUrlChange } = useQuery()
  useEffect(() => {
    if (edit === 'true') {
      setEditable(true)
      handleUrlChange('edit', null)
    }
  }, [])

  const handleGroupAdd = (group) => dispatch(setModal({ isOpen: true, type: 'invoiceGroups', props: { group, client, objectsNotInGroups } }))
  const handleMergedObjects = (group) => dispatch(setModal({ isOpen: true, type: 'mergedObjects', props: { group, client } }))


  useEffect(() => {
    if (selectedTab === 'Промени по договор' && !selectedObject) setSelectedObject(client?.annexes?.at(0))
  }, [selectedTab])

  const objectsNotInGroups = useMemo(() => {
    return client?.objects?.filter(({ _id }) => {
      return !client?.invoiceGroups
        ?.reduce((acc, { objects }) => [...acc, ...objects?.map(({ _id }) => _id)], [])
        ?.includes(_id)
    })
  }, [client])

  const { products } = useSelector(({ data }) => data)

  const renderContent = () => {
    switch (selectedTab) {
      case 'Данни за клиента':
        return (
          <>
            <ClientComp.Forms.MainProfile
              ref={formRef}
              data={client}
              editable={editable}
              setEditable={(value) => setEditable(value)}
            />
          </>
        )
      case 'Промени по договор':
        return (
          <>
            <div className="row row-annex-header">
              <p>Добавен на: {moment(selectedObject?.createdAt).format("DD.MM.YYYY | HH:mm")}</p>
              <p>Добавен от: {selectedObject?.user?.fullName}</p>
            </div>
            {['Annex', 'Resign'].includes(selectedObject?.type) && <ClientComp.Forms.AnnexMain
              isClientProfile
              isApplied={['Промени по договор'].includes(selectedTab) && selectedObject?.applied}
              data={{ ...selectedObject, ...selectedObject?.changes }}
              client={client}
              ref={formAnnexMainRef}
              editable={editable}
            />}
            {['Annex'].includes(selectedObject?.type)
              ? <ClientComp.Forms.AnnexContract
                isClientProfile
                isApplied={['Промени по договор'].includes(selectedTab) && selectedObject?.applied}
                ref={formRef}
                data={{ ...selectedObject, ...selectedObject?.changes, ...selectedObject?.changes?.contractSettings }}
                client={client}
                editable={editable}
                setEditable={(value) => setEditable(value)}
                setSelectedObject={(value) => setSelectedObject(value)}
              />
              : ['Resign'].includes(selectedObject?.type)
                ? <ClientComp.Forms.AnnexResignContract
                  isClientProfile
                  isApplied={['Промени по договор'].includes(selectedTab) && selectedObject?.applied}
                  ref={formRef}
                  data={{ ...selectedObject, ...selectedObject?.changes, ...selectedObject?.changes?.contractSettings }}
                  client={client}
                  editable={editable}
                  setEditable={(value) => setEditable(value)}
                  setSelectedObject={(value) => setSelectedObject(value)}
                />
                : ['Notification'].includes(selectedObject?.type)
                  ? <Shared.Table
                    columns={notificationsGeneratedTableFields}
                    data={[selectedObject]}
                    renderCell={(row, value, options) => renderCell.notifications?.notifications?.(row, value, { ...options, products })}
                  />
                  : null
            }
          </>
        )
      case 'Данни за обекти':
        switch (selectedObject) {
          case null:
            return <p className="empty-objects">Моля изберете обект!</p>
          case 'new':
            return (
              <ClientComp.Forms.Objects
                isCreate
                isClientProfile
                client={client}
                ref={formRef}
                setSelectedObject={(value) => setSelectedObject(value)}
              />
            )
          default:
            return (
              <>
                {selectedObject?.ignoreMonth &&
                  <div className="row row-ignore">
                    <p>Обектът няма да бъде фактуриран за месец {selectedObject?.ignoreMonth}</p>
                    <div className="row" onClick={() => dispatch(ignoreObjectForMonth({
                      payload: { itn: selectedObject?.itn, ignoreMonth: null },
                      onSuccess: () => dispatch(getClient({ payload: id, onSuccess: ({ objects }) => setSelectedObject(objects.find(({ _id }) => _id === selectedObject._id)) }))
                    }))}>
                      <p>Отигнорирай</p>
                      <Button.Icon name="plus" color='red' size={16} />
                    </div>
                  </div>
                }
                {selectedObject?.existingObject && <div className="row row-ignore">
                  <p>Обектът е добавен с незабавна активация</p>
                </div>}
                {selectedObject?.mergedObjects && <div className="row row-mergedObjects">
                  Група производители - {selectedObject?.mergedObjects?.name}
                  <div className="icon icon-edit" onClick={() => handleMergedObjects(selectedObject?.mergedObjects)} />
                </div>}
                <ClientComp.Forms.Objects
                  isClientProfile
                  ref={formRef}
                  client={client}
                  data={formRef?.current?.data || [selectedObject]}
                  editable={editable}
                  setEditable={(value) => setEditable(value)}
                  setSelectedObject={(value) => setSelectedObject(value)}
                />
              </>
            )
        }

      case 'Документи':
        return <ClientComp.Documents client={client} />
      case 'Фактури':
        return (
          <ClientComp.Invoices
            ref={formRef}
            id={id}
            client={client}
            editable={editable}
            setEditable={(value) => setEditable(value)}
          />
        )
      default:
        return null
    }
  }

  const renderContractOption = (object, i) => {
    if (object.type === 'autoRenewed') return (
      <div key={`contract-${i}`} className="single-object annex row" style={{ cursor: 'default' }}>
        <p>{`Автоматично преподписване\nНачало на договора: ${moment(object?.date).format('DD.MM.YYYY')}\nКрай на договора: ${moment(object?.deliveryEnd).format('DD.MM.YYYY')}`}</p>
      </div>
    )
    return (
      <div
        key={`contract-${i}`}
        className={`single-object annex row ${selectedObject?._id === object?._id && 'selected'} ${moment(object.activationDate).isAfter(moment()) && 'upcoming'}`}
        onClick={() => {
          setSelectedObject(object)
        }}
      >
        <p>{`${object?.type === 'Resign' ? 'Преподписване\n' : object?.type === 'Notification' ? 'Уведомление\n' : object?.type === 'Annex' ? 'Анекс\n' : ''
          }Добавен на: ${moment(object.createdAt).format('DD.MM.YYYY | HH:mm')} \nВ сила от: ${moment(object?.activationDate).format('DD.MM.YYYY')}`}</p>
        <div className="icon icon-arrow-right" />
      </div>
    )
  }

  const renderObjectOption = (object, i) => (
    <div
      key={`object-${i}`}
      className={`single-object row ${selectedObject?._id === object?._id && 'selected'} ${object?.isProducer && 'producer'}`}
      onClick={() => {
        const payload = object?.client ? object : client?.objects?.find(({ _id }) => _id === object?._id)
        setSelectedObject(payload)
        formRef.current.data = [payload]
      }}
    >
      <div className="col">
        <p className='no-margin'>Обект: {object?.itn} - {clientsStatus?.find(({ value }) => value === object.status).label}</p>
        {object?.description && <p className='no-margin'>Описание: {object?.description}</p>}
        {(object?.address || object?.city) && <p className='no-margin'>Адрес: {[object?.address, object?.city].filter(Boolean).join(' - ')}</p>}
        {object?.mergedObjects && <p className='no-margin'>Сдвоен: {object?.mergedObjects?.name}</p>}
      </div>
      <div className="icon icon-arrow-right" />
    </div>
  )

  return (
    <div className="screen-client-container">
      <div className="screen-client-header row">
        <h2>{client?.fullName}</h2>
        <p>
          ЕИК/ЕГН: <span>{client?.eic || client?.pin}</span>
        </p>
        <p className="row">
          МОЛ: <span>{client?.mol?.map(({ fullName }) => fullName)?.join('; ')}</span>
        </p>
        <p>
          Статус: <span>{getClientStatus(client)}</span>
        </p>
        <div className="row row-buttons">
          {editable || selectedObject === 'new' ? (
            <>
              {selectedObject !== 'new' && <Button.Raised className="btn-cancel" text="Откажи" onClick={() => {
                formRef.current.onClear()
                formAnnexMainRef?.current?.onClear()
              }} />}
              <Button.Raised text="Запази промените" onClick={() => formRef.current.onSave(formAnnexMainRef.current)} />
            </>
          ) : (
            <>
              <Popover popover={({ close }) => <Popup.ClientOptions hide={close} client={client} />}>
                <div className="icon icon-details" />
              </Popover>
              {(!isDealer || (["Неактивни", 'Потенциални'].some(s => getClientStatus(client) === s)) && selectedTab === 'Данни за клиента')
                && (['Данни за клиента', 'Фактури'].includes(selectedTab) || selectedObject !== null)
                // && (!['Данни за обекти'].includes(selectedTab) || selectedObject?.status !== 'transfer')
                && (<Button.Raised className="blue" text="Редакция" onClick={() => setEditable(true)} />)
              }
            </>
          )}
        </div>
      </div>
      <div className="screen-client-content row">
        <div className="screen-client-navigation">
          {tabs
            ?.filter((tab) => tab !== 'Промени по договор' || client?.annexes?.length || client?.contractSettings?.autoRenewed?.length)
            ?.filter((tab) => tab !== 'Фактури' || !isDealer)
            ?.map((tab, i) => (
              <React.Fragment key={`tab-${i}`}>
                <div
                  className={`single-tab row ${tab === selectedTab && 'selected'} ${['Промени по договор', 'Данни за обекти'].includes(selectedTab) && selectedTab === tab && 'no-margin'
                    }`}
                  onClick={() => {
                    setSelectedTab(tab)
                    setSelectedObject(null)
                  }}
                >
                  {tab}
                  <div className={`icon icon-arrow-right${tab === selectedTab ? '-white' : ''}`} />
                </div>
                {selectedTab === tab && selectedTab === 'Промени по договор' && (
                  <div className="objects-container">{
                    [...(client?.annexes || []), ...(client?.contractSettings?.autoRenewed?.map((c) => ({ ...c, type: 'autoRenewed' })) || [])]
                      ?.sort((a, b) => moment(a.createdAt || a.deliveryStart).isBefore(moment(b.createdAt || b.deliveryStart)) ? 1 : -1)
                      ?.map(renderContractOption)
                  }</div>
                )}
                {selectedTab === tab && selectedTab === 'Данни за обекти' && (
                  <div className="objects-container">
                    <Popover
                      popover={({ close }) => (
                        <Popup.ClientAddObject
                          handleSingleAdd={() => {
                            formRef.current.data = []
                            setSelectedObject('new')
                          }}
                          handleGroupAdd={handleGroupAdd}
                          handleMergedObjects={handleMergedObjects}
                          hide={close}
                          client={client}
                        />
                      )}
                    >
                      <div className="add-object">Добави Обект</div>
                    </Popover>
                    {client?.invoiceGroups?.map((group, i) => (
                      <div key={`group-${i}`} className={`single-group`}>
                        {i !== 0 && <div className="group-divider" />}
                        {client.defaultInvoiceGroup === group._id && <span>Основна</span>}
                        <div className="group-header row">
                          {group?.name}
                          {/* - {[group?.emails?.at(0), client?.email].filter(Boolean).join(', ')} */}
                          <div className="row row-icons">

                            <Popover popover={({ close }) => <div style={{ padding: `0 10px` }}>
                              {isEmpty(group?.emails)
                                ? [client?.email, ...(client?.contacts || [])?.filter(({ invoices }) => invoices)?.map(({ email }) => email)]
                                  ?.map((email, i) => <p key={`email-${i}`}>{email}</p>)
                                : group?.emails?.map((email, i) => <p key={`email-${i}`}>{email}</p>)}
                            </div>}>
                              <div className="icon icon-emails" />
                            </Popover>
                            <div className="icon icon-edit" onClick={() => handleGroupAdd(group)} />
                          </div>
                        </div>
                        {sortBy(group?.objects, 'isProducer')?.map(renderObjectOption)}
                      </div>
                    ))}
                    {[objectsNotInGroups, client?.invoiceGroups].every((arr) => !isEmpty(arr)) && (
                      <>
                        <div className="group-divider" />
                        <div className="group-header row">Обекти без група</div>
                      </>
                    )}
                    {sortBy(objectsNotInGroups, 'isProducer')?.map(renderObjectOption)}
                  </div>
                )}
              </React.Fragment>
            ))}
        </div>
        <div className="screen-client-inner-content col">{renderContent()}</div>
      </div>
    </div>
  )
}

export default Client
