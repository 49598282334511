import { dataTypes } from '../actions'

const initialState = {
  showCheckboxes: false,
  selectedCheckboxes: [],
}

const data = (state = initialState, { type, payload }) => {
  switch (type) {
    case dataTypes.SET_FIELDS:
      return { ...state, ...payload }
    case dataTypes.SET_FIELD:
      return { ...state, [payload.field]: payload.value }
    case dataTypes.GET_DATA_SUCCESS:
      if (payload.type === 'products') payload.data = payload.data.filter(({ name, forProducer }) => forProducer || ['Една тарифа', 'Две тарифи', 'Борса'].includes(name))
      return { ...state, [payload.type]: payload.data }
    default:
      return state
  }
}

export default data
