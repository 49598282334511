import React from 'react'
import { useDispatch } from 'react-redux'
import { setModal } from 'actions'
import { reasonsForLeaving } from 'config/constants'
import './styles.scss'

const ObjectLeavingReason = ({ object, value, field, fetch }) => {
  const dispatch = useDispatch()

  if (!value) return '--'
  return (
    <div className="client-cells-object-leaving-reason-container row">
      {value}
      <div
        className="icon icon-edit"
        onClick={() =>
          dispatch(
            setModal({
              isOpen: true,
              type: 'clientsLeaving',
              props: {
                object,
                field,
                options: reasonsForLeaving,
                fetch,
              },
            })
          )
        }
      />
    </div>
  )
}

export default ObjectLeavingReason
