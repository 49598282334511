import React from 'react'
import { NavLink } from 'react-router-dom'
import './styles.scss'

const ObjectPreviousClient = ({ value }) => {
  if (!value) return '--'
  return (
    <div className="client-cells-object-previous-client-container row">
      <NavLink to={`/clients/${value?._id}`}>
        <div className="icon icon-folder-custom" />
      </NavLink>
      {value?.fullName} | {value?.eic || value?.pin || ''}
    </div>
  )
}

export default ObjectPreviousClient
