import React from "react"
import { Input } from '@makedonski/admin-ui'
import { Inputs } from '../../../'
import "./styles.scss"
import { bn, mAdd } from "utilities"

const Interests = ({ invoice }) => {
    const { pricing } = invoice || {}
    const { additionalInformation = {} } = pricing || {}
    return <div className="invoices-forms-interests-container">
        <div className="invoices-forms-interests-header row">
            <h2>Лихви: </h2>

        </div>
        <div className="invoices-forms-interests-content">
            <div className="invoices-forms-interests-form-header row">
                {["Наименование", "Стойност"].map(label => <span key={`interests-label-${label}`}>{label}</span>)}
            </div>
            {Object.entries(additionalInformation)?.map(([key, value], i) => (
                <div key={`interests-${i}`} className="invoices-forms-interests-single row">
                    <div className="col"><Input.Text value={key} disabled /></div>
                    <div className="col"><Inputs.TextLabeled label='лв.' value={value} disabled /></div>
                </div>
            ))}
        </div>
        <div className="invoices-forms-interests-footer row">
            <p>{`Стойност на лихви`}</p>
            <Inputs.TextLabeled
                label="лв."
                value={Object.values(additionalInformation).reduce((acc, cur) => mAdd(acc, bn(cur)), 0) ?? '0'}
                disabled
            />
        </div>
    </div>
}

export default Interests