import { ofType, ActionsObservable } from 'redux-observable'
import { switchMap, mergeMap, catchError, debounceTime } from 'rxjs/operators'
import { Api, Alerts } from '@makedonski/socourt-utilities'
import { dataTypes, stopLoading } from '../actions'
import { URL } from '../config/settings'

export const getData = (action$) =>
  action$.pipe(
    ofType(dataTypes.GET_DATA),
    mergeMap(({ payload }) =>
      Api.get(`${URL}/data/${payload}`).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: dataTypes.GET_DATA_SUCCESS,
              payload: { type: payload, data: response[payload] },
            })
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const createData = (action$) =>
  action$.pipe(
    ofType(dataTypes.CREATE_DATA),
    mergeMap(({ payload: { type, data, onSuccess } }) =>
      Api.post(`${URL}/data/${type}`, JSON.stringify(data)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response[type.slice(0, type.length - 1)])
            obs.next({
              type: dataTypes.GET_DATA,
              payload: type,
            })
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const getClientsForDropdown = (action$) =>
  action$.pipe(
    ofType(dataTypes.GET_CLIENTS_FOR_DROPDOWN),
    debounceTime(300),
    switchMap(({ payload }) =>
      Api.post(`${URL}/clients/search/eic`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({ type: dataTypes.GET_DATA_SUCCESS, payload: { type: 'clients', data: response.clients } })
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )
