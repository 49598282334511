import React from 'react'
import { Button } from '@makedonski/admin-ui'
import { useDispatch } from 'react-redux'
import { setModal } from '../../../actions'
import './styles.scss'

const Confirmation = ({ title, buttonText = 'Продължи', children, onClick = () => {}, hideButton, buttons }) => {
  const dispatch = useDispatch()
  return (
    <div className="modal-confirmation-container">
      <div className="modal-confirmation-header">
        <h2>{title}</h2>
        <Button.Icon
          name="plus"
          size="26px"
          className="btn-close"
          onClick={() => dispatch(setModal({ isOpen: false }))}
        />
      </div>
      <div className="modal-confirmation-content">{children}</div>
      {!hideButton &&
        (buttons ? (
          buttons
        ) : (
          <div className="modal-confirmation-footer">
            <Button.Raised
              text={buttonText}
              onClick={() => {
                if (onClick) onClick()
                dispatch(setModal({ isOpen: false }))
              }}
            />
          </div>
        ))}
    </div>
  )
}

export default Confirmation
