import { Popover } from "@varld/popover"
import { getReferenceObjectsIgnored, startLoading, updateObject } from "actions"
import { Shared } from "components"
import { referenceIgnoredObjectsFields } from "config/constants"
import { useQuery } from "hooks"
import React, { useCallback, useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { mapClientsSearch, renderCell } from "utilities"
import "./styles.scss"

const ObjectsIgnored = ({ }) => {
    const dispatch = useDispatch()
    const { reference, hasNextPage, nextPage } = useSelector(({ references }) => references)

    const availableFilters = { fullName: { type: 'regex' }, eic: { type: 'regex' }, itn: { type: 'regex' }, ignoreMonth: { type: 'regex' } }
    const { sort: sortParam = '{}', filter: filterParam = '{}', } = useQuery()
    const filter = useMemo(() => JSON.parse(filterParam), [filterParam])
    const sort = useMemo(() => JSON.parse(sortParam), [sortParam])
    const searchQuery = useMemo(() => ({ ...mapClientsSearch(sort, filter, undefined, availableFilters) }), [sortParam, filterParam])

    const fetch = useCallback((payload) => {
        dispatch(startLoading())
        dispatch(getReferenceObjectsIgnored({ payload: { ...payload, ...searchQuery } }))
    }, [dispatch, searchQuery])
    useEffect(() => { fetch() }, [fetch])

    const sortingComponents = referenceIgnoredObjectsFields?.filter(({ sortable }) => sortable).reduce((acc, { value }) => {
        return {
            ...acc,
            [value]:
                <Popover popover={({ close }) => <Shared.SortingComponent
                    hide={close}
                    column={value}
                    availableSort={[]}
                    availableFilters={availableFilters}
                />}>
                    <div className="icon icon-arrow-down" />
                </Popover>

        }
    }, {})

    const handleIgnore = (_id) => {
        dispatch(startLoading())
        dispatch(updateObject({ data: { _id, ignoreMonth: "", }, onSuccess: () => fetch() }))
    }


    return <div className="reference-objects-ignored-container">
        <Shared.Table
            fixedWidth
            columns={referenceIgnoredObjectsFields}
            data={reference}
            renderCell={(row, field) => renderCell.references.objectsIgnored(row, field, { handleIgnore })}
            handlePagination={() => { hasNextPage && fetch({ page: nextPage }) }}
            sortingComponent={sortingComponents}
        />
    </div>
}

export default ObjectsIgnored