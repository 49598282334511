import { annexTypes } from '../actions'

const initialState = {
    notifications: [],
    hasNextPage: false,
    nextPage: 1,
}

const annex = (state = initialState, { type, payload }) => {
    switch (type) {
        case annexTypes.GET_NOTIFICATIONS_ANNEXES_SUCCESS:
            const newData = payload.page > 1 ? [...(state?.notifications || []), ...(payload?.docs || [])] : payload?.docs || []
            return { ...state, ...payload, notifications: newData }
        default:
            return state
    }
}

export default annex
