import React, { useState } from 'react'
import { Popover } from '@varld/popover'
import { Button } from '@makedonski/admin-ui'
import { useDispatch } from 'react-redux'
import { setModal } from '../../../actions'
import { Inputs, Card, Popup } from '../../'
import { mapPriority } from '../../../config/constants'
import './styles.scss'

const Task = ({}) => {
  const {
    priority = 'low',
    assignees = [{ fullName: 'a' }, { fullName: 'b' }],
    assigner = { fullName: 'v' },
    description,
    title,
  } = {}
  const dispatch = useDispatch()
  const tabs = ['Коментари', 'Активност', 'Чеклист', 'Файлове']
  const [selectedTab, setSelectedTab] = useState(tabs[0])

  const renderContent = () => {
    switch (selectedTab) {
      case 'Коментари':
        return <Inputs.Comments />
      case 'Активност':
        return <Card.SingleNotification />
      case 'Чеклист':
        return <Card.Checklist />
      case 'Файлове':
        return <Card.Files />
      default:
        return null
    }
  }

  const singlePerson = ({ coverPhoto, fullName }, i) => (
    <div key={`assignee-${i}`} className="single-assignee row">
      <div
        className="profile-image"
        style={{
          backgroundImage: `url(${coverPhoto || require('../../../assets/images/default-user-avatar.jpg')})`,
        }}
      />
      <p className="assignee-fullName">{fullName}</p>
    </div>
  )

  return (
    <div className="modal-task-container col">
      <div className="modal-task-status" style={{ backgroundColor: mapPriority[priority] }} />
      <Button.Icon name="plus" size="26px" onClick={() => dispatch(setModal({ isOpen: false }))} />

      <div className="row row-assignees">
        <Popover className="header-settings" popover={({ close }) => <Popup.TaskOptions hide={close} />}>
          <div className="settings-container col ripple">
            <div className="settings-circle" />
            <div className="settings-circle" />
            <div className="settings-circle" />
          </div>
        </Popover>
        <p>Изпълнител:</p>
        <div className="assignees-container row">{assignees?.map((person, i) => singlePerson(person || {}, i))}</div>
      </div>
      <div className="row row-assigner">
        <div className="row">
          <p>Възложена от:</p>
          {singlePerson(assigner)}
        </div>
        <div className="row">
          <p>Приоритет:</p>
          <div className="icon icon-proprity no-pointer no-active" style={{ backgroundColor: mapPriority[priority] }} />
          <p className="bold">Нисък</p>
        </div>
      </div>
      <div className="row row-dates">
        <div className="row">
          <p>
            Възложена на: <span>21.03.2021</span>
          </p>
        </div>
        <div className="row">
          <p>
            Краен срок: <span>22.03.2021</span>
          </p>
        </div>
      </div>
      <div className="row row-client">
        <div className="row">
          <p>
            Клиент: <span>ИМЕ ФИРМА | Име на обект</span>
          </p>
        </div>
        <div className="row">
          <p>
            Статус: <span>Потенциален | Чака договор</span>
          </p>
        </div>
      </div>
      <div className="task-description-container">
        <div className="icon icon-edit" />
        <h2>{title || 'Създаване на Договор'}</h2>
        <p>{description || 'Трябва да се създаде комбиниран договор с единична цена за 12 месеца. '}</p>
      </div>
      <div className="row row-buttons">
        <Button.Raised text="Завърши задачата" className="btn-complete" disabled />
        <Button.Raised text="Започни работа" className="btn-work" />
      </div>

      <div className="modal-task-dynamic-container col">
        <Button.UnderlinedTab buttons={tabs} onChange={(tab) => setSelectedTab(tab)} />
        <div className="modal-task-dynamic-content">{renderContent()}</div>
      </div>
    </div>
  )
}

export default Task
