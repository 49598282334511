import React from 'react'
import { Button, Input } from '@makedonski/admin-ui'
import { useDispatch } from 'react-redux'
import { setModal } from '../../../actions'
import { statusMap } from '../../../config/constants'
import './styles.scss'

const users = [
  {
    status: 'Working',
    fullName: 'Иван Александров',
    occupation: 'Търговец',
    phoneNumber: '+359888000000',
    email: 'mail@mailexample.com',
    birthday: '28/03/1994',
    description:
      'Кратко описание за служителя, с какво точно се занимава. Това описание се подава с цел другите колеги бързо да добият представа.',
  },
  {
    status: 'Working',
    fullName: 'Иван Александров',
    occupation: 'Търговец',
    phoneNumber: '+359888000000',
    email: 'mail@mailexample.com',
    birthday: '28/03/1994',
    description:
      'Кратко описание за служителя, с какво точно се занимава. Това описание се подава с цел другите колеги бързо да добият представа.',
  },
  {
    status: 'Working',
    fullName: 'Иван Александров',
    occupation: 'Търговец',
    phoneNumber: '+359888000000',
    email: 'mail@mailexample.com',
    birthday: '28/03/1994',
    description:
      'Кратко описание за служителя, с какво точно се занимава. Това описание се подава с цел другите колеги бързо да добият представа.',
  },
]

const Colleagues = ({ }) => {
  const dispatch = useDispatch()
  return (
    <div className="modal-colleagues-container">
      <div className="modal-colleagues-header row">
        <h2>Всички колеги</h2>
        <Button.Icon name="plus" size="26px" onClick={() => dispatch(setModal({ isOpen: false }))} />
      </div>
      <Input.Text placeholder="Търси по име" value="" onChange={({ target: { value } }) => console.log(value)} />
      <div className="modal-colleagues-content">
        {users?.map((user, i) => {
          //prettier-ignore
          const { coverPhoto, status, fullName, occupation, phoneNumber, email, birthday, description } = user
          return (
            <div key={`single-user-${i}`} className="single-user-container row">
              <div className="row col-main">
                <div
                  className="profile-image-container icon no-active no-pointer row"
                  style={{
                    backgroundImage: `url(${coverPhoto || require('../../../assets/images/default-user-avatar.jpg')
                      })`,
                  }}
                >
                  <div className="profile-status-container">
                    <div className="profile-status" style={{ backgroundColor: statusMap[status] }} />
                  </div>
                </div>
                <div className="user-data-main">
                  <p className="fullName">{fullName}</p>
                  <p className="occupation">{occupation}</p>
                </div>
              </div>
              <div className="col col-contact">
                {phoneNumber && (
                  <div className="row">
                    <div className="icon icon-phone-custom" />
                    <p className="phoneNumber">{phoneNumber || '--/--'}</p>
                  </div>
                )}
                {email && (
                  <div className="row">
                    <div className="icon icon-email" />
                    <p className="email">{email || '--/--'}</p>
                  </div>
                )}
              </div>
              <div className="col col-description">
                {birthday && (
                  <div className="row">
                    <div className="icon icon-birthday-custom" />
                    <p className="birthday">{birthday || '--/--'}</p>
                  </div>
                )}
                {description && (
                  <div className="row">
                    <p className="description">{description || '--/--'}</p>
                  </div>
                )}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Colleagues
