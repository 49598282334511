import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Shared } from 'components'
import { referencesEmployeesFields } from 'config/constants'
import { mapClientsSearch, renderCell } from 'utilities'
import './styles.scss'
import { getUserRoles, getUsers, setReferencesFields, startLoading } from 'actions'
import { Popover } from '@varld/popover'
import { useQuery } from 'hooks'



const Empoyees = ({ }) => {
  const dispatch = useDispatch()
  const { reference: employees } = useSelector(({ references }) => references)

  const [roles, setRoles] = useState([])
  useEffect(() => { dispatch(getUserRoles({ onSuccess: (res) => setRoles(res) })) }, [])
  const availableFilters = useMemo(() => ({
    fullName: { type: 'regex' },
    role: { type: 'idDropdown', values: roles.map(({ friendlyName, name }) => ({ _id: name, name: friendlyName })), single: true },
    group: { type: 'regex' },
  }), [roles])

  const { sort: sortParam = '{}', filter: filterParam = '{}', } = useQuery()
  const filter = useMemo(() => JSON.parse(filterParam), [filterParam])
  const sort = useMemo(() => JSON.parse(sortParam), [sortParam])
  const searchQuery = useMemo(() => ({ ...mapClientsSearch(sort, filter, undefined, availableFilters) }), [sortParam, filterParam])

  const fetch = () => {
    dispatch(startLoading())
    dispatch(getUsers({
      payload: { ...searchQuery },
      onSuccess: (res) => dispatch(setReferencesFields({ reference: res }))
    }))
  }
  useEffect(() => {
    fetch()
    return () => dispatch(setReferencesFields({ reference: [] }))
  }, [searchQuery])

  const sortingComponents = referencesEmployeesFields?.filter(({ sortable }) => sortable).reduce((acc, { value }) => {
    return {
      ...acc,
      [value]:
        <Popover popover={({ close }) => <Shared.SortingComponent
          hide={close}
          column={value}
          availableSort={[]}
          availableFilters={availableFilters}
        />}>
          <div className="icon icon-arrow-down" />
        </Popover>

    }
  }, {})

  return (
    <div className="references-empoyees-container">
      <Shared.Table
        fixedWidth
        columns={referencesEmployeesFields}
        data={employees}
        renderCell={renderCell.references.employees}
        sortingComponent={sortingComponents}
      />
    </div>
  )
}

export default Empoyees
