import React, { useEffect, useMemo, useState } from 'react'
import { Button, Input } from '@makedonski/admin-ui'
import { useDispatch, useSelector } from 'react-redux'
import { createUser, createUsersGroup, getUserRoles, getUsersGroups, setReferencesFields, updateUser } from 'actions'
import { Inputs } from 'components'
import './styles.scss'
import { isEmpty } from 'lodash'

const NewEmployee = ({ employee, onSuccess, close }) => {
  const dispatch = useDispatch()
  const { reference: employees } = useSelector(({ references }) => references)

  const { currentUser } = useSelector(({ general }) => general) || {}
  const isMaster = useMemo(() => currentUser?.roles?.map(({ name }) => name).includes('master'), [currentUser])

  const [roles, setRoles] = useState([])
  useEffect(() => { dispatch(getUserRoles({ onSuccess: (res) => setRoles(res) })) }, [])
  const [groups, setGroups] = useState([])
  useEffect(() => { dispatch(getUsersGroups({ onSuccess: (res) => setGroups(res) })) }, [])
  const [newGroup, setNewGroup] = useState("")

  const [data, setData] = useState({ ...employee })
  const selectedRoles = useMemo(() => data?.roles?.map((role) => roles?.find(({ _id }) => _id === role)?.friendlyName), [data, roles])
  const handleChange = (payload) => setData((data) => ({ ...data, ...payload }))
  useEffect(() => { setData({ ...employee }) }, [employee])

  const [showRequired, setShowRequired] = useState(false)

  const requiredFields = ['firstName', 'lastName', 'email', 'pass']
  const isRequired = (field) => showRequired && !data?.[field] && 'required'
  const isValid = () => {
    if (requiredFields.filter(f => !employee || f !== 'pass').some(f => !data?.[f])) return false
    if (isEmpty(data?.roles)) return false
    return true
  }

  const handleCreate = () => {
    if (!isValid()) {
      setShowRequired(true)
      return
    } else if (employee) {
      dispatch(updateUser({
        payload: data,
        onSuccess: (res) => {
          if (onSuccess) onSuccess()
          close()
        }
      }))
    } else dispatch(createUser({
      payload: data,
      onSuccess: (res) => {
        dispatch(setReferencesFields({ reference: [...employees, res] }))
        close()
      }
    }))
  }

  return (
    <div className="modal-new-employee-container">
      <div className="modal-new-employee-header row">
        <h2>{employee ? "Редакция" : "Добавяне"} на потребител</h2>
        <Button.Icon name="plus" size="26px" className="btn-close" onClick={close} />
      </div>
      <div className="modal-new-employee-content">
        <div className="row">
          <div className="col">
            <span>Име</span>
            <Input.Text
              value={data?.firstName || ""}
              onChange={({ target: { value } }) => handleChange({ firstName: value })}
              inputClassName={`${isRequired('firstName')}`}
            />
          </div>
          <div className="col">
            <span>Фамилия</span>
            <Input.Text
              value={data?.lastName || ""}
              onChange={({ target: { value } }) => handleChange({ lastName: value })}
              inputClassName={`${isRequired('lastName')}`}
            />
          </div>
        </div>
        <span>Роля</span>
        <Inputs.Select
          text={{ main: 'Роля', secondary: 'Избери роля' }}
          value={data?.roles}
          handleChange={(value) => handleChange({ roles: value })}
          options={roles.map(({ _id, friendlyName }) => ({ _id, name: friendlyName }))}
          search
          editable={!employee || isMaster}
          showRequired={showRequired}
        />
        {['Комисионер'].some((role) => selectedRoles?.includes(role)) && <>
          <span>Вид лице</span>
          <Inputs.RadioButtons
            buttons={['Физическо лице', 'Юридическо лице',]}
            value={data?.isCompany ? 'Юридическо лице' : 'Физическо лице'}
            onClick={(value) => handleChange({ isCompany: value === 'Юридическо лице' })}
          />
        </>}
        <span>Имейл</span>
        <Input.Text
          value={data?.email || ""}
          onChange={({ target: { value } }) => handleChange({ email: value })}
          inputClassName={`${isRequired('email')}`}
        />
        {['Комисионер', 'Търговец'].some((role) => selectedRoles?.includes(role)) && <>
          <span>Допълнителни имейл - разделени с ;</span>
          <Input.Text
            value={data?.additionalEmails || ""}
            onChange={({ target: { value } }) => handleChange({ additionalEmails: value })}
          />
        </>}
        <div className={`${roles.includes('Комисионер') && 'disabled'}`}>
          {/* {roles.includes('Комисионер') && <div className="overlay" />} */}
          {!employee && <>
            <span>Парола</span>
            <Inputs.Password
              value={data?.pass || ""}
              onChange={({ target: { value } }) => handleChange({ pass: value })}
              inputClassName={`${isRequired('pass')}`}
            />
          </>}
          {employee && isMaster && <>
            <span>Смени парола</span>
            <Inputs.Password
              value={data?.newPassword || ""}
              onChange={({ target: { value } }) => handleChange({ newPassword: value })}
              inputClassName={`${isRequired('newPassword')}`}
            />
          </>}
          <span>Група/екип</span>
          <Inputs.Select
            text={{ main: 'Група/екип', secondary: 'Избери група/екип' }}
            value={data?.group || ""}
            handleChange={(value) => handleChange({ group: value })}
            options={groups}
            search
            editable
            single
          >
            <div className="">
              <h4>Добави</h4>
              <div className="row">
                <Input.Text value={newGroup || ""} onChange={({ target: { value } }) => setNewGroup(value)} />
                {newGroup && <div className="icon icon-check" onClick={() => dispatch(createUsersGroup({
                  payload: { name: newGroup },
                  onSuccess: (res) => {
                    setGroups(groups => [...groups, res])
                    setNewGroup("")
                  }
                }))} />}
              </div>
            </div>
          </Inputs.Select>
        </div>
      </div>
      <div className="">
        <Button.Raised text={`${employee ? "Редактирай" : "Добави"} потребител`} onClick={handleCreate} />
      </div>
    </div>
  )
}

export default NewEmployee
