import React, { useState } from 'react'
import { Input, Button } from '@makedonski/admin-ui'
import { Alerts, Headers } from '@makedonski/socourt-utilities'
import { useDispatch } from 'react-redux'
import { isEmpty, pick } from 'lodash'
import { setModal, createInvoiceGroup, updateInvoiceGroup, deleteInvoiceGroup, updateClient, getClient, startLoading } from 'actions'
import { Inputs } from 'components'
import { URL } from 'config/settings'
import './styles.scss'
import { emailRegex } from 'config/constants'

const editFields = ['_id', 'client', 'name', 'clientNumber', 'emails', 'objects', 'fullName', 'eic', 'bulstat', 'city', 'address', 'tripartiteAgreement']
const InvoiceGroups = ({ close, group, client, objectsNotInGroups }) => {
  const dispatch = useDispatch()

  const [data, setData] = useState(pick({ ...group, tripartiteAgreement: !!group?.fullName, objects: group?.objects?.map(({ _id }) => _id) }, editFields))
  const handleChange = (payload) => setData({ ...data, ...payload })

  const isValid = () => {
    if (!data.name) {
      Alerts.error('Липсва име!')
      return false
    }
    if (!data.clientNumber) {
      Alerts.error('Липсва клиентски номер!')
      return false
    }
    if (data?.tripartiteAgreement && ['fullName', 'eic', 'bulstat', 'city', 'address'].some((f) => !data?.[f])) {
      Alerts.error('Липсват данни за тристранно споразумение!')
      return false
    }
    if (isEmpty(data.objects)) {
      Alerts.error('Изберете поне един обект!')
      return false
    }
    if (!isEmpty(data?.emails) && data?.emails?.filter(Boolean)?.some(e => !emailRegex.test(e))) {
      Alerts.error('Неправилно въведен имейл!')
      return false
    }
    return true
  }

  const [isDefaultGroup, setIsDefaultGroup] = useState(client?.defaultInvoiceGroup === group?._id || (!client?.defaultInvoiceGroup && !group?._id))

  const onSuccess = () => dispatch(getClient({ payload: client._id, getAllDetails: true, onSuccess: close }))

  return (
    <div className="modal-invoice-group-container">
      <div className="modal-invoice-group-header row">
        <h2>{group ? 'Редакция' : 'Създаване'} на група фактура</h2>
        <Button.Icon name="plus" size={30} onClick={close} />
      </div>
      <div className="modal-invoice-group-content">
        <span>Име на група</span>
        <Input.Text value={data?.name || ''} onChange={({ target: { value } }) => handleChange({ name: value })} />
        <span>Клиентски номер</span>
        <div className="row row-client-number">
          <Input.Text value={data?.clientNumber || ''} onChange={({ target: { value } }) => handleChange({ clientNumber: value })} />
          {!data.clientNumber && <Button.Raised
            text='Генерирай'
            onClick={async () => {
              const { clientNumber } = await fetch(`${URL}/clientNumber/request`, {
                method: 'POST',
                headers: Headers.getWithAuth(),
                body: JSON.stringify({ "requestedFor": "Clients" }),
              }).then(res => res.json())
              handleChange({ clientNumber })
            }}
          />}
        </div>
        <span>Допълнителен имейл адрес</span>
        <div className="additional-emails-container">
          {(!isEmpty(data?.emails) ? data?.emails : [''])?.map((email, i) =>
            <div key={`email-${i}`} className="row row-additional-email">
              <Input.Text
                value={data?.emails?.at(i) || ''}
                onChange={({ target: { value } }) => {
                  const newEmails = [...(data?.emails || [''])]
                  newEmails.splice(i, 1, value)
                  handleChange({ emails: newEmails })
                }}
              />
              {i !== 0
                ? <Button.Icon name="plus" color="red" onClick={() => {
                  const newEmails = [...(data?.emails || [''])]
                  newEmails.splice(i, 1)
                  handleChange({ emails: newEmails })
                }} />
                : <div className="icon icon-add" onClick={() => handleChange({ emails: [...(data?.emails || ['']), ''] })} />}
            </div>
          )}
        </div>
        <div className="default-group-container">
          <div className="row">
            <p>Основна група</p>
            <Button.Switch
              isOn={isDefaultGroup}
              onChange={() => setIsDefaultGroup(!isDefaultGroup)}
            />
          </div>
          <p>За издаване на неокончателни фактури</p>
        </div>
        <div className="tripartite-agreement-container">
          <div className="row">
            <p>Тристранно споразумение</p>
            <Button.Switch
              isOn={data?.tripartiteAgreement}
              onChange={() => {
                if (data?.tripartiteAgreement) handleChange({ tripartiteAgreement: !data?.tripartiteAgreement, fullName: undefined, city: undefined, address: undefined, eic: undefined, bulstat: undefined })
                else handleChange({ tripartiteAgreement: !data?.tripartiteAgreement })
              }}
            />
          </div>
          {data?.tripartiteAgreement && <>
            <div className="row">
              <div className="col">
                <span>Име на клиент</span>
                <Input.Text value={data?.fullName || ''} onChange={({ target: { value } }) => handleChange({ fullName: value })} />
              </div>
              <div className="col">
                <span>ЕИК/ЕГН</span>
                <Input.Text value={data?.eic || ''} onChange={({ target: { value } }) => handleChange({ eic: value })} />
              </div>
              <div className="col">
                <span>Булстат</span>
                <Input.Text value={data?.bulstat || ''} onChange={({ target: { value } }) => handleChange({ bulstat: value })} />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <span>Град</span>
                <Input.Text value={data?.city || ''} onChange={({ target: { value } }) => handleChange({ city: value })} />
              </div>
              <div className="col col-double">
                <span>Адрес</span>
                <Input.Text value={data?.address || ''} onChange={({ target: { value } }) => handleChange({ address: value })} />
              </div>
            </div>
          </>}
        </div>
        <span>Избор на точки</span>
        <Inputs.Checkboxes
          col
          buttons={[...(group?.objects || []), ...(objectsNotInGroups || [])]?.map(({ _id, itn, isProducer }) => ({
            label: `Обект: ${itn}${isProducer ? ' - Производство' : ''}`,
            value: _id,
          }))}
          value={data?.objects}
          onClick={(value) => handleChange({ objects: value })}
        />
      </div>
      <div className="modal-invoice-group-footer">
        <Button.Raised
          text={`${group ? 'Редактирай' : 'Създай'} група`}
          onClick={() => {
            if (!isValid()) return
            dispatch(startLoading())
            if (group) dispatch(updateInvoiceGroup({
              payload: { ...data, emails: data?.emails?.filter(Boolean) },
              onSuccess: (invoiceGroup) => {
                if (isDefaultGroup && invoiceGroup._id !== client.defaultInvoiceGroup) dispatch(updateClient({
                  data: { _id: client._id, defaultInvoiceGroup: invoiceGroup._id },
                  onSuccess
                }))
                else if (!isDefaultGroup && invoiceGroup._id === client.defaultInvoiceGroup) dispatch(updateClient({
                  data: { _id: client._id, defaultInvoiceGroup: null },
                  onSuccess
                }))
                else onSuccess()
              }
            }))
            else dispatch(createInvoiceGroup({
              payload: { ...data, emails: data?.emails?.filter(Boolean), client: client?._id },
              onSuccess: (invoiceGroup) => {
                if (isDefaultGroup) dispatch(updateClient({
                  data: { _id: client._id, defaultInvoiceGroup: invoiceGroup._id },
                  onSuccess
                }))
                else onSuccess()
              }
            }))
          }}
        />
        {group && (
          <Button.Raised
            text="Изтрий"
            className="btn-delete"
            onClick={() => {
              dispatch(startLoading())
              dispatch(deleteInvoiceGroup({
                payload: { _id: data?._id },
                onSuccess: (invoiceGroup) => {
                  if (invoiceGroup._id === client.defaultInvoiceGroup) dispatch(updateClient({
                    data: { _id: client._id, defaultInvoiceGroup: null },
                    onSuccess
                  }))
                  else onSuccess()
                }
              }))
            }}
          />
        )}
      </div>
    </div>
  )
}

export default InvoiceGroups
