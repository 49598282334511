import React from 'react'
import { Button } from '@makedonski/admin-ui'
import { useDispatch } from 'react-redux'
import { setOverlay } from '../../../actions'
import { Card } from '../../'
import './styles.scss'

const ClientFileSystem = ({}) => {
  const dispatch = useDispatch()
  return (
    <div className="card-client-file-system-container">
      <div className="card-client-file-system-header row">
        <h3>Файлове</h3>
        <div className="icon icon-add" />

        <Button.Icon name="plus" size={28} onClick={() => dispatch(setOverlay({ isOpen: false }))} />
      </div>
      <div className="card-client-file-system-content">
        <Card.FileSystem />
      </div>
    </div>
  )
}

export default ClientFileSystem
