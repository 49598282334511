import React, { useState } from 'react'
import { Input } from '@makedonski/admin-ui'
import './styles.scss'

const Password = ({ ...props }) => {
  const [showPass, setShowPass] = useState(false)
  return (
    <div className="inputs-password-container">
      <Input.Text
        autoComplete="current-password"
        type={!showPass ? 'password' : 'text'}
        {...props}
      />
      {props?.value && (
        <div
          className={`icon icon-eye-custom ripple ${showPass && 'active'}`}
          onClick={() => setShowPass(!showPass)}
        />
      )}
    </div>
  )
}

export default Password
