import S3FileUpload from "react-s3"
import { nanoid } from "nanoid"
import { useState, useEffect, useRef } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

export const emit = (socket, payload) => {
    if (!socket) return
    console.log('Socket out: ', payload);
    socket.emit("tasks", payload)
}

export const useQuery = () => {
    const history = useHistory()
    const location = useLocation()
    const query = new URLSearchParams(window.location.search)
    const handleUrlChange = (newValues = {}) => {
        Object.entries(newValues).forEach(([key, value]) => {
            if (!value) query.delete(key)
            else if (query.has(key)) query.set(key, value)
            else query.append(key, value)
        })

        history.replace({ ...location, search: '?' + query.toString() })
    }

    return { ...Array.from(query.keys()).reduce((a, key) => ({ ...a, [key]: query.get(key) }), {}), handleUrlChange }
}

export const uploadFiles = (files) => {
    const promises = []
    Array.from(files).forEach((f) =>
        promises.push(
            S3FileUpload.uploadFile(
                new File([f], [f?.name, nanoid()].join("_"), {
                    type: f?.type,
                    lastModified: f?.lastModified || new Date(),
                }),
                {
                    bucketName: 'dev-fidweb',
                    dirName: 'tasks',
                    region: 'eu-central-1',
                    accessKeyId: 'AKIA5L7PZ7W5MOVC5MHY',
                    secretAccessKey: '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',
                }
            )
        )
    )
    return Promise.all(promises)
}

export const useOnClickOutside = (ref, handler, isOpen = true, buttonRef) => {
    useEffect(() => {
        if (!isOpen) return
        const listener = (event) => {
            if (!ref.current || ref.current.contains(event.target)) return
            if (buttonRef && buttonRef.contains(event.target)) return
            handler(event)
        }
        document.addEventListener('mousedown', listener)
        document.addEventListener('touchstart', listener)
        return () => {
            document.removeEventListener('mousedown', listener)
            document.removeEventListener('touchstart', listener)
        }
    }, [ref, handler])
}

export const useCollapsable = (ref, open = false) => {
    const [isOpen, setIsOpen] = useState(open)
    const handleCollapse = () => {
        const elem = ref.current
        if (!elem) return
        elem.style.height = ''
        elem.style.transition = 'none'
        const startHeight = window.getComputedStyle(elem).height
        elem.classList.toggle('active')
        const height = window.getComputedStyle(elem).height
        elem.style.height = startHeight
        requestAnimationFrame(() => {
            elem.style.transition = ''
            requestAnimationFrame(() => (elem.style.height = height))
        })
        elem.addEventListener('transitionend', () => (elem.style.height = ''))
    }

    const firstRender = useRef(true)
    useEffect(() => {
        if (firstRender.current) firstRender.current = false
        else handleCollapse()
    }, [isOpen])

    return [isOpen, setIsOpen]
}
