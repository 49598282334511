import React from 'react'
import { Tasks as TasksComponents } from '../../components'
import { URL } from 'config/settings'
import './styles.scss'

const Tasks = ({ }) => {
  return (
    <div className="screen-tasks-container col">
      <TasksComponents URL={URL} />
    </div>
  )
}

export default Tasks
