import { noticesTypes } from '../actions'

const initialState = {
  notices: [],
  notice: {},
  hasNextPage: false,
  nextPage: 1,
}

const notices = (state = initialState, { type, payload }) => {
  switch (type) {
    case noticesTypes.SET_FIELD:
      return { ...state, [payload?.field]: payload?.value }
    case noticesTypes.GET_NOTICES_SUCCESS:
      return {
        ...state,
        notices: [...(state.notices || []), ...payload.docs],
        hasNextPage: payload.hasNextPage,
        nextPage: payload.nextPage,
      }
    default:
      return state
  }
}

export default notices
