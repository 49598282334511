import React, { useState } from 'react'
import { Button, Input } from '@makedonski/admin-ui'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { setModal, startLoading, stopLoading, updateUser } from '../../../actions'
import { statusMap } from '../../../config/constants'
import { Inputs } from 'components'
import './styles.scss'
import { uploadFile } from 'utilities'

const Profile = ({ close }) => {
  const dispatch = useDispatch()
  const { currentUser: profile } = useSelector(({ general }) => general)


  const [isEditing, setIsEditing] = useState(false)
  const [data, setData] = useState({})

  const handleChange = (field, value) => setData({ ...data, [field]: value })
  const handleUpload = (files) => {
    dispatch(startLoading())
    uploadFile({ file: files[0], createId: true }).then(({ location }) => {
      dispatch(stopLoading())
      handleChange('coverPhoto', location)
    })
  }
  const handleUpdateUser = () => dispatch(updateUser({ payload: { _id: profile._id, ...data }, isCurrentUser: true }))

  return (
    <div className="modal-profile-container">
      <div className="modal-profile-header row">
        <h2>Личен профил</h2>
        <Button.Icon name="plus" size="26px" className="btn-close" onClick={close} />
      </div>
      <div className="modal-profile-content row">
        <div className="profile-image-container col">
          <div
            className="profile-image icon no-active no-pointer"
            style={{ backgroundImage: `url(${data?.coverPhoto || profile?.coverPhoto || require('../../../assets/images/default-user-avatar.jpg')})`, }}
          >
            {/* <div className="profile-status icon" style={{ backgroundColor: statusMap[profile?.status] }} /> */}
          </div>
          {isEditing && (<Button.UploadButton text="Нова снимка" onChange={({ target: { files } }) => handleUpload(files)} />)}
        </div>
        <div className="profile-data-container col">
          <p>Лични данни</p>
          {[
            // { label: 'Имейл:', value: 'email' },
            { label: 'Име:', value: 'firstName', sizeSingle: true },
            { label: 'Фамилия:', value: 'lastName', sizeSingle: true },
            { label: 'Дата на раждане:', value: 'birthday', isDate: true },
            { label: 'Телефон:', value: 'phoneNumber' },
            // { label: 'Позиция:', value: 'occupation' },
            { label: 'Описание:', value: 'description', isTextArea: true },
          ]?.map(({ label, value, isTextArea, isDate, sizeSingle }, i) =>
            isTextArea ? (
              <Input.Textarea
                key={`input-field-${i}`}
                disabled={!isEditing}
                placeholder={label}
                onChange={({ target: { value: inputValue } }) => handleChange(value, inputValue)}
                value={data?.[value] || profile?.[value] || ''}
              />
            ) : isDate ? (
              <Inputs.DatePicker
                minDate={moment().subtract(100, 'years').toDate()}
                maxDate={moment().subtract(1, 'days').toDate()}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                key={`input-field-${i}`}
                onChange={(date) => handleChange(value, date)}
                placeholder={label}
                disabled={!isEditing}
                pickDate={
                  (data?.[value] && moment(data?.[value]).toDate()) ||
                  (profile?.[value] && moment(profile?.[value]).toDate()) ||
                  null
                }
              />
            ) : (
              <Input.Text
                key={`input-field-${i}`}
                className={`${sizeSingle && 'single'}`}
                disabled={!isEditing}
                placeholder={label}
                onChange={({ target: { value: inputValue } }) => handleChange(value, inputValue)}
                value={data?.[value] || profile?.[value] || ''}
              />
            )
          )}
          <p>Данни за вход в системата</p>
          <Input.Text disabled={true} value={profile?.email} />
          {isEditing && (
            <Button.Raised
              text="Промени парола"
              className="btn-change-pass"
              onClick={() => dispatch(setModal({ isOpen: true, type: 'changePassword', props: {} }))}
            />
          )}
          <div className="row buttons">
            {isEditing ? (
              <>
                <Button.Raised
                  className="btn-cancel"
                  text="Откажи "
                  onClick={() => {
                    setIsEditing(false)
                    setData({})
                  }}
                />
                <Button.Raised
                  className="btn-save"
                  text="Запази промените"
                  onClick={() => {
                    setIsEditing(false)
                    setData({})
                    handleUpdateUser()
                  }}
                />
              </>
            ) : (
              <Button.Raised text="Промени данните" className="btn-edit" onClick={() => setIsEditing(true)} />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile
