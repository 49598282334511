import React, { useState } from "react"
import { Button, Input } from "@makedonski/admin-ui"
import "./styles.scss"
import { emailRegex } from "config/constants"

const MailerTestEmail = ({ close, onSuccess }) => {
    const [email, setEmail] = useState('')
    return <div className="modal-mailer-test-email-container">
        <div className="modal-mailer-test-email-header row">
            <h2>Изпращане на тестови имейл</h2>
            <div className="icon icon-close" onClick={close} />
        </div>
        <div className="modal-mailer-test-email-content">
            <span>Изпрати до:</span>
            <Input.Text value={email} onChange={({ target: { value } }) => setEmail(value)} />
        </div>
        <div className="modal-mailer-test-email-footer">
            <Button.Raised text="Изпрати" disabled={!emailRegex.test(email)} onClick={() => { onSuccess(email); close() }} />
        </div>
    </div>
}

export default MailerTestEmail