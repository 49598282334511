import { ofType, ActionsObservable } from 'redux-observable'
import { switchMap, catchError } from 'rxjs/operators'
import { Api, Auth, Alerts } from '@makedonski/socourt-utilities'
import { authTypes, stopLoading } from '../actions'
import { URL } from '../config/settings'
import { history } from '../config/store'

export const login = (action$) =>
  action$.pipe(
    ofType(authTypes.LOGIN),
    switchMap(({ payload }) =>
      Api.post(`${URL}/user/login`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            window.localStorage.clear()
            Auth.authenticate(response.payload.token, {
              token: response.payload.token,
              ...response.payload?.user,
            }).then(() => {
              obs.next({
                type: authTypes.LOGIN_SUCCESS,
                payload: response.payload.user,
              })
              history.push('/')
              obs.next(stopLoading())
              obs.complete()
            })
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Грешка!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const logout = (action$) =>
  action$.pipe(
    ofType(authTypes.LOGOUT),
    switchMap(() =>
      ActionsObservable.create((obs) => {
        Auth.signout().then(() => {
          localStorage.clear()
          history.push('/login')
          obs.complete()
        })
      })
    )
  )

export const checkUser = (action$) => action$.pipe(
  ofType(authTypes.CHECK_USER),
  switchMap(() =>
    Api.get(`${URL}/user/check`).pipe(
      switchMap(({ response }) => ActionsObservable.create((obs) => {
        obs.next({ type: authTypes.LOGIN_SUCCESS, payload: response.user, })
        obs.complete()
      })),
      catchError((err) => ActionsObservable.create((obs) => {
        obs.next(stopLoading())
        if (err.status === 401) {
          Auth.signout().then(() => {
            Alerts.error('Невалидна сесия!', 'Моля влезте отново!')
            history.push('/login')
            obs.complete()
          })
        } else {
          Alerts.error('Грешка!', err?.response?.error?.message || '')
          obs.complete()
        }
      })
      )
    )
  )
)
