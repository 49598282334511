import { pick } from 'lodash'
import { commissionsTypes } from '../actions'

export const initialState = {
    commissions: [],
    hasNextPage: false,
    nextPage: 1,
    totalDocs: 0,
    showCheckboxes: false,
    selectedCheckboxes: []
}

const commissions = (state = initialState, { type, payload }) => {
    switch (type) {
        case commissionsTypes.SET_FIELDS:
            return { ...state, ...payload }
        case commissionsTypes.GET_COMMISSIONS_SUCCESS:
            const newData = [1, null].includes(payload.page) ? payload.docs : [...(state.commissions || []), ...payload.docs]
            return { ...state, commissions: newData, ...pick(payload, ['hasNextPage', 'nextPage', 'totalDocs']) }
        default:
            return state
    }
}

export default commissions