import React, { useEffect, useMemo, useRef, useCallback } from 'react'
import moment from 'moment'
import { Popover } from '@varld/popover'
import { Button } from '@makedonski/admin-ui'
import { ScrollSync } from 'react-scroll-sync'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { isEmpty, flatten } from 'lodash'
import {
  startLoading,
  setClientsField,
  setClientsFields,
  getClientsStart,
  updateObject,
  setModal,
  setObjectsFields,
  sendDocumentsToERP,
  exportClients,
  createComment,
  updateClient,
  getClientsFilters
} from 'actions'
import { Inputs, Popup, Shared } from 'components'
import { clientsTypesButtons, clientsSearchOptions, clientsObjectsFields, reasonsForLeaving, hideDealerFields, clientsStatus } from 'config/constants'
import { useQuery, usePrevious } from 'hooks'
import { checkIsDealer, mapClientsSearch, renderCell } from 'utilities'
import './styles.scss'

const Clients = ({ }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    clients,
    clientsFields: clientsFieldsInitial,
    lockedColumn,
    hasNextPage,
    nextPage: page,
    showCheckboxes,
    selectedCheckboxes,
    availableFilters,
    availableSort,
    clientsCount,
  } = useSelector((state) => state.clients)
  const { roles } = useSelector(({ general }) => general?.currentUser) || {}
  const isDealer = checkIsDealer()
  const permissions = useMemo(() => roles?.reduce((acc, { permissions }) => ({
    ...acc,
    ...Object.fromEntries(Object.entries(permissions).map(([key, value]) => {
      const values = flatten(roles.map(({ permissions }) => permissions[key]))
      return [key, values]
    }))
  }), {}), [roles])
  const { showCheckboxes: showCheckboxesObjects, selectedCheckboxes: selectedCheckboxesObjects } = useSelector(({ objects }) => objects)
  const { type: clientsType, searchBy = '', sort: sortParam = '{}', filter: filterParam = '{}', clear, handleUrlChangeMultiple, } = useQuery({ type: clientsTypesButtons })

  const prevType = usePrevious(clientsType)
  const filter = useMemo(() => JSON.parse(filterParam), [filterParam])
  const sort = useMemo(() => JSON.parse(sortParam), [sortParam])

  const clientsFields = useMemo(() => {
    return clientsFieldsInitial
      .filter(({ value }) => !isDealer || !hideDealerFields.includes(value))
      .filter(({ includedIn }) => !includedIn || includedIn.includes(clientsType))
      .filter(({ platform }) => !platform || platform.includes(process.env.REACT_APP_PLATFORM))
  }, [clientsFieldsInitial, clientsType, isDealer])

  const hasAvailableFilters = useMemo(() => !isEmpty(availableFilters), [availableFilters])
  const searchQuery = useMemo(() => {
    return { enableSortFor: availableSort, filters: availableFilters, ...mapClientsSearch(sort, filter, clientsType, availableFilters) }
  }, [sortParam, filterParam, clientsType, hasAvailableFilters])

  const clearCheckBoxes = () => {
    dispatch(setClientsFields({ showCheckboxes: false, selectedCheckboxes: [] }))
    dispatch(setObjectsFields({ showCheckboxes: false, selectedCheckboxes: [] }))
  }

  const fetch = (options) => {
    const { pageOne, getFilters } = options || {}
    if (!hasAvailableFilters && getFilters) {
      dispatch(startLoading())
      dispatch(getClientsFilters())
    }
    else dispatch(getClientsStart({ page: prevType !== clientsType || pageOne ? 1 : page, ...searchQuery }))
  }

  useEffect(() => () => dispatch(setClientsFields({ clients: null, nextPage: 1, hasNextPage: false, showCheckboxes: false, selectedCheckboxes: [] })), [])
  useEffect(() => { fetch({ pageOne: true, getFilters: true }) }, [searchQuery])
  useEffect(() => { if (showCheckboxes) clearCheckBoxes() }, [clientsType])

  const { filterPresets = [] } = useSelector(({ general }) => general?.currentUser) || {}
  const selectedFilterPreset = useMemo(() => filterPresets.find(({ applied }) => applied), [filterPresets])
  useEffect(() => {
    if (selectedFilterPreset) {
      const { lockedColumn, clientsFields, searchBy, search, sort, filter } = selectedFilterPreset?.preset || {}
      dispatch(setClientsFields({ lockedColumn, clientsFields }))
      handleUrlChangeMultiple({ searchBy, search, sort, filter })
    }
  }, [selectedFilterPreset])


  const toggleAllRowsExpanded = (payload) => {
    tableRef?.current?.toggleAllRowsExpanded(payload)
    tableFrozenRef?.current?.toggleAllRowsExpanded(payload)
  }
  // const handleSendDocumentsToERPInitial = () => {
  //   const transfers = clients
  //     ?.filter(({ _id }) => selectedCheckboxes?.includes(_id))
  //     ?.filter(({ objects }) => objects?.some(({ status }) => status === 'transfer'))
  //     ?.map(({ _id }) => _id)
  //   if (!isEmpty(transfers))
  //     dispatch(
  //       setModal({
  //         isOpen: true,
  //         type: 'confirmation',
  //         props: {
  //           title: 'Точки за пререгистрация',
  //           children:
  //             'Сред избраните точки има такива със статус "Пререгистрация". Моля продължете към избор на точки, за които да се генерират документи или продължете без тези обекти',
  //           buttons: (
  //             <div className="row" style={{ justifyContent: 'space-between' }}>
  //               <Button.Raised
  //                 text="Избери обекти"
  //                 onClick={() => {
  //                   handleSendDocumentsToERP({ hideSuccess: true })
  //                   dispatch(setModal({ isOpen: false }))
  //                   history.push(`/clients/transfer/confirm-documents?ids=${transfers}`)
  //                 }}
  //               />
  //               <div style={{ width: 20, height: 20 }}></div>
  //               <Button.Raised text="Продължи без" onClick={() => handleSendDocumentsToERP({})} />
  //             </div>
  //           ),
  //         },
  //       })
  //     )
  //   else handleSendDocumentsToERP({})
  // }

  // const handleSendDocumentsToERP = ({ hideSuccess }) => {
  //   if (!hideSuccess) dispatch(startLoading())
  //   dispatch(
  //     sendDocumentsToERP({
  //       data: { toProcess: selectedCheckboxes?.map((client) => ({ client })) },
  //       onSuccess: () => {
  //         clearCheckBoxes()
  //         if (!hideSuccess)
  //           dispatch(
  //             setModal({
  //               isOpen: true,
  //               type: 'confirmation',
  //               props: {
  //                 title: 'Изпратени документи',
  //                 children: 'Успешно генерирани и изпратени документи към ЕРП',
  //                 onClick: () => {
  //                   dispatch(startLoading())
  //                   fetch({ pageOne: true })
  //                 },
  //               },
  //             })
  //           )
  //       },
  //     })
  //   )
  // }

  const handleSendDocumentsToERP = () => {
    dispatch(setModal({
      isOpen: true, type: 'inputDate', props: {
        title: "Дата на входиране",
        defaultValue: new Date(),
        onSuccess: (documentsSentDate, close) => {
          dispatch(startLoading())
          selectedCheckboxesObjects.forEach((_id, i) => dispatch(updateObject({
            data: { _id, status: 'documents-sent', documentsSentDate },
            onSuccess: () => {
              if (i !== selectedCheckboxesObjects.length - 1) return
              fetch({ pageOne: true })
              close()
              clearCheckBoxes()
            }
          })))
        }
      }
    }))
  }

  const handleTerminateRegister = () => {
    dispatch(setModal({
      isOpen: true, type: 'objectStatus', props: {
        title: "Прекратяване на регистрация",
        textInput: true,
        dateInput: false,
        validate: (data) => data?.text?.length,
        onClick: (data) => {
          dispatch(startLoading())
          selectedCheckboxesObjects.forEach((_id, i) => {
            dispatch(
              updateObject({
                data: { _id, status: 'potential' },
                onSuccess: () => {
                  if (i !== selectedCheckboxesObjects.length - 1) return
                  dispatch(createComment({
                    data: {
                      refType: 'Clients',
                      ref: selectedCheckboxes.at(0),
                      text: `Прекратена регистрация\nПричина: ${data.text}\nДата: ${moment().format('DD.MM.YYYY')}\nТочки: ${clients
                        .find(({ _id }) => selectedCheckboxes.includes(_id))
                        .objects.filter(({ _id }) => selectedCheckboxesObjects.includes(_id))
                        .map(({ itn }) => itn).join(", ")}`
                    },
                    onSuccess: () => {
                      fetch({ pageOne: true })
                      clearCheckBoxes()
                      toggleAllRowsExpanded(false)
                    }
                  }))
                },
              })
            )
          })
        }
      }
    }))
  }

  const handleActivateObjects = () => {
    const client = clients.find(({ _id }) => _id === selectedCheckboxes.at(0))
    dispatch(setModal({
      isOpen: true, type: 'objectStatus', props: {
        title: "Активиране на клиент/точки",
        textInput: true,
        validate: (data) => data?.text?.length && data?.date,
        onClick: (data) => {
          dispatch(startLoading())
          selectedCheckboxesObjects.forEach((_id, i) => {
            dispatch(
              updateObject({
                data: moment(data.date).isBefore(moment())
                  ? { _id, status: 'active', activeFrom: data.date }
                  : { _id, activeFrom: data.date, confirmedOn: new Date() },
                onSuccess: () => {
                  if (i !== selectedCheckboxesObjects.length - 1) return
                  dispatch(createComment({
                    data: {
                      refType: 'Clients',
                      ref: selectedCheckboxes.at(0),
                      text: selectedCheckboxesObjects.length === client.objects.length
                        ? `Активиране на клиент\nПричина: ${data.text}\nДата: ${moment(data.date).format('DD.MM.YYYY')}`
                        : `Активиране на точки\nПричина: ${data.text}\nДата: ${moment(data.date).format('DD.MM.YYYY')}\nТочки: ${client.objects
                          .filter(({ _id }) => selectedCheckboxesObjects.includes(_id))
                          .map(({ itn }) => itn).join(", ")}`
                    },
                    onSuccess: () => {
                      if (client.objects.every(({ status }) => status === 'inactive')) dispatch(updateClient({
                        data: { ...client, contractSettings: { ...client.contractSettings, contractDate: data.date } }
                      }))
                      fetch({ pageOne: true })
                      clearCheckBoxes()
                      toggleAllRowsExpanded(false)
                    }
                  }))
                },
              })
            )
          })
        }
      }
    }))
  }

  const handleTerminateObjects = () => {
    const client = clients.find(({ _id }) => _id === selectedCheckboxes.at(0))
    dispatch(setModal({
      isOpen: true, type: 'objectStatus', props: {
        title: "Прекратяване на договор/точки",
        options: reasonsForLeaving.map((value) => ({ value, label: value })),
        validate: (data) => data?.dropdown && data?.date,
        onClick: (data) => {
          dispatch(startLoading())
          selectedCheckboxesObjects.forEach((_id, i) => {
            dispatch(
              updateObject({
                data: moment(data.date).isBefore(moment())
                  ? { _id, status: 'inactive', inactiveFrom: data.date }
                  : { _id, status: 'leaving', inactiveFrom: data.date, confirmedOn: new Date() },
                onSuccess: () => {
                  if (i !== selectedCheckboxesObjects.length - 1) return
                  dispatch(createComment({
                    data: {
                      refType: 'Clients',
                      ref: selectedCheckboxes.at(0),
                      text: selectedCheckboxesObjects.length === client.objects.length
                        ? `Прекратяване на договор\nПричина: ${data.dropdown}\nДата: ${moment(data.date).format('DD.MM.YYYY')}`
                        : `Прекратяване на точки\nПричина: ${data.dropdown}\nДата: ${moment(data.date).format('DD.MM.YYYY')}\nТочки: ${client.objects
                          .filter(({ _id }) => selectedCheckboxesObjects.includes(_id))
                          .map(({ itn }) => itn).join(", ")}`
                    },
                    onSuccess: () => {
                      fetch({ pageOne: true })
                      clearCheckBoxes()
                      toggleAllRowsExpanded(false)
                    }
                  }))
                },
              })
            )
          })
        }
      }
    }))
  }

  const handleResponsibleForRegistration = () => dispatch(setModal({
    isOpen: true, type: 'responsibleForRegistration', props: {
      clients: selectedCheckboxes,
      currentlyResponsible: clients
        .filter(({ _id }) => selectedCheckboxes.includes(_id))
        .reduce((acc, { _id, responsibleForRegistration }) => ({ ...acc, [_id]: responsibleForRegistration }), {}),
      onSuccess: () => {
        fetch({ pageOne: true })
        clearCheckBoxes()
      }
    }
  }))

  const handleForRegistration = () => {
    dispatch(startLoading())
    selectedCheckboxesObjects.forEach((_id, i) => dispatch(updateObject({
      data: {
        _id,
        status: 'registration',
        entryEnd: moment().startOf('month').add(9, 'days').add(new Date().getDate() <= 10 ? 0 : 1, 'months').toDate(),
        timesInPreregistration: 0,
        documentsSentDate: null,
        dpiGeneratedDate: null,
        dpiSignedDate: null,
        issue: null,
        additionalStatus: null,
        confirmedOn: null,
        activeFrom: null,
      },
      onSuccess: () => {
        if (i !== selectedCheckboxesObjects.length - 1) return
        fetch({ pageOne: true })
        clearCheckBoxes()
      }
    })))
  }

  const handleCreateGroup = () => {
    dispatch(setModal({ isOpen: true, type: 'createClientsGroup', props: { clients: selectedCheckboxes } }))
    clearCheckBoxes()
  }

  const handleRedistributeDealers = () => dispatch(setModal({
    isOpen: true, type: 'redistributeDealers', props: {
      clients: clients.filter(({ _id }) => selectedCheckboxes.includes(_id)),
      onSuccess: () => {
        fetch({ pageOne: true })
        clearCheckBoxes()
      }
    }
  }))


  const handleChangeStatus = () => {
    // dispatch(setModal({
    //   isOpen: true,
    //   type: 'clientsStatus',
    //   props: { clients: selectedCheckboxes, fetch: () => fetch({ isInitial: true }) },
    // }))
    // clearCheckBoxes()
  }

  const handleAddDocuments = () => {
    const url = new URLSearchParams(location.search)
    url.append('docs', showCheckboxes?.payload)
    url.append('client', selectedCheckboxes[0])
    history.push(`clients/create?` + url.toString())
  }

  const handleCancelAction = () => {
    clearCheckBoxes()
    toggleAllRowsExpanded(false)
  }

  const handleExportClients = ({ multi, contacts }) => {
    let payload = {
      payload: { ...searchQuery, pagination: false },
      fields: {
        clientsFields: [
          { label: 'ClientID', value: "_id" },
          ...clientsFields
        ],
        objectsFields: [
          { label: 'ObjectID', value: "_id" },
          ...clientsObjectsFieldsFiltered,
          { label: 'СТП Профил', value: 'STPMeasure' },
          { label: 'Прогнозна консумация', value: 'averageConsumption' },
        ]
      }
    }

    if (payload.fields.clientsFields.some(({ value }) => value === 'annex')) {
      const newClientFields = [...payload.fields.clientsFields]
      const columnIndex = newClientFields.findIndex(({ value }) => value === 'annex')
      newClientFields.splice(columnIndex + 1, 0, { label: 'Файл', value: 'annexFile' },)
      payload.fields.clientsFields = newClientFields
    }

    dispatch(exportClients({ payload, multi, contacts }))
  }

  const handleDepositsLeaving = () => {
    const url = new URLSearchParams()
    url.append('ids', selectedCheckboxes)
    url.append('monthYear', moment().format('MM/YYYY'))
    history.push(`/deposits/create?` + url.toString())
  }

  const handleConsumptionAnalysis = () => {
    const url = new URLSearchParams()
    url.append('clients', selectedCheckboxes)
    history.push(`/consumption_analysis?` + url.toString())
  }

  const tableRef = useRef()
  const tableFrozenRef = useRef()
  const disabledObjects = useCallback((objects, action) => {
    switch (action) {
      case 'forRegistration':
        return objects.filter(({ status }) => ![
          'potential'
        ].includes(status)).map(({ _id }) => _id)
      case "sendDocumentsToERP":
        return objects?.filter(({ status }) => ![
          'registration',
          'preregistration',
          'transfer'
        ].includes(status)).map(({ _id }) => _id)
      case 'terminateRegister':
        return objects?.filter(({ status, confirmedOn }) => ![
          'registration',
          'documents-sent',
          'documents-received',
          'documents-received-missing',
          'preregistration',
          'transfer'
        ].includes(status) || confirmedOn).map(({ _id }) => _id)
      case 'terminateObjects':
        return objects?.filter(({ status, confirmedOn }) => ![
          'active'
        ].includes(status) || confirmedOn).map(({ _id }) => _id)
      case 'activateObjects':
        return objects?.filter(({ status, confirmedOn }) => ![
          'inactive'
        ].includes(status) || confirmedOn).map(({ _id }) => _id)
      default:
        return []
    }
  }, [])

  const clientsObjectsFieldsFiltered = useMemo(() => {
    return clientsObjectsFields
      .filter(({ includedIn }) => !includedIn || includedIn.includes(clientsType))
      .filter(({ value }) => !isDealer || !hideDealerFields.includes(value))
  }, [clientsType])

  const ObjectsTable = useCallback(({ row }) => {
    return (<Shared.Table
      columns={clientsObjectsFieldsFiltered}
      data={row.original.objects || []}
      renderCell={(object, field) => renderCell.objects(object, field, { fetch, client: row.original })}
      containerStyle={{ maxHeight: 350 }}
      headerWidth={clientsObjectsFieldsFiltered.reduce((a, c) => a + (c.size || 300), 0)}
      outlines={row.original.objects?.reduce((acc, { _id, status, inactiveFrom }) => {
        return ['documents-received-missing']?.includes(status) || (status === 'leaving' && inactiveFrom) ? { ...acc, [_id]: '#fa4444' } : acc
      }, {})}
      useCheckboxes={!!showCheckboxesObjects}
      hideSelectAll
      selectedCheckboxes={selectedCheckboxesObjects}
      disabledCheckboxes={disabledObjects(row.original.objects, showCheckboxes.action)}
      onCheckboxChange={(value) => dispatch(setObjectsFields({ selectedCheckboxes: value }))}
    />)
  }, [clientsObjectsFieldsFiltered, showCheckboxesObjects, selectedCheckboxesObjects, fetch])
  const sortingComponents = clientsFields
    // .filter(({ sortable }) => sortable)
    // .filter(({ value }) => ['register', 'leaving'].includes(clientsType) || value !== 'status')
    .reduce((acc, { value }) => {
      return {
        ...acc,
        [value]:
          <Popover className="header-settings" popover={({ close }) => (
            <Popup.ClientsFieldFilter
              hide={close}
              column={value}
              lockColumn={() => dispatch(setClientsField({
                field: 'lockedColumn',
                value: lockedColumn?.value === value ? null : clientsFields.find((f) => f.value === value)
              }))}
              isLocked={lockedColumn?.value === value}
            />
          )}
          >
            <div className="icon icon-arrow-down" />
          </Popover>
      }
    }, {})

  const clientsTypesButtonsFilter = useMemo(() => {
    return clientsTypesButtons
      .filter(({ value }) => !permissions || ["all", value].some(p => permissions?.clients?.includes(p)))
      .map((opt) => isDealer && opt.value === 'active' ? { ...opt, label: 'Успешно регистрирани' } : opt)
  }, [permissions])

  const clientsFiltered = useMemo(() => {
    const orderIndex = clientsStatus.reduce((acc, { value }, i) => ({ ...acc, [value]: i }), {})
    return clients
      ?.filter(({ objects }) => !filter.status || clientsType === 'register' || (clientsType === 'leaving' && objects.every(({ status }) => filter.status.some(s => s === status))))
      ?.map(({ objects, ...c }) => ({
        ...c,
        objects: objects
          .filter(({ status, isClone }) => !filter.status || filter.status.includes(status) || (filter.status.includes('transfer') && isClone))
          .sort((a, b) => orderIndex[a.status] - orderIndex[b.status])
      }))
  }, [clients, filter])

  return (
    <div className="screen-clients-container">
      <div className="screen-clients-inner-container">
        <div className="screen-clients-header row">
          <Inputs.RoundedButtons
            buttons={clientsTypesButtonsFilter}
            value={clientsType}
            onChange={({ value }) => {
              if (!selectedFilterPreset) clear()
              handleUrlChangeMultiple({ type: value, searchBy })
            }}
          />
          <Inputs.SearchMulti
            search={filter?.[searchBy] || ''}
            searchBy={searchBy || 'eic'}
            handleChange={({ search, searchBy: newSearchBy }) => {
              const newFilter = { ...filter, [searchBy]: undefined, [newSearchBy]: search || undefined }
              handleUrlChangeMultiple({
                searchBy: search ? newSearchBy : undefined,
                filter: Object.values(newFilter).filter(Boolean).length ? JSON.stringify(newFilter) : undefined
              })
            }}
            options={clientsSearchOptions}
            popupText={{ secondary: ' ' }}
          />
          <Popover className="header-settings" popover={({ close }) => <Popup.ClientsFilterOptions hide={close} />}>
            <div className="row">
              <span className='selectedFilterPreset'>{selectedFilterPreset?.name}</span>
              <div className='btn-other-filters icon icon-filter' />
            </div>
          </Popover>
          <div className="row row-right">
            {showCheckboxes?.action && (<Button.Raised text="Откажи" className="btn-cancel" onClick={handleCancelAction} />)}
            {showCheckboxes?.action === 'addDocuments' ? (
              <Button.Raised text="Започни" onClick={handleAddDocuments} disabled={isEmpty(selectedCheckboxes)} />
            ) : showCheckboxes?.action === 'changeStatus' ? (
              <Button.Raised text="Промени" disabled={isEmpty(selectedCheckboxes)} onClick={handleChangeStatus} />
            ) : showCheckboxes?.action === 'createGroup' ? (
              <Button.Raised text="Създай група" disabled={isEmpty(selectedCheckboxes)} onClick={handleCreateGroup} />
            ) : showCheckboxes?.action === 'redistributeDealers' ? (
              <Button.Raised text="Преразпредели" disabled={isEmpty(selectedCheckboxes)} onClick={handleRedistributeDealers} />
            ) : showCheckboxes?.action === 'forRegistration' ? (
              <Button.Raised text="За регистрация" onClick={handleForRegistration} />
            ) : showCheckboxes?.action === 'terminateRegister' ? (
              <Button.Raised text="Прекратяване" onClick={handleTerminateRegister} disabled={isEmpty(selectedCheckboxesObjects)} />
            ) : showCheckboxes?.action === 'activateObjects' ? (
              <Button.Raised text="Активиране" onClick={handleActivateObjects} disabled={isEmpty(selectedCheckboxesObjects)} />
            ) : showCheckboxes?.action === 'terminateObjects' ? (
              <Button.Raised text="Прекратяване" onClick={handleTerminateObjects} disabled={isEmpty(selectedCheckboxesObjects)} />
            ) : showCheckboxes?.action === 'sendDocumentsToERP' ? (
              <Button.Raised text="Входирай" onClick={handleSendDocumentsToERP} disabled={isEmpty(selectedCheckboxesObjects)} />
            ) : showCheckboxes?.action === 'responsibleForRegistration' ? (
              <Button.Raised text="Разпредели" onClick={handleResponsibleForRegistration} disabled={isEmpty(selectedCheckboxes)} />
            ) : showCheckboxes?.action === 'depositsLeaving' ? (
              <Button.Raised text="Депозити" onClick={handleDepositsLeaving} disabled={isEmpty(selectedCheckboxes)} />
            ) : showCheckboxes?.action === 'consumptionAnalysis' ? (
              <Button.Raised text="Анализ" onClick={handleConsumptionAnalysis} disabled={isEmpty(selectedCheckboxes)} />
            ) : (
              <>
                <Popover className="header-settings" popover={({ close }) => (
                  <Popup.ClientsOptions hide={close} fetch={fetch} toggleAllRowsExpanded={toggleAllRowsExpanded} exportClients={handleExportClients} />
                )} >
                  <Button.Raised className="btn-other-actions" text="Други действия" />
                </Popover>
                {isDealer
                  ? <Button.Raised onClick={() => history.push('/clients/create?clientType=Консуматор')} className="btn-add-client" text="Добави клиент" />
                  : <Popover className="header-settings" popover={({ close }) => <div className="popup-clients-options-container">
                    <div className="row">
                      <p>Изберете действие</p>
                      <Button.Icon name="plus" onClick={close} />
                    </div>
                    <p onClick={() => history.push('/clients/create?clientType=Консуматор')}>Консуматор</p>
                    <p onClick={() => history.push('/clients/create?clientType=Производител')}>Производител</p>
                    <p onClick={() => history.push('/clients/create?clientType=Смесен')}>Смесен</p>
                  </div>} >
                    <Button.Raised className="btn-add-client" text="Добави клиент" />
                  </Popover>
                }
              </>
            )}
          </div>
        </div>
        <div className="screen-clients-content">
          <Shared.ConditionalWrapper
            condition={lockedColumn}
            wrapper={(children) =>
              <ScrollSync>
                <div className="row row-locked">
                  <Shared.Table
                    ref={tableFrozenRef}
                    columns={[lockedColumn]}
                    data={clientsFiltered || []}
                    renderCell={(row, field) => renderCell.clients(row, field, { type: clientsType, fetch })}
                    sortingComponent={sortingComponents}
                    joinRightSide
                    containerStyle={{ position: 'sticky', top: 0, left: 0, backgroundColor: '#fff', zIndex: 1 }}
                    selectedCheckboxes={selectedCheckboxes}
                    outlines={{
                      ...(clients || []).reduce((acc, { _id, objects }) => {
                        return objects.some(({ status, inactiveFrom }) => {
                          return ['documents-received-missing'].includes(status) || (status === 'leaving' && inactiveFrom)
                        }) ? ({ ...acc, [_id]: '#fa4444' }) : acc
                      }, {})
                    }}
                    useSyncScroll
                  />
                  {children}
                </div>
              </ScrollSync>
            }
          >
            <Shared.Table
              ref={tableRef}
              columns={clientsFields?.filter(({ value }) => value !== lockedColumn?.value)}
              data={clientsFiltered || []}
              renderCell={(row, field, options) => renderCell.clients(row, field, {
                type: clientsType,
                fetch,
                toggleRowExpanded: tableRef.current.toggleRowExpanded,
                toggleFrozenRowExpanded: tableFrozenRef?.current?.toggleRowExpanded,
                isExpanded: options?.isExpanded,
              })}
              maxExpandHeight={550}
              useCheckboxes={!!showCheckboxes}
              sortingComponent={sortingComponents}
              handlePagination={() => hasNextPage && fetch()}
              joinLeftSide={!!lockedColumn}
              outlines={(clients || []).reduce((acc, { _id, objects, isDetailsConfirmed }) => {
                return objects.some(({ status, inactiveFrom }) => {
                  return ['documents-received-missing'].includes(status) || (status === 'leaving' && inactiveFrom)
                }) ? ({ ...acc, [_id]: '#fa4444' })
                  : clientsType === 'register' && !isDetailsConfirmed
                    ? ({ ...acc, [_id]: '#ffb612' })
                    : acc
              }, {})}
              useSyncScroll={!!lockedColumn}
              hideSelectAll={['consumptionAnalysis'].includes(showCheckboxes?.action)}
              allowSingle={['sendDocumentsToERP', 'forRegistration', 'addDocuments', 'terminateRegister', 'terminateObjects', 'activateObjects'].includes(showCheckboxes?.action)}
              disabledCheckboxes={
                ['sendDocumentsToERP'].includes(showCheckboxes.action) ? clients?.filter(({ objects }) => objects?.every(({ status }) => !['registration', 'preregistration', 'transfer'].includes(status))).map(({ _id }) => _id)
                  : ['terminateRegister', 'terminateObjects', 'activateObjects'].includes(showCheckboxes.action) ? clients?.filter(({ objects }) => objects.every(({ confirmedOn }) => confirmedOn)).map(({ _id }) => _id)
                    : ['depositsLeaving'].includes(showCheckboxes.action) ? clients.filter(({ invoices }) => invoices?.filter(({ type, deletedAt }) => type === 'leaving' && !deletedAt).length).map(({ _id }) => _id)
                      : ['consumptionAnalysis'].includes(showCheckboxes.action) ? clients.filter(({ price: { marketIsBase } }) => !selectedCheckboxes?.length ? false : marketIsBase !== selectedCheckboxes.map((selected) => clients.find(({ _id }) => selected === _id)?.price?.marketIsBase).includes(true)).map(({ _id }) => _id)
                        : []
              }
              selectedCheckboxes={selectedCheckboxes}
              onCheckboxChange={(value) => dispatch(setClientsFields({ selectedCheckboxes: value }))}
              onCheckboxClick={(client) => {
                if (['forRegistration', 'sendDocumentsToERP', 'terminateRegister', 'terminateObjects', 'activateObjects'].includes(showCheckboxes.action)) {
                  tableRef.current.toggleAllRowsExpanded(false)
                  tableRef.current.setRowExpanded(client._id, true)
                  const payload = selectedCheckboxes.includes(client?._id) ? null : client?._id
                  if (payload) {
                    dispatch(
                      setObjectsFields({
                        showCheckboxes: { action: showCheckboxes.action },
                        selectedCheckboxes: ['forRegistration', 'sendDocumentsToERP', 'terminateRegister'].includes(showCheckboxes.action)
                          ? client?.objects?.filter(({ _id }) => !disabledObjects(client?.objects, showCheckboxes.action).includes(_id)).map(({ _id }) => _id)
                          : []
                      })
                    )
                  } else {
                    if (['forRegistration', 'sendDocumentsToERP', 'terminateRegister'].includes(showCheckboxes.action)) dispatch(setObjectsFields({ selectedCheckboxes: [] }))
                    else {
                      tableRef.current.setRowExpanded(client._id, false)
                      dispatch(setObjectsFields({ showCheckboxes: false, selectedCheckboxes: [] }))
                    }
                  }
                }
              }}
            >
              {ObjectsTable}
            </Shared.Table>
          </Shared.ConditionalWrapper >
        </div>
        <div className="screen-clients-footer row">
          <div className="screen-clients-inner-footer row">
            <p className="row">
              Общо клиенти: <span> {clientsCount ?? <span className="inline-loader"></span>}</span>
            </p>
            {showCheckboxes && <p className="row">
              Избрани: <span> {selectedCheckboxes.length}</span>
            </p>}
          </div>
        </div>
      </div >
    </div >
  )
}

export default Clients
