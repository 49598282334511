import React, { useState, useEffect, useMemo } from "react"
import Popup from 'reactjs-popup';
import { CKEditor } from 'ckeditor4-react';
import moment from "moment"
import { isEmpty, pick } from "lodash"
import { Inputs } from "../"
import { emit, useQuery } from '../helpers'
import "./styles.scss"


const TaskForm = ({ socket, task, setTask, assignees: allAssignees }) => {
    const { _id, category } = useQuery()
    const [data, setData] = useState({
        priority: 'Нисък',
        category,
        dateStart: moment().startOf('day').toDate()
    })
    const handleChange = (payload) => setData((data) => ({ ...data, ...payload }))
    useEffect(() => {
        if (!isEmpty(task)) setData({
            ...pick(task, ['title', 'description', 'category', 'dateStart', 'dateEnd', 'priority']),
            assignedTo: task?.assignedTo?.map(({ _id }) => _id)
        })
    }, [task])

    const [showRequired, setShowRequired] = useState(false)

    const handleButton = () => {
        if (['title', 'dateStart', 'dateEnd'].some((f) => !data?.[f])) {
            setShowRequired(true)
            return
        } else if (_id) emit(socket, { type: 'tasks/updateOne', payload: { taskId: _id, updatedFields: data } })
        else emit(socket, { type: 'tasks/create', payload: data })
    }

    const [assigneesQuery, setAssigneesQuery] = useState("")
    const assignees = useMemo(() => allAssignees?.filter(({ fullName }) => fullName?.toLowerCase()?.includes(assigneesQuery?.toLowerCase())), [allAssignees, assigneesQuery])



    return <div className="task-management-form-container">
        <div className="task-management-form-header card row">
            <h2>{_id ? 'Редакция на' : 'Нова'} задача</h2>
            <div className="raised-button-container" onClick={handleButton}>{_id ? 'Запази' : 'Създай задача'}</div>
        </div>
        <div className="task-management-form-content col">
            <div className="task-management-form-general card">
                <h3>Основна информация</h3>
                <div className="row">
                    <div className="col col-full">
                        <span>Заглавие</span>
                        <input
                            className={`input-text-container ${showRequired && !data.title && 'required'}`}
                            value={data?.title || ""}
                            onChange={({ target: { value } }) => handleChange({ title: value })}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <span>Начална дата</span>
                        <Inputs.Datepicker
                            className={showRequired && !data.dateStart ? 'required' : ''}
                            selected={data.dateStart ? moment(data.dateStart).toDate() : null}
                            onChange={(dateStart) => handleChange({ dateStart: moment(dateStart).startOf('day').toDate() })}
                            maxDate={data?.dateEnd} />
                    </div>
                    <div className="col">
                        <span>Kрайна дата</span>
                        <Inputs.Datepicker
                            className={showRequired && !data.dateEnd ? 'required' : ''}
                            selected={data.dateEnd ? moment(data.dateEnd).toDate() : null}
                            onChange={(dateEnd) => handleChange({ dateEnd: moment(dateEnd).endOf('day').toDate() })}
                            minDate={data?.dateStart} />
                    </div>
                    <div className="col col-medium">
                        <span>Приоритет</span>
                        <Inputs.RadioButtons
                            buttons={[
                                { value: 'Висок', label: 'Висок', color: '#FA4444' },
                                { value: 'Среден', label: 'Среден', color: '#FFB612' },
                                { value: 'Нисък', label: 'Нисък', color: '#00CB5E' },
                            ]}
                            value={data?.priority} onClick={(priority) => handleChange({ priority })}
                        />
                    </div>
                    <div className="col col-big">
                        <span>Изпълнител</span>
                        <div className="row">
                            <Popup
                                className="task-management-popup-assignees-outer-container"
                                trigger={<div className="tasks-icon tasks-icon-plus"></div>}
                                position="right center"
                                nested
                            >
                                {close => <div className="task-management-popup-assignees-container">
                                    <div className="task-management-popup-assignees-header row">
                                        <h2>Служители</h2>
                                        <div className="tasks-icon tasks-icon-close" onClick={close} />
                                    </div>
                                    <div className="task-management-popup-assignees-content">
                                        <input
                                            className='input-text-container'
                                            placeholder="Търси"
                                            value={assigneesQuery || ""}
                                            onChange={({ target: { value } }) => setAssigneesQuery(value)}
                                        />
                                        <div className="scroll-container">
                                            <Inputs.Checkboxes
                                                buttons={assignees
                                                    ?.map(({ _id, fullName, coverPhoto }) => ({
                                                        icon: coverPhoto,
                                                        value: _id,
                                                        label: fullName,
                                                    }))}
                                                value={data.assignedTo}
                                                onClick={(assignedTo) => handleChange({ assignedTo })}
                                                col
                                                includeCoverPhotos
                                            />
                                        </div>
                                    </div>
                                </div>}
                            </Popup>
                            <div className="assignees-container row">
                                {assignees?.filter(({ _id }) => data?.assignedTo?.includes(_id))?.map(({ coverPhoto, fullName }, i) => (
                                    <div key={`assignee-${i}`} className="single-assignee row">
                                        <div
                                            className="profile-image tasks-icon"
                                            style={{ backgroundImage: `url(${coverPhoto || require('../assets/default-user-avatar.jpg')})` }}
                                        />
                                        <p className="assignee-fullName">{fullName}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="task-management-form-description card">
                <h3>Кратко описание</h3>
                {(!_id || data.category) && <CKEditor initData={data?.description} config={editorConfig} onChange={(e) => handleChange({ description: e.editor.getData() })} />}
            </div>
        </div>
    </div>
}

export const editorConfig = {
    toolbar: [
        { name: 'corrections', items: ['Undo', 'Redo'] },
        { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike'] },
        { name: 'styles', items: ['Format'] },
        { name: 'paragraph', items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'NumberedList', 'BulletedList'] },
        { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteText'] },
        { name: 'tools', items: ['Maximize'] },
    ],
    enterMode: 'ENTER_BR',
    plugins: [
        'basicstyles',
        'clipboard',
        'emoji',
        'enterkey',
        'entities',
        'floatingspace',
        'indentlist',
        'justify',
        'link',
        'list',
        'toolbar',
        'undo',
        'wysiwygarea',
        "maximize",
        'format',
        'resize',
        "autogrow"
    ],
}

export default TaskForm