import { producersTypes } from '../actions'
const initialState = {
    showCheckboxes: false,
    selectedCheckboxes: [],
}

const producers = (state = initialState, { type, payload }) => {
    switch (type) {
        case producersTypes.SET_FIELDS:
            return { ...state, ...payload }
        case producersTypes.PRODUCERS_REFERENCES_BROWSE_SUCCESS:
        case producersTypes.PRODUCERS_INVOICES_BROWSE_SUCCESS:
            const newData = payload?.page === 1 ? payload?.docs : [...(state?.docs || []), ...(payload?.docs || [])]
            return { ...state, ...payload, docs: newData, }
        default:
            return state
    }
}

export default producers