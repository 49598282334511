import React from "react"
import { isString, isArray } from 'lodash'
import "./styles.scss"

const Checkboxes = ({ className = '', buttons, value, onClick, disabled, col, reversed, includeCoverPhotos }) => {
    return <div className={`task-managemenat-inputs-checkboxes-container ${className} ${col ? 'col' : 'row'}`}>
        {buttons?.map((button, i) => {
            const selected = value?.includes(button?.value || button)
            return (
                <div
                    key={`single-checkbox-${i}`}
                    className={`single-checkbox ${selected && 'selected'} ${reversed && 'reversed'}`}
                    onClick={() => {
                        if (disabled || !onClick) return
                        if (value && !isArray(value)) {
                            onClick()
                            return
                        }
                        if (selected) onClick(value.filter((v) => v !== (button?.value || button)))
                        else onClick([...(value || []), button?.value || button])
                    }}
                >
                    <div className="checkbox-outer" style={button.color ? { backgroundColor: selected ? button.color : 'white', borderColor: button.color } : {}}>
                        <div className={`checkbox-inner`} />
                    </div>
                    <div className="label-container row">
                        {includeCoverPhotos && (
                            <div
                                className="button-image tasks-icon"
                                style={{ backgroundImage: `url(${button?.icon || require('../../assets/default-user-avatar.jpg')})` }}
                            />
                        )}
                        <p>{isString(button) ? button : button?.label || button?.value}</p>
                    </div>
                </div>
            )
        })}
    </div>
}

export default Checkboxes