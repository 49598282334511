import React, { useRef } from 'react'
import { Button } from '@makedonski/admin-ui'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setModal } from 'actions'
import { useCollapsable, useQuery } from 'hooks'
import './styles.scss'
import moment from 'moment'

const Group = ({ option, hide, onClick }) => {
  const { label, options } = option || {}

  const collapsableRef = useRef()
  const [isExpanded, setExpanded] = useCollapsable(collapsableRef)

  return (
    <>
      <p onClick={() => setExpanded(!isExpanded)}>{label}</p>
      <div className='collapsable-rows' ref={collapsableRef}>
        {options?.map(({ label, value }, i) => <p
          key={`option-${i}`} onClick={() => {
            onClick(value)
            hide()
          }}
        >{label}
        </p>)}
      </div>
    </>
  )
}

const IntermediateInvoicesOptions = ({ hide }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { startDate } = useQuery()
  return (
    <div className='popup-intermediate-invoices-options-container'>
      <div className='popup-intermediate-invoices-options-header row'>
        <h4>Изберете действие</h4>
        <Button.Icon name='plus' onClick={hide} />
      </div>
      <div className='popup-intermediate-invoices-options-content'>
        {
          [{
            label: 'Авансова фактура на 10 дни',
            value: 'advance-10',
            options: [
              { label: 'Първа фактура', value: '1' },
              { label: 'Втора фактура', value: '2' },
              { label: 'Трета фактура', value: '3' }
            ]
          },
          {
            label: 'Авансова фактура на 15 дни',
            value: 'advance-15',
            options: [
              { label: 'Първа фактура', value: '1' },
              { label: 'Втора фактура', value: '2' }
            ]
          },
          {
            label: 'Авансова фактура на 30 дни',
            value: 'advance-30',
            options: [
              { label: 'Първа фактура', value: '1' }

            ]
          },
          {
            label: 'Междинна фактура на 10 дни',
            value: 'intermediate-10',
            options: [
              { label: 'Първа фактура', value: '1' },
              { label: 'Втора фактура', value: '2' },
              { label: 'Трета фактура', value: '3' }
            ]
          },
          {
            label: 'Междинна фактура на 15 дни',
            value: 'intermediate-15',
            options: [
              { label: 'Първа фактура', value: '1' }
              // { label: 'Втора фактура', value: '2' },
            ]
          }
          ].map(option => <Group
            key={`group-${option.value}`}
            option={option}
            hide={hide}
            onClick={(consecutiveNumber) => dispatch(setModal({
              isOpen: true,
              type: 'intermediateInvoicesPrice',
              props: {
                title: 'Настройка единична цена',
                data: { date: startDate || moment().format('DD.MM.YYYY') },
                validate: ({ text }) => text?.length,
                onClick: ({ text: price, date: month }) => {
                  const url = new URLSearchParams()
                  url.append('type', option.value)
                  url.append('consecutiveNumber', consecutiveNumber)
                  url.append('price', price)
                  url.append('monthYear', moment(month, 'DD.MM.YYYY').format('MM/YYYY'))
                  url.append('status', 'active')
                  history.push('/intermediate-invoices/generate?' + url.toString())
                }
              }
            }))}
          />)
        }
      </div>

    </div>
  )
}

export default IntermediateInvoicesOptions
