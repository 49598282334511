import React from 'react'
import { Button } from '@makedonski/admin-ui'
import './styles.scss'

const Documents = ({}) => {
  const handleUpload = () => {}

  return (
    <div className="employee-documents-container">
      <div className="employee-documents-header">
        <h2>Документи</h2>
      </div>
      <div className="employee-documents-contract row">
        <p>Договор</p>
        <Button.UploadButton
          text="Добави"
          multiple
          onChange={({ target: { files } }) => handleUpload(files)}
        />
      </div>
      <div className="employee-documents-agrrement row">
        <p>Допълнително споразумение</p>
        <Button.UploadButton
          text="Добави"
          multiple
          onChange={({ target: { files } }) => handleUpload(files)}
        />
      </div>
      <div className="employee-documents-others row">
        <p>Други</p>
        <Button.UploadButton
          text="Добави"
          multiple
          onChange={({ target: { files } }) => handleUpload(files)}
        />
      </div>
    </div>
  )
}

export default Documents
