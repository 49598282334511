import React from 'react'
import { ScrollSync } from 'react-scroll-sync'
import moment from 'moment'
import { Shared } from '../../'
import { useQuery } from '../../../hooks'
import { times } from 'lodash'
import { referencesReportsTypes, referencesReportsLength } from 'config/constants'
import './styles.scss'

//prettier-ignore
const data = [{ name: 'Данаил' }, { name: 'Данаил' }, { name: 'Данаил' }, { name: 'Данаил' }, { name: 'Данаил' }, { name: 'Данаил' }, { name: 'Данаил' }, { name: 'Данаил' }, { name: 'Данаил' }, { name: 'Данаил' }, { name: 'Данаил' }, { name: 'Данаил' }, { name: 'Данаил' }, { name: 'Данаил' }]
//prettier-ignore
const fields = [{ value: 'name', label: 'Име', size: 200 }, { value: 'lastName', label: 'Фамилия', size: 200, background: 'lightgray' }]

const Reports = ({ }) => {
  const { length, startDate, endDate } = useQuery({ type: referencesReportsTypes, length: referencesReportsLength })
  const months =
    length === 'month' && startDate && endDate
      ? Math.round(moment.duration(moment(endDate).endOf('month').diff(moment(startDate).startOf('month'))).asMonths())
      : 1

  return (
    <div className="references-reports-container">
      <ScrollSync>
        <div className="row">
          {times(months).map((month, i) => (
            <Shared.Table
              key={`table-${i}`}
              columns={fields}
              data={data}
              renderCell={() => "-"}
              containerStyle={{ width: '100%' }}
              fixedWidth
              joinRightSide={!!months && i !== months - 1}
              joinLeftSide={!!months && i !== 0}
            />
          ))}
        </div>
      </ScrollSync>
    </div>
  )
}

export default Reports
