import { ofType, ActionsObservable } from 'redux-observable'
import { switchMap, mergeMap, catchError } from 'rxjs/operators'
import { Api, Alerts } from '@makedonski/socourt-utilities'
import { groupsTypes, dataTypes, stopLoading } from '../actions'
import { URL } from '../config/settings'

export const getGroups = (action$) => action$.pipe(
  ofType(groupsTypes.GET_GROUPS),
  switchMap(() =>
    Api.get(`${URL}/groups`).pipe(
      switchMap(({ response }) => ActionsObservable.create((obs) => {
        obs.next({ type: dataTypes.GET_DATA_SUCCESS, payload: { type: 'groups', data: response.groups } })
        obs.next(stopLoading())
        obs.complete()
      })),
      catchError((err) => ActionsObservable.create((obs) => {
        Alerts.error('Error!', err?.response?.error?.message || '')
        obs.next(stopLoading())
        obs.complete()
      }))
    )
  )
)

export const getGroup = (action$) => action$.pipe(
  ofType(groupsTypes.GET_GROUP),
  switchMap(({ payload: { data, onSuccess } }) =>
    Api.get(`${URL}/groups/${data}`).pipe(
      switchMap(({ response }) => ActionsObservable.create((obs) => {
        if (onSuccess) onSuccess(response.group)
        obs.next(stopLoading())
        obs.complete()
      })),
      catchError((err) => ActionsObservable.create((obs) => {
        Alerts.error('Error!', err?.response?.error?.message || '')
        obs.next(stopLoading())
        obs.complete()
      }))
    )
  )
)

export const createGroup = (action$) => action$.pipe(
  ofType(groupsTypes.CREATE_GROUP),
  switchMap(({ payload, onSuccess }) =>
    Api.post(`${URL}/groups`, JSON.stringify(payload)).pipe(
      switchMap(({ response }) => ActionsObservable.create((obs) => {
        obs.next({ type: groupsTypes.GET_GROUPS })
        if (onSuccess) onSuccess(response.group)
        obs.complete()
      })),
      catchError((err) => ActionsObservable.create((obs) => {
        Alerts.error('Error!', err?.response?.error?.message || '')
        obs.next(stopLoading())
        obs.complete()
      }))
    )
  )
)

export const updateGroup = (action$) => action$.pipe(
  ofType(groupsTypes.UPDATE_GROUP),
  mergeMap(({ payload, onSuccess }) =>
    Api.put(`${URL}/groups`, JSON.stringify(payload)).pipe(
      switchMap(({ response }) => ActionsObservable.create((obs) => {
        obs.next({ type: groupsTypes.GET_GROUPS })
        if (onSuccess) onSuccess(response.group)
        obs.complete()
      })),
      catchError((err) => ActionsObservable.create((obs) => {
        Alerts.error('Error!', err?.response?.error?.message || '')
        obs.next(stopLoading())
        obs.complete()
      }))
    )
  )
)

export const deleteGroup = (action$) => action$.pipe(
  ofType(groupsTypes.DELETE_GROUP),
  switchMap(({ payload, onSuccess }) =>
    Api.delete(`${URL}/groups`, JSON.stringify(payload)).pipe(
      switchMap(({ response }) => ActionsObservable.create((obs) => {
        obs.next({ type: groupsTypes.GET_GROUPS })
        if (onSuccess) onSuccess(response.group)
        obs.complete()
      })),
      catchError((err) => ActionsObservable.create((obs) => {
        Alerts.error('Error!', err?.response?.error?.message || '')
        obs.next(stopLoading())
        obs.complete()
      }))
    )
  )
)
