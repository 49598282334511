import { default as Activity } from './Activity'
import { SingleNotification } from './Activity'
import { default as Shortcuts } from './Shortcuts'
import { default as Comments } from './Comments'
import { default as Checklist } from './Checklist'
import { default as Files } from './Files'
import { default as ClientHistory } from './ClientHistory'
import { default as ClientComments } from './ClientComments'
import { default as FileSystem } from './FileSystem'
import { default as ClientFileSystem } from './ClientFileSystem'
import { default as ClientDocumentPreview } from './ClientDocumentPreview'
import { default as Invoice } from './Invoice'
import { default as ClientGroups } from './ClientGroups'
import { default as UsersGroups } from './UsersGroups'

const Card = {
  Activity,
  SingleNotification,
  Shortcuts,
  Comments,
  Checklist,
  Files,
  ClientHistory,
  ClientComments,
  FileSystem,
  ClientFileSystem,
  ClientDocumentPreview,
  Invoice,
  ClientGroups,
  UsersGroups
}

export default Card
