import { ofType, ActionsObservable } from 'redux-observable'
import { switchMap, catchError, debounceTime } from 'rxjs/operators'
import { Api, Alerts } from '@makedonski/socourt-utilities'
import { nomenclaturesTypes, stopLoading } from 'actions'
import { URL } from 'config/settings'

export const createNomenclature = (action$) =>
  action$.pipe(
    ofType(nomenclaturesTypes.CREATE_NOMENCLATURE),
    debounceTime(100),
    switchMap(({ payload, onSuccess }) =>
      Api.post(`${URL}/nomenclatures`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response.payload)
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const getNomenclatures = (action$) =>
  action$.pipe(
    ofType(nomenclaturesTypes.GET_NOMENCLATURES),
    switchMap(({ onSuccess }) =>
      Api.get(`${URL}/nomenclatures`).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response.nomenclatures.details)
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )
