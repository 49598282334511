import React from 'react'
import { Button } from '@makedonski/admin-ui'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setOverlay } from '../../../../actions'
import './styles.scss'

const map = {
  offer: 'Оферти',
  contract: 'Договро',
  analysis: 'Анализ',
}

const Document = ({ client, field }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const documents =
    field === 'offer'
      ? client.offers
      : field === 'contract' && client.generatedContracts
      ? [client.generatedContracts]
      : []

  return (
    <div className="client-cells-document-container row">
      {documents?.length ? (
        <>
          Да
          <div
            className="icon icon-arrow-right-2"
            onClick={() =>
              dispatch(
                setOverlay({
                  isOpen: true,
                  type: 'document',
                  props: { title: map[field], type: `${field}s`, documents },
                })
              )
            }
          />
        </>
      ) : (
        <Button.Raised
          text="Създай"
          onClick={() => history.push(`/clients/create?type=potential&docs=${field}&client=${client._id}`)}
        />
      )}
    </div>
  )
}

export default Document
