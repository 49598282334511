import React, { useState } from 'react'
import { Button, Input } from '@makedonski/admin-ui'
import { useDispatch } from 'react-redux'
import { setModal, createGroup } from '../../../actions'
import './styles.scss'

const CreateClientsGroup = ({ clients }) => {
  const dispatch = useDispatch()
  const [data, setData] = useState()

  return (
    <div className="modal-create-clients-group-container">
      <div className="modal-create-clients-group-header row">
        <h2>Нова група свързани клиенти</h2>
        <Button.Icon
          name="plus"
          size="26px"
          className="btn-close"
          onClick={() => dispatch(setModal({ isOpen: false }))}
        />
      </div>
      <div className="modal-create-clients-group-content">
        <span>Име на група</span>
        <Input.Text value={data || ''} onChange={({ target: { value } }) => setData(value)} />
      </div>
      <div className="modal-create-clients-group-footer">
        <Button.Raised
          text="Запази"
          disabled={!data}
          onClick={() => {
            dispatch(createGroup({ payload: { name: data, clients } }))
            dispatch(setModal({ isOpen: false }))
          }}
        />
      </div>
    </div>
  )
}

export default CreateClientsGroup
